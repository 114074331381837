import React from "react"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"

class ProgressBar extends React.Component {

  render() {
    var data = this.props.data;
    if (!this.deprMess) {
      this.deprMess = true
      MCHistory.log(MCHistory.T_WARNING, 'Using linked progress bar is deprecated. Use "steps" widget instead.', this.props.field.flow.debug())
    }
    var activeIndex = 0;
    for (var i=0; i<data.step.length; i++) {
      if (Array.isArray(data.step[i].action) && data.active) {
        if (data.step[i].action.indexOf(data.active) > -1) {
          activeIndex = i;
          break;
        }
      }
    }
    var steps = [];
    for (var i=0; i<data.step.length; i++) {
      var stepClass = 'step';
      if (Array.isArray(data.step[i].action) && data.active) {
        if (activeIndex == i) {
          stepClass += ' active';
        }
      }
      if (i < activeIndex) {
        stepClass += ' completed';
      }
      steps.push(
        <div className={stepClass} key={'step' + i}>
          <div className="content">
            <div className="title">{data.step[i].title}</div>
          </div>
        </div>
      );
    }
    return (
      <div className={MC.classes("ui", MC.getFieldWideClassFromInt(steps.length) , "ordered steps")}>
        {steps}
      </div>
    );
  }

}

export {ProgressBar};