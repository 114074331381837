import React from "react"

import {MC} from './MC.js'
import {MCHistory} from "./MCHistory.js"
import {Modal} from "./Modal.jsx"
import {ReactFlow} from "./ReactFlow.jsx"

class Dialog extends React.Component {

  state = {flowName: null, input: null, start: false}
  flowRef = React.createRef()

  componentDidMount() {
    this.checkStart()
  }

  componentDidUpdate() {
    this.checkStart()
  }

  checkStart() {
    const props = this.props
    if (props.dialog && MC.isPlainObject(props.dialog) && props.dialog.start) {
      let dialog = props.dialog
      dialog.start = false
      if (!MC.isNull(props.dialog.flowName)) {
        let flowInstanceId = MC.generateId()
        this.setState({flowName: props.dialog.flowName, input: props.dialog.input, size: props.dialog.size, asContext: props.dialog.asContext, start: true, flowInstanceId: flowInstanceId})
        MCHistory.history(this.props.dialog.parentFlow, this.props.dialog.parentFlow.getActionByCode(dialog.actionCode), 'DIALOG CALL', {'Input': dialog.input, 'Trace': dialog.trace, target: {...dialog.action.interface, ...{id: dialog.flowName}}, executionId: flowInstanceId}, {start: dialog.lStartDate, end: Date.now(), duration: performance.now() - dialog.lStart})  
      } else {
        this.setState({flowName: null, input: null, start: false})
      }
    } else if (this.state.start) {
      this.setState({start: false})
    }
  }

  close = () => {
    if (this.state.flowName) {
      if (this.props.dialog.action.dialogCloseAction) {
        let child = this.flowRef.current
        child.flow.handleSubmit(child.state.formData, this.props.dialog.action.dialogCloseAction)
      } else {
        this.setState({flowName: null, input: null})
        this.props.dialog.parentFlow.closeDialog()
      }
    }
  }

  onEnd = (output, message, options) => {
    if (this.state.flowName) {
      this.setState({flowName: null, input: null})
      document.querySelector('body').classList.remove('showing-modal')
      this.props.dialog.parentFlow.endDialog(output, message, false, options.endAction)
    }
  }

  render() {
    if (this.state.flowName && this.props.dialog) {
      let closeIcon = !MC.isNull(this.props.dialog.closeIcon) ? this.props.dialog.closeIcon : this.state.asContext ? false : true
      let flow = this.props.dialog.parentFlow
      let flowHtml = <ReactFlow configuration={flow.confPath} flowName={this.state.flowName} env={flow.env} input={this.state.input} start={this.state.start} onEndFunction={this.onEnd} embedded={true} 
        options={flow.reactFlow().props.options} parent={flow.reactFlow()} configurationObject={flow.context.data.env.cfg} debug={flow.wantedLogLevel} console={false} autoScrollUp={false}
        mconf={flow.reactFlow().props.mconf} instanceId={this.state.flowInstanceId} ref={this.flowRef}/>
      return <Modal show={true} onClose={this.close} cssClass={MC.classes(this.state.size, this.props.dialog.cssClass)} closeIcon={closeIcon} asContext={this.state.asContext} target={this.props.dialog.target}>{flowHtml}</Modal>
    } else {
      return null
    }
  }

}

export {Dialog}