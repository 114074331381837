import React from "react"
import tippy from "tippy.js"

import {MC} from "../client/MC.js"

import {GetText} from "./GetText.js"

class Button extends React.Component {

  rooRef = React.createRef()

  componentDidMount() {
    tippy(this.rooRef.current, {arrow: true, delay: 400, animation: 'scale', theme: 'light-border', allowHTML: true})
  }

  render() {
    let cls = MC.classes("ui", this.props.className, {"icon": this.props.icon}, "button", {"disabled": this.props.disabled, "active": this.props.active})
    let icon = this.props.icon ? <i className={this.props.icon + " icon"}/> : null
    return <button className={cls} onClick={this.props.onClick} data-tippy-content={this.props.help} ref={this.rooRef}>{icon}{this.props.title}</button>
  }

}

class SaveButton extends React.Component {

  handleSave = () => {
    this.props.onSave()
  }

  render() {
    var cls = MC.classes("ui button primary", {"disabled": !this.props.isSavePossible, "loading": this.props.inProcess})
    return <button className={cls} onClick={this.handleSave}>{GetText.t("Save")}</button>
  }

}

class ResolutionButtons extends React.Component {

  refRoot = React.createRef()
  state = {menuActive: false}

  handleClick = (event) => {
    let resolution = event.currentTarget.getAttribute("data")
    this.props.onChangeResolution(resolution)
  }

  componentDidMount() {
    tippy(this.refRoot.current.querySelectorAll("button"), {arrow: true, delay: 400, animation: 'scale', theme: 'light-border', allowHTML: true})
    document.addEventListener('click', this.handleClickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleClickOutside = (e) => {
    const node = this.refRoot.current
    if (node && node.contains(e.target)) {
      return
    }
    if (this.state.menuActive) {
      this.setState({menuActive: false})
    }
  }

  toggleMenu = () => {
    this.setState({menuActive: !this.state.menuActive})
  }  

  render() {
    var self = this
    var shortcuts = {"x-small": "XS", small: "S", medium: "M", large: "L"};
    var resolutions = ["x-small", "small", "medium", "large"].filter(function(resolution) {
      return self.props.autolayout.indexOf(resolution) == -1
    })
    var items = resolutions.map(function(e) {
      var text = shortcuts[e]
      var help = e + " layout"
      var cls = "ui button"
      if (self.props.resolution == e) {
        cls += " active"
      }
      if (self.props.definedResolutions.indexOf(e) != -1) {
        text= <u>{text}</u>
        help += " (" + GetText.t("with grid") + ")"
      }
      return <button className={cls} key={e} data={e} onClick={self.handleClick} data-tippy-content={help}>{text}</button>
    })
    let deleteGridClass = MC.classes("item", {"disabled": !this.props.isDeleteResolutionEnabled})
    let createGridClass = MC.classes("item", {"disabled": self.props.definedResolutions.indexOf(self.props.resolution) != -1})
    let menuCls = MC.classes("menu", this.state.menuActive && "visible transition")
    return <div className="ui icon buttons" ref={this.refRoot}>
              {items}
              <div className="ui floating dropdown icon button" onClick={this.toggleMenu}>
                <i className="dropdown icon" />
                <div className={menuCls}>
                  <div className={createGridClass} data-value="create" onClick={this.props.onCreateGrid}>
                    <i className="file icon"/>
                    {GetText.t("Create grid")}
                  </div>
                  <div className={deleteGridClass} data-value="delete" onClick={this.props.onDeleteGrid}>
                    <i className="delete icon red"/>
                    {GetText.t("Delete grid")}
                  </div>
                </div>
              </div>
            </div>
  }

}


class Menu extends React.Component {

  state = {search: "", searchIds: []}
  refRoot = React.createRef()
  searchMenuRef = React.createRef()

  handleSearchChange = (event) => {
    this.setSearchValue(event.target.value)
  }

  setSearchValue = (value, clearResults) => {
    if (value == "") {
      this.props.setSelectedFields([])
      this.setState({search: value, searchIds: []})
      document.removeEventListener('click', this.handleClickOutside, false)
    } else {
      let form = this.props.form
      let fields = form.findFieldsHelp(value, form.fields, true)
      let fieldsRbsId = fields.map(function(field) {return field.rbsid})
      let fieldsId = fields.map(function(field) {return field.id})
      this.props.setSelectedFields(fieldsRbsId)
      this.setState({search: value, searchIds: clearResults ? [] : fieldsId})
      if (!clearResults) {
        document.addEventListener('click', this.handleClickOutside, false)
      } else {
        document.removeEventListener('click', this.handleClickOutside, false)
      }
    }
  }

  componentDidMount() {
    var helpPopup =  `<div style="min-width: 450px; width: 450px; padding: 10px;">
      <div class="ui header">${GetText.t('Controls')}</div>
      <table class="ui very basic table">
        <tbody>
          <tr>
            <td>
              <div class="ui label">Shift+S</div>
            </td>
            <td>${GetText.t('Save')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+X</div>
            </td>
            <td>${GetText.t('Cut')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+C</div>
            </td>
            <td>${GetText.t('Copy')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+V</div>
            </td>
            <td>${GetText.t('Paste')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+Z</div>
            </td>
            <td>${GetText.t('Undo')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+Y</div>
            </td>
            <td>${GetText.t('Redo')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+L</div>
            </td>
            <td>${GetText.t('Toggle end of line')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+A</div>
            </td>
            <td>${GetText.t('Select All')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Backspace</div>
              <div class="ui label">Delete</div>
            </td>
            <td>${GetText.t('Delete')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Ctrl+Click</div>
            </td>
            <td>${GetText.t('Select field')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">${GetText.t('Context menu')}</div>
            </td>
            <td>${GetText.t('Show palette')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">Shift+Drag</div>
            </td>
            <td>${GetText.t('Change offset')}</td>
          </tr>
          <tr>
            <td>
              <div class="ui label">${GetText.t('Double click')}</div>
            </td>
            <td>${GetText.t('Change the name or title')}</td>
          </tr>
        </tbody>
      </table>
    </div>`
    tippy(this.refRoot.current.querySelector(".help.icon"), {content: helpPopup, interactive: true, maxWidth: 470, arrow: true, delay: 400, animation: 'scale', theme: 'light-border', allowHTML: true})
  }

  handleSearchClick = () => {
    this.setSearchValue(this.state.search)
  }

  handleClickOutside = (e) => {
    const node = this.searchMenuRef.current
    if (node && node.contains(e.target)) {
      return
    }
    if (this.state.searchIds.length > 0) {
      e.stopPropagation()
      this.setSearchValue(this.state.search, true)
    }
  }

  handleSearchSelect = (value, e) => {
    this.setSearchValue(value, true)
    
  }

  render() {
    var resolution = this.props.resolution;
    var definedResolutions = this.props.definedResolutions;
    var isDeleteResolutionEnabled = (definedResolutions.indexOf(resolution) != -1) && (definedResolutions.length > 1);
    var undoRedoButtons = <div className="ui icon buttons">
                            <Button icon="undo" onClick={this.props.onUndo} disabled={!this.props.isUndoStack} help={GetText.t('Undo')}/>
                            <Button icon="horizontally flipped undo" onClick={this.props.onRedo} disabled={!this.props.isRedoStack} help={GetText.t('Redo')}/>
                          </div>;
    var resolutionButtons = <ResolutionButtons definedResolutions={this.props.definedResolutions}
                                               autolayout={this.props.autolayout}
                                               resolution={this.props.resolution}
                                               isDeleteResolutionEnabled={isDeleteResolutionEnabled}
                                               onChangeResolution={this.props.onChangeResolution}
                                               onDeleteGrid={this.props.onDeleteGrid}
                                               onCreateGrid={this.props.onCreateGrid}/>;
    var saveButton = <SaveButton isSavePossible={this.props.isSavePossible} onSave={this.props.onSave} inProcess={this.props.saveInProcess}/>;
    var miniclientButtons
    let searchResults = null
    if (this.state.searchIds.length > 0) {
      let items = []
      for (let id of this.state.searchIds) {
        items.push(<a className="result" onClick={(e) => { this.setSearchValue(id, true); e.stopPropagation() }} key={id}><div className="result"><div className="title">{id}</div></div></a>)
      }
      searchResults = <div className="results transition visible">{items}</div>
    }
    return  <div className="meta menu top component" ref={this.refRoot}>
              {miniclientButtons}
              <div className="meta menu-item">
                {undoRedoButtons}
              </div>
              <div className="meta menu-item">
                {resolutionButtons}
              </div>
              <div className="meta menu-item">
                <div className="ui icon buttons">
                  <Button icon="eye" active={this.props.ghostMode} onClick={this.props.onTongueGhostMode} help={GetText.t('Toggle display of invisible fields')}/>
                  <Button icon="lab" active={this.props.modelerState.structuralMode} onClick={this.props.tongueStructuralMode} help={GetText.t('Toggle structural view')}/>
                </div>
            </div>
            <div className="meta menu-item">
              <Button icon="browser"  onClick={this.props.showPalette} help={GetText.t('Show palette')}/>
            </div>
            <div className="meta menu-item">
              <div className="ui search" ref={this.searchMenuRef}>
                <div className="ui icon input">
                    <input className="prompt"
                           id="searchInput"
                           type="text"
                           name="formfieldsearch"
                           placeholder={GetText.t("Search...")}
                           onChange={this.handleSearchChange}
                           onClick={this.handleSearchClick}
                           value={this.state.search}
                           autoComplete="off"/>
                    <i className="search icon"/>
                </div>
                {searchResults}
              </div>
            </div>
            <div className="meta menu-item">
              <i className="help icon"></i>
           </div>
              <div className="meta right menu">
                <div className="meta menu-item">
                  {saveButton}
                </div>
              </div>
            </div>;
    }

}

export {Menu}