import React from "react"

class DateTimePickerMonths extends React.Component {

  render() {
    let date = this.props.adjustDateMinMax(this.props.viewDate)
    this.viewDate = date
    let isAllowedPrev = this.props.modelerActive ? true : MC.isValidDay(date.minus({years: 1}).endOf('year'), this.props.min, this.props.max)
    let isAllowedNext = this.props.modelerActive ? true : MC.isValidDay(date.plus({years: 1}).startOf('year'), this.props.min, this.props.max)
    return (
      <div className="rdtMonths">
        <table key="a">
          <thead>
            <tr>
              <th key="prev" className={MC.classes("rdtPrev", !isAllowedPrev && "rdtDisabled")} onClick={isAllowedPrev ? this.props.subtractTime(1, 'years') : null}><span>‹</span></th>
              <th  key="year" className="rdtSwitch" onClick={this.props.showView('years')} colSpan={2} data-value={date.year}>{date.year}</th>
              <th key="next" className={MC.classes("rdtNext", !isAllowedNext && "rdtDisabled")} onClick={isAllowedNext ? this.props.addTime(1, 'years') : null}><span>›</span></th>
            </tr>
          </thead>
        </table>
        <table key="months"><tbody key="b">{this.renderMonths(date)}</tbody></table>
      </div>
    )
  }

  renderMonths(date) {
    let self = this
    let month = date.month
    let year = date.year
    let rows = []
    let i = 1
    let months = []
    let irrelevantDate = 1
    while (i <= 12) {
      let classes = 'rdtMonth'
      let currentMonth = date.set({ year: year, month: i, day: irrelevantDate })
      let noOfDaysInMonth = currentMonth.endOf('month').toFormat('d')
      let daysInMonth = Array.from({ length: noOfDaysInMonth }, function (e, i) {
        return i + 1
      })
      let validDay = daysInMonth.find(function (d) {
        var day = currentMonth.set({day: d})
        return self.props.isValidDay(day)
      })
      let isDisabled = (validDay === undefined)
      if (isDisabled) {
        classes += ' rdtDisabled'
      }
      if (date && i === date.month && year === date.year) {
        classes += ' rdtActive'
      }
      let props = { key: i, 'data-value': i, className: classes }
      if (!isDisabled) {
        props.onClick = (this.props.updateOn === 'months' ? this.updateSelectedMonth : this.props.setDate('month'))
      }
      months.push(this.renderMonth(props, i, date))
      if (months.length === 4) {
        rows.push(<tr key={month + '_' + rows.length}>{months}</tr>)
        months = []
      }
      i++
    }
    return rows
  }

  updateSelectedMonth = (event) => {
    this.props.updateSelectedDate(event, true, this.viewDate)
  }

  renderMonth(props, month, date) {
    let monthStr = date.set({month: month}).toFormat('LLLL')
    var strLength = 3
    var monthStrFixedLength = monthStr.substring(0, strLength)
    return <td {...props}>{capitalize(monthStrFixedLength)}</td>
  }

}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default DateTimePickerMonths