import React from 'react'

import {MC} from '../MC.js'

export default class Dimmer extends React.Component {

  containerRef = React.createRef()

  componentDidMount() {
    this.toggleStyles(this.props.active)
  }

  componentDidUpdate(prevProps) {
    const {active: currentActive} = this.props
    const {active: prevActive} = prevProps
    if (prevActive !== currentActive) this.toggleStyles(currentActive)
  }

  toggleStyles(active) {
    const containerRef = this.containerRef.current
    if (!containerRef || !containerRef.style) return
    if (active) {
      containerRef.style.setProperty('display', 'flex', 'important')
    } else {
      containerRef.style.removeProperty('display')
    }
  }

  render() {
    const classes = MC.classes('ui dimmer', {'active transition visible': this.props.active}, this.props.className)
    return (
      <div className={classes}>
        <div className="content">{this.props.children}</div>
      </div>
    )
  }
}