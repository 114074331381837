import {DateTime} from "luxon"

import {FieldDef} from "../modeler/FieldDef.js"
import {MC} from './MC.js'
import {MCCache} from "./MCCache.js"
import {MCHistory} from "./MCHistory.js"
import {MCBrws} from "./MCBrws.js"
import {Expression} from "./Expression.js"
import {ReactFlow} from "./ReactFlow.jsx"

let Flow = function() {}

Flow.prototype.init = function(reactFlow) {
  this.opStart = performance.now()
  this.opStartDate = Date.now()
  this.instanceId = MC.generateId()
  if (reactFlow) {
    this.reactFlowObj = reactFlow
  }
  this.parentFlow = null
  this.flowName = null
  this.lang = null
  this.flow = null
  this.input = {}
  this.inputMapTrace = null
  this.env = {}
  this.onEndFunction = null
  this.afterRenderForm = null
  this.lazyAction = null
  this.serverSide = false
  this.cache = false
  this.confPath = null
  this.confNsMap = null
  this.context = {data: {}}
  this.wantedLogLevel = null
  this.logLevel = null
  this.logicTimers = {}
  this.lazyActionLogic = null
  return this
}  

Flow.prototype.setParentFlow = function(pFlow) {
  this.parentFlow = pFlow;
  return this;
};

Flow.prototype.setFlowConfiguration = function(vConfiguration, fConFlowName) {
  this.confPath = MC.noStrailingSlash(vConfiguration)
  this.flowName = fConFlowName;
  return this;
};

Flow.prototype.setFlowConfigurationProps = function(conf, fConfPath, fConFlowName, cNsMap) {
  this.conf = MC.extend({}, conf)
  this.confPath = MC.noStrailingSlash(fConfPath)
  this.flowName = fConFlowName;
  this.confNsMap = cNsMap;
  return this;
};

Flow.prototype.setConfPath = function(fConfPath) {
  this.confPath = MC.noStrailingSlash(fConfPath)
  return this;
};

Flow.prototype.setLang = function(val) {
  this.lang = val;
  return this;
};

Flow.prototype.setInput = function(val) {
  this.input = val || {};
  return this;
};

Flow.prototype.setInstanceId = function(val) {
  this.instanceId = val
  return this
}

Flow.prototype.setLazyAction = function(actionCode, logic, altMapping) {
  this.lazyAction = actionCode
  this.lazyActionLogic = logic
  this.lazyActionAltMapping = altMapping
  return this
};

Flow.prototype.setServerSide = function(iface) {
  this.serverSide = true
  if (!MC.isNull(iface)) {
    iface.id = this.flowName
    if (iface.cache === true) {
      this.cache = true
    }
    this.flow = iface
  }
  return this
}

Flow.prototype.setCacheable = function() {
  this.cacheable = true
}  

Flow.prototype.setOnEndFunction = function(val) {
  if (MC.isFunction(val)) {
    this.onEndFunction = val;
  } else {
    this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setOnEndFunction' has to be function!");
  }
  return this;
};

Flow.prototype.setAfterRenderFormFunction = function(val) {
  if (MC.isFunction(val)) {
    this.afterRenderForm = val;
  } else {
    this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setAfterRenderFormFunction' has to be function!");
  }
  return this;
};

Flow.prototype.setEnv = function(env) {
  this.env = Object.assign({}, env)
};

Flow.prototype.setWantedLogLevel = function(level) {
  if (!level) {
    this.wantedLogLevel = null
    return
  }
  if (level == 'true' || level == true) {
    level = 'AUTO'
  }
  const knownParams = ['NONE', 'AUTO', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
  if (knownParams.indexOf(level) < 0) {
    this.endOperationException('SYS_UnrecoverableRuntimeExc', `Parameter of called 'setWantedLogLevel' must be one of ${knownParams} or true!`)
  }
  this.wantedLogLevel = level
}

Flow.prototype.cacheKey = function() {
  return this.confPath + '/' + this.flowName + ':' + JSON.stringify(this.input)
}

Flow.prototype.loadAndStart = function(input, inputMapTrace) {
  if (!this.confPath || !this.flowName || !this.lang) {
    this.endOperationException('SYS_UnrecoverableRuntimeExc', 'Configuration path, flow name and lang must be set when starting flow!')
    return
  }
  this.input = input || {}
  this.inputMapTrace = inputMapTrace
  let origConf = this.conf || this.env.cfg
  if (!origConf && this.context.data.env) {
    origConf = this.context.data.env.cfg
  }
  if (origConf) {
    this.loadAndStartStep1(origConf)
  } else {
    let self = this
    MC.getConfiguration(this.confPath, this.wantedLogLevel, this, this.reactFlow().props.mconf).then(function (conf) {
      self.loadAndStartStep1(conf)
    })
  }
}

Flow.prototype.getFlowDefinition = function() {
  let self = this
  return new Promise(function(resolve, reject) {
    if (!MC.isNull(self.flow)) {
      resolve(self.flow);
    } else {
      const url = self.reactFlow().props.mconf.baseUrl + ReactFlow.flowTemplate.replace('{configuration}', self.confPath).replace('{flowName}', self.flowName || '').replace('{lang}', self.lang)
      if (MCCache.has(url)) {
        resolve(MCCache.get(url))
      } else {
        MC.callServer('GET', url, MC.getJsonType()).then(function (result) {
          if (result.status == 200) {
            var def = JSON.parse(result.content);
            MCCache.put(url, def);
            resolve(def);
          } else {
            reject('Error in flow definition at RI ' + url  + '\n' + result.content);
          }
        }).catch(function (err) {
          if (navigator.onLine) {
            self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed for url ' + url + ': ' + err.message);
            return;
          } else {
            self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message);
            return;
          }
        });
      }
    }
  });
};

Flow.prototype.loadAndStartStep1 = function(conf) {
  if (this.reactFlow() && !this.parentFlow) {
    let loaderState = {}
    if (!this.reactFlow().props.parent && conf && ['init', 'none', 'all'].indexOf(conf['mini:loader']) >= 0) {
      loaderState.loader = conf['mini:loader']
    }
    this.reactFlow().requestDimmer(loaderState, conf)
    if (conf['mini:sessionInactivityTimeout']) {
      this.reactFlow().initActivityMonitor(conf['mini:sessionInactivityTimeout'])
    }
  }
  if (!this.reactFlow()) {
    return
  }
  let self = this;
  this.getFlowDefinition().then(function (data) {
    self.flow = data;
    self.selectLogLevel(conf)
    if (self.serverSide) {
      self.runOnServer();
    } else {
      if (self.cacheable) {
        let key = self.cacheKey()
        if (MCCache.has(key)) {
          self.takenFromCache = true
          self.endOperation(MCCache.get(key), null, false)
          return
        }
      }
      if (!self.reactFlow()) {
        return
      }
      if (!self.env.system) {
        self.env.system = {}
        self.env.system.flowId = self.instanceId
        self.env.system.correlationId = self.env.system.flowId
        if (navigator.userAgentData) {
          let nodeName = ""
          for (let brand of navigator.userAgentData.brands) {
            nodeName += brand.brand + " " + brand.version + ", "
          }
          nodeName += navigator.userAgentData.mobile ? "mobile" : "desktop"
          self.env.system.nodeName = nodeName           
        } else {
          self.env.system.nodeName =  navigator.userAgent
        }
        self.env.system.flowConfig = self.confPath
        self.env.system.language = self.lang
        self.env.system.userLoginId = self.reactFlow().props.mconf.userId
      }
      if (!MC.isNull(conf)) {
        MC.prepareNamespaces(conf, self.confNsMap)
        MC.translateNamespaces(conf, self.flow.ns)
        self.env.cfg = conf
      }
      self.env.operation = {};
      self.env.operation.operationName = self.flow.id;
      self.env.operation.rootOperationName = self.parentFlow ? self.parentFlow.flow.id : self.flow.id;
      self.env.ns = self.flow.ns;
      if (MC.isNull(self.env.context)) {
        MC.getEnvironmentContext(self.confPath, conf['fl:environmentOperation'], self.reactFlow().props.mconf).then(function(context) {
          self.env.context = context;
          if (!MC.isNull(context) && MC.isPlainObject(context)) {
            if (MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
              self.setLang(context.request.language);
            }
            if (MC.isPlainObject(context.internalUser) && !MC.isNull(context.internalUser.internalUserId)) {
              self.env.system.userLoginId = context.internalUser.internalUserId;
            }
          }
          self.loadAndStartStep2();
        });
      } else {
        self.loadAndStartStep2();
      }
    }
  }).catch(function (err) {
    self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed: ' + err)
    return;
  })
};

Flow.prototype.loadAndStartStep2 = function() {
  this.addToContext(this.context.data, 'env', this.env);
  if (!this.flow.action && !this.parentFlow) {
    MCHistory.history(this, null, 'OPERATION START', {'Input': this.input, 'Environment': this.debug('TRACE') ? this.env : null}, {end: this.opStartDate});
  } else if (this.parentFlow) {
    if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.flow.id) < 0) {
      let me = this
      let parent = this.parentFlow
      if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.parentFlow.flow.id) > -1 && this.parentFlow.parentFlow) {
        parent = this.parentFlow.parentFlow
        me = this.parentFlow
      }
      MCHistory.history(parent, parent.context.action, "CALL ACTION START", {'Input': me.input, 'Trace': me.inputMapTrace, executionId: this.instanceId, target: this.flow})
    }
  }
  if (!MC.isNull(this.flow.svl)) {
    this.addToContext(this.context.data, 'svl', this.flow.svl);
  }
  if (!MC.isNull(this.flow.vmt)) {
    this.addToContext(this.context.data, 'vmt', this.flow.vmt);
  }
  this.progress({'Input': this.input, 'Trace': this.inputMapTrace});
};

Flow.prototype.selectLogLevel = function(conf) {
  if (this.wantedLogLevel === 'AUTO') {
    const knownParams = ['NONE', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
    if (conf['fl:componentLoggingThreshold']) {
      for (let cmpSetting of MC.asArray(conf['fl:componentLoggingThreshold'])) {
        if (cmpSetting['fl:name'] && (cmpSetting['fl:level'] || cmpSetting['fl:operationLoggingThreshold'])) {
          let cmpName = cmpSetting['fl:name'].startsWith('/') ? cmpSetting['fl:name'].substring(1) : cmpSetting['fl:name']
          if (cmpName === this.flow.model) {
            if (cmpSetting['fl:operationLoggingThreshold']) {
              for (let opSetting of MC.asArray(cmpSetting['fl:operationLoggingThreshold'])) {
                if (MC.asArray(opSetting['fl:name']).indexOf(this.flow.id) >= 0) {
                  if (knownParams.indexOf(opSetting['fl:level']) < 0) {
                    this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:operationLoggingThreshold/fl:level' must be one of ${knownParams}!`);
                  } else {
                    this.logLevel = opSetting['fl:level']
                  }
                }
              }
            }
            if (!this.logLevel && cmpSetting['fl:level']) {
              if (knownParams.indexOf(cmpSetting['fl:level']) < 0) {
                this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:level' must be one of ${knownParams}!`);
              } else {
                this.logLevel = cmpSetting['fl:level']
              }
            }
          }
        }
      }
    }
    if (!this.logLevel) {
      if (conf['fl:defaultLoggingThreshold']) {
        if (knownParams.indexOf(conf['fl:defaultLoggingThreshold']) < 0) {
          this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:defaultLoggingThreshold' must be one of ${knownParams}!`);
        } else {
          this.logLevel = conf['fl:defaultLoggingThreshold']
        }
      } else {
        this.logLevel = 'TRACE'
      }
    }
  } else if (this.wantedLogLevel) {
    this.logLevel = this.wantedLogLevel
  } else {
    this.logLevel = null
  }
}

Flow.prototype.progress = function(logObject) {
  if (this.getRootFlow().isRenderingInterupted) {
    return
  }
  if (this.flow.mock && this.context.data.env && this.context.data.env.cfg && this.context.data.env.cfg["fl:useMocks"] == "true") {
    var mockOutput = this.runMock();
    if (mockOutput !== false) {
      this.endOperation(mockOutput);
      return;
    }
  }
  if (this.flow.kind == 'function') {
    this.runFunctionOperation();
  } else if (this.flow.kind == 'framework') {
    this.runFrameworkOperation();
  } else if (this.flow.kind == 'decisiontable') {
    this.runDecisionTableOperation()
  } else if (this.flow.action) {
    if (!this.context.nextAction) {
      this.context.nextAction = this.getStartAction();
    } else {
      let text = null
      if (logObject.isException) {
        text = 'EXCEPTION END'
      }
      if (logObject.takenFromCache) {
        text = 'FROM CLIENT CACHE'
        delete logObject.takenFromCache
      }
      MCHistory.history(this, this.context.action, text, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: this.context.actionStart ? performance.now() - this.context.actionStart : 0})
    }  
    this.context.action = this.context.nextAction;
    this.initActionStartTime()
    this.context.altmapping = this.context.nextAltmapping
    this.context.nextAction = this.getActionById(this.context.action.nextaction)
    this.context.nextAltmapping = this.context.action.altmapping
    if (!this.context.nextAction && this.context.action.kind != 'end' && this.context.action.kind != 'decision' && !(this.context.action.kind == 'call' && this.context.action.leaveFlow)) {
      this.endOperationException('SYS_InvalidModelExc', this.context.action.kind + " action must have next action defined!");
      return;
    }
    var state = this.context.action.kind;
    switch (state) {
      case "start":
        this.startAction();
        break;
      case "form":
        this.formAction();
        break;
      case "call":
        this.callAction();
        break;
      case "decision":
        this.decisionAction();
        break;
      case "end":
        this.endAction();
        break;
      case "transform":
        this.transformAction();
        break;
      case "feedback":
        this.feedbackAction();
        break;
      default:
        this.endOperationException('SYS_InvalidModelExc', 'Unsupported action kind: "' + state + '"!');
        break;
    }
  } else {
    this.endOperationException('SYS_InvalidModelExc', "Operation has no action or has not supported operation type '" + this.flow.kind + "'!");
  }
};

Flow.prototype.progressLazyForm = function(formData, logObject, lazyAction, lazyActionLogic, altMapping) {
  let self = this
  let text = null
  if (!MC.isNull(lazyAction)) {
    if (logObject.takenFromCache) {
      text = 'FROM CLIENT CACHE'
      delete logObject.takenFromCache
    }
    MCHistory.history(this, lazyAction, text, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    this.initActionStartTime()
  }
  if (self.context.action.kind !== 'form' || lazyActionLogic && lazyActionLogic.formExecutionId && lazyActionLogic.formExecutionId != self.formExecutionId) {
    return
  }
  if (!MC.isNull(this.context.dataActions)) {
    let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), self.context)
    if (input) {
      self.setFormFields(formData, input)
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    MCHistory.history(self, self.context.action, 'FORM PRE-RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    this.formData = formData
    this.initActionStartTime()
    self.callAction(this.context.dataActions.pop())
  } else {
    delete this.context.dataActions;
    let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), self.context)
    if (input) {
      self.setFormFields(formData, input)
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    let formTitle = formData.title
    if (formData.param && formData.param['@title']) {
      formTitle = formData.param['@title']
    }
    if (formTitle) {
      document.title = formTitle
    }
    if (MC.isFunction(self.afterRenderForm)) {
      self.afterRenderForm(formData, true)
    }
    let rootFlow = this.getRootFlow()
    if (!MC.isNull(rootFlow.context.feedback)) {
      formData.feedback = rootFlow.context.feedback
      rootFlow.context.feedback = []
    }
    if(formData.flow && formData.flow.modelerReact) {
      formData.flow.modelerReact.resetStacks();
    }
    if (this.reactFlow()) {
      if (this.reactFlow().props.autoScrollUp !== false && this.hardRenderMode) { // it called only in form action and not in dialogs
        // scroll up must be called before setState for working focus
        window.scrollTo(0, 0);
      }
      if (this.hardRenderMode) {
        this.getRootFlow().runReady = true
        this.hardRenderMode = false;
      }
      MCHistory.history(self, self.context.action, 'FORM RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart});
      this.reactFlow().stopDimmer({state: 'form', formData: formData, firstFormRendered: true})
    }
    if (lazyActionLogic) {
      this.initLogicTimer(lazyActionLogic)
      if (lazyActionLogic.event.find(e => e.e == 'beforeunload')) {
        this.reactFlow().resolveUnload(formData)
      }
    }
  }
};

Flow.prototype.getStartAction = function() {
  if (this.flow.action) {
    for (var i=0; i < this.flow.action.length; i++) {
      if (this.flow.action[i].code == 'start') {
        return this.flow.action[i];
      }
    }
  }
  this.endOperationException('SYS_InvalidModelExc', "Can not find start action!");
  return null;
};

Flow.prototype.getActionById = function(id) {
  if (this.flow.action) {
    for (var i=0; i < this.flow.action.length; i++) {
      if (this.flow.action[i].id == id) {
        return this.flow.action[i];
      }
    }
  }
  return null;
};

Flow.prototype.getActionByCode = function(code) {
  if (this.flow.action) {
    for (var i=0; i < this.flow.action.length; i++) {
      if (this.flow.action[i].code == code) {
        return this.flow.action[i];
      }
    }
  }
  return null;
};

Flow.prototype.convertInputTreeData = function(inputTree, valueTree, contextTree) {
  if (Array.isArray(valueTree) && valueTree.length > 0) {
    valueTree = valueTree[0]
  }
  for (let i=0; i<inputTree.input.length; i++) {
    let param = inputTree.input[i]
    let key = param.name
    if (MC.isNull(key)) {
      if (param.basictype == 'anyType' && !MC.isNull(valueTree)) {
        MC.extend(contextTree, valueTree)
      }
    } else {
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1)
      }
      if (valueTree && !MC.isNull(valueTree[key])) {
        let values
        let inValues = valueTree[key]
        if (param.name.endsWith('*')) {
          if (!Array.isArray(inValues)) {
            let arr = []
            arr.push(inValues)
            inValues = arr
          }
          values = []
          for (let m=0; m<inValues.length; m++) {
            if (typeof inValues[m] === 'undefined') continue
            let value
            if (param.input && inValues[m] !== '') {
              value = {}
              if (!this.convertInputTreeData(param, inValues[m], value)) {
                return false
              }
            } else {
              try {
                value = MC.normalizeValue(inValues[m], param.basictype)
              } catch (e) {
                e.message = 'Error parsing operation input - parameter: ' + param.name + ', value: ' + inValues[m] + ', message: ' + e.message
                this.endOperationException('SYS_MappingExc', e)
                return false
              }
            }
            values.push(value)
          }
        } else {
          if (Array.isArray(inValues)) {
            inValues = inValues[0]
          }
          if (param.input && inValues !== '') {
            values = {}
            if (!this.convertInputTreeData(param, inValues, values)) {
              return false
            }
          } else {
            try {
              values = MC.normalizeValue(inValues, param.basictype)
            } catch (e) {
              e.message = 'Error parsing operation input - parameter: ' + param.name + ', value: ' + inValues + ', message: ' + e.message
              this.endOperationException('SYS_MappingExc', e)
              return false
            }
          }
        }
        contextTree[key] = values
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Input parameter '" + param.name + "' must have value!")
          return false
        }
      }
    }
  }
  return true
}

Flow.prototype.addToContext = function(context, actionCode, object) {
  if (MC.isNull(object)) {
    delete context[actionCode]
  } else {
    context[actionCode] = object
  } 
}

Flow.prototype.startAction = function() {
  if (this.flow.input && Array.isArray(this.flow.input)) {
    var result = {};
    if (this.convertInputTreeData(this.flow, this.input, result)) {
      this.addToContext(this.context.data, 'start', result);
      this.progress({'Input': this.input, 'Output': result, 'Environment': this.debug('TRACE') ? this.env : null});
    }
  } else {
    this.progress({'Input': this.input, 'Environment': this.debug('TRACE') ? this.env : null});
  }
};

Flow.prototype.findAllLazyDataActions = function(field, result) {
  if (!result) {
    result = []
  }
  if ('embeddeddialog' == field.widget) {
    return result
  }
  const opts = this.getDataActionOpts(field.param)
  if (!MC.isNull(opts.action)) {
    result.push(opts)
  } else if (field.fields) {
    for (let i = 0; i < field.fields.length; i++) {
      result = this.findAllLazyDataActions(field.fields[i], result)
    }
  }
  return result 
}

Flow.prototype.getDataActionOpts = function(param) {
  const dataAction = MC.getFieldParamValue(param, '@dataAction')
  const dataEvent = MC.getFieldParamValue(param, '@dataEvent')
  let opts = {} 
  if (dataAction) {
    opts.action = this.getActionByCode(dataAction)
    MCHistory.log(MCHistory.T_WARNING, 'Using "@dataAction" is deprecated. Use "@dataEvent" with corresponding form event instead.', this.debug())
  } else if (dataEvent) {
    if (Array.isArray(this.context.action.actionLink)) {
      let event = this.context.action.actionLink.find(el => el.name == dataEvent)
      if (!event) {
        this.endOperationException('SYS_InvalidModelExc', "Data event with name '" + dataEvent + "' not found in action '" + this.context.action.code + "'!")
        return
      }
      if (event && event.nextaction) {
        opts.altMapping = event.altmapping
        opts.altMappingBack = event.altmappingback
        opts.action = this.getActionById(event.nextaction)
      }
    }
  }
  return opts
}

Flow.prototype.formAction = function() {
  let formId = this.context.action.form
  this.context.data['@lastFormAction'] = this.context.action.code
  if (!formId) {
    this.endOperationException('SYS_InvalidModelExc', "Form action '" + this.context.action.code + "' has no form selected!")
    return
  }
  let form = this.flow.form[formId]
  let dataActions = this.findAllLazyDataActions(form)
  if (!MC.isNull(dataActions)) {
    this.context.dataActions = dataActions
  }
  this.hardRenderMode = true
  this.formExecutionId = MC.generateId()
  if (!this.reactFlow()) {
    return
  }
  this.reactFlow().isFormActive = true
  this.progressLazyForm(this.prepareFormData(MC.extend({}, form), formId), null, null, null, this.context.altmapping)
}

Flow.prototype.prepareFormData = function(formData, formId) {
  formData.formId = formId;
  formData.model = this.flow.model;
  formData.lang = this.lang;
  MC.setPropertyRecusively(formData, 'fields', 'flow', this)
  MC.initParentFields(formData)
  if (this.flow.progressBar) {
    formData.progressBar = this.flow.progressBar;
    formData.progressBar.active = this.context.action.code;
  }
  return formData;
};

Flow.prototype.handleSubmit = function(field, action) {
  if (!MC.isEmptyObject(field) && MC.isModelerActive(field)) {
    return
  }
  if (!action && !MC.isEmptyObject(field)) {
    action = field.param['@behavior']
  }
  let self = this
  let submit = false
  if ('submit' == action) {
    self.focusedOnFirst = false
    self.eventForm(field, 'beforesubmit')
    let formExecutionId = this.formExecutionId
    MC.validateFieldTree(self.reactFlow().state.formData, field, 0).then(function (valid) {
      if (valid) {
        self.submitForm(field, action, null, formExecutionId)
      } else {
        self.reactFlow().forceUpdate()
      }
    }).catch(function (exception) {
      if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
        self.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log)
      } else {
        self.endOperationException('SYS_UnrecoverableRuntimeExc', exception)
      }
    })
  } else if ('cancel' == action) {
    submit = true
  } else if ('store' == action) {
    submit = true
  }
  if (submit) {
    self.submitForm(field, action, null, this.formExecutionId)
  }
}

Flow.prototype.submitForm = function(triggeredByField, behaviour, submitAction, formExecutionId) {
  if (this.context.action.kind !== 'form' || this.formExecutionId != formExecutionId) {
    return
  }
  this.clearLogicTimers();
  if (triggeredByField && MC.getFieldParamBooleanValue(triggeredByField.param, '@confirm')) {
    let message = MC.getFieldParamValue(triggeredByField.param, '@confirmMessage')
    if (!message) {
      message = MC.formatMessage("confirm", this.reactFlow().props.mconf.lang);
    }
    this.reactFlow().setState({message: {heading: message, size: 'tiny', onClose: this.cancelSubmitForm.bind(this), buttons: [
          {title: "OK", class: "green", icon: "checkmark icon", action: this.confirmSubmitForm.bind(this, triggeredByField, behaviour, submitAction, formExecutionId)},
          {title: MC.formatMessage("cancel", this.reactFlow().props.mconf.lang), class: "orange",  icon: "cancel icon", action: this.cancelSubmitForm.bind(this)}
        ]}})
  } else {
    this.confirmSubmitForm(triggeredByField, behaviour, submitAction, formExecutionId)
  }
};

Flow.prototype.initActionStartTime = function() {
  this.context.actionStart = performance.now()
  this.context.actionStartDate = Date.now()
}

Flow.prototype.confirmSubmitForm = function(triggeredByField, behaviour, submitAction, formExecutionId) {
  if (this.context.action.kind !== 'form' || this.formExecutionId != formExecutionId) {
    return
  }
  this.initActionStartTime()
  var action = this.context.action;
  var output = {};
  let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
  this.formExecutionId = null
  this.mapFormOutput(this.reactFlow().state.formData, output, triggeredByField, behaviour, 'no', repeaterRows, submitAction);
  this.addToContext(this.context.data, action.code, output);
  this.reactFlow().isFormActive = false
  if (this.reactFlow().state.loader == 'all') {
    this.reactFlow().requestDimmer({message: null})
  } else {
    this.reactFlow().setState({message: null})
  }
  this.progress({'Output': output, target: this.reactFlow().state.formData})
}

Flow.prototype.cancelSubmitForm = function() {
  this.reactFlow().setState({message: null});
};

Flow.prototype.eventForm = function(triggeredByField, event, target, receivedData, options) {
  if (!this.reactFlow()) {
    return
  }
  let self = this
  var formData = self.reactFlow().state.formData;
  var logic = [];
  let resolveUnload = 'beforeunload' == event
  if (!MC.isNull(formData) && Array.isArray(formData.logic)) {
    for (var i = 0; i < formData.logic.length; i++) {
      var actl =  formData.logic[i];
      if (Array.isArray(actl.event)) {
        for (var e = 0; e < actl.event.length; e++) {
          if (actl.event[e]['e'] == event && (Array.isArray(actl.event[e]['f']) && triggeredByField && actl.event[e]['f'].indexOf(triggeredByField.rbsid) > -1 || MC.isNull(actl.event[e]['f']) && (!triggeredByField || triggeredByField.formId))) {
            if (logic.indexOf(actl) == -1) {
              if (event == 'click') {
                var behavior = MC.getFieldParamValue(triggeredByField.param, '@behavior')
                if (!MC.isNull(behavior) && behavior !== '' && behavior !== 'formlogic') {
                  continue;
                }
              }
              logic.push(actl);
            }
          }
        }
      }
      if (options && options.logic == actl) { // call from timer
        if (logic.indexOf(actl) == -1) {
          logic.push(actl)
        }  
      }
    }
  }
  if (logic.length > 0) {
    let lStart = performance.now()
    let lStartDate = Date.now()
    var context = {};
    context['@event'] = event;
    if (triggeredByField) {
      if (!triggeredByField.formId) {
        context['@widgetName'] = triggeredByField.id
        context['@widgetPath'] = self.getFormFieldPath(formData, triggeredByField, '')
        if (MC.getFieldParamValue(triggeredByField.param, '@iteration')) {
          context['@widgetIndex'] = MC.getFieldParamValue(triggeredByField.param, '@iteration')
        }
      }
    }
    if (target && target.field) {
      let nodeTarget = target.node ? MC.closestHasAttr(target.node, 'data-widget-name').getAttribute('data-widget-name')  : null
      if (nodeTarget && nodeTarget != target.field.id) {
        context['@widgetTarget'] = nodeTarget
      } else {
        context['@widgetTarget'] = target.field.id
        context['@widgetTargetPath'] = self.getFormFieldPath(formData, target.field, '')
        if (MC.getFieldParamValue(target.field.param, '@iteration')) {
          context['@widgetTargetIndex'] = MC.getFieldParamValue(target.field.param, '@iteration')
        }
      }
    }
    context['env'] = {};
    context['svl'] = {};
    context['vmt'] = {};
    for (var prop in self.context.data) {
      if (prop == 'env' || prop == 'svl' || prop == 'vmt') {
        context[prop] = self.context.data[prop]
      }
    }
    if (!MC.isNull(receivedData) && MC.isPlainObject(receivedData)) {
      context['eventData'] = receivedData;
    }
    if (!triggeredByField) {
      triggeredByField = true;
    }
    if (['keypress', 'keyup', 'keydown'].indexOf(event) > -1) {
      let e = options.e
      let kData = {}
      kData.key = e.key
      kData.isAlt = e.altKey
      kData.isCtrl = e.ctrlKey
      kData.isShift = e.shiftKey
      kData.isMeta = e.metaKey
      context.keyboardData = kData
    }
    loopLogics:
    for (var l = 0; l < logic.length; l++) {
      context.form = {};
      this.mapFormOutput(formData, context.form, triggeredByField, 'store', 'no', null, null)
      let trcObj = []
      if (Array.isArray(logic[l].condition) && logic[l].condition.length > 0) {
        for (var i = 0; i < logic[l].condition.length; i++) {
          let trc = this.debug('TRACE')
          var expression = new Expression();
          expression.init(logic[l].condition[i], context, {trace: trc})
          var res = expression.evaluate();
          if (expression.getError()) {
            this.endOperationException('SYS_MappingExc', expression.getErrorMessage());
            return false;
          }
          res = MC.castToScalar(res, 'boolean')
          if (trc) {
            trcObj.push(expression.getTrace())
          }
          if (MC.isNull(res) || res === '') {
            this.endOperationException('SYS_MappingExc', 'Expression of form logic condition with name "' + logic[l].name + '" must evaluate to boolean, but result value is null or empty!', null, null, null, {'Input': context, 'Condition': trcObj.length == 0 ? null : {result: res, trace: trcObj}})
            return
          }
          if (!res) {
            MCHistory.history(self, self.context.action, 'FORM LOGIC: ' + logic[l].name, {'Input': context, 'Condition': trcObj.length == 0 ? null : {result: res, trace: trcObj}, target: formData}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, logic: logic[l]})
            this.initLogicTimer(logic[l]);
            continue loopLogics;
          }
        }
      }
      if (['keypress', 'keyup', 'keydown'].indexOf(event) > -1) {
        options.e.preventDefault()
      }  
      var logContext = MC.extend({}, context);
      let [input, trace] = self.mapToResultObject({mapping: logic[l].mapping}, {data: context})
      if (!input) {
        this.endOperationException('SYS_MappingExc', trace[0], logContext, null, null, trace[1])
        return
      }
      this.setFormFields(formData, input)
      this.reactFlow().stopDimmer({state: 'form', formData: formData})
      MCHistory.history(self, self.context.action, 'FORM LOGIC: ' + logic[l].name, {'Input': logContext, 'Condition': trcObj.length == 0 ? null : {result: res, trace: trcObj}, 'Output': input, 'Trace' : trace, target: formData}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, logic: logic[l]})
      if (!MC.isNull(logic[l].dataAction) || !MC.isNull(logic[l].actionLink)) {
        this.initActionStartTime()
        if (self.context.action.kind !== 'form') {
          return
        }
        logic[l].formExecutionId = this.formExecutionId
        resolveUnload = false
        let dRes = self.callLogicAction(logic[l], triggeredByField)
        if (!MC.isNull(logic[l].dataAction)) {
          MCHistory.log(MCHistory.T_WARNING, 'Using "data action" in form logic is deprecated. Use corresponding form event instead.', this.debug())
        }
        if (!MC.isNull(dRes)) {
          self.reactFlow().setState({dialog: {flowName: dRes.flowName, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, triggeredByField: triggeredByField, size: dRes.size, 
            start: true, parentFlow: this, logic: logic[l], altMapping: dRes.altMapping, asContext: dRes.asContext, cssClass: dRes.cssClass, closeIcon: dRes.closeIcon, target: target ? target.node : null, lStart: lStart, lStartDate: lStartDate, action: dRes.action}})
        }
        if (dRes === undefined) {
          continue loopLogics
        }
      }
      var behavior = logic[l].behavior;
      if (!MC.isNull(logic[l].behavior)) {
        if ('submit' == behavior) {
          this.focusedOnFirst = false;
          this.eventForm(triggeredByField, 'beforesubmit')
          let formExecutionId = this.formExecutionId
          MC.validateFieldTree(this.reactFlow().state.formData, triggeredByField, 0).then(function (valid) {
            if (valid) {
              self.submitForm(triggeredByField, behavior, logic[l].name, formExecutionId)
            } else {
              self.reactFlow().forceUpdate();
              self.initLogicTimer(logic[l]);
            }
          }).catch(function (exception) {
            if (MC.isPlainObject(exception) && !MC.isNull(exception.type)) {
              self.endOperationException(exception.type, exception.message, exception.input, exception.output, exception.log);
            } else {
              self.endOperationException('SYS_UnrecoverableRuntimeExc', exception);
            }
          });
        } else {
          this.submitForm(triggeredByField, behavior, logic[l].name, this.formExecutionId)
        }
        return;
      } else if (MC.isNull(logic[l].dataAction) && MC.isNull(logic[l].actionLink)) {
        this.initLogicTimer(logic[l])
      }
    }
  }
  if (resolveUnload) {
    this.reactFlow().resolveUnload(formData)
  }
}

Flow.prototype.endDialog = function(output, message, notRerender, endAction) {
  let actionCode = this.reactFlow().state.dialog.actionCode
  let input = this.reactFlow().state.dialog.input
  let submitOpts = null
  let dAction = this.reactFlow().state.dialog.action
  let dialogInState = this.reactFlow().state.dialog
  if (dAction && dAction.submitParent || endAction && ['submit', 'store', 'cancel'].indexOf(endAction.parentAction) >= 0) {
    submitOpts = {}
    submitOpts.triggeredByField = dialogInState.triggeredByField
  }
  let logic = this.reactFlow().state.dialog.logic
  this.reactFlow().setState({dialog: null})
  if (!MC.isNull(message)) {
    this.endOperationException(output, message, input, null, null)
  } else if (!notRerender) {
    this.endEmbeddedDialog(actionCode, input, output, submitOpts, logic, dialogInState.altMapping, endAction)
  }
}

Flow.prototype.closeDialog = function() {
  const actionCode = this.reactFlow().state.dialog.actionCode
  this.addToContext(this.context.data, actionCode, null)
  let submitOpts = null
  const dAction = this.reactFlow().state.dialog.action
  if (dAction.submitParent) {
    submitOpts = {}
    submitOpts.triggeredByField = this.reactFlow().state.dialog.triggeredByField
  }
  this.reactFlow().setState({dialog: null})
  if (submitOpts) {
    this.submitForm(submitOpts.triggeredByField, 'store', null, this.formExecutionId)
  }
}

Flow.prototype.endEmbeddedDialog = function(actionCode, input, output, submitOpts, logic, altMapping, endAction) {
  if (endAction.parentAction == 'none') {
    return
  }
  this.addToContext(this.context.data, actionCode, output)
  this.progressLazyForm(this.reactFlow().state.formData, {'Input': input, 'Output': output}, null, logic, altMapping)
  if (endAction.parentAction == 'refresh') {
    return
  }
  if (submitOpts || endAction.parentAction) {
    this.handleSubmit(submitOpts.triggeredByField, endAction.parentAction || 'store')
  }
}

Flow.prototype.paginateForm = function(opts, field) {
  this.initActionStartTime()
  var action = this.context.action
  var output = {}
  this.mapFormOutput(this.reactFlow().state.formData, output, field, 'store', 'no', null, null)
  this.addToContext(this.context.data, action.code, output)
  if (this.reactFlow().state.loader == 'all') {
    this.reactFlow().requestDimmer()
  } else {
    this.reactFlow().setState()
  }
  this.callAction(opts)
}

Flow.prototype.mapFormOutput = function(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRows, submitAction) {
  if (definition.formId) { // is form root
    if (triggeredByField) {
      contextTree['@submitTrigger'] = triggeredByField.id
      contextTree['@submitTriggerPath'] = this.getFormFieldPath(definition, triggeredByField, '')
      if (!MC.isNull(repeaterRows)) {
        contextTree['@submitTriggerIndex'] = repeaterRows;
      }
    }
    if (submitAction) {
      contextTree['@submitAction'] = submitAction
    }
    contextTree['@submitBehaviour'] = behaviour
    contextTree['@width'] = this.reactFlow().containerRef.current.offsetWidth
    Object.assign(contextTree, definition.param)
    if (definition.data) {
      contextTree.data = definition.data 
    }
  }
  if (definition.id === 'rows*') {
    if (Array.isArray(repeaterRows) && (!Array.isArray(iterations) || iterations.length < repeaterRows.length)) {
      let iterationsToPass = Array.isArray(iterations) ? repeaterRows.slice(0, iterations.length+1) : [repeaterRows[0]]
      this.mapFormOutputStep(definition.rows[iterationsToPass[iterationsToPass.length-1]], contextTree, triggeredByField, behaviour, iterationsToPass, repeaterRows)
    } else {
      if (Array.isArray(definition.rows) && definition.rows.length > 0) {
        for (var i=0; i<definition.rows.length; i++) {
          let iterationsToPass = Array.isArray(iterations) ? [...iterations, i] : [i]
          this.mapFormOutputStep(definition.rows[i], contextTree, triggeredByField, behaviour, iterationsToPass, true)
        }
      }
    }
  } else {
    this.mapFormOutputStep(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRows)
  }
}

Flow.prototype.setParamInFormOutput = function(object, prop, valueObj, iterations) {
  if ('@iteration' == prop) { // internal helper parameter should not be in form output
    return
  }
  if (MC.isPlainObject(valueObj)) {
    object[prop] = {};
    for (var propIn in valueObj) {
      var param = valueObj[propIn];
      if (Array.isArray(param) && Array.isArray(iterations)) {
        this.setParamInFormOutput(object[prop], propIn, MC.getFieldParamValue(valueObj, propIn))
      } else {
        this.setParamInFormOutput(object[prop], propIn, param, iterations);
      }
    }
  } else {
    if (Array.isArray(iterations) && Array.isArray(object)) {
      if (!object[iterations[iterations.length - 1]]) {
        object[iterations[iterations.length - 1]] = {}
      }
      object[iterations[iterations.length - 1]][prop] = valueObj;
    } else {
      object[prop] = valueObj;
    }
  }
}

Flow.prototype.mapFormOutputStep = function(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRow) {
  for (var i=0; i<definition.fields.length; i++) {
    var field = definition.fields[i];
    var values = {};
    let enabled = field.param && field.param['@enabled'] != false && field.param['@permitted'] != false
    if (field.fields && field.fields.length > 0 && enabled) {
      if (field.id === 'rows*') {
        if (!MC.isCorrespondingRepeater(field, triggeredByField)) {
          repeaterRow = null
        }
        values = []
        this.mapFormOutput(field, values, triggeredByField, behaviour, iterations, repeaterRow, null)
      } else if (field.widget === 'dynamicPanel') {
        let dynamicOutput = {}
        this.mapFormOutput(field, dynamicOutput, triggeredByField, behaviour, iterations, repeaterRow, null)
        values['fields'] = dynamicOutput
      } else {    
        this.mapFormOutput(field, values, triggeredByField, behaviour, iterations, repeaterRow, null)
      }
    }
    if ('cancel' != behaviour && enabled) {
      if (field.id !== 'rows*') {
        for (var prop in field.param) {
          var param = field.param[prop];
          this.setParamInFormOutput(values, prop, param, iterations);
        }
      } else {
        if (Array.isArray(repeaterRow)) {
          const repeaterRowIndex = Array.isArray(iterations) ? repeaterRow.length - iterations.length : 0
          if (Array.isArray(field.rows)) {
            for (let prop in field.rows[repeaterRow[repeaterRowIndex]].param) {
              if (values[0]) {
                let param = field.rows[repeaterRow[repeaterRowIndex]].param[prop]
                this.setParamInFormOutput(values, prop, param, [0])
              }
            }
          }
          values['@submittedRowIndex'] = repeaterRow[repeaterRowIndex] + 1
        } else {
          if (Array.isArray(field.rows)) {
            for (var r = 0; r < field.rows.length; r++) {
              for (let prop in field.rows[r].param) {
                let param = field.rows[r].param[prop]
                this.setParamInFormOutput(values, prop, param, [r])
              }
            }  
          }
        }
      }
      if (field.scriptedWidget && field.scriptedWidgetObject) {
        if (MC.isFunction(field.scriptedWidgetObject.getValue)) {
          var value = field.scriptedWidgetObject.getValue();
          for (prop in value) {
            values[prop] = value[prop];
          }
        }
      }
    }
    if (field == triggeredByField) {
      values['@submittedBy'] = true;
    }
    if (!MC.isNull(values)) {
      if (definition.id !== 'rows*') {
        contextTree[field.id == 'rows*' ? 'rows' : field.id] = values;
      } else {
        if (Array.isArray(iterations)) {
          var index = iterations[iterations.length - 1];
          if (Array.isArray(repeaterRow)) {
            index = 0;
          }
          if (!contextTree[index]) {
            contextTree[index] = {};
          }
          contextTree[index][field.id] = values;
        }
      }
    }
  }
};

Flow.prototype.getFormFieldPath = function(definition, triggeredByField, path) {
  if (!definition.fields) {
    return null
  }
  for (let i = 0; i < definition.fields.length; i++) {
    let field = definition.fields[i]
    let subpath = path + (path === '' ? '' : '/') + field.id
    if (triggeredByField && field.rbsid == triggeredByField.rbsid) {
      return subpath
    } else {
      let subres = this.getFormFieldPath(field, triggeredByField, subpath)
      if (subres !== null) {
        return subres
      }
    }
  }
  return null
}

Flow.prototype.setFormFields = function(definition, valueObj) {
  if (valueObj == null || valueObj == undefined) {
    return
  }
  if (Array.isArray(valueObj) && definition.id === 'rows*') {
    if (!Array.isArray(definition.rows)) {
      definition.rows = []
    }
    if (definition.rows.length > valueObj.length) {
      definition.rows = definition.rows.slice(0, valueObj.length)
    }
    for (let i=0;  i<valueObj.length; i++) {
      if (!definition.rows[i]) {
        let iToPass = definition.param['@iteration'] != undefined ? [...definition.param['@iteration'], i] : [i]
        definition.rows[i] = MC.copyFormField({id: definition.id, fields: definition.fields, param: definition.param, flow: definition.flow}, iToPass)
        FieldDef.setProto(definition.rows[i])
      }
      this.setFormFields(definition.rows[i], valueObj[i])
    }
    MC.initParentFields(definition)
  } else {
    if (valueObj == '' && definition.id === 'rows*' && definition.rows) { // deleting collection by empty
      definition.rows = []
      return
    }
    let wasDyanamicPanel = false
    for (let target in valueObj) {
      let value = valueObj[target]
      if (target == 'data') {
        definition.data = MC.extendFormField(definition.data, value)
        continue
      } else if (definition.widget == 'dynamicPanel' && (target == 'fields' || target == 'paramTree')) {
        if (!wasDyanamicPanel) {
          wasDyanamicPanel = true
          if (valueObj.fields) {
            definition.fields = []
            for (let f of value) {
              definition.fields.push(MC.extend({}, f))
            }
            MC.setFieldsPropertyRecusively(definition, 'flow', definition.flow)
            FieldDef.setProto(definition)
            MC.ensureIterations(definition, [])
            MC.initParentFields(definition)
          }
          if (valueObj.paramTree) {
            this.setFormFields(definition, valueObj.paramTree)
          }
          continue
        }
      }
      let subField = null
      if (definition.fields) {
        for (let i=0; i<definition.fields.length; i++) {
          if (definition.fields[i].id == target || definition.fields[i].id == (target + '*')) {
            subField = definition.fields[i]
            break
          }
        }
      }
      if (subField) {
        this.setFormFields(subField, value)
      } else {
        if (definition.scriptedWidget) {
          definition.param[target] = value
        } else {
          if (MC.isPlainObject(value)) {
            definition.param[target] = MC.extendFormField(definition.param[target], value)
          } else if (!MC.isNull(value)) {
            if (value === '' && Array.isArray(definition.param[target])) {
              delete definition.param[target]
            } else {
              definition.param[target] = value
            }
          }
        }
      }
    }
  }
}

Flow.prototype.convertMockTreeData = function(outputTree, mockTree, contextTree, actionCode) {
  for (var i=0; i<outputTree.output.length; i++) {
    var param = outputTree.output[i];
    var key = param.name;
    if (key.endsWith('*')) {
      key = key.substring(0, key.length-1);
    }
    if (!MC.isNull(mockTree[key])) {
      var values;
      var mockValues = mockTree[key];
      if (Array.isArray(mockValues)) {
        var values = [];
        for (var m=0; m<mockValues.length; m++) {
          var value;
          if (param.output) {
            value = {};
            this.convertMockTreeData(param, mockValues[m], value, false);
          } else {
            value = MC.normalizeValue(mockValues[m], param.basictype);
          }
          values.push(value);
        }
      } else {
        if (param.output) {
          values = {};
          this.convertMockTreeData(param, mockValues, values, false);
        } else {
          values =  MC.normalizeValue(mockValues, param.basictype);
        }
      }
      if (actionCode) {
        if (!contextTree[actionCode]) {
          contextTree[actionCode] = {}
        }
        contextTree[actionCode][key] = values
      } else {
        contextTree[key] = values
      }
    } else {
      if (param.mandat) {
        this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!");
      }
    }
  }
};

Flow.prototype.callLogicAction = function(logic, triggeredByField, dialog) {
  if (this.context.action.kind !== 'form' || logic.formExecutionId && logic.formExecutionId != this.formExecutionId) {
    return
  }
  let action = null
  let altMapping = null
  let altMappingBack = null
  if (logic.dataAction) {
    action = this.getActionByCode(logic.dataAction)
  } else if (logic.actionLink) {
    if (Array.isArray(this.context.action.actionLink)) {
      action = this.context.action.actionLink.find(el => el.name == logic.actionLink)
      if (!action) {
        this.endOperationException('SYS_InvalidModelExc', "Data event with name '" +logic.actionLink + "' not found in action '" + this.context.action.code + "'!")
        return
      }
      if (action && action.nextaction) {
        altMapping = action.altmapping
        altMappingBack = action.altmappingback
        action = this.getActionById(action.nextaction)
      }
    }
  }
  if (action.kind != 'dialog' && action.kind != 'call') {
    this.endOperationException('SYS_InvalidModelExc', "Action '" + action.code + "' is not dialog or call action!")
    return
  }
  var formOutput = {}
  let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
  this.mapFormOutput(this.reactFlow().state.formData, formOutput, triggeredByField, 'store', 'no', repeaterRows, logic.name ? logic.name : null)
  this.addToContext(this.context.data, this.context.action.code, formOutput)
  if (action.kind == 'dialog' || dialog) {
    if ('force' == dialog && action.kind != 'dialog') {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + action.code + "' must be dialog action if you want show it in dialog!")
      return
    }
    let flowName = action.calls
    if (!flowName) {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + (logic.dataAction || logic.actionLink) + "' calls no operation!")
      return
    }
    let [input, trace] = this.mapToResultObject(this.selectMapping(action, altMapping), this.context)
    if (!input) {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    return {flowName: flowName, input: input, size: action.dialogSize ? action.dialogSize : null, trace: trace, altMapping: altMappingBack, actionCode: action.code, action: action, asContext: action.asContext, cssClass: action.cssclass, closeIcon: action.closeIcon}
  } else {
    return this.callAction({action: action, altMappingBack: altMappingBack, altMapping: altMapping}, logic)
  }
};

Flow.prototype.callDialog = function(field) { 
  let dRes = this.callLogicAction({dataAction: MC.getFieldParamValue(field.param, '@dialogAction'), actionLink: MC.getFieldParamValue(field.param, '@dialogEvent'), formExecutionId: this.formExecutionId}, field, 'force')
  if (!MC.isNull(dRes)) {
    if (!MC.isNull(MC.getFieldParamValue(field.param, '@dialogAction'))) {
      MCHistory.log(MCHistory.T_WARNING, 'Using "@dialogAction" is deprecated. Use "@dialogEvent" with corresponding form event instead.', field.flow.debug())
    }
    this.reactFlow().setState({dialog: {flowName: dRes.flowName, input: dRes.input, trace: dRes.trace, actionCode: dRes.actionCode, triggeredByField: field, size: dRes.size, 
      start: true, parentFlow: this, altMapping: dRes.altMapping, asContext: dRes.asContext, cssClass: dRes.cssClass, closeIcon: dRes.closeIcon, lStart: performance.now(), lStartDate: Date.now(), action: dRes.action}})
  }
}  

Flow.prototype.callAction = function(options, logic) {
  let self = this
  var action = this.context.action
  if (options) {
    action = options.action
    if (MC.isNull(action)) {
      this.endOperationException('SYS_InvalidModelExc', "Lazy data action with code '" + options.code + "' not found!")
      return
    }
  }
  if (!action.calls) {
    this.endOperationException('SYS_InvalidModelExc', "Call action '" + action.code + "' calls no operation, not supported!")
    return
  }
  let input = null
  let trace = null
  if (action.multiCall && this.multiInput && this.multiInput.length > 0) {
    input = this.multiInput.shift()
  } else {
    [input, trace] = this.mapToResultObject(this.selectMapping(action, options && typeof options != 'string' ? options.altMapping : this.context.altmapping), this.context)
  }
  if (input) {
    if (action.leaveFlow) {
      this.leaveOperation(action.calls, input, trace)
    } else {
      if (action.multiCall) {
        if (!this.multiInput) {
          if (input['input'] && Array.isArray(input['input']) && input['input'].length > 0) {
            this.multiInputOrig = input
            this.multiInput = MC.extend([], input['input'])
            this.multiTrace = trace
            input = this.multiInput.shift()
          } else {
            this.addToContext(this.context.data, action.code, null)
            this.progress({'Input': input, 'Trace': trace})
            return
          }
        }
      }
      const flow = new Flow()
      flow.init().setLang(self.lang).setConfPath(this.confPath).setParentFlow(self)
      if (action.interface.isFrontend) {
        flow.setFlowConfiguration(this.confPath, action.calls)
      } else {
        flow.setFlowConfigurationProps(this.context.data.env.cfg, this.confPath, action.calls, this.confNsMap)
        flow.setServerSide(action.interface)
      }
      if (options) {
        flow.setLazyAction(action, logic, typeof options != 'string' ? options.altMappingBack : null)
      }
      if (MC.isFunction(self.afterRenderForm)) {
        flow.setAfterRenderFormFunction(self.afterRenderForm)
      }
      if (action.interface.cache) {
        flow.setCacheable()
      }
      flow.setEnv(this.env)
      flow.setWantedLogLevel(this.wantedLogLevel)
      flow.loadAndStart(input, trace)
    }
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return undefined
  }
}

Flow.prototype.runOnServer = function() {
  var input = {};
  if (this.flow.input && Array.isArray(this.flow.input)) {
    if (this.convertInputTreeData(this.flow, this.input, input)) {
      MC.replaceValues(input, "", null)
      this.input = input
    } else {
      return;
    }
  }
  if (!this.parentFlow) {
    MCHistory.history(this, null, 'OPERATION START', {'Input': this.input}, {end: this.opStartDate})
  }
  if (!this.reactFlow()) {
    return
  }
  let url = this.reactFlow().props.mconf.baseUrl + ReactFlow.flowServerUrl + this.confPath + '/' + this.flowName
  if (this.debug('BASIC')) {
    url += '?includeid=true&loggingthreshold=' + this.logLevel
  }
  let method = 'POST';
  let content = JSON.stringify(input);
  if (this.cache) {
    method = 'GET'
    url += (url.indexOf('?') > 0 ? '&' : '?') + 'inputdata=' + encodeURIComponent(JSON.stringify(input))
    content = null;
  }
  let self = this
  MC.callServer(method, url, MC.getJsonType(), content, MC.getJsonType()).then(function (res) {
    try {
      var output = {};
      var content = res.content;
      var log = {}
      if (content) {
        try {
          output = JSON.parse(content);
        } catch (e) {
          self.endOperationException('SYS_IntegrationExc', 'Invalid server response: ' + content, input, null, null, self.inputMapTrace)
          return
        }
        if (!MC.isNull(output['fl:flowId']) && !MC.isNull(output['fl:flowLogId'])) {
          log = {flowId: output['fl:flowId'], flowLogId: output['fl:flowLogId']}
        }
      }
      log.requestId = res.requestId
      if (res.status == 200 || res.status == 204) {
        if (!MC.isNull(output['fl:flowId']) && !MC.isNull(output['fl:flowLogId'])) { // deleting only for ok response, for bad will be stored into env.exception
          delete output['fl:flowId']
          delete output['fl:flowLogId']
        }
        MC.replaceValues(output, null, "")
        self.endOperation(output, log)
      } else {
        var type = 'SYS_IntegrationExc';
        if (output.errorName) {
          type = output.errorName;
        }
        var message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
        if (output.errorMessage) {
          message = output.errorMessage;
        }
        self.endOperationException(type, message, input, output, log, self.inputMapTrace)
        return
      }
    } catch (e) {
      self.endOperationException('SYS_IntegrationExc', e.message, input, null, null, self.inputMapTrace)
      return
    }
  }).catch(function (err) {
    if (navigator.onLine) {
      self.endOperationException('SYS_IntegrationExc', 'Calling server flow failed for url ' + url + ': ' + err.message, input, null, null, self.inputMapTrace)
      return
    } else {
      self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message, input, null, null, self.inputMapTrace)
      return
    }
  });
};

Flow.prototype.convertOutputTreeData = function(outputTree, valueTree, contextTree) {
  if (Array.isArray(valueTree) && valueTree.length > 0) {
    valueTree = valueTree[0]
  }
  for (let i=0; i<outputTree.output.length; i++) {
    let param = outputTree.output[i]
    let key = param.name
    if (MC.isNull(key)) {
      if (param.basictype == 'anyType' && !MC.isNull(valueTree)) {
        MC.extend(contextTree, valueTree)
      }
    } else {
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1)
      }
      if (valueTree && !MC.isNull(valueTree[key])) {
        try {
          let values
          let inValues = valueTree[key]
          if (param.name.endsWith('*')) {
            if (!Array.isArray(inValues)) {
              let arr = []
              arr.push(inValues)
              inValues = arr
            }
            values = []
            for (let m=0; m<inValues.length; m++) {
              if (typeof inValues[m] === 'undefined') continue
              let value;
              if (param.output && inValues[m] !== '') {
                value = {};
                if (!this.convertOutputTreeData(param, inValues[m], value)) {
                  return false
                }
              } else {
                value = MC.normalizeValue(inValues[m], param.basictype)
              }
              if (!MC.isNull(value)) {
                values.push(value)
              }
            }
          } else {
            if (Array.isArray(inValues)) {
              inValues = MC.getFirstNotNull(inValues)
            }
            if (param.output && inValues !== '') {
              values = {}
              if (!this.convertOutputTreeData(param, inValues, values)) {
                return false
              }
            } else {
              values = MC.normalizeValue(inValues, param.basictype)
            }
          }
          if (!MC.isNull(values)) {
            contextTree[key] = values
          }
        } catch (e) {
          e.message = 'Error building operation output: ' + e.message
          this.endOperationException('SYS_MappingExc', e)
          return false
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_MappingExc', "Output parameter '" + param.name + "' must have value!")
          return false
        }
      }
    }
  }
  return true
}

Flow.prototype.decisionAction = function() {
  try {
    var action = this.context.action;
    if (!action.branch) {
      this.endOperationException('SYS_InvalidModelExc', 'Decision action "' + action.code + '" must have at least one branch defined!');
      return;
    }
    var testedBranches = {};
    for (var i = 0; i < action.branch.length; i++) {
      var branch = action.branch[i];
      var passed = true;
      let trc = this.debug('TRACE')
      let opts = {function: this.flow.function, trace: trc}
      testedBranches[branch.name] = {}
      if (branch.expr && Array.isArray(branch.expr)) {
        for (var e = 0; e < branch.expr.length; e++) {
          var expression = new Expression()
          expression.init(branch.expr[e], this.context.data, opts)
          var res = expression.evaluate();
          if (expression.getError()) {
            MC.error(expression.getErrorMessage());
          }
          if (res === undefined) { // $variable assign is ignored, must be before casting to boolean
            res = true
          }
          res = MC.castToScalar(res, 'boolean')
          if (trc) {
            if (!testedBranches[branch.name]['trace']) {
              testedBranches[branch.name]['trace'] = []
            }
            testedBranches[branch.name]['trace'].push(expression.getTrace())
          }
          if (MC.isNull(res) || res === '') {
            this.endOperationException('SYS_MappingExc', 'Expression of decision action condition in branch "' + branch.name + '" must evaluate to boolean, but result value is null or empty!', null, null, null, {'Tested branches': testedBranches})
            return
          }
          if (!res) {
            passed = false
            break
          }
        }
      } 
      testedBranches[branch.name]['result'] = passed
      if (passed) {
        if (!branch.nextaction) {
          this.endOperationException('SYS_InvalidModelExc', 'Branch "' + branch.name + '" of decision action "' + action.code + '" must have next action defined!');
          return;
        }
        this.context.nextAction = this.getActionById(branch.nextaction)
        this.context.nextAltmapping = branch.altmapping
        var result = {};
        result['branch'] = branch.name;
        result['action'] = this.context.nextAction.code;
        this.progress({'Result': result, 'Tested branches': testedBranches});
        return;
      }
    }
    this.endOperationException('SYS_MappingExc', 'No branch of decision action "' + action.code + '" passed!');
    return;
  } catch (ex) {
    this.endOperationException('SYS_MappingExc', ex.message);
  }
};

Flow.prototype.endAction = function() {
  let [output, trace] = this.mapToResultObject(this.selectMapping(this.context.action, this.context.altmapping), this.context)
  if (output) {
    MCHistory.history(this, this.context.action, null, {'Output': output, 'Trace' : trace}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    if (this.context.action.throwsException) {
      let mess = "End action exception"
      if (output) {
        if (output['sys:errorCode']) {
          mess += ' [' + output['sys:errorCode'] + ']'
        }
        if (output['sys:errorMessage']) {
          mess += ': ' + output['sys:errorMessage']
        }
      }
      this.endOperationException(this.context.action.throwsException.name, mess)
    } else {
      this.endOperation(output, null, this.context.action.redirect)
    }
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return
  }
}

Flow.prototype.selectMapping = function(action, altMappingName) {
  if (altMappingName) {
    if (Array.isArray(action.altMapping)) {
      for (let mapping of action.altMapping) {
        if (mapping.name == altMappingName) {
          return {name: altMappingName, mapping: mapping.mapping}
        }
      }
    }
    return {exception: 'Alternative mapping with name ' + altMappingName + ' not found in acion "' + action.code + '"!'}
  } else {
    return {mapping: action.mapping}
  }
}

Flow.prototype.preparePathToRedirect = function(output) {
  let path = output.path
  if (!MC.isNull(path)) {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    let sep = path.indexOf('?') > -1 ? '&' : '?'
    if (output.parameters && MC.isPlainObject(output.parameters)) { 
      for (let key in output.parameters) {
        if (output.parameters.hasOwnProperty(key)) {
          path += sep + key + '=' + encodeURIComponent(output.parameters[key])
        }
        sep = '&'
      }
    }
    return path
  } else {
    this.endOperationException('SYS_MappingExc', 'Output of redirect operation not contains path!', null, output, null, null)
  }
}

Flow.prototype.isDispatchRedirectNeeded = function(input, output) {
  if (MC.isNull(output.path) || MC.isNull(input.path)) { // dispatch operation not redirects, or is not has valid input
    return false
  }
  if (input.path != output.path) {
    return true
  }
  let queryParameters1 = input.parameters
  if (!queryParameters1 && !MC.isPlainObject(queryParameters1)) {
    queryParameters1 = {}
  }
  let queryParameters2 = output.parameters
  if (!queryParameters2 && !MC.isPlainObject(queryParameters2)) {
    queryParameters2 = {}
  }
  if (queryParameters1['debug'] && !queryParameters2['debug']) {
    queryParameters2['debug'] = queryParameters1['debug']
  }
  if (queryParameters1['jsi.debug'] && !queryParameters2['jsi.debug']) {
    queryParameters2['jsi.debug'] = queryParameters1['jsi.debug']
  }
  if (queryParameters1['configuration'] && !queryParameters2['configuration']) {
    queryParameters2['configuration'] = queryParameters1['configurationg']
  }
  if (Object.keys(queryParameters1).length != Object.keys(queryParameters2).length) {
    return true
  }
  for (let param in queryParameters1) {
    if (queryParameters1[param] !== queryParameters2[param]) {
      return true
    }
  }
  return false
}

Flow.prototype.endOperation = function(unorderedOutput, log, redirect) {
  var output = {};
  if (redirect) {
    output = unorderedOutput
    if (this.input && this.input['MNC.dispatchOperation'] === true) { // end of dispatch operation in MINI_App_FE, this is for old dispatch operations compatibility and will be removed
      if (!this.isDispatchRedirectNeeded(this.input, output)) {
        redirect = false
        MCHistory.log(MCHistory.T_WARNING, 'Using redirect to identical path in dispatch operation is deprecated. Use end action without redirect in dispatch operation instead.', this.debug())
      }
    }
  } else if (unorderedOutput && this.takenFromCache) {
    output = unorderedOutput
  } else if (unorderedOutput && this.flow.output) {
    if (!this.convertOutputTreeData(this.flow, unorderedOutput, output)) {
      return false
    }
  }
  if (redirect && this.reactFlow()) {
    const npath = this.preparePathToRedirect(output)
    this.reactFlow().routeTo(null, npath)
  } else if (!this.parentFlow) {
    if (!this.flow.action || this.serverSide) {
      let trace = this.flow.kind == 'decisiontable' ? log : null
      log = this.flow.kind == 'decisiontable' ? null : log
      MCHistory.history(this, null, 'OPERATION END', {'Output': output, 'Server log': log, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart});
    }
    if (MC.isFunction(this.onEndFunction)) {
      if (this.reactFlow() && this.reactFlow().props.clearStateOnEnd) {
        this.reactFlow().stopDimmer({state: null})
      }
      this.onEndFunction(output, undefined, {endAction: this.context && this.context.action})
    } else {
      this.showOutput(output);
    }
  } else {
    if (this.cacheable && !this.serverSide && !this.takenFromCache) {
      MCCache.put(this.cacheKey(), output)
    }
    if (!MC.isNull(this.lazyAction)) {
      this.parentFlow.addToContext(this.parentFlow.context.data, this.lazyAction.code, output)
      const formData = this.parentFlow.formData ? this.parentFlow.formData : this.parentFlow.reactFlow().state.formData
      this.parentFlow.progressLazyForm(formData, {'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace, executionId: this.instanceId, target: this.flow, takenFromCache: this.takenFromCache}, this.lazyAction, this.lazyActionLogic, this.lazyActionAltMapping)
    } else {
      if (['framework', 'function', 'decisiontable'].indexOf(this.flow.kind) > -1) {
        let trace = this.flow.kind == 'decisiontable' ? log : this.inputMapTrace
        MCHistory.history(this, null, 'OPERATION', {'Input': this.input, 'Output': output, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
      }
      let action = this.parentFlow.context.action;
      if (!action && ['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.parentFlow.flow.id) > -1) {
        if (MC.isFunction(this.parentFlow.onEndFunction)) {
          if (this.parentFlow.reactFlow() && this.parentFlow.reactFlow().props.clearStateOnEnd) {
            this.parentFlow.reactFlow().stopDimmer({state: null})
          }
          this.parentFlow.onEndFunction(output, undefined, {endAction: this.context && this.context.action})
          return
        } else {
          this.input = this.parentFlow.input
          this.parentFlow = this.parentFlow.parentFlow
          action = this.parentFlow.context.action
          output = {output: output, executionId: this.instanceId}
        }
      }
      let executionId = this.instanceId
      if (action.multiCall) {
        if (!this.parentFlow.multiOutput) {
          this.parentFlow.multiOutput = [];
        }
        this.parentFlow.multiOutput.push(output);
        MCHistory.history(this.parentFlow, this.parentFlow.context.action, 'MULTICALL(' + this.parentFlow.multiOutput.length + ')', {'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.parentFlow.multiTrace, executionId: this.instanceId, target: this.flow, takenFromCache: this.takenFromCache}, {start: this.parentFlow.context.actionStartDate, end: Date.now(), duration: performance.now() - this.parentFlow.context.actionStart})
        if (this.parentFlow.multiInput.length > 0) {
          this.parentFlow.callAction()
          return;
        } else {
          output = {'output': this.parentFlow.multiOutput};
          this.parentFlow.multiInput = null;
          this.parentFlow.multiOutput = null;
          this.inputMapTrace = this.parentFlow.multiTrace; 
          this.parentFlow.multiTrace = null;
          this.parentFlow.context.actionStart = null
          this.parentFlow.context.actionStartDate = null
          this.input = this.parentFlow.multiInputOrig;
          this.parentFlow.multiInputOrig = null;
          log = null
          executionId = null
        }
      }
      this.parentFlow.addToContext(this.parentFlow.context.data, action.code, output)
      log = this.flow.kind == 'decisiontable' ? null : log
      this.context.data = {}
      this.parentFlow.progress({'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace, executionId: executionId, target: this.flow, takenFromCache: this.takenFromCache});
    }
  }
};

Flow.prototype.endOperationException = function(type, message, input, output, log, trace) {
  this.isRenderingInterupted = true
  this.clearLogicTimers();
  if (!MC.isPlainObject(type)) {
    type = this.buildExceptionTypeObject(type);
  }
  if (this.flow) {
    if (this.context.action) {
      message = " operation " + this.flow.id + " / action " + this.context.action.code + " / " + message;
    } else {
      message = " operation " + this.flow.id + " / " + message;
    }
  }
  var exception = {};
  exception.errorName = type.name;
  exception.errorMessage = message;
  exception.errorCode = type.name;
  if (!MC.isNull(output)) {
    exception = output;
  }
  if (log) {
    exception.serverRequestId = log.requestId
  }
  if (!this.context.data.env) {
    this.context.data.env = {}
  }
  this.context.data.env.exception = exception;
  let relevantException = false;
  if (this.context.action && this.context.action.exception) {
    relevantException = this.getRelevantException(type, this.context.action.exception);
  }
  if (relevantException) {
    MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
    this.context.nextAction = this.getActionById(relevantException.nextaction);
    this.context.nextAltmapping = relevantException.altmapping
    this.isRenderingInterupted = false
    this.progress({'Input': input, 'Output': exception, 'Trace': trace, 'Server log': log, isException: true, exceptionHandled: true})
  } else {
    var startAction = null;
    if (this.flow && this.flow.action) {
      startAction = this.getStartAction();
    }
    let isThrowedFromEnd = false;
    if (this.context.action && this.context.action.kind === 'end' && this.context.action.throwsException && this.context.action.throwsException.name === type.name) {
      isThrowedFromEnd = true;
    }
    relevantException = false;
    if (startAction && startAction.exception) {
      relevantException = this.getRelevantException(type, startAction.exception);
    }
    if (!isThrowedFromEnd && relevantException) {
      MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
      this.context.nextAction = this.getActionById(relevantException.nextaction);
      this.context.nextAltmapping = relevantException.altmapping
      this.isRenderingInterupted = false
      this.progress({'Input': input, 'Output': exception, 'Trace': trace, 'Server log': log, isException: true, exceptionHandled: true})
    } else {
      if (!this.parentFlow) {
        MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log, 'Trace': trace, isException: true}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
        if (MC.isFunction(this.onEndFunction)) {
          if (this.reactFlow()) {
            if (this.reactFlow().props.clearStateOnEnd) {
              this.reactFlow().stopDimmer({state: null})
            } else {
              this.reactFlow().stopDimmer({state: 'exception', exception: {type: type.name, message: message}})
            }
          }
          this.onEndFunction(type.name, message, {endAction: null})
        } else if (this.reactFlow()) {
          this.reactFlow().stopDimmer({state: 'exception', exception: {type: type.name, message: message}});
        }
      } else {
        if (this.flow && this.flow.action && !this.flow.mock) {
          MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': exception, 'Server log': log, 'Trace': trace, isException: true}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
        }
        if (this.parentFlow.context && this.parentFlow.context.action && this.parentFlow.context.action.multiCall) {
          this.parentFlow.multiInput = null
          this.parentFlow.multiOutput = null
          trace = this.parentFlow.multiTrace
          this.parentFlow.multiTrace = null
          input = this.parentFlow.multiInputOrig
          this.parentFlow.multiInputOrig = null
        }
        this.parentFlow.endOperationException(type, message, input, exception, log, trace);
      }
    }
  }
};

Flow.prototype.buildExceptionTypeObject = function(type) {
  let exception = {name: type}
  exception.parent = []
  if (this.flow && this.flow.exception && this.flow.exception[type]) {
    let curr = this.flow.exception[type]
    while (!MC.isNull(curr.parent)) {
      exception.parent.push(curr.parent)
      curr = this.flow.exception[curr.parent]
    }
  } else {
    exception.parent.push('SYS_RootExc')
  }
  return exception
}

Flow.prototype.getRelevantException = function(thrown, caughtArr) {
  if (Array.isArray(caughtArr) && caughtArr.length > 0) {
    for (let caught of caughtArr) {
      if (thrown.name == caught.name || Array.isArray(thrown.parent) && thrown.parent.indexOf(caught.name) > -1) {
        return caught;
      }
    }
  }
  return false;
};

Flow.prototype.leaveOperation = function(calls, input, trace) {
  MCHistory.history(this, this.context.action, 'LEAVE FLOW', {'Output': input, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
  if (!this.parentFlow) {
    this.setFlowConfiguration(this.confPath, calls)
    let cfg = this.context.data.env.cfg
    this.context = {data: {env: {}}}
    this.env = {}
    this.context.data.env.cfg = cfg //TODO: configuration should be deleted too, now throws js error
    this.flow = null
    this.loadAndStart(input)
  } else {
    this.parentFlow.leaveOperation(calls, input, trace)
  }
}

Flow.prototype.transformAction = function() {
  var action = this.context.action;
  let [input, trace] = this.mapToResultObject(this.selectMapping(action, this.context.altmapping), this.context)
  if (input) {
    var output = {};
    if (!this.convertOutputTreeData(action, input, output)) {
      return false
    }
    this.addToContext(this.context.data, action.code, output);
    this.progress({'Input': input, 'Output': output, 'Trace' : trace});
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return
  }
};

Flow.prototype.feedbackAction = function() {
  try {
    var action = this.context.action;
    var feedback = MC.extend({}, action.feedback);
    if (MC.isNull(feedback)) {
      this.endOperationException('SYS_InvalidModelExc', "Feedback action '" + action.code + "' has no feedback assigned!");
      return;
    }
    let result = {};
    let trace;
    if (!MC.isNull(action.mapping)) {
      [result, trace] = this.mapToResultObject(this.selectMapping(action, this.context.altmapping), this.context)
      if (!result) {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      var nresult = {};
      for (var prop in result) {
        nresult['@' + prop] = result[prop];
      }
      feedback.param = {value: nresult};
      feedback.title = MC.formatValue('', 'message', null, feedback.title, feedback)
      feedback.help = MC.formatValue('', 'message', null, feedback.help, feedback)
    }
    let rootFlow = this.getRootFlow()
    if (!Array.isArray(rootFlow.context.feedback)) {
      rootFlow.context.feedback = []
    }
    rootFlow.context.feedback.push(feedback)
    this.progress({'Input': result, 'Trace' : trace})
  } catch (e) {
    this.endOperationException('SYS_MappingExc', e.message, null, null, null, null)
  }
  
}

Flow.prototype.showOutput = function(output) {
  if (this.debug()) {
    this.reactFlow().stopDimmer({state: 'output', output: output})
  } else {
    this.reactFlow().stopDimmer({state: ''})
  }
}

Flow.prototype.makeObject = function(key, valueObj, checkNullInColl) {
  let newValue = null
  let newKey = null
  if (key.indexOf('/') > 0) {
    newKey = key.substring(0, key.indexOf('/'))
    if (newKey.endsWith("*") && Array.isArray(valueObj)) {
      newValue = []
      for (let i=0; i<valueObj.length; i++) {
        let res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj[i], true)
        newValue.push(res)
      }
    } else {
      let res = this.makeObject(key.substring(key.indexOf('/')+1), valueObj, checkNullInColl);
      if (newKey.endsWith('*')) {
        newValue = []
        newValue.push(res)
      } else {
        newValue = res
      }
    }
  } else {
    newKey = key
    if (key.endsWith('*') && !Array.isArray(valueObj) && valueObj !== '') {
      newValue = [valueObj];
    } else {
      newValue = valueObj;
    }
    if (MC.isNull(valueObj) && checkNullInColl) {
      this.wasNullInBuiltObject = true
    }
  }
  let object = {}
  if (newKey.endsWith('*')) {
    newKey = newKey.substring(0, newKey.length-1)
  }
  object[newKey] = newValue
  return object
}

Flow.prototype.makeObjectRecursive = function(valueObj, extend) {
  if (MC.isPlainObject(valueObj)) { 
    let object = {}
    for (let key in valueObj) {
      let newObject = this.makeObject(key, valueObj[key], false)
      if (!MC.isNull(newObject)) {
        if (extend) {
          MC.extend(false, object, newObject)
        } else {
          Object.assign(object, newObject)
        }
      }
    }
    if (this.wasNullInBuiltObject) {
      object = MC.fixSparseColls(object)
      this.wasNullInBuiltObject = false
    }
    if (MC.isEmptyObject(object)) {
      return null
    } else {
      return object
    }
  } else {
    return valueObj
  }
}

Flow.prototype.mapToResultObject = function(mappingObj, context) {
  if (mappingObj.exception) {
    return [false, [mappingObj.exception, null]]
  }
  let result = {}
  let traceObj = {}
  let trc = this.debug('TRACE')
  if (mappingObj.name && trc) {
    traceObj['Used alternative mapping'] = mappingObj.name
  }
  if (mappingObj.mapping && Array.isArray(mappingObj.mapping)) {
    let opts = {function: this.flow.function, trace: trc}
    for (let mapping of mappingObj.mapping) {
      if (MC.isEmptyObject(mapping)) {
        continue
      }
      try {          
        let expression = new Expression()
        expression.init(mapping, context.data, opts)
        let valueObj = expression.evaluate()
        if (trc) {
          const newTrace = expression.getTraceAsPaths()
          for (const path in newTrace) {
            if (traceObj[path]) {
              if (!Array.isArray(traceObj[path])) {
                traceObj[path] = [traceObj[path]];
              }
              traceObj[path].push(newTrace[path])
            } else {
              traceObj[path] = newTrace[path]
            }
          }
        }
        if (expression.getError()) {
          MC.error(expression.getErrorMessage())
        }
        if (!MC.isNull(valueObj)) {
          let extend = true
          if (this.isOnlySourceMapping(mapping)) {
            extend = false
          }
          valueObj = this.makeObjectRecursive(valueObj, extend)
          if (!MC.isNull(valueObj)) {
            if (Array.isArray(valueObj) && MC.isEmptyObject(result)) { // case where array is mapped into root
              result = valueObj
            } else {
              for (let key in valueObj) {
                result[key] = valueObj[key]
              }
            }
          }
        }
      } catch (ex) {
        return [false, [ex.message, traceObj]]
      }
    }
  }
  return [result, traceObj]
}

Flow.prototype.isOnlySourceMapping = function(mapping) {
  if (!mapping.operator) {
    if (!mapping.target) {
      if (mapping.source) {
        return true
      }
    } else {
      if (mapping.expr.length == 1) {
        return this.isOnlySourceMapping(mapping.expr[0])
      }
    }  
  }
  return false
}

Flow.prototype.runMock = function() {
  try {
    var operation = this.flow;
    if (operation.output) {
      var output = {};
      if (Array.isArray(operation.mock) && operation.mock.length > 0) {
        // mapping into input for evaluating conditions
        for (var i=0; i<operation.mock.length; i++) {
          var passed = true;
          if (operation.mock[i].expr) {
            var expression = new Expression();
            expression.init(operation.mock[i].expr[0], {input: this.input}, {trace: this.debug('TRACE')})
            if (!expression.evaluate()) {
              passed = false;
            }
            if (expression.getError()) {
              MC.error(expression.getErrorMessage());
            }
          }
          if (passed) {
            this.convertMockTreeData(operation, operation.mock[i].data, output);
            return output;
          }
        }
      }
    }
  } catch (ex) {
    this.endOperationException('SYS_MappingExc', ex.message);
  }
  return false;
};

Flow.prototype.runFunctionOperation = function() {
  var type = this.flow.functiontype;
  if (type == 'javascript') {
    if (!this.flow.functioncode) {
      this.endOperationException('SYS_InvalidModelExc', 'Code of function operation ' + this.flow.id + ' can not be empty!');
    }
    var input = this.input;
    var output = {};
    eval(this.flow.functioncode);
    this.endOperation(output);
  } else {
    this.endOperationException('SYS_InvalidModelExc', 'Unsupported function type: ' + type);
  }
};

Flow.prototype.runFrameworkOperation = function() {
  let input = {}
  if (this.flow.input && Array.isArray(this.flow.input)) {
    if (!this.convertInputTreeData(this.flow, this.input, input)) {
      return
    }
  }
  let self = this
  if (this.flow.id == 'BRWS_ExternalUrlWindowOpen') {
    var url = input.url;
    if (!MC.isNull(input.params) && MC.isPlainObject(input.params)) {
      for (var param in input.params) {
        url += (url.indexOf('?') < 0 ? '?' : '&') + param + '=' + input.params[param];
      }
    }
    var target = input.target;
    if (target == 'blank') {
      window.open(url);
      this.endOperation(null);
    } else if (target == 'top') {
      window.top.location.href = url;
    } else if (target == 'parent') {
      window.parent.location.href = url;
    } else {
      this.reactFlow().routeTo(null, url)
    }
  } else if (this.flow.id == 'FWK_EventSend') {
    var urlarr = window.location.href.split("/");
    if (input.iframeId) {
      document.getElementById(input.iframeId).contentWindow.postMessage(input, urlarr[0] + "//" + urlarr[2])
    } else if (input.parent === true && parent) {
      parent.postMessage(input, urlarr[0] + "//" + urlarr[2]);
    } else {
      window.postMessage(input, urlarr[0] + "//" + urlarr[2]);
    }
    this.endOperation(null);
  } else if (this.flow.id == 'FWK_EnvironmentRefresh') {
    if (this.context.data.env.cfg['fl:environmentOperation']) {
      MC.getEnvironmentContext(this.confPath, this.context.data.env.cfg['fl:environmentOperation'], self.reactFlow().props.mconf).then(function(context) {
        self.updateEnvContext(context);
        self.endOperation(null);
      });
    } else {
      self.updateEnvContext(null);
      self.endOperation(null);
    }
  } else if (this.flow.id == 'FWK_CacheClear') {  
    MCCache.clear()
    self.endOperation(null)
  } else if (['BRWS_LocalDataStore', 'BRWS_LocalDataGet', 'BRWS_LocalDataList', 'BRWS_LocalDataDelete'].indexOf(this.flow.id) > -1) {
    let res;
    switch (this.flow.id) {
      case 'BRWS_LocalDataStore': res = MCBrws.store(input.path, input.data); break;
      case 'BRWS_LocalDataGet': res = MCBrws.get(input.path); break;
      case 'BRWS_LocalDataList': res = MCBrws.list(input.path); break;
      case 'BRWS_LocalDataDelete': res = MCBrws.delete(input.path); break;
    }
    if (res.error) {
      this.endOperationException('SYS_InvalidModelExc', res.error);
    } else {
      this.endOperation(res.result);
    }
  } else if (this.flow.id == 'BRWS_ResizeImage') {
    let quality = 0.75;
    if (input.quality && MC.isNumeric(input.quality) && input.quality >= 0 && input.quality <= 1) {
      quality = parseFloat(input.quality);
    }
    let maxWidth = 800;
    if (input.maxWidth && MC.isNumeric(input.maxWidth) && input.maxWidth > 0) {
      maxWidth = parseInt(input.maxWidth);
    }
    let maxHeight = 600;
    if (input.maxHeight && MC.isNumeric(input.maxHeight) && input.maxHeight > 0) {
      maxHeight = parseInt(input.maxHeight);
    }
    let image = new Image();
    image.onload = function() {
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      let output = {contentType: "image/jpeg"};
      let data = canvas.toDataURL('image/jpeg', quality);
      output.data = data.substring(data.indexOf(';base64,')+8);
      self.endOperation(output);
    };
    image.src = 'data:' + input.contentType + ';base64,' + input.data;
  } else if (this.flow.id == 'BRWS_ImageAdaptiveThreshold') {
    let ratio = 0.6
    if (input.ratio && MC.isNumeric(input.ratio) && input.ratio >= 0 && input.ratio <= 1) {
      ratio = parseFloat(input.ratio)
    }
    let image = new Image()
    image.onload = function() {
      let width = image.width
      let height = image.height
      let sourceCanvas = document.createElement('canvas')
      sourceCanvas.width = width
      sourceCanvas.height = height
      let ctx = sourceCanvas.getContext("2d")
      ctx.drawImage(image, 0, 0)
      let sourceImageData = ctx.getImageData(0, 0, width, height)
      let sourceData = sourceImageData.data
      let integral = new Int32Array(width * height)
      let x = 0, y = 0, lineIndex = 0, sum = 0
      for (x = 0; x < width; x++) {
        sum += sourceData[x << 2]
        integral[x] = sum
      }
      for (y = 1, lineIndex = width; y < height; y++, lineIndex += width) {
        sum = 0
        for (x = 0; x < width; x++) {
          sum += sourceData[(lineIndex + x) << 2]
          integral[lineIndex + x] = integral[lineIndex - width + x] + sum
        }
      }
      let s = width >> 4; // in fact it's s/2, but since we never use s...
      let canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      let result = canvas.getContext('2d').createImageData(width, height)
      let resultData = result.data
      let resultData32 = new Uint32Array(resultData.buffer)
      x = 0; y = 0; lineIndex = 0
      for (y = 0; y < height; y++, lineIndex += width) {
        for (x = 0; x < width; x++) {
          let value = sourceData[(lineIndex + x) << 2]
          let x1 = Math.max(x - s, 0)
          let y1 = Math.max(y - s, 0)
          let x2 = Math.min(x + s, width - 1)
          let y2 = Math.min(y + s, height - 1)
          let area = (x2 - x1 + 1) * (y2 - y1 + 1)
          let localIntegral = integral[x2 + y2 * width]
          if (y1 > 0) {
            localIntegral -= integral[x2 + (y1 - 1) * width]
            if (x1 > 0) {
              localIntegral += integral[(x1 - 1) + (y1 - 1) * width]
            }
          }
          if (x1 > 0) {
            localIntegral -= integral[(x1 - 1) + (y2) * width];
          }
          if (value * area > localIntegral * ratio) {
            resultData32[lineIndex + x] = 0xFFFFFFFF
          } else {
            resultData32[lineIndex + x] = 0xFF000000
          }
        }
      }
      canvas.getContext("2d").putImageData(result, 0, 0)
      let output = {contentType: "image/png"}
      let data = canvas.toDataURL('image/png', 1)
      output.data = data.substring(data.indexOf(';base64,')+8)
      self.endOperation(output)        
    }
    image.src = 'data:' + input.contentType + ';base64,' + input.data
  } else if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE'].indexOf(this.flow.id) > -1) {
    if ((MC.isNull(input.operation) || input.operation === '') && (MC.isNull(input.operationId) || input.operationId === '')) {
      this.endOperationException('SYS_InvalidModelExc', `"operation" or "operationId" input parameter of ${this.flow.id} can not be null or empty!`)
      return
    }
    let confPath = this.confPath
    if ('FWK_OperationWithConfigExecute_FE' == this.flow.id) {
      confPath = input.flowConfig
    }
    const flow = new Flow()
    flow.init().setLang(self.lang).setConfPath(confPath).setParentFlow(self).setFlowConfiguration(confPath, input.operation)
    if (MC.isFunction(self.afterRenderForm)) {
      flow.setAfterRenderFormFunction(self.afterRenderForm)
    }
    if (input.executionId) {
      flow.setInstanceId(input.executionId)
    }
    if ('FWK_OperationExecute_FE' == this.flow.id) {
      flow.setEnv(this.env)
    }
    flow.setWantedLogLevel(this.wantedLogLevel)
    flow.loadAndStart(input.input, null)
  } else if (['BRWS_LocalStorageItemSet', 'BRWS_LocalStorageItemGet', 'BRWS_LocalStorageItemRemove'].indexOf(this.flow.id) > -1) {
    try {
      let res = null
      switch (this.flow.id) {
        case 'BRWS_LocalStorageItemSet':
          if (!MC.isNull(input.data)) {
            localStorage.setItem(input.key, JSON.stringify(input.data))
          } else {
            localStorage.removeItem(input.key)
          }
          break
        case 'BRWS_LocalStorageItemGet': 
          res = localStorage.getItem(input.key)
          res = {data: res ? JSON.parse(res) : null}
          break
        case 'BRWS_LocalStorageItemRemove': 
          localStorage.removeItem(input.key)
          break
      }
      this.endOperation(res)
    } catch (e) {
      this.endOperationException('SYS_TechnicalExc', e.message)
    }
  } else if (this.flow.id == 'BRWS_GetBase') {
    const base = document.querySelector('base').href.split('?')[0]
    this.endOperation({base: base.substring(0, base.lastIndexOf("/") + 1)})
  } else if (this.flow.id == 'BRWS_GetThisRi') {
    this.endOperation({ri: this.reactFlow().props.mconf.rbPath})
  } else if (this.flow.id == 'BRWS_GetRi') {
    let base = document.querySelector('base').href.split('?')[0]
    base = base.substring(0, base.lastIndexOf("/") + 1)
    this.endOperation({ri: window.location.href.substring(base.length)})
  } else if (this.flow.id == 'BRWS_SetRi') {
    let base = document.querySelector('base').href.split('?')[0]
    base = base.substring(0, base.lastIndexOf("/") + 1)
    let currRi = window.location.href.substring(base.length)
    let url = input.ri
    url = MC.ensureSystemParameters(currRi, url)
    if (url !== currRi) {
      window.history.pushState(null, 'title', base + url)
    }
    this.endOperation()
  } else if (this.flow.id == 'FWK_Wait_FE') {
    setTimeout(() => { self.endOperation()}, input.waitTime)
  } else if (this.flow.id == 'BRWS_OperationsPreload') {
    if (input.operationName && Array.isArray(input.operationName)) {
      MC.preloadFlowDefinitions(self.confPath, input.operationName, self.lang, self.reactFlow().props.mconf).then(() => {
        self.endOperation()
      }).catch((err) => {
        this.endOperationException('SYS_IntegrationExc', 'Error while preloading definition of operations! ' + err)
      })
    } else {
      this.endOperation()
    }
  } else if (['FWK_ListFlowLogEntries', 'FWK_ListFlowLogEntriesTree'].indexOf(this.flow.id) > -1) {
    this.endOperation(MCHistory.listFLowLog(input.executionId, 'FWK_ListFlowLogEntriesTree' == this.flow.id))
  } else if (this.flow.id == 'FWK_ListLogMessages') {
    this.endOperation({'messages': MCHistory.getLog().map(m => { return {type: m.type, mess: m.mess, time: MC.luxonToDateTimeString({v: DateTime.fromJSDate(m.time)}, 'dateTime', false)} })})
  } else if (this.flow.id == 'BRWS_LinkSet') {
    let link = input.rel == 'icon' ? document.querySelector("link[rel~='icon']") : document.querySelector("link[data-key~='" + input.key + "']")
    if (!link) {
      link = document.createElement('link')
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.dataset.key = input.key
    link.rel = input.rel
    link.type = input.type
    link.href = MC.rebaseUrl(this.parentFlow.flow.model, input.href, this.reactFlow().props.mconf)
    this.endOperation()
  } else if (this.flow.id == 'BRWS_LinkRemove') {
    let link = document.querySelector("link[data-key~='" + input.key + "']")
    if (link) {
      link.disabled = true
      link.remove()
    }
    this.endOperation()
  } else if (this.flow.id == 'BRWS_StyleSet') {
    let style = document.querySelector("style[data-key~='" + input.key + "']")
    if (!style) {
      style = document.createElement('style')
      document.getElementsByTagName('head')[0].appendChild(style)
      style.dataset.key = input.key
      style.type = 'text/css'
    }
    if (style.styleSheet) {
      style.styleSheet.cssText = input.css
    } else {
      style.appendChild(document.createTextNode(input.css))
    }
    this.endOperation() 
  } else if (this.flow.id == 'BRWS_StyleRemove') {
    let style = document.querySelector("style[data-key~='" + input.key + "']")
    if (style) {
      style.remove()
    }
    this.endOperation()
  } else if (this.flow.id == 'BRWS_ScriptLoad') {
    let script = document.querySelector("script[data-key~='" + input.key + "']")
    if (script) {
      script.remove()
    }
    script = document.createElement("script")
    script.type = "text/javascript"
    script.dataset.key = input.key
    document.body.appendChild(script)
    script.onload = () => {
      this.endOperation()
    }
    script.onerror = () => {
      this.endOperationException('SYS_InvalidModelExc', 'Error loading script from url ' + input.src)
    }
    script.src = MC.rebaseUrl(this.parentFlow.flow.model, input.src, this.reactFlow().props.mconf)
  } else if (this.flow.id == 'BRWS_ScriptEval') {
    let script = document.querySelector("script[data-key~='" + input.key + "']")
    if (script) {
      script.remove()
    }
    script = document.createElement("script")
    script.type = "text/javascript"
    script.dataset.key = input.key
    document.body.appendChild(script) 
    script.appendChild(document.createTextNode(input.script))
    this.endOperation()
  } else {
    this.endOperationException('SYS_InvalidModelExc', 'Unsupported framework operation: ' + this.flow.id)
  }
}

Flow.prototype.updateEnvContext = function(context) {
  this.env.context = context;
  if (!MC.isNull(context) && MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
    this.setLang(context.request.language);
  }
  this.addToContext(this.context.data, 'env', this.env);
  if (this.parentFlow) {
    this.parentFlow.updateEnvContext(context);
  }
  if (this.debug('TRACE')) {
    MCHistory.history(this, null, 'ENVIRONMENT UPDATE', {'Environment': this.env}, {end: Date.now()})
  }
}

Flow.prototype.initLogicTimer = function(logic) {
  const self = this
  if (MC.isNumeric(logic.timer) && logic.timer > 0) {
    this.logicTimers[logic.name] = setTimeout(() => { 
      self.eventForm(null, null, null, null, {logic: logic})
    }, 1000*logic.timer) 
  }
}

Flow.prototype.initLogicTimers = function() {
  this.clearLogicTimers()
  if (this.reactFlow().state.formData.logic && Array.isArray(this.reactFlow().state.formData.logic)) {
    for (let logic of this.reactFlow().state.formData.logic) {
      this.initLogicTimer(logic)
    }
  }
}

Flow.prototype.clearLogicTimers = function() {
  if (!this.logicTimers) return
  for (let timerKey in this.logicTimers) {
    clearTimeout(this.logicTimers[timerKey])
  }
}

Flow.prototype.getCfgParameter = function(key) {
  if (this.context.data.env && this.context.data.env.cfg) {
    return this.context.data.env.cfg[key]
  }
}

Flow.prototype.runDecisionTableOperation = function() {
  let input = this.input || {}
  let inputdef = this.flow.input
  let output = {}
  let decisions = this.flow.decision
  let testedRows = []
  if (Array.isArray(decisions) && decisions.length > 0) {
    for (let row of decisions) {
      let rowTrace = {}
      let matches = true
      for (let inpar of inputdef) {
        let inputVal = MC.normalizeValue(input[inpar.name], inpar.basictype)
        let rowVal = MC.findByObjectParamValue(row.input, "name", inpar.name)
        if (rowVal) {
          rowVal = MC.normalizeValue(rowVal.val, inpar.basictype)
        }
        if (inputVal !== rowVal) {
          matches = false
        }
        if (this.debug('TRACE')) {
          rowTrace[inpar.name] = rowVal
        }
      }
      if (this.debug('TRACE')) {
        testedRows.push(rowTrace)
      }
      if (matches) {
        for (let out of row.output) {
          output[out.name] = out.val
        }
        break
      }
    }
  }
  if (MC.isEmptyObject(output)) {
    output = null
  }
  this.endOperation(output, {'Tested rows': testedRows})
}

Flow.prototype.getRootFlow = function() {
  return this.parentFlow ? this.parentFlow.getRootFlow() : this
}

Flow.prototype.reactFlow = function() {
  return this.getRootFlow().reactFlowObj
}

Flow.prototype.debug = function(requestedLevel) {
  if (!requestedLevel) {
    requestedLevel = 'NONE'
  }
  const levels = ['NONE', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
  if (this.logLevel) {
    if (levels.indexOf(requestedLevel) <= levels.indexOf(this.logLevel)) {
      return true
    } else {
      return false
    }
  } else if (this.wantedLogLevel) {
    if (this.wantedLogLevel == 'AUTO') return requestedLevel
    if (levels.indexOf(requestedLevel) <= levels.indexOf(this.logLevel)) {
      return true
    } else {
      return false
    }
  }
  return false
}

Flow.prototype.runLazyUpdate = function(field, paging) {
  if (!paging) {
    MC.putFieldParamValue(field.param, 'settings/@page', null)
  }
  const opts = this.getDataActionOpts(field.param)
  if (opts.action) {
    this.paginateForm(opts, field)
  } else {
    this.submitForm(field, 'store', null, this.formExecutionId)
  }
}

export {Flow}