import React from "react"

import Dropdown from '../client/semantic-widgets/Dropdown.jsx'
import {ReactFlow} from "../client/ReactFlow.jsx"
import {MC} from "../client/MC.js"
import {MCCache} from "../client/MCCache.js"

class TestApp extends React.Component {

  FSPL_NS = 'http://metarepository.com/fspl/svc_mta#';
  testsToRun = [];
  state = {modelKey: null, modelKeyFilled: null, testId: null, testsList: [], testResults: [], currentTest: null, batchIsRunning: false, flowName: null,
    input: null, start: false};

  resetStart = () => {
    this.setState({start: false})
  }

  changeModelDropdown = (e, d) => {
    this.changeModel(d.value)
  }

  changeModelKey = (evt) => {
    this.setState({modelKeyFilled: evt.target.value, start: false})
  }

  clickReloadInModel = () => {
    this.changeModel(this.state.modelKeyFilled)
  }

  changeModel = (val) => {
    this.setState({modelKey: val, modelKeyFilled: val, testsList: [], start: false})
    let self = this
    MC.callServer('GET', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_TestList?inputdata=' + encodeURIComponent(JSON.stringify({model: val})), MC.getJsonType()).then(function (res) {
      self.setState({modelKey: val, testsList: res.content ? JSON.parse(res.content).test : [], start: false})
    }).catch (function (err) {
      throw new Error(err.message)
    })
  }

  changeTest = (e, d) => {
    this.setState({testId: d.value, start: false});
  };

  onRunAllTests = () => {
    this.setState({testResults: [], start: false});
    this.runAllTests(this.state.modelKey);
  };

  onRunTest = () => {
    this.setState({testResults: [], start: false});
    this.runTest();
  };

  runTest = () => {
    MCCache.clear()
    if (this.props.mconf.userId == null) {
      MC.error('User login used for environment is empty! Are you signed in?')
      return
    }
    let self = this
    MC.callServer('GET', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_TestGet?inputdata=' + encodeURIComponent(JSON.stringify({model: this.state.modelKey, testid: this.state.testId})), MC.getJsonType()).then(function (res) {
      if (res.status == 200 || res.status == 204) {
        let test = JSON.parse(res.content)
        let input = test.inputdata ? MC.xmlStringToObject(test.inputdata, null, false) : {}
        self.setState({currentTest: test, flowName: test.operation, input: input, start: true})
      } else {
        throw new Error(res.content)
      }
    }).catch (function (err) {
      throw new Error(err.message)
    })
  }

  runAllTests = (modelKey) => {
    MCCache.clear()
    if (this.props.mconf.userId == null) {
      MC.error('User login used for environment is empty! Are you signed in?')
      return
    }
    let self = this
    MC.callServer('GET', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_TestList?inputdata=' + encodeURIComponent(JSON.stringify({model: modelKey})), MC.getJsonType()).then(function (res) {
      if (res.content) {
        let activeTests = []
        for (let test of MC.asArray(JSON.parse(res.content).test)) {
          if (test['name'] && test['name'].split(' ').indexOf('auto') > -1) {
            activeTests.push(test)
          }
        }
        self.testsToRun = activeTests
        self.setState({batchIsRunning: true, start: false})
        self.runTestBatch()
      }
    }).catch (function (err) {
      throw new Error(err.message)
    })
  }

  runTestBatch = () => {
    let self = this
    if (Array.isArray(this.testsToRun) && this.testsToRun.length > 0) {
      let testID = this.testsToRun[0].id
      this.testsToRun.shift()
      MC.callServer('GET', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_TestGet?inputdata=' + encodeURIComponent(JSON.stringify({model: this.state.modelKey, testid: testID})), MC.getJsonType()).then(function (res) {
        if (res.status == 200 || res.status == 204) {
          let test = JSON.parse(res.content)
          let input = test.inputdata ? MC.xmlStringToObject(test.inputdata, null, false) : {}
          self.setState({currentTest: test, flowName: test.operation, input: input, start: true})
        } else {
          throw new Error(res.content)
        }
      }).catch (function (err) {
        throw new Error(err.message)
      })
    }
  }

  onEndTest = (result, mess) => {
    let exMess = '';
    let results = this.state.testResults;
    let expected = this.state.currentTest.outputdata ? this.state.currentTest.outputdata : ''
    expected = MC.xmlStringToObject(expected, null, false)
    if (expected) {
      if (expected.parsererror) {
        delete expected.parsererror
      }
    } else {
      expected = ''
    }
    if (MC.isPlainObject(result)) {
      result = MC.objectToXML(result, 0)
    } else {
      result = '';
      exMess = result + ': ' + mess
    }
    results.push({result: result, expected: MC.objectToXML(expected, 0), name: this.state.currentTest.name, exception: exMess})
    this.setState({currentTest: null, testResults: results, start: false, flowName: null, input: null})
    if (Array.isArray(this.testsToRun) && this.testsToRun.length > 0) {
      this.runTestBatch()
    } else {
      this.setState({batchIsRunning: false})
    }
  }

  render() {
    var disabledClass = 'ui ten wide field' + (this.state.testsList.length > 0 ? '' : ' disabled');
    var runableAll = (this.state.modelKey ? true : false);
    var runable = (this.state.testId ? true : false);
    var testOpts = [];
    testOpts.push({value: '', text: ''});
    for (var i = 0; i < this.state.testsList.length; i++) {
      testOpts.push({value: this.state.testsList[i].id, text: this.state.testsList[i].name});
    }
    var results = [];
    var failed = [];
    var passed = 0;
    for (var i=0; i<this.state.testResults.length; i++) {
      var res = this.state.testResults[i];
      var title = 'FAILED';
      var headerCss = 'ui red header';
      var  mCss = 'ui red message';
      var diff = (
            <div>
              <div>
                  <br/><br/><strong>Obtained output:</strong><br/> <pre>{res.result}</pre>
              </div>
              <div>
                <strong>Expected output:</strong><br/> <pre>{res.expected}</pre>
              </div>
            </div>);
      if (MC.stripWhiteSpaceInXML(res.result) == MC.stripWhiteSpaceInXML(res.expected)) {
        title = 'PASSED';
        headerCss = 'ui green header';
        mCss = 'ui green message';
        if (this.state.testResults.length > 1) {
          diff = null;
        }
        passed++;
      } else {
        failed.push(res.name);
      }
      results.push(
        <div key={i} className={mCss}>
          <div className={headerCss}>
            {res.name} - {title}
          </div>
          {diff}
        </div>
      );
    }
    var stats = null;
    if (this.state.testResults.length > 1) {
      var icon = <i className="notched circle loading icon"></i>;
      if (!this.state.batchIsRunning) {
        icon = <i className="checkmark icon"></i>;
      }
      stats = (
        <div key="stats" className="ui icon message">
          {icon}
          <div className="content">
            <div className="ui grey label">
              TESTS: <div className="detail">{passed + failed.length}</div>
            </div>
            <div className="ui green label">
              PASSED: <div className="detail">{passed}</div>
            </div>
            <div className="ui red label">
              FAILED: <div className="detail">{failed.length}</div>
            </div>
            <div>FAILED: {JSON.stringify(failed)}</div>
          </div>
        </div>
      );
    }
    var configuration = this.state.modelKey;
    if (configuration && configuration.indexOf('/')) {
      configuration = configuration.substring(0, configuration.lastIndexOf('/'))
    }
    return (
      <div>
        <div className="ui icon message">
          <i className="legal icon"></i>
          <div className="content">
            <form className="ui form" id="inputForm" onSubmit={this.handleSubmitNoInput}>
              <div className="field">
                <label>User</label>
                <div className="ui label">
                  <i className="user icon"></i>{this.props.mconf.userId}
                </div>
              </div>
              <div className="two fields">
                <div className="ui four wide field">
                  <label htmlFor="modelKeySelect">Model</label>
                  <Dropdown className="selection" onChange={this.changeModelDropdown} value={this.state.modelKey} selectOnNavigation={false} selectOnBlur={false} 
                    options={[{value: '', text: ''}, {value: 'appmodeler;v=4/test/tc', text: 'appmodeler;v=4/test/tc'}]}/>
                </div>
                <div className="ui six wide field">
                  <label htmlFor="inputRun">&nbsp;</label>
                  <div className="ui fluid icon input">
                    <input type="text" name="modelKey" value={this.state.modelKeyFilled ? this.state.modelKeyFilled : ''} onChange={this.changeModelKey}/>
                    <i className="refresh link icon" onClick={this.clickReloadInModel}></i>
                  </div>
                </div>
                <div className="ui three wide field">
                  <label htmlFor="inputRun">&nbsp;</label>
                  <button className="ui right labeled icon button olive" type="button" id="inputRun" disabled={!runableAll} onClick={this.onRunAllTests}>
                    <i className="right play icon"></i>
                    Run all tests
                  </button>
                </div>
              </div>
              <div className="two fields">
                <div className={disabledClass}>
                  <label htmlFor="flowId">Test:</label>
                  <Dropdown className="selection" search onChange={this.changeTest} value={this.state.testId} selectOnNavigation={false} selectOnBlur={false} options={testOpts}/>
                </div>
                <div className="ui three wide field">
                  <label htmlFor="inputRunAll">&nbsp;</label>
                  <button className="ui right labeled icon button green" type="button" id="inputRunAll" disabled={!runable} onClick={this.onRunTest}>
                    <i className="right play icon"></i>
                    Run test
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {stats}
        {results}
        <ReactFlow configuration={configuration} flowName={this.state.flowName} serverSide={false} input={this.state.input} mconf={this.props.mconf}
                   start={this.state.start} onEndFunction={this.onEndTest} clearStateOnEnd={true} debug="TRACE" console={false} resetStart={this.resetStart}/>
      </div>
    )
  }

}

export default TestApp