import React from 'react'

export class PreviousButton extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    this.props.previousSlide()
  }

  render() {
    const disabled = (this.props.currentSlide === 0 && !this.props.wrapAround) || this.props.slideCount === 0
    return <button className="prev-button" disabled={disabled} onClick={this.handleClick} aria-label="previous">{this.props.textPrev}</button>
  }
}

export class NextButton extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
    this.nextButtonDisable = this.nextButtonDisabled.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    this.props.nextSlide()
  }

  nextButtonDisabled(params) {
    const {wrapAround, slidesToShow, currentSlide, cellAlign, slideCount} = params
    let buttonDisabled = false;
    if (!wrapAround) {
      const lastSlideIndex = slideCount - 1;
      let slidesShowing = slidesToShow;
      let lastSlideOffset = 0;
      switch (cellAlign) {
        case 'center':
          slidesShowing = (slidesToShow - 1) * 0.5;
          lastSlideOffset = Math.floor(slidesToShow * 0.5) - 1;
          break;
        case 'right':
          slidesShowing = 1;
          break;
      }
      if (slidesToShow > 1) {
        buttonDisabled =
          currentSlide + slidesShowing > lastSlideIndex + lastSlideOffset;
      } else {
        buttonDisabled = currentSlide + 1 > lastSlideIndex;
      }
    }
    return buttonDisabled;
  }

  render() {
    const {wrapAround, slidesToShow, currentSlide, cellAlign, slideCount, textNext} = this.props
    const disabled = this.nextButtonDisabled({wrapAround, slidesToShow, currentSlide, cellAlign, slideCount})
    return <button  className="next-button" disabled={disabled} onClick={this.handleClick} aria-label="next">{textNext}</button>
  }

}

export class PagingDots extends React.Component {

  getDotIndexes(slideCount, slidesToScroll, slidesToShow, cellAlign) {
    const dotIndexes = []
    let lastDotIndex = slideCount - slidesToShow
    switch (cellAlign) {
      case 'center':
      case 'right':
        lastDotIndex += slidesToShow - 1;
        break;
    }
    if (lastDotIndex < 0) {
      return [0]
    }
    for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
      dotIndexes.push(i)
    }
    dotIndexes.push(lastDotIndex)
    return dotIndexes
  }

  render() {
    const indexes = this.getDotIndexes(this.props.slideCount, this.props.slidesToScroll, this.props.slidesToShow, this.props.cellAlign)
    return (
      <ul className="paging-items">
        {indexes.map(index => {
          return (
            <li key={index} className={this.props.currentSlide === index ? 'paging-item active' : 'paging-item'}>
              <button type="button" onClick={this.props.goToSlide.bind(null, index)} aria-label={`slide ${index + 1} bullet`}>
                <span className="paging-dot"/>
              </button>
            </li>
          )
        })}
      </ul>
    )
  }

}

const MIN_ZOOM_SCALE = 0
const MAX_ZOOM_SCALE = 1

export class ScrollTransition extends React.Component {
  constructor(props) {
    super(props);

    this.getListStyles = this.getListStyles.bind(this);
  }

  getSlideDirection(start, end, isWrapping) {
    let direction = 0;
    if (start === end) return direction;

    if (isWrapping) {
      direction = start < end ? -1 : 1;
    } else {
      direction = start < end ? 1 : -1;
    }

    return direction;
  }

  /* eslint-disable complexity */
  getSlideTargetPosition(index, positionValue) {
    let targetPosition =
      (this.props.slideWidth + this.props.cellSpacing) * index;
    const startSlide = Math.min(
      Math.abs(Math.floor(positionValue / this.props.slideWidth)),
      this.props.slideCount - 1
    );

    let offset = 0;

    if (
      this.props.animation === 'zoom' &&
      (this.props.currentSlide === index + 1 ||
        (this.props.currentSlide === 0 &&
          index === this.props.children.length - 1))
    ) {
      offset = this.props.slideOffset;
    } else if (
      this.props.animation === 'zoom' &&
      (this.props.currentSlide === index - 1 ||
        (this.props.currentSlide === this.props.children.length - 1 &&
          index === 0))
    ) {
      offset = -this.props.slideOffset;
    }

    if (this.props.wrapAround && index !== startSlide) {
      const direction = this.getSlideDirection(
        startSlide,
        this.props.currentSlide,
        this.props.isWrappingAround
      );
      let slidesBefore = Math.floor((this.props.slideCount - 1) / 2);
      let slidesAfter = this.props.slideCount - slidesBefore - 1;

      if (direction < 0) {
        const temp = slidesBefore;
        slidesBefore = slidesAfter;
        slidesAfter = temp;
      }

      const distanceFromStart = Math.abs(startSlide - index);
      if (index < startSlide) {
        if (distanceFromStart > slidesBefore) {
          targetPosition =
            (this.props.slideWidth + this.props.cellSpacing) *
            (this.props.slideCount + index);
        }
      } else if (distanceFromStart > slidesAfter) {
        targetPosition =
          (this.props.slideWidth + this.props.cellSpacing) *
          (this.props.slideCount - index) *
          -1;
      }
    }

    return targetPosition + offset;
  }
  /* eslint-enable complexity */

  formatChildren(children) {
    const { top, left, currentSlide, slidesToShow } = this.props;
    const positionValue = this.props.vertical ? top : left;
    return React.Children.map(children, (child, index) => {
      const visible =
        index >= currentSlide && index < currentSlide + slidesToShow;
      return (
        <li
          className={`slider-slide${visible ? ' slide-visible' : ''}`}
          style={this.getSlideStyles(index, positionValue)}
          key={index}
        >
          {child}
        </li>
      );
    });
  }

  getSlideStyles(index, positionValue) {
    const targetPosition = this.getSlideTargetPosition(index, positionValue);
    const transformScale =
      this.props.animation === 'zoom' && this.props.currentSlide !== index
        ? Math.max(
            Math.min(this.props.zoomScale, MAX_ZOOM_SCALE),
            MIN_ZOOM_SCALE
          )
        : 1.0;
    return {
      boxSizing: 'border-box',
      display: this.props.vertical ? 'block' : 'inline-block',
      height: 'auto',
      left: this.props.vertical ? 0 : targetPosition,
      listStyleType: 'none',
      marginBottom: this.props.vertical ? this.props.cellSpacing / 2 : 'auto',
      marginLeft: this.props.vertical ? 'auto' : this.props.cellSpacing / 2,
      marginRight: this.props.vertical ? 'auto' : this.props.cellSpacing / 2,
      marginTop: this.props.vertical ? this.props.cellSpacing / 2 : 'auto',
      MozBoxSizing: 'border-box',
      position: 'absolute',
      top: this.props.vertical ? targetPosition : 0,
      transform: `scale(${transformScale})`,
      transition: 'transform .4s linear',
      verticalAlign: 'top',
      width: this.props.vertical ? '100%' : this.props.slideWidth
    };
  }

  getListStyles(styles) {
    const { deltaX, deltaY } = styles;
    const listWidth =
      this.props.slideWidth * React.Children.count(this.props.children);
    const spacingOffset =
      this.props.cellSpacing * React.Children.count(this.props.children);
    const transform = `translate3d(${deltaX}px, ${deltaY}px, 0)`;
    return {
      transform,
      WebkitTransform: transform,
      msTransform: `translate(${deltaX}px, ${deltaY}px)`,
      position: 'relative',
      display: 'block',
      margin: this.props.vertical
        ? `${(this.props.cellSpacing / 2) * -1}px 0px`
        : `0px ${(this.props.cellSpacing / 2) * -1}px`,
      padding: 0,
      height: this.props.vertical
        ? listWidth + spacingOffset
        : this.props.slideHeight,
      width: this.props.vertical ? 'auto' : listWidth + spacingOffset,
      cursor: this.props.dragging === true ? 'pointer' : 'inherit',
      boxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      touchAction: `pinch-zoom ${this.props.vertical ? 'pan-x' : 'pan-y'}`
    };
  }

  render() {
    const children = this.formatChildren(this.props.children)
    const deltaX = this.props.deltaX
    const deltaY = this.props.deltaY
    return (
      <ul className="slider-list" style={this.getListStyles({deltaX, deltaY})}>
        {children}
      </ul>
    )
  }

}

ScrollTransition.defaultProps = {
  cellSpacing: 0,
  currentSlide: 0,
  deltaX: 0,
  deltaY: 0,
  dragging: false,
  isWrappingAround: false,
  left: 0,
  slideCount: 0,
  slideHeight: 0,
  slideWidth: 0,
  top: 0,
  vertical: false,
  wrapAround: false,
  zoomScale: 0.85
}