import React from "react"
import tippy from "tippy.js"

import {MC} from "../client/MC.js"

import {InspectorOption} from "./InspectorOption.jsx"
import {GetText} from "./GetText.js"

class Inspector extends React.Component {

  state = {search: ""}
  searchRef = React.createRef()

  componentDidMount() {
    if (this.props.loaded && this.props.inspectedFields.length > 0) {
      this.searchRef.current.focus()
    }
    this.updateTooltips()
  }

  componentDidUpdate () {
    this.updateTooltips()
  }

  updateTooltips() {
    tippy('[data-tippy-content]', {arrow: true, delay: 400, animation: 'scale', theme: 'light-border', allowHTML: true})
  }

  handleSearchChange = (event) => {
    this.setState({search: event.target.value})
  }

  editInRepository = () => {
    let mconf = this.props.mconf
    this.props.inspectedFields.forEach(function(field) {
      if (!field.isNotStored) {
        let modeler = field.getModelerReact()
        window.open(mconf.baseUrl + 'miniapp/formedit/' + modeler.state.form.model + '?id=' + modeler.state.form.formId + '&p=svc:FormField;' + field.rbsid, '_blank')
      }
    })
  }

  inspectedFieldsOptions = () => {
    var inspectedFields = this.props.inspectedFields;
    var fieldsOptions = inspectedFields.map(function(field) {
      return field.getOptions()
    });
    var moreThenOne = inspectedFields.length > 1;
    return fieldsOptions[0].filter(function(option) {
      var isInEvery = fieldsOptions.every(function(options) {
        return options.find(function(innerOption){
          return innerOption.name == option.name;
        });
      });
      return isInEvery && (!moreThenOne || option.path[0] != "id");
    });
  }

  inspectedFieldsOptionValue = (path) => {
    var inspectedFields = this.props.inspectedFields;
    var values = inspectedFields.map(function(field) {
      return field.getOption(path);
    });
    return reduceValues(values)
  }

  inspectedFieldsSetOptionValue = (path, value) => {
    var inspectedFields = this.props.inspectedFields;
    console.log(path, value)
    inspectedFields.forEach(function(field) {
      field.setOption(path, value);
    })
  }

  groupOptions = () => {
    let self = this
    let options = this.inspectedFieldsOptions()
    let inspectorOptions = options.filter(function (option) {
      return option.name.toUpperCase().includes(self.state.search.toUpperCase())
    })
    return ["basic", "grid", "column", "others"].map(function(group) {
      let groupOptions = inspectorOptions.filter(function(option) {
        return option.group == group
      })
      return [group, groupOptions]
    })
  }

  makeInspectorOption = (option) => {
    var field = this.props.inspectedField;
    var self = this;
    var value = self.inspectedFieldsOptionValue(option.path);
    let cls = MC.classes("meta row option", {"warning": !isIndeterminate(value) && !isValid(option, value)})
    let help = null
    if (option.help) {
      help = <i className="help icon" data-tippy-content={option.help}/>
    }
    return <div className={cls} key={option.path.join('_')}>
             <div className="name">{option.name}</div>
             <div className="value"><InspectorOption
                          option={option}
                          field={field}
                          onOptionValue={self.inspectedFieldsOptionValue}
                          onSetOptionValue={self.inspectedFieldsSetOptionValue}
                          onSetSelectedField={self.props.onSetSelectedField}
                          onStoreForm={self.props.onStoreForm}
                          onGetFormCopy={self.props.onGetFormCopy}
                          onCancelChange={self.props.onCancelChange}
                          onFormWasChanged={self.props.onFormWasChanged}/></div>
            <div className="help">{help}</div> 
           </div>
  }

  makeInspectorItems = () => {
    var self = this;
    var groupOptions = this.groupOptions();
    groupOptions = groupOptions.filter(function(group) {
      return group[1].length != 0;
    })
    var groupNames = {basic: GetText.t("Basic"), grid: "Grid", column: GetText.t("Column"), others: GetText.t("Others")}
    var modelerReact = this.props.inspectedFields[0].getModelerReact()
    var form = modelerReact.state.form;
    var resolution = modelerReact.state.resolution;
    var availableResolution = MC.getAvailableResolution(resolution, form);
    groupNames["grid"] += " (" + availableResolution + ")";
    var inspectorOptions = groupOptions.map(function (groupAndOptions) {
      var group = groupAndOptions[0];
      var options = groupAndOptions[1];
      var inspectorOptions = options.map(function(option) {
        return self.makeInspectorOption(option);
      });
      var groupHeader = <div key={'group-' + groupNames[group]} className="group-name">{groupNames[group]}</div>
      return [groupHeader, inspectorOptions];
    });
    return inspectorOptions.reduce((a, b) => {return a.concat(b)}, [])
  }
    
  render () {
    var self = this;
    var inspectedFields = this.props.inspectedFields;
    var areInspectedFields = inspectedFields.length != 0;
    if (this.props.loaded && areInspectedFields) {
        var inspectorItems = self.makeInspectorItems();
        var editInRepository;
        var isAtLeastOneOld = this.props.inspectedFields.some(function(field) {
          return !field.isNotStored
        });
        if (isAtLeastOneOld) {
          editInRepository = <div className="meta menu-item">
                              <button className="ui icon button" onClick={this.editInRepository} data-tippy-content={GetText.t("Edit in repository")}><i className={"edit icon"}/></button>
                              </div>
        }     
        return (
            <div id="inspector" style={{top: this.props.position.y, left: this.props.position.x,  width: this.props.width}}>
              <div className="meta menu top component">
                <div className="category search menu-item">
                  <div className="ui icon input mini meta menu-item">
                    <input ref={this.searchRef} className="search" id="searchInput" type="text" name="propertysearch" placeholder={GetText.t("Search...")} onChange={this.handleSearchChange} value={this.state.search}/>
                    <i className="search icon"/>
                  </div>
                </div>
                <div className="meta menu-item">
                  <button className="ui  button" onClick={this.props.onDeleteSelectedFields} data-tippy-content={GetText.t("Delete field")}>{GetText.t("Delete")}</button>
                </div>
                {editInRepository}
              </div>
              <div className={MC.classes('inner', {'large': this.props.large})}>
                {inspectorItems}
              </div>
            </div>)
      }
      if (areInspectedFields && !this.props.loaded) {
          return <div className="" id ="inspector">
              <div className="ui segment basic loading">
              </div>
          </div>;
      }
      return <div className="" id ="inspector"/>;
  }
}

function reduceValues (values) {
  return values.reduce(function(v1, v2) {
    if (isIndeterminate(v1)) {
      return v1;
    }
    if (v1 == v2) {
      return v1;
    } else {
      return {message: "indeterminate"};
    }
  });
}

function isIndeterminate (value) {
  return value instanceof Object && value.message == "indeterminate";
}

function isValid(option, value) {
  if (option.required && MC.isNull(value)) {
    return false
  }
  if (!MC.isNull(value) && Array.isArray(option.enum) && option.enum.indexOf(value) == -1) {
    return false
  }
  return true
}

export {Inspector}