import Decimal from "decimal.js"
import {DateTime} from "luxon"

import {MC} from './MC.js'
import {Duration} from "./Duration.js"
import {JdateFormat} from "./JdateFormat.js";

Decimal.set({precision: 36})

let Expression = function() {}

Expression.prototype.init = function(data, cData, opts) {
  this.data = data
  this.cData = cData
  this.opts = opts || {}
  this.errorObject = null
  this.trace = {}
  if (!this.opts.scope) {
    this.opts.scope = {vars: {}}
  }
} 

Expression.prototype.error = function(newError, path) {
  if (!MC.isNull(newError) && this.errorObject == null) {
    if (typeof newError === 'string') {
      this.errorObject = {msg: newError}
      this.trace.error = newError
    } else if (MC.isPlainObject(newError) && !MC.isNull(newError.msg)) {
      this.errorObject = newError
    } else {
      this.errorObject = {msg: 'Passed error object for logging has unknown type!'}
    }
    if (!MC.isNull(path) && MC.isNull(this.errorObject.path)) {
      this.errorObject.path = path
    }
    if (this.errorObject && this.scalarPos !== undefined) {
      this.errorObject.scalarPos = this.scalarPos
    }
  }
}

Expression.prototype.clearError = function() {
  this.errorObject = null
}

Expression.prototype.getError = function() {
  return this.errorObject
}

Expression.prototype.getErrorMessage = function() {
  return (this.errorObject.path ? (' Target path "' + this.errorObject.path + '": ') : '') + this.errorObject.msg
}

Expression.prototype.getTrace = function() {
  if (MC.isEmptyObject(this.trace)) {
    return null;
  } else {
    return this.trace;
  }
};

Expression.prototype.getTraceAsPaths = function() {
  let result = {};
  this.getSubtraceAsPaths(result, this.trace, this.trace.path);   
  return result;
};

Expression.prototype.getSubtraceAsPaths = function(result, trace, path) {
  if (!path) {
    path = "/"
  }
  if (trace.subpath && trace.subpath.length > 0) {
    for (let subTrace of trace.subpath) {
      this.getSubtraceAsPaths(result, subTrace, trace.path);   
    }
  } else {
    if (result[path]) {
      if (!Array.isArray(result[path])) {
        result[path] = [result[path]]; 
      }
      result[path].push(trace);
    } else {
      result[path] = trace;
    }
  }
};

Expression.prototype.sortExprs = function(origExprs) {
  if (origExprs.length > 1) {
    let sortedExprs = []
    for (let expr of origExprs) {
      if (!expr.target) {
        sortedExprs.push(expr)
      }
    }
    for (let expr of origExprs) {
      if (expr.target) {
        sortedExprs.push(expr)
      }
    }
    return sortedExprs
  } else {
    return origExprs
  }
}

Expression.prototype.stripDeepCollections = function(value, fromLevel) {
  if (Array.isArray(value)) {
    if (fromLevel > 0) {
      fromLevel--
      for (var i=0; i<value.length; i++) {
        value[i] = this.stripDeepCollections(value[i], fromLevel)
      }
    } else {
      value = this.stripDeepCollections(MC.getFirstNotNull(value), fromLevel)
    }
  }
  return value
}

Expression.prototype.evaluate = function(path) {
  if (this.data.target) {
    if (!path) {
      path = this.data.target
    }
    if (this.opts.trace) {
      this.trace.path = path
    }
    if (this.data.expr) {
      let subpaths = {}
      if (this.opts.trace) { 
        this.trace.subpath = []
      }  
      let expr = this.sortExprs(this.data.expr)
      for (let i=0; i<expr.length; i++) {
        let opsTopas = this.opts 
        if (!expr[i].operator) { 
          // copy context in case that is not present operator ($variable) - subtree
          // if $variable is present, it is need to be set into current not copied context
          opsTopas = Object.assign({}, this.opts)
          if (opsTopas.scope.vars) {
            opsTopas.scope = {vars: Object.assign({}, opsTopas.scope.vars)}
          }
        }
        let expression = new Expression()
        expression.init(expr[i], this.cData, opsTopas)
        if (expr[i].target) {
          let nextPath = (path ? path + '/' : '') + expr[i].target
          let value = expression.evaluate(nextPath)
          this.error(expression.getError())
          if ((!MC.isNull(value)) && MC.isNull(subpaths[nextPath])) {
            if (MC.isPlainObject(value)) {
              Object.assign(subpaths, value)
            } else {
              subpaths[nextPath] = value
            }
          }
        } else {
          let value = expression.evaluate()
          this.error(expression.getError(), path)
          if ((!MC.isNull(value)) && MC.isNull(subpaths[path])) {
            subpaths[path] = value
          }
        }
        if (this.opts.trace) {
          this.trace.subpath.push(expression.getTrace())
        }  
      }
      if (this.opts.trace) {
        this.trace.path = path
      }  
      if (!MC.isNull(subpaths)) {
        for (let key in subpaths) {
          let expectedLevel = (key.match(/\*/g) || []).length
          subpaths[key] = this.stripDeepCollections(subpaths[key], expectedLevel)
        }
        return subpaths
      } else {
        return null
      }
    }
  } else if (this.data.operator) {
    if (this.opts.trace) {
      if (this.data.operator.startsWith('$')) {
        this.trace.variable = this.data.operator
      } else {
        this.trace.operator = this.data.operator
      }
    }  
    if ('submittedBy' == this.data.operator) {
      if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0]) && !MC.isNull(this.data.expr[0].source)) {
        if (!this.data.expr[0].source.endsWith('/@submittedBy')) {
          this.data.expr[0].source = this.data.expr[0].source + '/@submittedBy';
        }
        this.opts.submittedByPath = this.data.expr[0].source;
      }
    }
    let res;
    if (this.data.operator.startsWith('$')) {
      if (this.data.expr && this.data.expr.length > 0) {
        let subTrace = []
        for (var i=0; i<this.data.expr.length; i++) {
          let expression = new Expression()
          expression.init(this.data.expr[i], this.cData, this.opts)
          let res = expression.evaluate()
          this.opts.scope.vars[this.data.operator] = res
          if (this.opts.trace) {
            this.trace.resultInVariable = MC.isNull(res) ? null : res
          }
          this.error(expression.getError(), this.data.operator)
          if (this.opts.trace) { 
            subTrace.push(expression.getTrace())
          }  
          if (!MC.isNull(res))  {
            break
          }
        }
        if (subTrace.length > 0) {
          this.trace.args = subTrace
        }
      }
    } else if (['try', 'if', 'select', 'every', 'group', 'some', 'sort', 'filter', 'find', 'treeFind', 'map', 'reduce', 'treeReduce', 'quote'].indexOf(this.data.operator) > -1) {
      res = this.evaluateOperator(this.data.expr || [])
    } else {
      let argsToPass = [];
      let subTrace = [];
      if (this.data.expr) {
        for (var i=0; i<this.data.expr.length; i++) {
          var expression = new Expression();
          expression.init(this.data.expr[i], this.cData, this.opts)
          argsToPass[i] = expression.evaluate();
          this.error(expression.getError());
          if (this.opts.trace) {
            subTrace.push(expression.getTrace());
          }  
        }
      }
      if (subTrace.length > 0) {
        this.trace.args = subTrace;
      }
      res = this.evaluateOperator(argsToPass);
    }
    if (this.opts.trace) {
      this.trace.result = MC.isNull(res) ? null : res
    }
    return res;
  } else if (this.data.source) {
    let res = this.evaluateSource()
    if (this.opts.trace) {
      this.trace.result = res
    }  
    return res
  } else {
    this.error('Expression must have operator or source defined!')
    return false
  }
}

Expression.prototype.setBase = function(relativeBase, relativeToBase) {
  if (relativeBase == null) {
    this.opts.base.shift();
    this.opts.position.shift();
    this.opts.positionValue.shift();
    this.opts.resultValue.shift();
  } else if (relativeBase.length == 0) {
    this.opts.base.unshift("");
    this.opts.position.unshift([]);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(null);
  } else if (relativeToBase == -1) {
    this.opts.base.unshift(relativeBase);
    this.opts.position.unshift([]);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(null);
  } else {
    var fromBase = this.opts.base.slice().reverse()[relativeToBase - 1];
    this.opts.base.unshift(this.relativize(fromBase, relativeBase));
    var fromPosition = this.opts.position.slice().reverse()[relativeToBase - 1];
    var shared = MC.collectionDepth(MC.commonAncestor(fromBase, relativeBase));
    var newPosition = fromPosition.slice(0, shared);
    this.opts.position.unshift(newPosition);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(null);
  }
};

Expression.prototype.clearBase = function(opts) {
  opts.base = null
  opts.position = null
  opts.positionValue = null
  opts.resultValue = null
}  

Expression.prototype.bases = function() {
  return this.opts.base;
};

Expression.prototype.base = function() {
  return this.opts.base[0];
};

Expression.prototype.setPosition = function(position) {
  if (position == null) {
    this.opts.position[0].pop();
  } else {
    this.opts.position[0].push(position);
  }
};

Expression.prototype.setPositionValue = function(value) {
  if (value == null) {
    this.opts.positionValue[0].pop()
  } else {
    this.opts.positionValue[0].push(value)
  }
};

Expression.prototype.pushResultValue = function(resultValue) {
  this.opts.resultValue.push(resultValue)
}

Expression.prototype.popResultValue = function() {
  this.opts.resultValue.pop()
}

Expression.prototype.peekResultValue = function() {
  return this.opts.resultValue[this.opts.resultValue.length-1]
}

Expression.prototype.resultValues = function() {
  return this.opts.resultValue
}

Expression.prototype.position = function() {
  return this.opts.position[0];
};

Expression.prototype.positions = function() {
  return this.opts.position;
};

Expression.prototype.positionValues = function() {
  return this.opts.positionValue;
};

Expression.prototype.enterBaseContext = function() {
  if (!Array.isArray(this.opts.base)) {
    this.opts.base = [];
  }
  if (!Array.isArray(this.opts.position)) {
    this.opts.position = [];
  }
  if (!Array.isArray(this.opts.positionValue)) {
    this.opts.positionValue = [];
  }
  if (!Array.isArray(this.opts.resultValue)) {
    this.opts.resultValue = []
  }
  var newBase = null;
  var relativeToBase = -1;
  if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0])) {
    if (!MC.isNull(this.data.expr[0].source)) {
      newBase = this.data.expr[0].source
      const first = newBase.indexOf('/') > -1 ? newBase.substr(0, newBase.indexOf('/')) : newBase
      if (first == "." || first == ".." || first.match(/^\$v[0-9]+$/)) {
        if (newBase.startsWith("$v")) {
          const i = newBase.indexOf("/")
          relativeToBase = parseInt(i == -1 ? newBase.substring(2) : newBase.substring(2, i))
          newBase = i == -1 ? "." : newBase.substring(i + 1)
        } else {
          relativeToBase = this.bases().length
        }
      }
    } else if (!MC.isNull(this.data.expr[0].operator) && Array.isArray(this.data.expr[0].expr)) {
      var functionName = this.data.expr[0].operator;
      var fa1e = this.data.expr[0].expr[0];
      if (!MC.isNull(fa1e.source)) {
        var v1s = fa1e.source;
        if (v1s.startsWith("'")) {
          v1s = v1s.substring(1, v1s.length - 1);
        }
        if (functionName == 'path') {
          newBase = v1s;
        } else if (functionName == 'relative') {
          newBase = v1s;
          if (newBase.startsWith("$v")) {
            var i = newBase.indexOf("/");
            relativeToBase = parseInt(i == -1 ? newBase.substring(2) : newBase.substring(2, i));
            newBase = i == -1 ? "." : newBase.substring(i + 1);
          } else {
            relativeToBase = this.bases().length;
          }
        }
      }
    }
  }
  if (newBase == null) {
    this.setBase("", -1);
  } else {
    this.setBase(newBase, relativeToBase);
  }
  return newBase;
};

Expression.prototype.leaveBaseContext = function(base) {
  this.setBase(null, 0);
};

Expression.prototype.evaluateSource = function(data) {
  var source = data == null ? this.data.source : data.source;
  if (this.opts.trace) {
    this.trace.source = source;
  }  
  const first = source.indexOf('/') > -1 ? source.substr(0, source.indexOf('/')) : source
  if (first == "." || first == ".." || first.match(/^\$v[0-9]+$/)) {
    return this.operatorRelative([source])
  } else if (source.startsWith("$")) {
    if (source.indexOf('/') > -1) {
      let tokens = source.split("/")
      if (!this.opts.scope.vars.hasOwnProperty(tokens[0])) {
        this.error(`Undefined variable with name ${tokens[0]}!`)
        return null
      }
      let res = this.opts.scope.vars[tokens[0]]
      tokens.shift()
      return this.getValue(res, tokens, Array.isArray(res))
    } else {
      if (!this.opts.scope.vars.hasOwnProperty(source)) {
        this.error(`Undefined variable with name ${source}!`)
        return null
      }
      return this.opts.scope.vars[source]
    }
  } if (source.startsWith("'")) {
    return source.substring(1, source.length - 1);
  } else if (MC.isNumeric(source)) {
    return (new Decimal(source)).toFixed()
  } else if (source == 'true') {
    return true;
  } else if (source == 'false') {
    return false;
  } else if (source == 'null') {
    return null;
  } else if (source == 'empty') {
    return '';
  } else if (source.indexOf('(') > 0 && source.endsWith(')')) {
    return MC.normalizeValue(source.substring(source.indexOf('(') + 1, source.length - 1), source.substring(0, source.indexOf('(')))
  } else {
    var tokens = source.split("/");
    var value = this.cData[tokens[0]]
    if (tokens.length == 1) {
      if (MC.isPlainObject(value) && MC.isEmptyObject(value)) {
        return null
      } else {
        return MC.isNull(value) ? null : value
      }
    } else {
      let isCollection = tokens[0].endsWith('*')
      tokens.shift()
      return this.getValue(value, tokens, isCollection)
    }
  }
};

Expression.prototype.getValue = function(value, tokens, isCollection) {
  var result;
  if (isCollection && Array.isArray(value)) {
    result = [];
    for (var i=0; i<value.length; i++) {
      if (tokens.length > 1) {
        var subTokens = tokens.slice();
        subTokens.shift();
        result[i] = this.getValue(this.getValueByKey(value[i], tokens[0]), subTokens, tokens[0].endsWith('*'));
      } else {
        var item = this.getValueByKey(value[i], tokens[0]);
        result[i] = MC.isNull(item) ? null : item;
      }
    }
    return result
  } else if (!MC.isNull(value)) {
    if (Array.isArray(value)) {
      value = value[0];
    }
    result = this.getValueByKey(value, tokens[0]);
    if (result == undefined && value['@customwidget'] && MC.isPlainObject(value['value'])) {
      result = value['value'][tokens[0]];
    }
    if (tokens.length > 1) {
      var subTokens = tokens.slice();
      subTokens.shift();
      result = this.getValue(result, subTokens, tokens[0].endsWith('*'));
    }
    if (!MC.isNull(result) || Array.isArray(result)) {
      if (isCollection) {
        if (Array.isArray(result)) {
          return [...result]
        } else {
          return [result]
        }
      } else {
        return result;
      }
    } else {
      return null;
    }
  } else {
    return null
  }
}

Expression.prototype.getValueByKey = function(object, key) {
  if (object === '') {
    return null
  }
  if (key.endsWith('*')) {
    key = key.substring(0, key.length - 1)
  }
  if (Array.isArray(object)) {
    let res = []
    for (let item of object) {
      res.push(this.getValueByKey(item, key))
    }
    return res
  } else {
    return object[key]
  }
}

Expression.prototype.evaluateOperator = function(args) {
  if (this.errorObject && this.errorObject.scalarPos !== undefined) {
    this.scalarPosToPass = this.errorObject.scalarPos
    delete this.errorObject.scalarPos
  }
  switch (this.data.operator) {
    case '>': return this.scalarOperator(this.operatorGreater, args); break;
    case '<': return this.scalarOperator(this.operatorLower, args); break;
    case '>=': return this.scalarOperator(this.operatorGreaterEquals, args); break;
    case '<=': return this.scalarOperator(this.operatorLowerEquals, args); break;
    case '==': return this.scalarOperator(this.operatorEquals, args); break;
    case '!=': return this.scalarOperator(this.operatorNotEquals, args); break;
    case '+': return this.scalarOperator(this.operatorPlus, args); break;
    case '-':
    case '−': return this.scalarOperator(this.operatorMinus, args); break;
    case '*': return this.scalarOperator(this.operatorMultiply, args); break;
    case '/': return this.scalarOperator(this.operatorDivide, args); break;
    case 'abs': return  this.scalarOperator(this.operatorAbs, args); break;
    case 'accumulateData': return this.operatorAccumulateData(args); break;
    case 'addDuration': return this.scalarOperator(this.operatorAddDuration, args); break;
    case 'and': return this.scalarOperator(this.operatorAnd, args); break;
    case 'appCfgVal': return this.scalarOperator(this.operatorAppCfgVal, args); break;
    case 'appCfgVal2': return this.scalarOperator(this.operatorAppCfgVal2, args); break;
    case 'avg': return this.operatorAvg(args); break;
    case 'buildUri': return this.scalarOperator(this.operatorBuildUri, args); break;
    case 'cast': return this.scalarOperator(this.operatorCast, args); break;
    case 'castable': return this.operatorCastable(args); break;
    case 'collection': return this.operatorCollection(args); break;
    case 'collectionItem': return this.operatorCollectionItem(args); break;
    case 'collectionSize': return this.operatorCollectionSize(args); break;
    case 'collectionUnwrap': return this.operatorCollectionUnwrap(args); break;
    case 'concat': return this.scalarOperator(this.operatorConcat, args); break;
    case 'contains': return this.operatorContains(args); break;
    case 'count': return this.operatorCount(args); break;
    case 'currentDate': return this.operatorCurrentDate(); break;
    case 'dataNode': return this.operatorDataNode(args); break;
    case 'dataToEntries': return this.scalarOperator(this.operatorDataToEntries, args); break;
    case 'dataToJson': return this.scalarOperator(this.operatorDataToJson, args); break;
    case 'dataToXml': return this.scalarOperator(this.operatorDataToXml, args); break;
    case 'decodeBase64': return this.scalarOperator(this.operatorDecodeBase64, args); break;
    case 'decodeHex': return this.scalarOperator(this.operatorDecodeHex, args); break;
    case 'decodeUrl': return this.scalarOperator(this.operatorDecodeUrl, args); break;
    case 'delete': return this.operatorDelete(args); break;
    case 'deleteData': return this.operatorDeleteData(args); break;
    case 'distinct': return this.operatorDistinct(args); break;
    case 'div': return this.scalarOperator(this.operatorDiv, args); break;
    case 'durationBetween': return this.scalarOperator(this.operatorDurationBetween, args); break;
    case 'durationComponent': return this.scalarOperator(this.operatorDurationComponent, args); break;
    case 'emptyToNull': return this.scalarOperator(this.operatorEmptyToNull, args); break;
    case 'encodeBase64': return this.scalarOperator(this.operatorEncodeBase64, args); break;
    case 'encodeHex': return this.scalarOperator(this.operatorEncodeHex, args); break;
    case 'encodeUrl': return this.scalarOperator(this.operatorEncodeUrl, args); break;
    case 'endsWith': return this.scalarOperator(this.operatorEndsWith, args); break;
    case 'entriesToData': return this.operatorEntriesToData(args); break;
    case 'escapeHtml': return this.scalarOperator(this.operatorEscapeHtml, args); break;
    case 'every': return this.operatorEvery(args); break;
    case 'exists': return this.operatorExists(args); break;
    case 'false': return false; break;
    case 'fill': return this.operatorFill(args); break;
    case 'filter': return this.operatorFilter(args); break;
    case 'fillTimezone': return this.scalarOperator(this.operatorFillTimezone, args); break;
    case 'find': return this.operatorFind(args); break;
    case 'first': return this.operatorFirst(args); break;
    case 'firstNonNull': return this.operatorFirstNonNull(args); break;
    case 'fromMilliseconds': return this.scalarOperator(this.operatorFromMilliseconds, args); break;
    case 'flatten': return this.operatorFlatten(args); break;
    case 'formatDate': return this.scalarOperator(this.operatorFormatDate, args); break;
    case 'formatIban': return this.scalarOperator(this.operatorFormatIban, args); break;
    case 'formatNumber': return this.scalarOperator(this.operatorformatNumber, args); break;
    case 'group': return this.operatorGroup(args); break;
    case 'hasData': return this.scalarOperator(this.operatorHasData, args); break;
    case 'ibanToDisplay': return this.scalarOperator(this.operatorIbanToDisplay, args); break;
    case 'if': return this.operatorIf(args); break;
    case 'indexOf': return this.scalarOperator(this.operatorIndexOf, args); break;
    case 'isEmpty': return this.operatorIsEmpty(args); break;
    case 'isNull': return this.operatorIsNull(args); break;
    case 'join': return this.operatorJoin(args); break;
    case 'jsonToData': return this.scalarOperator(this.operatorJsonToData, args); break;
    case 'last': return this.operatorLast(args); break;
    case 'lastIndexOf': return this.scalarOperator(this.operatorLastIndexOf, args); break;
    case 'length': return this.scalarOperator(this.operatorLength, args); break;
    case 'logarithm': return this.scalarOperator(this.operatorLogarithm, args); break;
    case 'lookup': return this.operatorLookup(args); break;
    case 'map': return this.operatorMap(args); break;
    case 'matches': return this.scalarOperator(this.operatorMatches, args); break;
    case 'max': return this.operatorMax(args); break;
    case 'mergeData': return this.operatorMergeData(args); break;
    case 'mergeDataDeep': return this.operatorMergeDataDeep(args); break;
    case 'min': return this.operatorMin(args); break;
    case 'mod': return this.scalarOperator(this.operatorMod, args); break;
    case 'namespaceForPrefix': return this.operatorNamespaceForPrefix(args); break;
    case 'normalizeDuration': return this.scalarOperator(this.operatorNormalizeDuration, args); break;
    case 'not': return this.scalarOperator(this.operatorNot, args); break;
    case 'nullToEmpty': return this.scalarOperator(this.operatorNullToEmpty, args); break;
    case 'or': return this.scalarOperator(this.operatorOr, args); break;
    case 'parseDate': return this.scalarOperator(this.operatorParseDate, args); break;
    case 'parseUri': return this.scalarOperator(this.operatorParseUri, args); break;
    case 'path': return this.scalarOperator(this.operatorPath, args); break;
    case 'position': return this.operatorPosition(args); break;
    case 'power': return this.scalarOperator(this.operatorPower, args); break;
    case 'quote': return this.operatorQuote(args); break;
    case 'random': return this.scalarOperator(this.operatorRandom, args); break;
    case 'reduce': return this.operatorReduce(args); break;
    case 'relative': return this.operatorRelative(args); break;
    case 'removeDiacritics': return this.scalarOperator(this.operatorRemoveDiacritics, args); break;
    case 'removeTimezone': return this.scalarOperator(this.operatorRemoveTimezone, args); break;
    case 'replace': return this.scalarOperator(this.operatorReplace, args); break;
    case 'result': return this.operatorResult(args); break;
    case 'reverse': return this.operatorReverse(args); break;
    case 'riRelativize': return this.scalarOperator(this.operatorRiRelativize, args); break;
    case 'riResolve': return this.scalarOperator(this.operatorRiResolve, args); break;
    case 'round': return this.scalarOperator(this.operatorRound, args); break;
    case 's:=':
    case 's:==':
    case 's:!==':
    case 's:!=':
    case 's:=~':
    case 's::=':
    case 's:<':
    case 's:<=':
    case 's:>':
    case 's:>=':
    case 's:<*':
    case 's:<=*':
    case 's:>*':
    case 's:>=*': return this.operatorStorageOperator(this.data.operator.substring(2), args); break;
    case 's:and': return this.adaptiveArgsOperator(this.operatorStorageAnd, args); break;
    case 's:or': return this.adaptiveArgsOperator(this.operatorStorageOr, args); break;
    case 's:path': return this.operatorStoragePath(args); break;
    case 's:property': return this.operatorStorageProperty(args); break;
    case 's:trailingPath': return this.operatorStorageTrailingPath(args); break;
    case 's:value': return this.operatorStorageValue(args); break;
    case 'select': return this.operatorSelect(args); break;
    case 'setTimezone': return this.scalarOperator(this.operatorSetTimezone, args); break;
    case 'shorten': return this.scalarOperator(this.operatorShorten, args); break;
    case 'some': return this.operatorSome(args); break;
    case 'sort': return this.operatorSort(args); break;
    case 'split': return this.scalarOperator(this.operatorSplit, args); break;
    case 'startsWith': return this.scalarOperator(this.operatorStartsWith, args); break;
    case 'staticValue': return this.scalarOperator(this.operatorStaticValue, args); break;
    case 'staticValues': return this.scalarOperator(this.operatorStaticValues, args); break;
    case 'staticValueKeys': return this.scalarOperator(this.operatorStaticValueKeys, args); break;
    case 'stringContains': return this.scalarOperator(this.operatorStringContains, args); break;
    case 'stringFind': return this.scalarOperator(this.operatorStringFind, args); break;
    case 'submittedBy': return this.operatorSubmittedBy(args); break;
    case 'submittedRowIndex': return this.operatorSubmittedRowIndex(args); break;
    case 'subsequence': return this.operatorSubsequence(args); break;
    case 'substring': return this.scalarOperator(this.operatorSubstring, args); break;
    case 'substring1': return this.scalarOperator(this.operatorSubstring1, args); break;
    case 'sum': return this.operatorSum(args); break;
    case 'tableLookup': return this.scalarOperator(this.operatorTableLookup, args); break;
    case 'timezone': return this.scalarOperator(this.operatorTimezone, args); break;
    case 'toDate': return this.scalarOperator(this.operatorToDate, args); break;
    case 'toDateTime': return this.scalarOperator(this.operatorToDateTime, args); break;
    case 'toMilliseconds': return this.scalarOperator(this.operatorToMilliseconds, args); break;
    case 'toLowerCase': return this.scalarOperator(this.operatorToLowerCase, args); break;
    case 'toUpperCase': return this.scalarOperator(this.operatorToUpperCase, args); break;
    case 'toTime': return this.scalarOperator(this.operatorToTime, args); break;
    case 'toTimezone': return this.scalarOperator(this.operatorToTimezone, args); break;
    case 'treeFind': return this.operatorTreeFind(args); break;
    case 'treeReduce': return this.operatorTreeReduce(args); break;
    case 'trim': return this.scalarOperator(this.operatorTrim, args); break;
    case 'trim0': return this.scalarOperator(this.operatorTrim0, args); break;
    case 'try': return this.operatorTry(args); break;
    case 'true': return true; break;
    case 'typeOf': return this.operatorTypeOf(args); break;
    case 'union': return this.operatorUnion(args); break;
    case 'unquote': this.error('function "unquote" can be used only inside "quote" function!'); return false; break;
    case 'update': return this.operatorUpdate(args); break;
    case 'updateData': return this.operatorUpdateData(args); break;
    case 'uuid': return this.operatorUUID(); break;
    case 'validateBic': return this.scalarOperator(this.operatorValidateBic, args); break;
    case 'validateIban': return this.scalarOperator(this.operatorValidateIban, args); break;
    case 'valueAt': return this.operatorValueAt(args); break;
    case 'valueToJson': return this.operatorDataToJson(args); break;
    case 'xmlToData': return this.scalarOperator(this.operatorXmlToData, args); break;
    default:
      if (this.opts && this.opts.function && this.opts.function[this.data.operator]) {
        if ('scalar' === this.opts.function[this.data.operator].mode) {
          return this.scalarOperator(this.customOperator, args, this.opts.function[this.data.operator])
        } else {
          return this.customOperator(args, this.opts.function[this.data.operator])
        }
      } else {
        this.error('Unknown function "' + this.data.operator + '"!'); 
        return null; 
      }
    break;
  }
};

Expression.prototype.scalarOperator = function(operator, args, customOp) {
  if (this.errorObject && this.scalarPosToPass !== undefined) {
    this.errorObject.scalarPos = this.scalarPosToPass
    delete this.scalarPosToPass
  }
  if (!MC.isFunction(operator)) {
    this.error('ScalarOperator can be called only with operator function as first argument!');
  }
  if (MC.isNull(args) && !Array.isArray(args)) {
    return operator.call(this, args, customOp)
  }
  let size = -1
  for (let i=0; i<args.length; i++) {
    if (Array.isArray(args[i])) {
      if (args[i].length > size) {
        size = args[i].length;
      }
    }
  }
  if (size == -1) {
    return operator.call(this, args, customOp)
  } else if (size == 0) {
    let scalarArguments = []
    for (let i = 0; i < args.length; i++) {
      if (Array.isArray(args[i]) || MC.isNull(args[i])) {
        scalarArguments.push(null)
      } else {
        scalarArguments.push(args[i])
      }
    }
    return operator.call(this, scalarArguments, customOp)
  } else {
    let resultColl = []
    for (let i = 0; i < size; i++) {
      if (this.opts.recScalarPos) {
        this.scalarPos = i
      }
      let itemArgumentsValue = []
      for (let j = 0; j < args.length; j++) {
        let argument = args[j]
        if (Array.isArray(argument)) {
          if (i < argument.length) {
            itemArgumentsValue.push(argument[i])
          } else {
            itemArgumentsValue.push(null)
          }
        } else {
          itemArgumentsValue.push(argument)
        }
      }
      let itemArguments = []
      for (let j = 0; j < itemArgumentsValue.length; j++) {
        itemArguments.push(itemArgumentsValue[j])
      }
      resultColl.push(this.scalarOperator(operator, itemArguments, customOp))
    }
    this.scalarPos = undefined
    return resultColl
  }
}

Expression.prototype.customOperator = function(args, def) {
  let opts = Object.assign({}, this.opts)
  opts.scope = {vars: {}}
  this.clearBase(opts)
  if (def.arg) {
    if (this.opts.trace && !Array.isArray(this.trace.args)) {
      this.trace.args = []
    }
    for (let i=0; i<def.arg.length; i++) {
      let argdef = def.arg[i]
      let value = MC.isNull(args[i]) && !MC.isNull(argdef.defaultValue) ? argdef.defaultValue : args[i]
      if (!argdef.optional && value === undefined) {
        this.error(`Argument ${i} of custom function ${def.name} is mandatory.`)
        return 
      }
      value = argdef.collection ? MC.asArray(value) : argdef.basicType != 'anyType' ? MC.castToScalar(value, argdef.basicType) : value
      if (this.opts.trace && !this.trace.args[i]) {
        this.trace.args[i] = {}
      }
      if (argdef.name) {
        opts.scope.vars['$'+argdef.name] = value
        if (this.opts.trace) {
          this.trace.args[i].variable = '$'+argdef.name
        }
      }
      opts.scope.vars['$'+(i+1)] = value
      if (this.opts.trace) {  
        this.trace.args[i].variable = '$'+(i+1)
      }
    }  
  }
  if (def.mapping && def.mapping.length > 0) {
    let exprs = this.sortExprs(def.mapping)
    for (let expr of exprs) {
      let expression = new Expression()
      expression.init(expr, this.cData, opts)
      let res = expression.evaluate()
      this.error(expression.getError(), def.name)
      if (this.opts.trace) {
        this.trace.eval = expression.getTrace()
      }  
      if (res != undefined && !MC.isNull(res)) {
        if (!def.optional && MC.isNull(res)) {
          this.error(`Custom function ${def.name} does not permit null value as a result.`)
          return 
        }
        if (def.basicType) {
          if (def.collection) {
            res = MC.asArray(res)
          } else {
            res = def.basicType != 'anyType' ? MC.castToScalar(res, res.basicType) : res
          }
        } else {
          res = null
        }
        return res
      }
    }          
  }
  return null
}

Expression.prototype.adaptiveArgsOperator = function(operator, args) {
  if (!MC.isFunction(operator)) {
    this.error('AdaptiveOperator can be called only with operator function as first argument!');
  }
  if (args.length == 1 && Array.isArray(args[0])) {
    args = args[0]
  }
  return operator.call(this, args)
}

Expression.prototype.operatorGreater = function(args) {
  if (args.length != 2) {
    this.error('Operator ">" works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  return (args[0] > args[1]);
};

Expression.prototype.operatorLower = function(args) {
  if (args.length != 2) {
    this.error('< operator works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  if (args[0] === null && typeof(args[1]) === 'string') {
    return true;
  }
  return (args[0] < args[1]);
};

Expression.prototype.operatorGreaterEquals = function(args) {
  if (args.length != 2) {
    this.error('>= operator works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  if (args[0] === null && typeof(args[1]) === 'string' && args[1].trim() === '') {
    return false;
  }
  return (args[0] >= args[1]);
};

Expression.prototype.operatorLowerEquals = function(args) {
  if (args.length != 2) {
    this.error('<= operator works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  if (args[0] === null && typeof(args[1]) === 'string') {
    return true;
  }
  return (args[0] <= args[1]);
};

Expression.prototype.operatorCount = function(args) {
  if (args.length > 1) {
    this.error('Count operator works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return 0;
  } else if (Array.isArray(args[0])) {
    var result = 0;
    for (var i=0; i<args[0].length; i++) {
      if (Array.isArray(args[0][i])) {
        result += this.operatorCount([args[0][i]]);
      } else if (!MC.isNull(args[0][i])) {
        result++;
      }
    }
    return result;
  } else {
    return 1;
  }
};

Expression.prototype.operatorCollectionSize = function(args) {
  if (args.length > 1) {
    this.error('Function "collectionSize" works only with one argument! Passed arguments:' + JSON.stringify(args));
  }
  if (MC.isNull(args[0])) {
    return 0;
  } else if (Array.isArray(args[0])) {
    return args[0].length;
  } else {
    return 1;
  }
};

Expression.prototype.operatorAvg = function(args) {
  if (args.length > 1) {
    this.error('Function "avg" works only with one argument! ' + args.length + ' args were passed.')
  }
  if (MC.isNull(args[0])) {
    return null;
  } else if (Array.isArray(args[0])) {
    let sumObject = {sum: new Decimal(0), count: 0}
    this.avgSum(args[0], sumObject)
    if (sumObject.count == 0) {
      return null;
    } else {
      let result = sumObject.sum.div(sumObject.count)
      return result.toFixed(result.isInt() ? 0 : 18)
    }
  } else {
    if (MC.isNumeric(args[0])) {
      return args[0]
    } else {
      this.error('Argument of "avg" must be number! Passed: ' + args[0])
    }
  }
}

Expression.prototype.avgSum = function(values, sumObject) {
  for (var i=0; i<values.length; i++) {
    if (Array.isArray(values[i])) {
      this.avgSum(values[i], sumObject)
    } else if (!MC.isNull(values[i]) && values[i] !== '') {
      if (MC.isNumeric(values[i])) {
        sumObject.sum = sumObject.sum.add(new Decimal(values[i]))
        sumObject.count++
      } else {
        this.error('Argument of "avg" must be number! Passed: ' + values[i])
      }
    }
  }
}

Expression.prototype.operatorValueAt = function(args) {
  if (args.length != 2) {
    this.error('Function "valueAt" must have exactly two arguments! Passed arguments:' + JSON.stringify(args))
  }
  let index
  try {
    index = MC.castToScalar(args[1], 'int')
  } catch (e) {
    this.error('Second argument of function "valueAt" must be an integer! "' + args[1] + '" was passed.')
  }
  if (MC.isNull(index) || index === '') {
    this.error('Second argument of function "valueAt" must not be null or empty')
    return
  }
  index = parseInt(index)
  let coll = this.flattenCollection(args[0], true)
  if (index < 0) {
    index = coll.length + index
  }
  if (index < 0 || index >= coll.length) {
    return null
  }
  return coll[index]
}

Expression.prototype.operatorCollectionItem = function(args) {
  if (args.length != 2) {
    this.error('Function "collectionItem" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let index
  try {
    index = MC.castToScalar(args[1], 'int')
  } catch (e) {
    this.error('Second argument of function "collectionItem" must be an integer! "' + args[1] + '" was passed.')
  }
  if (MC.isNull(index) || index === '') {
    index = 0
  }
  index = parseInt(index)
  let argument1 = args[0]
  if (!Array.isArray(argument1)) {
    if (index == 0 || index == -1) {
      return argument1
    } else {
      return null
    }
  } else {
    let size = argument1.length
    if (index < 0) {
      index = size + index
    }
    if (index < 0 || index >= size) {
      return null
    } else {
      return argument1[index]
    }
  }
}

Expression.prototype.operatorFirst = function(args) {
  if (args.length != 1) {
    this.error('Function "first" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  let argument1 = args[0]
  if (!Array.isArray(argument1)) {
    return argument1
  } else {
    if (argument1.length == 0) {
      return null
    } else {
      return argument1[0]
    }
  }
}

Expression.prototype.operatorLast = function(args) {
  if (args.length != 1) {
    this.error('Function "last" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  let argument1 = args[0]
  if (!Array.isArray(argument1)) {
    return argument1
  } else {
    if (argument1.length == 0) {
      return null
    } else {
      return argument1[argument1.length-1]
    }
  }
}

Expression.prototype.operatorSubsequence = function(args) {
  if (args.length < 2 || args.length > 3) {
    this.error('Function "subsequence" must have two or three arguments! Passed arguments: ' + args)
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let argument1Coll = MC.asArray(args[0])
  let length = argument1Coll.length
  let from = MC.castToScalar(args[1], 'int')
  from = MC.isNull(from) || from === '' ? 0 : parseInt(from)
  if (from < 0) {
    from = from + length
  }
  if (from < 0) {
    from = 0
  } else if (from > length) {
    from = length
  }
  let to = null
  if (args.length == 3) {
    to = MC.castToScalar(args[2], 'int')
  }
  to = MC.isNull(to) || to === '' ? length : parseInt(to)
  if (to < 0) {
    to = to + length
  }
  if (to < 0) {
    to = 0
  } else if (to < length) {
    to++
  } else if (to > length) {
    to = length
  }
  if (to < from) {
    to = from
  }
  let result = argument1Coll.slice(from, to)
  if (result.length == 0) {
    return null
  }
  return result
}

Expression.prototype.operatorConcat = function(args) {
  if (args.length == 0) {
    return null;
  } else {
    var result = ''
    for (var i=0; i<args.length; i++) {
      if (!MC.isNull(args[i])) {
        result += MC.castToScalar(args[i], 'string')
      }
    }
    return result
  }
}

Expression.prototype.operatorSubmittedBy = function(args) {
  if (args.length > 1) {
    this.error('SubmittedBy operator works only with one argument! ' + args.length + ' args were passed.');
    return;
  }
  if (MC.isNull(this.cData['@lastFormAction']) || MC.isNull(this.opts.submittedByPath) || !this.opts.submittedByPath.startsWith(this.cData['@lastFormAction'] + '/')) {
    return false;
  }
  let argument = MC.castToScalar(args[0], 'boolean')
  return argument == true
}

Expression.prototype.operatorSubmittedRowIndex = function(args) {
  if (args.length > 1) {
    this.error('submittedRowIndex operator works only with one argument! Passed arguments: ' + JSON.stringify(args));
  }
  if (Array.isArray(args[0])) {
    if (MC.isNumeric(args[0][0]['@submittedRowIndex'])) {
      return args[0][0]['@submittedRowIndex'];
    }
  }
  return -1;
};

Expression.prototype.normalizeargs = function(args) {
  var size = 1;
  for (var i=0; i<args.length; i++) {
    if (Array.isArray(args[i])) {
      if (args[i].length > size) {
        size = args[i].length;
      }
    }
  }
  if (size > 1) {
    for (var i = 0; i < args.length; i++) {
      if (!Array.isArray(args[i])) {
        var token = args[i];
        args[i] = [];
        for (var f = 0; f < size; f++) {
          args[i][f] = token === undefined ? null : token;
        }
      } else {
        if (args[i].length < size) {
          args[i] = [...args[i]] // copy to not update original array
          for (var f = 0; f < size; f++) {
            if (f > args[i].length) {
              args[i][f] = null;
            }
          }
        }
      }
    }
  } else {
    for (var i = 0; i < args.length; i++) {
      if (args[i] === undefined) {
        args[i] = null;
      } else if (Array.isArray(args[i]) && args[i].length == 1) {
        args[i] = args[i][0];
      }
    }
  }
  return args;
};

Expression.prototype.operatorSelect = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    this.error('Function "select" must have two to four args! ' + exprs.length + ' args were passed.')
    return
  }
  var expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }  
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  var base = this.enterBaseContext();
  var result = this.select(arg1Coll, exprs);
  this.leaveBaseContext(base);
  return result;
};

Expression.prototype.select = function(arg1Coll, exprs) {
  var result = [];
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    var item = arg1Coll[i];
    this.setPosition(i);
    this.setPositionValue(item);
    var resultItem;
    if (Array.isArray(item)) {
      resultItem = this.select(item, exprs);
    } else {
      var expr2 = new Expression();
      expr2.init(exprs[1], this.cData, this.opts)
      let isSelectedValue = MC.castToScalar(expr2.evaluate(), 'boolean')
      if (expr2.getError()) {
        this.error(expr2.getError());
        result.push(null);
      }
      if (this.opts.trace) {
        this.trace.args[1].push(expr2.getTrace());
      }  
      if (isSelectedValue === true) {
        if (exprs.length > 2) {
          var expr3 = new Expression();
          expr3.init(exprs[2], this.cData, this.opts)
          resultItem = expr3.evaluate()
          if (expr3.getError()) {
            this.error(expr3.getError());
            resultItem = null;
          }
          if (this.opts.trace) {
            if (!this.trace.args[2]) {
              this.trace.args[2] = [];
            }
            this.trace.args[2].push(expr3.getTrace());
          }  
        } else {
          resultItem = item;
        }
      } else {
        if (exprs.length > 3) {
          var expr4 = new Expression();
          expr4.init(exprs[3], this.cData, this.opts)
          resultItem = expr4.evaluate()
          if (expr4.getError()) {
            this.error(expr4.getError());
            resultItem = null;
          }
          if (this.opts.trace) {
            if (!this.trace.args[3]) {
              this.trace.args[3] =[];
            }
            this.trace.args[3].push(expr4.getTrace());
          }  
        } else {
          resultItem = null;
        }
      }
    }
    result.push(resultItem);
    this.setPosition(null);
    this.setPositionValue(null);
  }
  if (result.length == 0) {
    return null;
  } else {
    return result;
  }
};

Expression.prototype.operatorFilter = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    this.error('Function "filter" must have two to four args! ' + exprs.length + ' args were passed.')
    return
  }
  var expr1 = new Expression();
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }  
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  var base = this.enterBaseContext();
  var result = [];
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    var item = arg1Coll[i];
    this.setPosition(i);
    this.setPositionValue(item);
    var expr2 = new Expression();
    expr2.init(exprs[1], this.cData, this.opts)
    let isSelectedValue = MC.castToScalar(expr2.evaluate(), 'boolean')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace());
    }  
    if (expr2.getError()) {
      this.error(expr2.getError());
      result.push(null);
    }
    var resultItem;
    if (isSelectedValue === true) {
      if (exprs.length > 2) {
        var expr3 = new Expression();
        expr3.init(exprs[2], this.cData, this.opts)
        resultItem = expr3.evaluate()
        if (expr3.getError()) {
          this.error(expr3.getError());
          resultItem = null;
        }
        if (this.opts.trace) {
          if (!this.trace.args[2]) {
            this.trace.args[2] = [];
          }
          this.trace.args[2].push(expr3.getTrace());
        }  
      } else {
        resultItem = item;
      }
    } else {
      if (exprs.length > 3) {
        var expr4 = new Expression();
        expr4.init(exprs[3], this.cData, this.opts)
        resultItem = expr4.evaluate()
        if (expr4.getError()) {
          this.error(expr4.getError());
          resultItem = null;
        }
        if (this.opts.trace) {
          if (!this.trace.args[3]) {
            this.trace.args[3] =[];
          }
          this.trace.args[3].push(expr4.getTrace());
        }  
      } else {
        resultItem = null;
      }
    }
    if (!MC.isNull(resultItem)) {
      result.push(resultItem);
    }
    this.setPosition(null);
    this.setPositionValue(null);
  }
  this.leaveBaseContext(base);
  if (result.length == 0) {
    return null;
  } else {
    return result;
  }
};

Expression.prototype.operatorFind = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    this.error('Function "find" must have two to four args! ' + exprs.length + ' args were passed.')
    return null
  }
  var expr1 = new Expression();
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }  
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  var base = this.enterBaseContext();
  var result = null;
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    var item = arg1Coll[i];
    this.setPosition(i);
    this.setPositionValue(arg1Coll[i]);
    var expr2 = new Expression();
    expr2.init(exprs[1], this.cData, this.opts)
    let isSelectedValue = MC.castToScalar(expr2.evaluate(), 'boolean')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace());
    }  
    if (expr2.getError()) {
      this.error(expr2.getError());
      result = null;
    }
    var resultItem;
    if (isSelectedValue === true) {
      if (exprs.length > 2) {
        let expr3 = new Expression();
        expr3.init(exprs[2], this.cData, this.opts)
        resultItem = expr3.evaluate()
        if (expr3.getError()) {
          this.error(expr3.getError());
          resultItem = null;
        }
        if (this.opts.trace) {
          if (!this.trace.args[2]) {
            this.trace.args[2] = [];
          }
          this.trace.args[2].push(expr3.getTrace());
        }  
      } else {
        resultItem = item;
      }
    }
    if (!MC.isNull(resultItem)) {
      result = resultItem;
      break;
    }
    this.setPosition(null);
    this.setPositionValue(null);
  }
  this.leaveBaseContext(base);
  if (exprs.length > 3 && MC.isNull(result)) {
    let expr4 = new Expression()
    expr4.init(exprs[3], this.cData, this.opts)
    result = expr4.evaluate()
    if (expr4.getError()) {
      this.error(expr4.getError())
      resultItem = null
    }
    if (this.opts.trace) {
      if (!this.trace.args[3]) {
        this.trace.args[3] =[]
      }
      this.trace.args[3].push(expr4.getTrace())
    }  
  } 
  return result
}

Expression.prototype.operatorTreeFind = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    this.error('Function "treeFind" must have 2 to 4 arguments! ' + exprs.length + ' args were passed.')
    return null
  }
  let base = this.enterBaseContext()
  let expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  let arg1Coll = expr1.evaluate()
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()]
  }  
  if (expr1.getError()) {
    this.error(expr1.getError())
    return null
  }
  if (MC.isNull(arg1Coll)) {
    return null
  }
  if (arg1Coll === '') {
    return ''
  }
  let result = null
  arg1Coll = MC.asArray(arg1Coll)
  if (this.opts.trace) {   
    this.trace.args.push([])
  }  
  arg1Coll = [...arg1Coll]
  while (arg1Coll.length > 0) {
    let item = arg1Coll.shift()
    this.setPosition(0)
    this.setPositionValue(item)
    let expr2 = new Expression()
    expr2.init(exprs[1], this.cData, this.opts)
    let isSelectedValue = MC.castToScalar(expr2.evaluate(), 'boolean')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace())
    }  
    if (expr2.getError()) {
      this.error(expr2.getError())
      return null
    }
    if (exprs.length > 2) {
      let expr4 = new Expression()
      expr4.init(exprs[2], this.cData, this.opts)
      let subNodes = expr4.evaluate()
      if (expr4.getError()) {
        this.error(expr4.getError())
        return null
      }
      if (this.opts.trace) {
        if (!this.trace.args[2]) {
          this.trace.args[2] = []
        }
        this.trace.args[2].push(expr4.getTrace())
      }  
      if (!MC.isNull(subNodes)) {
        subNodes = MC.asArray(subNodes)
        arg1Coll = arg1Coll.concat(subNodes)
      }
    }
    let resultItem
    if (isSelectedValue === true) {
      if (exprs.length > 3) {
        let expr3 = new Expression()
        expr3.init(exprs[3], this.cData, this.opts)
        resultItem = MC.castToScalar(expr2.evaluate())
        if (expr3.getError()) {
          this.error(expr3.getError())
          resultItem = null
        }
        if (this.opts.trace) {
          if (!this.trace.args[3]) {
            this.trace.args[3] = []
          }
          this.trace.args[3].push(expr3.getTrace())
        }  
      } else {
        resultItem = item
      }
    }
    if (!MC.isNull(resultItem)) {
      result = resultItem
      break
    }
    this.setPosition(null)
    this.setPositionValue(null)
  }
  this.leaveBaseContext(base)
  return result
}

Expression.prototype.getData = function(item, dataPath) {
  if (Array.isArray(item)) {
    item = item[0];
  }
  let i = dataPath.indexOf('/');
  let key = dataPath;
  if (i > -1) {
    key = key.substring(0, i);
  }
  if (key.endsWith('*')) {
    key = key.substring(0, key.length -1);
  }
  if (i < 0) {
    return item[key];
  } else {
    if (item[key]) {
      return this.getData(item[key], dataPath.substring(i + 1));
    }
  }
};

Expression.prototype.operatorRelative = function(args) {
  if (args.length > 1) {
    this.error('Function "relative" must have zero or one argument! Passed arguments: ' + JSON.stringify(args))
    return null
  }
  var basePathRefs = this.bases();
  if (MC.isNull(basePathRefs)) {
    this.error('Base is not defined, cannot use function "relative" here!')
    return null
  }
  var relativePath;
  if (args.length == 0) {
    relativePath = ".";
  } else {
    if (MC.isNull(args[0]) || args[0] === '') {
      relativePath = ".";
    } else {
      relativePath = args[0]+'';
    }
  }
  if (relativePath == "." || relativePath.startsWith("./")) {
    relativePath = relativePath.replace(".", "$v" + basePathRefs.length);
  } else if (!relativePath.startsWith("$v")) {
    relativePath = "$v" + basePathRefs.length + "/" + relativePath;
  }
  var i = relativePath.indexOf("/");
  var relativeToBase = parseInt(i == -1 ? relativePath.substring(2) : relativePath.substring(2, i));
  if (relativeToBase > this.positions().length) {
    this.error("Iteration variable $v" + relativeToBase + " not defined")
    return null
  }
  relativePath = i == -1 ? "." : relativePath.substring(i +  1);
  var positions = this.positions().slice().reverse()[relativeToBase - 1].slice();
  let positionValues = this.positionValues().slice().reverse()[relativeToBase - 1].slice()
  var basePathRef = basePathRefs.slice().reverse()[relativeToBase - 1]
  if (relativePath === ".") {
    return positionValues[0]
  } else if (!relativePath.startsWith('..')) {
    let positionValue = this.flattenCollection(positionValues)[0]
    if (MC.isPlainObject(positionValue)) { 
      return this.getValue(positionValue, relativePath.split('/'), false)
    } else if (MC.isNull(positionValue) || positionValue === '') {
      return null
    } else {
      this.error("Value at base path '" + basePathRef + "' is not a complex (data node) value, cannot dereference relative subpath '" + relativePath + "'")
      return null
    }
  }
  var steps = basePathRef.split('/');
  var shortenedBasePath;
  var relativePathFull = relativePath;
  if (relativePath.startsWith('..')) {
    while (relativePath.startsWith('..')) {
      var last = steps.pop();
      if (MC.isNull(last)) {
        this.error('Relative function error - unable to resolve relative path "' + relativePathFull + '" on base path "' + basePathRef + '"');
        return null;
      }
      if (last.endsWith('*')) {
        positions.pop();
      }
      if (relativePath.length == '..'.length) {
        relativePath = null;
      } else {
        relativePath = relativePath.substring('..'.length + '/'.length);
      }
    }
    shortenedBasePath = steps.join('/');
  } else {
    shortenedBasePath = basePathRef;
  }
  var resolvedPath = this.relativize(shortenedBasePath, relativePath);
  let dereferenced = this.evaluateSource({source: resolvedPath});
  while (positions.length > 0) {
    dereferenced = MC.asArray(dereferenced);
    var position = positions.shift();
    if (position >= dereferenced.length) {
      return null;
    }
    dereferenced = dereferenced[position];
  }
  return dereferenced;
};

Expression.prototype.operatorResult = function(args) {
  if (args.length > 1) {
    this.error('Function "result" must have zero or one argument! Passed arguments: ' + JSON.stringify(args))
    return null
  }
  let resultValues = this.resultValues()
  if (!Array.isArray(resultValues) || resultValues.length == 0) {
    this.error('Function "result" cannot be used here, not in a reduce context!')
    return null
  }
  if (args.length == 0) {
    return this.peekResultValue()
  } else {
    this.error('Function "result" with argument is not implemented yet!')
    return null
  }
}

Expression.prototype.operatorPosition = function(args) {
  if (args.length > 1) {
    this.error('Function "position" must have zero or one argument! Passed arguments: ' + JSON.stringify(args))
  }
  let position = this.position()
  if (args.length < 1) {
    if (Array.isArray(position)) {
      position = position[position.length - 1]
      if (!MC.isNumeric(position)) {
        this.error('Position is not defined, cannot use function "position" here! Passed arguments: ' + JSON.stringify(args))
        return null
      }
    }
  } else {
    let nesting = MC.castToScalar(args[0], 'string')
    if (MC.isNull(nesting) || nesting === '') {
      this.error('Nesting depth value must not be null or empty if argument is specified! Passed arguments: ' + JSON.stringify(args))
    }
    if (!nesting.startsWith("$v")) {
      this.error('Invalid nesting depth value reference, must match "$v" + number! Passed arguments: ' + JSON.stringify(args))
    }
    let nestingInt = parseInt(nesting.substring(2))
    if (nesting == NaN) {
      this.error('Invalid nesting depth value reference, must match "$v" + number! Passed arguments: ' + JSON.stringify(args))
    }
    let positions = this.positions()
    if (nestingInt <= 0 || nestingInt > positions.length) {
      this.error('Nesting depth value out of range: ' + nestingInt + ', must be in 1 to ' + position.length + '! Passed arguments: ' + JSON.stringify(args))
    }
    positions =  positions[(positions.length - 1) - (nestingInt - 1)]
    if (nestingInt <= 0 || nestingInt > positions.length) {
      position = positions[positions.length - 1]
    } else {
      position = positions[nestingInt - 1]
    }
  }
  return position
}

Expression.prototype.relativize = function(path, relPath) {
  if (relPath == '.') {
    return path;
  } else if (relPath.startsWith('./')) {
    relPath = relPath.replace(/\.\//g, '');
    return this.relativize(path, relPath);
  } else if (relPath.startsWith('../')) {
    relPath = relPath.replace(/\.\.\//, '');
    path = path.substring(0, path.lastIndexOf('/'));
    return this.relativize(path, relPath);
  } else {
    return path + (path ? '/' : '') + relPath;
  }
};

Expression.prototype.operatorEquals = function(args) {
  if (args.length != 2) {
    this.error('== operator works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  return (args[0] == args[1]);
};

Expression.prototype.normalizeToCompare = function(args) {
  if (typeof(args[0]) === "boolean") {
    if (MC.isNumeric(args[1])) {
      this.error('Can not compare boolean with number! ' + JSON.stringify(args));
    }
    args[0] = args[0].toString()
    args[1] = typeof(args[1]) === "string" ? args[1].toLowerCase() : args[1]
  }
  if (typeof(args[1]) === "boolean") {
    if (MC.isNumeric(args[0])) {
      this.error('Can not compare boolean with number! ' + JSON.stringify(args));
    }
    args[1] = args[1].toString()
    args[0] = typeof(args[0]) === "string" ? args[0].toLowerCase() : args[0]
  }
  if (typeof(args[0]) === "string" && MC.isNumeric(args[0])) {
    args[0] = Number(args[0]).valueOf();
  }
  if (typeof(args[1]) === "string" && MC.isNumeric(args[1])) {
    args[1] = Number(args[1]).valueOf();
  }
  return args;
};

Expression.prototype.operatorNotEquals = function(args) {
  if (args.length != 2) {
    this.error('!= operator works only with two args! ' + args.length + ' args were passed.');
  }
  args = this.normalizeToCompare(args);
  return (args[0] != args[1]);
};

Expression.prototype.operatorEmptyToNull = function(args) {
  if (args.length != 1) {
    this.error('emptyToNull operator works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0]) || args[0] == '') {
    return null;
  } else {
    return args[0];
  }
};

Expression.prototype.buildIfArrayResult = function(nargs, errorObj, pos) {
  if (Array.isArray(nargs[0]) && nargs[0].length > 1) {
    nargs = this.normalizeargs(nargs)
    const result = []
    for (var i=0; i<nargs[0].length; i++) {
      result.push(this.buildIfArrayResult([nargs[0][i], nargs[1][i], nargs[2][i]], errorObj, i))
    }
    return result
  } else {
    const condit = MC.castToScalar(nargs[0], 'boolean')
    if (condit) {
      if (errorObj.then && (MC.isNull(errorObj.then.scalarPos) || errorObj.then.scalarPos == pos)) {
        delete errorObj.then.scalarPos
        this.error(errorObj.then)
      }
      return nargs[1]
    } else {
      if (errorObj.else && (MC.isNull(errorObj.else.scalarPos) || errorObj.else.scalarPos == pos)) {
        delete errorObj.else.scalarPos
        this.error(errorObj.else)
      }
      return nargs[2]
    }
  }
}

Expression.prototype.operatorIf = function(exprs) {
  if (exprs.length < 2 || exprs.length > 3) {
    this.error('If operator works only with two or three arguments! ' + exprs.length + ' arguments were passed.')
    return null
  }
  var expression = new Expression()
  expression.init(exprs[0], this.cData, this.opts)
  var conditions = expression.evaluate()
  if (expression.getError()) {
    this.error(expression.getError())
  }
  if (this.opts.trace) {
    this.trace.args = [expression.getTrace()]
  }  
  var thens
  var thenErr
  if (Array.isArray(conditions) || conditions == true) {
    expression = new Expression()
    expression.init(exprs[1], this.cData, Object.assign({}, this.opts, {recScalarPos: true}))
    var thens = expression.evaluate()
    if (this.opts.trace) {
      this.trace.args.push(expression.getTrace())
    }  
    thenErr = expression.getError()
  }
  var elses
  var elseErr
  if (exprs.length > 2 && (Array.isArray(conditions) || conditions == false)) {
    expression = new Expression()
    expression.init(exprs[2], this.cData, Object.assign({}, this.opts, {recScalarPos: true}))
    elses = expression.evaluate()
    elseErr = expression.getError()
    if (this.opts.trace) {
      this.trace.args.push(expression.getTrace())
    }  
  }
  let nargs = [conditions, thens, elses] 
  let res = this.buildIfArrayResult(nargs, {then: thenErr, else: elseErr}, 'nopos')
  return res
}

Expression.prototype.operatorMin = function(args) {
  if (args.length == 0) {
    this.error('Function "min" must have at least one argument! ')
  } else if (args.length == 1) {
    if (!Array.isArray(args[0])) {
      if (MC.isNumeric(args[0])) {
        return args[0]
      } else {
        return null
      }
    } else {
      let result = null
      for (let i=0; i<args[0].length; i++) {
        if (Array.isArray(args[0][i])) {
          let subRes = this.operatorMin([args[0][i]])
          if (result == null || (new Decimal(subRes)).lessThan(result)) {
            result = subRes
          }
        } else if (MC.isNumeric(args[0][i]) && !MC.isNull(args[0][i])) {
          if (result == null || (new Decimal(args[0][i])).lessThan(result)) {
            result = args[0][i]
          }
        }
      }
      return result
    }
  } else {
    if (Array.isArray(args[0])) {
      return this.scalarOperator(this.operatorMin, args)
    } else {
      let result = null
      for (let i=0; i<args.length; i++) {
        if (Array.isArray(args[i])) {
          let subRes = this.operatorMin([args[i]])
          if (result == null || (new Decimal(subRes)).lessThan(result)) {
            result = subRes
          }
        } else if (MC.isNumeric(args[i]) && !MC.isNull(args[i])) {
          if (result == null || (new Decimal(args[i])).lessThan(result)) {
            result = args[i]
          }
        }
      }
      return result
    }
  }
}

Expression.prototype.operatorMax = function(args) {
  if (args.length == 0) {
    this.error('Function "max" must have at least one argument! ');
  } else if (args.length == 1) {
    if (!Array.isArray(args[0])) {
      if (MC.isNumeric(args[0])) {
        return args[0]
      } else {
        return null
      }
    } else {
      let result = null
      for (let i=0; i<args[0].length; i++) {
        if (Array.isArray(args[0][i])) {
          let subRes = this.operatorMax([args[0][i]]);
          if (result == null || (new Decimal(subRes)).greaterThan(result)) {
            result = subRes
          }
        } else if (MC.isNumeric(args[0][i]) && !MC.isNull(args[0][i])) {
          if (result == null || (new Decimal(args[0][i])).greaterThan(result)) {
            result = args[0][i]
          }
        }
      }
      return result
    }
  } else {
    if (Array.isArray(args[0])) {
      return this.scalarOperator(this.operatorMax, args)
    } else {
      let result = null
      for (let i=0; i<args.length; i++) {
        if (Array.isArray(args[i])) {
          let subRes = this.operatorMax([args[i]])
          if (result == null || (new Decimal(subRes)).greaterThan(result)) {
            result = subRes
          }
        } else if (MC.isNumeric(args[i]) && !MC.isNull(args[i])) {
          if (result == null || (new Decimal(args[i])).greaterThan(result)) {
            result = args[i]
          }
        }
      }
      return result
    }
  }
}

Expression.prototype.operatorPlus = function(args) {
  if (args.length < 2) {
    this.error('Function "+" works only with two or more args! ' + args.length + ' args were passed.')
  }
  let result = null
  for (let i=0; i<args.length; i++) {
    if (!MC.isNull(args[i])) {
      if (MC.isNumeric(args[i])) {
        if (result == null || result === '') {
          result = new Decimal(args[i])
        } else {
          result = result.add(new Decimal(args[i]))
        }
      } else if (args[i] === '') {
        if (result == null) {
          result = ''
        }
      } else {
        this.error('Function "+" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
      }
    }
  }
  if (result == null || result === '') {
    return result
  } else {
    return result.toFixed(result.isInt() ? 0 : 18)
  }
}

Expression.prototype.operatorMinus = function(args) {
  if (args.length < 2) {
    this.error('Function "-" works only with two or more args! ' + args.length + ' args were passed. Passed arguments: ' + JSON.stringify(args))
    return      
  }
  if (!MC.isNumeric(args[0])) {
    this.error('First argument of function "-" must be numeric! Passed arguments: ' + JSON.stringify(args))
    return      
  }
  let result = new Decimal(args[0])
  for (let i=1; i<args.length; i++) {
    if (MC.isNumeric(args[i])) {
      result = result.minus(new Decimal(args[i]))
    } else if (!MC.isNull(args[i]) && args[i] !== '') {
      this.error('Function "-" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  return result.toFixed(result.isInt() ? 0 : 18)
}

Expression.prototype.operatorMultiply = function(args) {
  if (args.length < 2) {
    this.error('Function "*" must have at least two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let result = null
  for (let i=0; i<args.length; i++) {
    if (MC.isNull(args[i]) || args[i] === '') {
      this.error('Cannot multiply with argument ' + (i + 1) + ' of function "*", value is ' + args[i] + '! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (!MC.isNumeric(args[i])) {
      this.error('Cannot cast to number argument ' + (i + 1) + ' of function "*", value is ' + args[i] + '! Passed arguments: ' + JSON.stringify(args))
      return
    }  
    if (result == null) {
      result = new Decimal(args[i])
    } else {
      result = result.mul(new Decimal(args[i]))
    }
  }
  return result.toFixed()
}

Expression.prototype.operatorPower = function(args) {
  if (args.length != 2) {
    this.error('Function "power" works only with two! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0]) || args[0]==='' && MC.isNull(args[1]) || args[1]==='') {
    return null
  }
  if (MC.isNull(args[0]) || args[0]==='' || MC.isNull(args[1]) || args[1]==='') {
    this.error('Either none or both args of function "power" must be specified Passed arguments: ' + JSON.stringify(args))
  }
  return (new Decimal(args[0])).pow(new Decimal(args[1])).toPrecision(18)
}

Expression.prototype.operatorDivide = function(args) {
  if (args.length < 2) {
    this.error('Function "/" works only with two or more args! ' + args.length + ' args were passed.')
    return
  }
  if (!MC.isNumeric(args[0])) {
    this.error('First argument of function "/" must be numeric! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let result = new Decimal(args[0])
  for (let i=1; i<args.length; i++) {
    if (MC.isNumeric(args[i])) {
      if (Number(args[i]).valueOf() == 0) {
        this.error('Operator "/" not allows dividing by zero! Passed arguments: ' + JSON.stringify(args))
        return
      }
      result = result.div(new Decimal(args[i]))
    } else if (!MC.isNull(args[i]) && args[i] !== '') {
      this.error('Function "/" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  return result.toFixed()
}

Expression.prototype.operatorMod = function(args) {
  if (args.length < 2) {
    this.error('Function "mod" works only with two or more args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (!MC.isNumeric(args[0]) || !MC.isNumeric(args[1])) {
    this.error('Function "mod" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let result = new Decimal(args[0])
  for (let i=1; i<args.length; i++) {
    if (MC.isNumeric(args[i])) {
      result = result.mod(new Decimal(args[i]))
    } else {
      this.error('Function "mod" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  return result.toFixed()
}

Expression.prototype.operatorDiv = function(args) {
  if (args.length < 2) {
    this.error('Function "div" works only with two or more args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (!MC.isNumeric(args[0]) || !MC.isNumeric(args[1])) {
    this.error('Function "div" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let result = new Decimal(args[0])
  for (let i=1; i<args.length; i++) {
    if (MC.isNumeric(args[i])) {
      result = result.div(new Decimal(args[i])).floor()
    } else {
      this.error('Operator "div" works only with numeric args! Passed arguments: ' + JSON.stringify(args))
    }
  }
  return result.toFixed()
}

Expression.prototype.operatorOr = function(args) {
  for (var i=0; i<args.length; i++) {
    if (args[i]) {
      return true;
    }
  }
  return false;
};

Expression.prototype.operatorAnd = function(args) {
  for (var i=0; i<args.length; i++) {
    if (!args[i]) {
      return false;
    }
  }
  return true;
};

Expression.prototype.operatorNot = function(args) {
  if (args.length != 1) {
    this.error('Not operator works only with one argument! ' + args.length + ' args were passed.');
  }
  if (args[0] == true || args[0] == 'true') {
    return false;
  } else if (args[0] == false || args[0] == 'false') {
    return true;
  } else {
    return !args[0];
  }
};

Expression.prototype.operatorSum = function(args) {
  if (args.length > 1) {
    this.error('Function "sum" works only with one argument! Passed arguments: ' + JSON.stringify(args[0]))
  }
  if (Array.isArray(args[0])) {
    args[0] = this.operatorFlatten(args)
    let result = null
    for (let i=0; i<args[0].length; i++) {
      if (MC.isNull(args[0][i]) || args[0][i] === '') {
        continue
      }
      if (MC.isNumeric(args[0][i])) {
        if (result == null) {
          result = new Decimal(args[0][i])
        } else {
          if (!(result instanceof Decimal)) {
            this.error('Sum not works with different types of argumetns! Passed arguments: ' + JSON.stringify(args[0]))
          }
          result = result.add(new Decimal(args[0][i]))
        }
      } else {
        let act = new Duration()
        act.parseIsoString(args[0][i])
        if (!act.isValidDuration()) {
          this.error('Sum works only with numbers or durations! Passed arguments: ' + JSON.stringify(args[0]))
        }
        if (result == null) {
          result = act
        } else {
          if (!MC.isDurationObject(result)) {
            this.error('Sum not works with different types of argumetns! Passed arguments: ' + JSON.stringify(args[0]))
          }
          result.add(act)
        }
      }
    }
    if (result == null) {
      return null
    } else if (MC.isDurationObject(result)) {
      return result.toIsoString()
    } else {
      return result.toFixed()
    }
  } else {
    if (MC.isNumeric(args[0])) {
      return args[0]
    } else {
      return null
    }
  }
}

Expression.prototype.operatorUnion = function(args) {
  if (Array.isArray(args)) {
    var result = [];
    for (var i=0; i<args.length; i++) {
      if (Array.isArray(args[i]) && args[i].length > 0) {
        result = result.concat(args[i]);
      } else if (!MC.isNull(args[i])) {
        result.push(args[i]);
      }
    }
    return result;
  } else {
    if (Array.isArray(args[0])) {
      return args[0];
    } else {
      var result = [];
      result.push(args[0]);
      return result;
    }
  }
};

Expression.prototype.operatorContains = function(args) {
  if (args.length != 2) {
    this.error('Contains operator works only with two args! ' + args.length + ' args were passed.');
  }
  if (args[0] == null) {
    return false;
  }
  if (Array.isArray(args[1])) {
    var result = [];
    for (var v=0; v<args[1].length; v++) {
      if (Array.isArray(args[0])) {
        var found = false;
        for (var i=0; i<args[0].length; i++) {
          if (args[0][i] == args[1][v]) {
            found = true;
          }
        }
        result.push(found);
      } else {
        result.push(args[0] == args[1][v]);
      }
    }
    return result;
  } else {
    if (Array.isArray(args[0])) {
      for (var i=0; i<args[0].length; i++) {
        if (args[0][i] == args[1]) {
          return true;
        }
      }
      return false;
    } else {
      return (args[0] == args[1]);
    }
  }

};

Expression.prototype.operatorCollection = function(args) {
  var result = [];
  for (var i=0; i<args.length; i++) {
    result.push(args[i]);
  }
  return result;
};

Expression.prototype.operatorIsEmpty = function(args) {
  if (args.length != 1) {
    this.error('IsEmpty operator just works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return true;
  } else {
    if (args[0] === '') {
      return true;
    } else {
      if (Array.isArray(args[0])) {
        for (var i=0; i<args[0].length; i++) {
          if (!MC.isNull(args[0][i]) && args[0][i] != '') {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
  }
};

Expression.prototype.operatorIsNull = function(args) {
  if (args.length != 1) {
    this.error('IsNull operator just works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return true;
  } else {
    if (Array.isArray(args[0])) {
      for (var i=0; i<args[0].length; i++) {
        if (!MC.isNull(args[0][i])) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
};

Expression.prototype.operatorFill = function(args) {
  if (args.length != 2) {
    this.error('Function "fill" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let arg2 = MC.castToScalar(args[1], 'string')
  let count = 0
  if (!MC.isNull(arg2) && arg2 !== '') {
    if (!MC.isNumeric(arg2)) {
      this.error('Second argument of function "fill" must be number! Passed arguments: ' + JSON.stringify(args))
    }
    count = parseInt(arg2)
    if (count < 0) {
      count = 0
    }
  }
  if (count == 0) {
    return null
  }
  let coll = []
  for (let i = 0; i < count; i++) {
    coll.push(args[0])
  }
  return coll
}

Expression.prototype.operatorHasData = function(args) {
  if (args.length > 1) {
    this.error('Operator hasData works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0]) || args[0] === '') {
    return false;
  } else {
    return true;
  }
};

Expression.prototype.operatorExists = function(args) {
  if (args.length > 1) {
    this.error('Operator exists works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return false;
  } else {
    return true;
  }
};

Expression.prototype.operatorStorageProperty = function(args) {
  if (args.length != 1) {
    this.error('"s:property" function works only with one argument! ' + args.length + ' args were passed.')
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let nsis = this.cData.env.ns
  let prefixed = args[0]
  if (prefixed.indexOf(':') > -1 && Array.isArray(nsis)) {
    let tokens = prefixed.split(':')
    for (var i=0; i<nsis.length; i++) {
      var ns = nsis[i]
      if (ns.prefix == tokens[0]) {
        prefixed = '{' + ns.uri + '}' + tokens[1]
        break
      }
    }
  } else if (prefixed.indexOf(':') < 0) {
    prefixed = '{}' + prefixed
  }  
  return prefixed
}

Expression.prototype.operatorStorageValue = function(args) {
  if (args.length != 1) {
    this.error('Function "s:value" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  let arg1 = MC.castToScalar(args[0], 'string')
  if (MC.isNull(arg1)) {
    return null
  }
  if (arg1 === '') {
    return ''
  }
  return encodeURIComponent(arg1)
}

Expression.prototype.operatorStorageAnd = function(args) {
  let parts = []
  for (let arg of args) {
    arg = MC.castToScalar(arg, 'string')
    if (!MC.isNull(arg) && arg !== '') {
      parts.push(arg)
    }
  }
  if (parts.length == 0) {
    return null
  } else if (parts.length == 1) {
    return parts[0]
  } else {
    return '(' + parts.join(';') + ')'
  }
}

Expression.prototype.operatorStorageOr = function(args) {
  let parts = []
  for (let arg of args) {
    arg = MC.castToScalar(arg, 'string')
    if (!MC.isNull(arg) && arg !== '') {
      parts.push(arg)
    }
  }
  if (parts.length == 0) {
    return null
  } else if (parts.length == 1) {
    return parts[0]
  } else {
    return 'or(' + parts.join(';') + ')'
  }
}

Expression.prototype.operatorStorageOperator = function(operator, args) {
  if (args.length != 2) {
    this.error('"' + operator + '" operator works with two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0]) || args[0] == '') {
    this.error('"' + operator + '" must have first argument not empty! Passed arguments: ' + JSON.stringify(args));
  }
  return  encodeURIComponent(args[0]) + operator + encodeURIComponent(args[1]);
};

Expression.prototype.operatorStoragePath = function(args) {
  if (args.length < 1) {
    this.error('"s:path" operator must have at least one argument! ' + args.length + ' args were passed.');
  }
  var filter = '';
  var sep = '';
  for (var i = 0; i < args.length; i++) {
    if (!MC.isNull(args[i])) {
      filter += sep;
      sep = '/';
      filter += args[i];
    }
  }
  if (filter == '') {
    return null;
  } else {
    return filter;
  }
};

Expression.prototype.operatorStorageTrailingPath = function(args) {
  if (args.length < 1) {
    this.error('"s:trailingPath" operator must have at least one argument! ' + args.length + ' args were passed.');
  }
  var filter = '';
  var sep = '';
  for (var i = 0; i < args.length; i++) {
    if (!MC.isNull(args[i])) {
      filter += sep;
      sep = '/';
      filter += args[i];
    }
  }
  if (filter == '') {
    return null;
  } else {
    return '*/' + filter;
  }
};

Expression.prototype.operatorSubstring = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Operator "substring" works only with 2 or 3 args! ' + args.length + ' args were passed.');
  }
  if (!MC.isNumeric(args[1])) {
    this.error('Operator "substring" must have integer as second argument! Passed arguments: ' + JSON.stringify(args) + '.');
  } else {
    args[1] = Number(args[1]).valueOf();
  }
  if (!MC.isNull(args[2])) {
    if (!MC.isNumeric(args[2])) {
      this.error('Operator "substring" must have integer as third argument! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      args[2] = Number(args[2]).valueOf();
    }
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    var s = args[0]+'';
    if (args[1] < 0) {
      args[1] = s.length + Number(args[1]);
    }
    if (MC.isNull(args[2])) {
      args[2] = s.length;
    } else if (args[2] < 0) {
      args[2] = s.length + Number(args[2]);
    }
    if (args[1] > args[2] || args[1] > s.length) {
      return '';
    }
    return s.substring(Number(args[1]), Number(args[2] + 1));
  }
};

Expression.prototype.operatorSubstring1 = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Operator "substring1" works only with 2 or 3 args! ' + args.length + ' args were passed.');
  }
  if (!MC.isNumeric(args[1])) {
    this.error('Operator "substring1" must have integer as second argument! Passed arguments: ' + JSON.stringify(args) + '.');
  } else {
    args[1] = Number(args[1]).valueOf();
  }
  if (!MC.isNull(args[2])) {
    if (!MC.isNumeric(args[2])) {
      this.error('Operator "substring" must have integer as third argument! Passed arguments: ' + JSON.stringify(args) + '.');
    } else {
      args[2] = Number(args[2]).valueOf();
    }
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    var s = args[0]+'';
    if (args[1] < 0) {
      args[1] = s.length + Number(args[1]) + 1;
    }
    if (MC.isNull(args[2])) {
      args[2] = s.length;
    } else if (args[2] < 0) {
      args[2] = s.length + Number(args[2]) + 1;
    }
    args[1] = args[1] > 0 ? args[1] - 1 : args[1];
    if (args[1] > args[2] || args[1] > s.length) {
      return '';
    }
    return s.substring(Number(args[1]), Number(args[2]));
  }
};

Expression.prototype.operatorTrim = function(args) {
  if (args.length != 1) {
    this.error('Operator trim works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').trim();
  }
};

Expression.prototype.operatorTrim0 = function(args) {
  if (args.length != 1) {
    this.error('Operator trim works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').replace(/^0+/, '');
  }
};

Expression.prototype.operatorLength = function(args) {
  if (args.length != 1) {
    this.error('Operator length works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').length;
  }
};

Expression.prototype.operatorCurrentDate = function() {
  var mockNow = this.operatorAppCfgVal(['fl:mockNow'])
  if (!MC.isNull(mockNow)) {
    return mockNow
  } else {
    return DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
  }
}

Expression.prototype.operatorFormatDate = function(args) {
  if (args.length != 2 && args.length != 1) {
    this.error('Function "formatDate" works only with one or two args! ' + args.length + ' args were passed.')
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if (!MC.isValidDateStringByType(args[0], 'dateTime')) {
    this.error('Function "formatDate" must have date, dateTime or time as first argument! Passed arguments: ' + JSON.stringify(args))
  }
  let lo = MC.dateTimeStringToLuxon(args[0])
  if (!lo.v.isValid) {
    this.error('Function "formatDate" must have date, dateTime or time as first argument! Passed arguments: ' + JSON.stringify(args))
  }  
  if (MC.isNull(args[1]) || args[1] === '') {
    return lo.v.toFormat("yyyy-MM-dd HH:mm:ss")
  } else if (typeof(args[1]) !== 'string') {
    this.error('Second argument of function "formatDate" must be string! Passed arguments: ' + JSON.stringify(args))
  } else {
    return MC.formatDate(args[0], args[1])
  }
}

Expression.prototype.operatorStartsWith = function(args) {
  if (args.length != 2) {
    this.error('Operator startsWith works only with two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return false;
  } else {
    return (args[0]).startsWith(args[1]);
  }
};

Expression.prototype.operatorEndsWith = function(args) {
  if (args.length != 2) {
    this.error('Operator endsWith works only with two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return false;
  } else {
    return (args[0]).endsWith(args[1]);
  }
};

Expression.prototype.operatorSplit = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('"Function split must have one or two arguments"! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let string = MC.castToScalar(args[0], 'string')
  let splitBy = args.length > 1 ? MC.castToScalar(args[1], 'string') : ''
  if (MC.isNull(splitBy)) {
    return string
  }
  return string.split(new RegExp(splitBy))
}

Expression.prototype.operatorUUID = function() {
  return MC.generateId()
}

Expression.prototype.operatorAppCfgVal = function(args) {
  if (args.length != 1) {
    this.error('Function "appCfgValGet" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0]) || args[0] === '') {
    this.error('Argument of function "appCfgValGet" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (MC.isNull(this.cData.env.cfg)) {
    return null;
  }
  const path = args[0].split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/');
  return this.getData(this.cData.env.cfg, path);
};

Expression.prototype.operatorAppCfgVal2 = function(args) {
  if (args.length != 1) {
    this.error('Function "appCfgValGet2" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0]) || args[0] === '') {
    this.error('Argument of function "appCfgValGet2" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (MC.isNull(this.cData.env.cfg)) {
    return null;
  }
  const path = args[0].split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/');
  return this.getData(this.cData.env.cfg, path);
};

Expression.prototype.operatorJsonToData = function(args) {
  if (args.length != 1) {
    this.error('Function "jsonToData" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return  ''
  }
  let data = JSON.parse(args[0])
  data = MC.nullsToEmpty(data)
  return data
}

Expression.prototype.operatorDataToJson = function(args) {
  if (args.length != 1) {
    this.error('Function "dataToJson" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  args[0] = MC.emptysToNull(args[0])
  return JSON.stringify(args[0], null, '  ')
}

Expression.prototype.operatorRound = function(args) {
  if (args.length < 1 || args.length > 3) {
    this.error('Function "round" must have one, two or three arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if (!MC.isNumeric(args[0])) {
    this.error('First argument of function "round" must be a number! Passed arguments: ' + JSON.stringify(args))
  }
  let decimalPlaces = 0
  if (!MC.isNull(args[1]) && args[1] !== '') {
    if (!MC.isNumeric(args[1])) {
      this.error('Second argument of "round" operator must be a number if used! Passed arguments: ' + JSON.stringify(args))
    } else if (args[1] == 0) {
      this.error('Precision (second argument) of funciton "round"  must not be zero if used! Passed arguments: ' + JSON.stringify(args))
    } else {
      decimalPlaces = parseInt(args[1])
    }
  }
  let mode = args[2]
  if (mode == 0 || mode === "up") {
    if (new Decimal(args[0]).greaterThanOrEqualTo(0)) {
      mode = "ceiling"
    } else {
      mode = "floor"
    }
  }
  let result
  if (MC.isNull(mode) || mode === '' || mode == 4 || mode == "half_up") {
    mode = Decimal.ROUND_HALF_UP
  } else if (mode == 1 || mode === "down") {
    mode = Decimal.ROUND_DOWN
  } else if (mode == 2 || mode === "ceiling") {
    mode = Decimal.ROUND_CEIL
  } else if (mode == 3 || mode === "floor") {
    mode = Decimal.ROUND_FLOOR
  } else if (mode == 5 || mode === "half_down") {
    mode = Decimal.ROUND_HALF_DOWN
  } else if (mode == 6 || mode === "half_even") {
    mode = Decimal.ROUND_HALF_EVEN
  } else {
    this.error('Unsupported rounding type ("' + mode + '") of funciton "round" in third argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (decimalPlaces < 0) {
    let shift = Decimal.pow(10, -1*decimalPlaces-1)
    result = Decimal.div(args[0], shift).toDP(0, mode)
    result = Decimal.mul(result, shift)
  } else {
    result = (new Decimal(args[0])).toDP(decimalPlaces, mode)
  }
  return result.toFixed()
}

Expression.prototype.operatorJoin = function(args) {
  if (args.length != 2 && args.length != 1) {
    this.error('Operator join works only with one or two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (!Array.isArray(args[0])) {
    return args[0]+'';
  }
  var separator = '';
  if (!MC.isNull(args[1])) {
    separator = args[1];
  }
  var argsn = [];
  argsn.push(args[0]);
  argsn = this.operatorFlatten(argsn);
  var result = '';
  for (var i = 0; i < argsn.length; i++) {
    if (!MC.isNull(argsn[i])) {
      if (i > 0) {
        result += separator;
      }
      result += argsn[i];
    }
  }
  return result;
};

Expression.prototype.operatorToUpperCase = function(args) {
  if (args.length != 1) {
    this.error('Operator toUpperCase works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').toUpperCase();
  }
};

Expression.prototype.operatorToLowerCase = function(args) {
  if (args.length != 1) {
    this.error('Operator toLowerCase works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').toLowerCase();
  }
};

Expression.prototype.operatorEscapeHtml = function(args) {
  if (args.length != 1) {
    this.error('Operator escapeHtml works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  } else {
    return (args[0]+'').replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;");
  }
};

Expression.prototype.operatorMatches = function(args) {
  if (args.length != 2) {
    this.error('Operator matches works only with two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return false;
  } else {
    return (new RegExp('^' + args[1] + '$')).test(args[0]);
  }
};

Expression.prototype.operatorEncodeHex = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Operator encodeHex works only with one ore two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var input = args[0]+'';
  var bytes = MC.toUTF8Array(input);
  var out = [];
  var digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
  for (var i = 0, j = 0; i < bytes.length; i++) {
    out[j++] = digits[(0xF0 & bytes[i]) >>> 4];
    out[j++] = digits[0x0F & bytes[i]];
  }
  return out.join('');
};

Expression.prototype.operatorDecodeHex = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Operator decodeHex works only with one or two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var data = args[0]+'';
  var len = data.length;
  if (len % 2) {
    this.error('Argument of operator "decodeHex" has odd number of characters!');
  }
  var digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
  var out = [];
  for (var i = 0, j = 0; j < len; i++) {
    var f = digits.indexOf(data.charAt(j)) << 4;
    j++;
    f = f | digits.indexOf(data.charAt(j));
    j++;
    out[i] = (f & 0xFF);
  }
  return MC.fromUTF8Array(out);
};

Expression.prototype.operatorEncodeBase64 = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Function "encodeBase64" must have one or two arguments! Passed aruments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let arg = MC.castToScalar(args[0], 'string')
  try {
    return btoa(unescape(encodeURIComponent(arg)))
  } catch (e) {
    return btoa(arg)
  }
}

Expression.prototype.operatorDecodeBase64 = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Function "decodeBase64" must have one or two arguments! Passed aruments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let arg = MC.castToScalar(args[0], 'string')
  try {
    return decodeURIComponent(escape(window.atob(arg)))
  } catch (e) {
    return window.atob(arg)
  }
}

Expression.prototype.operatorNullToEmpty = function(args) {
  if (args.length != 1) {
    this.error('Operator nullToEmpty works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return '';
  } else {
    return args[0];
  }
};

Expression.prototype.operatorFlatten = function(args) {
  if (args.length != 1) {
    this.error('Operator "Flatten" works only with one argument! ' + args.length + ' args were passed.');
  }
  return this.flattenCollection(args[0], false);
};

Expression.prototype.flattenCollection = function(coll, withNull) {
  var result = [];
  if (Array.isArray(coll)) {
    for (var i=0; i<coll.length; i++) {
      result = result.concat(this.flattenCollection(coll[i], withNull));
    }
  } else {
    if (MC.isNull(coll)) {
      if (withNull) {
        result.push(coll);
      }
    } else {
      result.push(coll);
    }
  }
  return result;
};

Expression.prototype.operatorDataToXml = function(args) {
  if (args.length < 1 || args.length > 3) {
    this.error('Function "dataToXml" must have one to three arguments! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if (!MC.isPlainObject(args[0])) {
    return args[0]
  } else {
    let strict = false
    if (args.length > 1) {
      if (MC.castToScalar(args[1], 'boolean')) {
        strict = true
      }
    }
    let pretty = false
    if (args.length > 2) {
      if (MC.castToScalar(args[2], 'boolean')) {
        pretty = true
      }
    }
    let data = strict && Object.getOwnPropertyNames(args[0]).length > 1 ? {data: args[0]} : args[0]
    let xml = MC.objectToXML(data, 0)
    if (strict) {
      xml = '<?xml version="1.0"?>\n' + xml
    }
    if (pretty) {
      return xml
    } else {
      return MC.stripWhiteSpaceInXML(xml)
    }
  }
}

Expression.prototype.operatorXmlToData = function(args) {
  if (args.length != 1) {
    this.error('Function "xmlToData" works only with one argument! ' + args.length + ' args were passed.')
  }
  if (MC.isNull(args[0])) {
    return null
  } else {
    return MC.xmlStringToObject(MC.normalizeValue(args[0], 'string'), this.cData.env.ns, true)
  }
}

Expression.prototype.operatorStaticValue = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Function "staticValue" works only with two or three args! Passed arguments: ' + JSON.stringify(args) + '.')
    return null
  }
  if (MC.isNull(args[0]) || args[0] == '') {
    return null
  }
  if (MC.isNull(args[1])) {
    this.error('Second argument of "staticValue" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.')
    return null
  } else {
    const valueKey = MC.castToScalar(args[0], 'string')
    const staticList = MC.castToScalar(args[1], 'string')
    const lang = MC.castToScalar(args[2], 'string')
    let value = this.cData.svl ? this.cData.svl[staticList] : undefined
    if (value === undefined) {
      this.error('Static value list with name "' + staticList + '" is undefined! Passed arguments: ' + JSON.stringify(args) + '.')
      return null
    }
    value = MC.asArray(value)
    let svl
    for (let i = 0; i<value.length; i++) {
      if (value[i].value == valueKey) {
        svl = value[i]
        break
      }
    }
    if (!MC.isNull(svl)) {
      if (MC.isNull(lang)) {
        return svl['title']
      } else {
        if (svl['mut'] && svl['mut'][lang]) {
          return svl['mut'][lang]
        } else {
          return svl['title']
        }
      }
    }
    return null
  }
}

Expression.prototype.operatorStaticValues = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Operator "staticValues" works only with one or two args! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    this.error('First argument of "staticValues" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.');
  } else {
    let value = this.cData.svl ? MC.asArray(this.cData.svl[args[0]]) : undefined
    if (MC.isNull(value)) {
      return null;
    } else {
      var result = [];
      for (var i = 0; i<value.length; i++) {
        if (!MC.isNull(value[i])) {
          if (!MC.isNull(args[1]) && value[i]['mut'] && value[i]['mut'][args[1]]) {
            result.push(value[i]['mut'][args[1]]);
          } else if (!MC.isNull(value[i].title)) {
            result.push(value[i].title);
          } else {
            result.push('');
          }
        }
      }
      return result;
    }
  }
};

Expression.prototype.operatorStaticValueKeys = function(args) {
  if (args.length != 1) {
    this.error('Operator "staticValueKeys" works only with one argument! ' + args.length + ' args were passed.');
  }
  if (MC.isNull(args[0])) {
    this.error('First argument of "staticValueKeys" can not be empty! Passed arguments: ' + JSON.stringify(args) + '.');
  } else {
    let value = this.cData.svl ? MC.asArray(this.cData.svl[args[0]]) : undefined
    if (MC.isNull(value)) {
      return null;
    } else {
      var result = [];
      for (var i = 0; i<value.length; i++) {
        result.push(value[i].value);
      }
      return result;
    }
  }
};

Expression.prototype.operatorFromMilliseconds = function(args) {
  if (args.length != 1) {
    this.error('Operator "fromMilliseconds" works only with one argument! ' + args.length + ' args were passed.')
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] == '') {
    return ''
  }
  if (MC.isNumeric(args[0])) {
    let opts = undefined
    if (!MC.isNull(this.cData.env.cfg)) {
      let timezone = this.getData(this.cData.env.cfg, 'fl:localTimezoneId')
      if (timezone != null) {
        opts = {zone: timezone}
      }
    }
    let lux = DateTime.fromMillis(Number(args[0]), opts)
    if (lux.isValid) {
      return MC.luxonToDateTimeString({v: lux}, 'dateTime', true)
    } else {
      this.error('Argument of "fromMilliseconds" must be valid unix milliseconds number! Passed: ' + args[0])
    }
  } else {
    this.error('Argument of "fromMilliseconds" must be number! Passed: ' + args[0])
  }
}

Expression.prototype.operatorToMilliseconds = function(args) {
  if (args.length != 1) {
    this.error('Function "toMilliseconds" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let lux = MC.dateTimeStringToLuxon(args[0])
  if (lux.v.isValid) {
    if (!MC.hasTimezone(args[0])) {
      this.error('Argument of function "toMilliseconds" value must have timezone! Passed arguments: ' + JSON.stringify(args))
    }
    return lux.v.toMillis()
  } else {
    this.error('Argument of function "toMilliseconds" must be valid date time! Passed arguments: ' + JSON.stringify(args))
  }
}

Expression.prototype.operatorTry = function(exprs) {
  if (exprs.length > 0) {
    if (this.opts.trace) {
      this.trace.args = []
    }  
    for (let i=0; i<exprs.length; i++) {
      let expression = new Expression()
      expression.init(exprs[i], this.cData, this.opts)
      try {
        const result = expression.evaluate()
        if (this.opts.trace) {
          let trace = expression.getTrace()
          this.trace.args.push(trace)
        }  
        if (!expression.getError()) {
          return result
        }
      } catch (e) {
        if (this.opts.trace) {
          this.trace.args.push("EVAL ERROR!")
        }  
      }
    }
    return null;
  } else {
    this.error('Function "try" must have at least one argument!')
  }
}

Expression.prototype.operatorToDate = function(args) {
  if (args.length != 1) {
    this.error('Function "toDate" works only with one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  } else if (args[0] == '') {
    return ''
  } else {
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (lux.v.isValid) {
      return lux.v.toFormat('yyyy-MM-dd')
    } else {
      this.error('Argument of "toDate" must be valid date time! Passed: ' + args[0])
    }
  }
}

Expression.prototype.operatorToTime = function(args) {
  if (args.length != 1) {
    this.error('Function "toTime" works only with one argument! Passed arguments: ' + JSON.stringify(args))
    return null
  }
  if (MC.isNull(args[0])) {
    return null
  } else if (args[0] == '') {
    return ''
  } else {
    let lux = MC.dateTimeStringToLuxon(args[0])
    if (lux.v.isValid) {
      return MC.luxonToDateTimeString(lux, 'time')
    } else {
      this.error('Argument of "toTime" must be valid date time! Passed: ' + args[0])
      return null
    }
  }
}

Expression.prototype.operatorAddDuration = function(args) {
  if (args.length < 2) {
    this.error('Operator "addDuration" must have at least two args! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  } else if (args[0] == '') {
    return ''
  } else {
    let result = MC.dateTimeStringToLuxon(args[0])
    if (!result.v.isValid) {
      this.error('Operator "addDuration" must have date, dateTime or time as first argument! Passed arguments: ' + JSON.stringify(args))
    }
    for (var i=1; i<args.length; i++) {
      if (!MC.isNull(args[i]) && args[i] !== '') {
        var act = new Duration()
        act.parseIsoString(args[i])
        if (!act.isValidDuration()) {
          this.error('Operator "addDuration" works only with durations from second argument! Passed arguments: ' + JSON.stringify(args))
        }
        MC.luxonAdd(result, act)
      }
    }
    return MC.luxonToDateTimeString(result, 'dateTime')
  }
};

Expression.prototype.operatorDataNode = function(args) {
  let result = {}
  for (var i=0; i<args.length; i += 2) {
    let key = MC.castToScalar(args[i], 'string')
    if (MC.isNull(key) || key === '') {
      this.error('Complex value property name in function "dataNode" cannot be null or empty! Passed arguments: ' + JSON.stringify(args))
      return
    }
    if (key.endsWith('*')) {
      key = key.substring(0, key.length-1)
    }  
    let value = i + 1 < args.length ? args[i+1] : null
    result[key] = value
  }
  return result
}

Expression.prototype.operatorDurationBetween = function(args) {
  if (args.length!= 2 && args.length!= 3) {
    this.error('Function "durationBetween" must have two or three args! Passed arguments: ' + JSON.stringify(args))
  }
  let date1 = MC.dateTimeStringToLuxon(args[0])
  if (!date1.v.isValid) {
    this.error('Function "addDuration" must have valid dateTime as first argument! Passed arguments: ' + JSON.stringify(args))
  }
  let date2 = MC.dateTimeStringToLuxon(args[1])
  if (!date2.v.isValid) {
    this.error('Function "addDuration" must have valid dateTime as second argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.objectHasTimezone(date1) != MC.objectHasTimezone(date2)) {
    this.error('Either both of the args must have timezone or none of them!')
  }
  if (args.length == 3 && !MC.isNull(args[2]) && args[2] !== '') {
    const units = ["y", "M", "d", "H", "m", "s", "S"]
    if (units.indexOf(args[2]) == -1) {
      this.error('Unknown duration unit ' + args[1] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
    }
    let result = new Duration()
    switch (args[2]) {
      case 'y': result.from(Math.floor(date2.v.diff(date1.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
      case 'M': result.from(0, Math.floor(date2.v.diff(date1.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
      case 'd': result.from(0, 0, Math.floor(date2.v.diff(date1.v, 'days').toObject().days), 0, 0, 0, 0); break
      case 'H': result.from(0, 0, 0, Math.floor(date2.v.diff(date1.v, 'hours').toObject().hours), 0, 0, 0); break
      case 'm': result.from(0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'minutes').toObject().minutes), 0, 0); break
      case 's': result.from(0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'seconds').toObject().seconds), 0); break
      case 'S': result.from(0, 0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'milliseconds').toObject().milliseconds)); break
    }
    return result.toIsoString()
  } else {
    let duration = MC.durationBetween(date1, date2)
    return duration.toIsoString()
  }
}

Expression.prototype.operatorToTimezone = function(args) {
  if (args.length != 1 && args.length != 2) {
    this.error('Operator "toTimezone" must have one or two two args! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if ((args[0]+'').match(/^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?)?)?)?$/i)) {
    this.error('Cannot convert to different timezone, value ' + args[0] + ' is in no timezone!')
  }
  let lux = MC.dateTimeStringToLuxon(args[0])
  if (!MC.isNull(args[1]) && args[1] !== '') {
    if (args[1] == 'Z') {
      lux.v = lux.v.setZone('utc')
    } else {
      lux.v = lux.v.setZone('utc' + args[1])
    }
  } else {
    let timezone = null;
    if (!MC.isNull(this.cData.env.cfg)) {
      timezone = this.getData(this.cData.env.cfg, 'fl:localTimezoneId')
    }
    if (timezone != null) {
      lux.v = lux.v.setZone(timezone)
    } else {
      let offsetToset = Math.floor(DateTime.local().offset/60)
      lux.v = lux.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
    }
  }
  return MC.luxonToDateTimeString(lux)
};

Expression.prototype.operatorTimezone = function(args) {
  if (args.length > 1) {
    this.error('Operator "toTimezone" must have no or one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (args.length == 0) {
    let mockNow = this.operatorAppCfgVal(['fl:mockNow'])
    if (MC.isNull(mockNow)) {
      return DateTime.local().toFormat('ZZ')
    } else {
      return DateTime.fromISO(mockNow).toFormat('ZZ')
    }
  }
  if (MC.isNull(args[0]) || args[0] === '') {
    this.error('Argument of function "timezone" can not be empty ot null! Passed arguments: ' + JSON.stringify(args))
  }
  let lux = MC.dateTimeStringToLuxon(args[0])
  if (!lux.v.isValid) {
    this.error('Argument of function "timezone" must be valid dateTime, date or time string! Passed arguments: ' + JSON.stringify(args))
  }
  let match = (args[0]).match(/^([\d-:\.T]*)(([+-]\d\d:\d\d)|Z)$/i)
  if (match) {
    return match[2]
  } else {
    return null
  } 
}

Expression.prototype.operatorParseDate = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('Function "parseDate" must have one or two args! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let value = args[0]+''
  let formatString = args[1]+''
  if (formatString.indexOf('y') == -1) {
    value = '0000-' + value
    formatString = 'yyyy-' + formatString
  }
  let lux = {v: DateTime.fromFormat(value, JdateFormat.toLuxonFormatString(formatString), {setZone: true}), _i: value}
  if (!lux.v.isValid) {
    this.error(`Cannot parse dateTime with function "parseDate" from string "${args[0]}" with pattern "${args[1]}"!`)
  } else {
    return MC.luxonToDateTimeString(lux, 'dateTime')
  }
}

Expression.prototype.operatorParseUri = function(args) {
  if (args.length !== 1) {
    this.error('Function "parseUri" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  const str = args[0]+''
  let o = {
    key: ["source","scheme","authority","userInfo","user","password","host","port","relative","pathString","directory","file","queryString","fragment"],
    parser: {
      strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
      loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
    }
  }
  let m = o.parser["strict"].exec(str)
  let uri = {}
  let i = 14
  while (i--) uri[o.key[i]] = m[i] || null
  let queryObj = []
  const queryString = uri[o.key[12]]
  if (queryString != null) {
    queryString.replace(/(?:^|&)([^&=]*)=?([^&]*)/g, function (g0, g1, g2) {
      g2 = decodeURIComponent(g2)
      if (g1) {
        if (queryObj[g1]) {
          if (Array.isArray(queryObj[g1])) {
            queryObj[g1].push(g2)
          } else {
            queryObj[g1] = [queryObj[g1], g2]
          }
        } else {
          queryObj[g1] = g2
        }
      } 
    })
  }
  if (!MC.isEmptyObject(queryObj)) {
    let queryArr = []
    for (let key in queryObj) {
      queryArr.push({name: key, value: queryObj[key]})
    }   
    queryArr.sort(function(a,b) {
      return a.name.localeCompare(b.name)
    })
    uri.query = {}
    for (let param of queryArr) {
      uri.query[param.name] = param.value
    }  
  }
  uri.trailingSlash = uri.pathString !== null && uri.pathString.endsWith('/')
  if (uri.pathString) {
    uri['path'] = []
    for (let segment of uri.pathString.split('/')) {
      if (MC.isNull(segment) || segment == '') continue
      let path = {}
      if (segment.indexOf(';')) {
        path.name = segment.split(';')[0]
        for (let matrix of segment.split(';')) {
          if (matrix.indexOf('=') > 0) {
            if (!path.parameters) path.parameters = {}
            path.parameters[matrix.split('=')[0]] = matrix.split('=')[1]       
          }
        }
      } else {
        path.name = segment
      }
      uri['path'].push(path)
    }
  }
  if (uri['fragment']) {
    uri['fragment'] = decodeURIComponent(uri['fragment'])
  }
  return uri    
}

Expression.prototype.operatorBuildUri = function(args) {
  if (args.length !== 2) {
    this.error('Function "buildUri" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let template = MC.isNull(args[0]) ? '' : MC.normalizeValue(args[0], 'string')
  let params = MC.isPlainObject(args[1]) ? args[1] : {}
  let keys = Object.getOwnPropertyNames(params)
  let keysToAdd = []
  while (keys.length > 0) {
    let key = keys.shift()
    if (template.indexOf(`{${key}}`) >= 0) {
      template = template.replace(`{${key}}`, encodeURIComponent(params[key]))
    } else {
      keysToAdd.push(key)
    }
  }
  let sep = template.indexOf('?') >= 0 ? '&' : '?'
  for (let key of keysToAdd) {
    for (let val of MC.asArray(params[key])) {
      if (!MC.isNull(val)) {
        template += sep + `${key}=${encodeURIComponent(val)}`
        sep = '&'
      }
    }
  }   
  return template
}

Expression.prototype.operatorToDateTime = function(args) {
  if (args.length != 2) {
    this.error('Function "toDateTime" must have must have exactly two args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let date = args[0]
  if (MC.isNull(date) || date === '') {
    date = '0000-01-01'
  } else {  
    let test = MC.dateTimeStringToLuxon(args[0])
    if (!test.v.isValid) {
      this.error('First argument of function "toDateTime" has invalid format! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  let time = args[1]
  if (MC.isNull(time) || time === '') {
    time = '00:00:00'
  } else {
    let test = MC.dateTimeStringToLuxon(args[1])
    if (!test.v.isValid) {
      this.error('Second argument of function "toDateTime" has invalid format! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  let tz1 = this.operatorTimezone([date])
  let tz2 = this.operatorTimezone([time])
  if (tz1 && tz2 && tz1 != tz2) {
    this.error('Date and time args of function "toDateTime" are in different timezones! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let zoneOut = ''
  if (tz1) {
    zoneOut = tz1
  } else if (tz2) {
    zoneOut = tz2
  }
  date = this.operatorRemoveTimezone([date])
  time = this.operatorRemoveTimezone([time])
  return date + 'T' + time + zoneOut
}

Expression.prototype.operatorSetTimezone = function(args) {
  if (args.length < 1 || args.length > 3) {
    this.error('Function "setTimezone" must have one to three arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let lux = MC.dateTimeStringToLuxon((args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, ''), true)
  if (!lux.v.isValid) {
    this.error('First argument of function "setTimezone" has to be valid date or time value! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let another = MC.dateTimeStringToLuxon((args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, ''), true)
  if (!MC.isNull(args[1])) {
    if (args[1] == 'Z') {
      another.v = another.v.setZone('utc')
    } else {
      another.v = another.v.setZone('utc' + args[1])
    }
  } else {
    let timezone = null
    if (!MC.isNull(this.cData.env.cfg)) {
      timezone = this.getData(this.cData.env.cfg, 'fl:localTimezoneId')
    }
    if (timezone != null) {
      another.v = another.v.setZone(timezone)
    } else {
      let offsetToset = Math.floor(DateTime.local().offset/60)
      another.v = another.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
    }
    let strict = true
    if (!MC.isNull(args[2])) {
      strict = MC.castToScalar(args[2], 'boolean')
    }
    if (strict) {
      let valid = MC.checkDateTimeValidDST(another.v, args[0])
      if (!valid) {
        this.error("Local datetime value " + args[0] + " in timezone " + another.v.zoneName + " is invalid")
        return
      }
    }
  }
  // shift the luxon by the difference in offsets
  another.v = another.v.plus({ minutes: lux.v.offset - another.v.offset})
  return MC.luxonToDateTimeString(another, null, true)
};

Expression.prototype.operatorRemoveTimezone = function(args) {
  if (args.length != 1) {
    this.error('Function "removeTimezone" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  return (args[0]+'').replace(/(([+-]\d\d:\d\d)|Z)/, '');
};

Expression.prototype.operatorNormalizeDuration = function(args) {
  if (args.length < 2 && args.length > 4) {
    this.error('Function "normalizeDuration" must have two, three or four args! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if (MC.isNull(args[1]) || args[1] === '') {
    this.error('Second argument of function "normalizeDuration" cannot be null or empty! Passed arguments: ' + JSON.stringify(args));
    return
  }
  let date = MC.dateTimeStringToLuxon(args[1])
  if (!date.v.isValid) {
    this.error('Second argument of function "normalizeDuration" must be valid dateTime! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  let atStart = true
  if (args.length > 2) {
    if (args[2] === 'false' || args[2] === false) {
      atStart = false
    }
  }
  let duration = new Duration()
  duration.parseIsoString(args[0])
  if (!duration.isValidDuration()) {
    this.error('First argument of function "normalizeDuration" must be valid duration! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (!atStart) {
    duration.negate()
  }
  let other = MC.dateTimeStringToLuxon(args[1])
  MC.luxonAdd(other, duration)

  if (args.length > 3) {
    const units = ["y", "M", "d", "H", "m", "s", "S"];
    if (units.indexOf(args[3]) == -1) {
      this.error('Unknown duration unit ' + args[3] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
    }
    var result = new Duration();
    switch (args[3]) {
      case 'y': result.from(Math.floor(other.v.diff(date.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
      case 'M': result.from(0, Math.floor(other.v.diff(date.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
      case 'd': result.from(0, 0, Math.floor(other.v.diff(date.v, 'days').toObject().days), 0, 0, 0, 0); break
      case 'H': result.from(0, 0, 0, Math.floor(other.v.diff(date.v, 'hours').toObject().hours), 0, 0, 0); break
      case 'm': result.from(0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'minutes').toObject().minutes), 0, 0); break
      case 's': result.from(0, 0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'seconds').toObject().seconds), 0); break
      case 'S': result.from(0, 0, 0, 0, 0, 0, Math.floor(other.v.diff(date.v, 'milliseconds').toObject().milliseconds)); break
    }
    return result.toIsoString()
  } else {
    let result = MC.durationBetween(date, other)
    if (!atStart) {
      result.negate()
    }
    return result.toIsoString()
  }
}

Expression.prototype.operatorFillTimezone = function(args) {
  if (args.length < 1 || args.length > 3) {
    this.error('Function "fillTimezone" must have one to three arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let lux = MC.dateTimeStringToLuxon(args[0])
  if (!lux.v.isValid) {
    this.error('First argument of function "fillTimezone" must be valid dateTime! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.hasTimezone(args[0])) {
    return args[0]
  }
  let another = MC.dateTimeStringToLuxon(args[0])
  if (!MC.isNull(args[1])) {
    if (args[1] == 'Z') {
      another.v = another.v.setZone('utc')
    } else {
      another.v = another.v.setZone('utc' + args[1])
    }
  } else {
    let timezone = null
    if (!MC.isNull(this.cData.env.cfg)) {
      timezone = this.getData(this.cData.env.cfg, 'fl:localTimezoneId')
    }
    if (timezone != null) {
      another.v = another.v.setZone(timezone)
    } else {
      let offsetToset = Math.floor(DateTime.local().offset/60)
      another.v = another.v.setZone('utc' + (offsetToset > 0 ? '+' : '') + offsetToset)
    }
    let strict = true
    if (!MC.isNull(args[2])) {
      strict = MC.castToScalar(args[2], 'boolean')
    }
    if (strict) {
      let valid = MC.checkDateTimeValidDST(another.v, args[0])
      if (!valid) {
        this.error("Local datetime value " + args[0] + " in timezone " + another.v.zoneName + " is invalid")
        return
      }
    }
  }
  // shift the lux by the difference in offsets
  another.v = another.v.plus({ minutes: lux.v.offset - another.v.offset})
  return MC.luxonToDateTimeString(another, null, true)
}

Expression.prototype.operatorDurationComponent = function(args) {
  if (args.length != 2) {
    this.error('Function "durationComponent" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var duration = new Duration();
  duration.parseIsoString(args[0]);
  if (!duration.isValidDuration()) {
    this.error('First argument of function "durationComponent" must be valid duration! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[1]) || args[1] === '') {
    this.error('Second argument of function "durationComponent" can not be null or empty! Passed arguments: ' + JSON.stringify(args));
  }
  var units = ["y", "M", "d", "H", "m", "s", "S"];
  if (units.indexOf(args[1]) == -1) {
    this.error('Unknown duration unit ' + args[1] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units));
  }
  switch (args[1]) {
    case 'y': return duration.getYears();
    case 'M': return duration.getMonths();
    case 'd': return duration.getDays();
    case 'H': return duration.getHours();
    case 'm': return duration.getMinutes();
    case 's': return duration.getSeconds();
    case 'S': return duration.getMilliseconds();
  }
};

Expression.prototype.operatorEvery = function(exprs) {
  if (exprs.length != 2) {
    this.error('Function "every" must have exactly two args! ' + exprs.length + ' args were passed.')
    return
  }
  var expr1 = new Expression();
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }  
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  if (arg1Coll.length == 0) {
    return true;
  }
  var base = this.enterBaseContext();
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    this.setPosition(i);
    this.setPositionValue(arg1Coll[i]);
    var expr2 = new Expression();
    expr2.init(exprs[1], this.cData, this.opts)
    let value = MC.castToScalar(expr2.evaluate(), 'boolean')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace());
    }  
    if (expr2.getError()) {
      this.error(expr2.getError());
      return null;
    }
    this.setPosition(null);
    this.setPositionValue(null);
    if (MC.isNull(value) || value === '' || !value) {
      this.leaveBaseContext(base)
      return false
    }
  }
  this.leaveBaseContext(base);
  return true;
};

Expression.prototype.operatorGroup = function(exprs) {
  if (exprs.length < 2 || exprs.length > 3) {
    this.error('Function "group" must have two or thre arguments! ' + exprs.length + ' args were passed.')
    return null
  }
  let expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  let arg1Coll = expr1.evaluate()
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()]
  }
  if (expr1.getError()) {
    this.error(expr1.getError())
    return null
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null
  }
  if (arg1Coll === '') {
    return ''
  }
  arg1Coll = MC.asArray(arg1Coll)
  if (arg1Coll.length == 0) {
    return null
  }
  let groups = new Map()
  let base = this.enterBaseContext()
  if (this.opts.trace) {
    this.trace.args.push([])
  }  
  for (let i = 0; i < arg1Coll.length; i++) {
    this.setPosition(i)
    this.setPositionValue(arg1Coll[i])
    let expr2 = new Expression()
    expr2.init(exprs[1], this.cData, this.opts)
    let groupingKey = MC.castToScalar(expr2.evaluate(), 'string')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace())
    }
    if (expr2.getError()) {
      this.error(expr2.getError())
      return null
    }
    let group = groups.get(groupingKey)
    if (!group) {
      group = []
      groups.set(groupingKey, group)
    }
    group.push(arg1Coll[i])
    this.setPosition(null)
    this.setPositionValue(null)
  }
  this.leaveBaseContext(base)
  let result = []
  if (exprs.length == 2) {
    for (let val of groups.values()) {
      result.push(val)
    }
  } else {
    let expr3 = new Expression()
    expr3.init(exprs[2], this.cData, this.opts)
    let filterAndOrder = expr3.evaluate()
    if (this.opts.trace) {
      this.trace.args.push(expr3.getTrace())
    }  
    if (MC.isNull(filterAndOrder)) {
      return null
    }
    if (filterAndOrder == '') {
      return ''
    }
    for (let filterItem of MC.asArray(filterAndOrder)) {
      let filterKey = MC.castToScalar(filterItem, 'string')
      let group = groups.get(filterKey)
      result.push(MC.isNull(group) ? null : group)
    }
  }
  if (result.length == 0) {
    return null
  }
  return result
}

Expression.prototype.operatorSome = function(exprs) {
  if (exprs.length != 2) {
    this.error('Function "some" must have exactly two args! ' + exprs.length + ' args were passed.')
    return null
  }
  var expr1 = new Expression();
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  if (arg1Coll.length == 0) {
    return false;
  }
  var base = this.enterBaseContext();
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    this.setPosition(i);
    this.setPositionValue(arg1Coll[i]);
    var expr2 = new Expression();
    expr2.init(exprs[1], this.cData, this.opts)
    let value = MC.castToScalar(expr2.evaluate(), 'boolean')
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace());
    }  
    if (expr2.getError()) {
      this.error(expr2.getError());
      return null;
    }
    this.setPosition(null);
    this.setPositionValue(null);
    if (MC.isNull(value) || value === '') {
      continue;
    }
    if (value) {
      this.leaveBaseContext(base);
      return true;
    }
  }
  this.leaveBaseContext(base);
  return false;
};

Expression.prototype.operatorMap = function(exprs) {
  if (exprs.length != 2) {
    this.error('Function "map" must have exactly two args! ' + exprs.length + ' args were passed.')
    return null
  }
  var expr1 = new Expression();
  expr1.init(exprs[0], this.cData, this.opts)
  var arg1Coll = expr1.evaluate();
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()];
  }  
  if (expr1.getError()) {
    this.error(expr1.getError());
    return null;
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null;
  }
  if (arg1Coll === '') {
    return '';
  }
  arg1Coll = MC.asArray(arg1Coll);
  var base = this.enterBaseContext();
  var result = [];
  if (this.opts.trace) {
    this.trace.args.push([]);
  }  
  for (var i = 0; i < arg1Coll.length; i++) {
    this.setPosition(i);
    this.setPositionValue(arg1Coll[i]);
    var expr2 = new Expression();
    expr2.init(exprs[1], this.cData, this.opts)
    result.push(expr2.evaluate());
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace());
    }  
    if (expr2.getError()) {
      this.error(expr2.getError());
      return null;
    }
    this.setPosition(null);
    this.setPositionValue(null);
  }
  this.leaveBaseContext(base);
  if (MC.isNull(result)) {
    return null;
  } else {
    return result;
  }
};

Expression.prototype.operatorReduce = function(exprs) {
  if (exprs.length < 2 || exprs.length > 3) {
    this.error('Function "reduce" must have two or three arguments! ' + exprs.length + ' arguments were passed.')
    return null
  }
  const expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  let arg1Coll = expr1.evaluate()
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()]
  }  
  if (expr1.getError()) {
    this.error(expr1.getError())
    return null
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null
  }
  if (arg1Coll === '') {
    return ''
  }
  if (Array.isArray(arg1Coll) && arg1Coll.length == 0) {
    return null
  }
  arg1Coll = MC.asArray(arg1Coll)
  let result = null
  let expr3
  if (exprs.length == 3) {
    expr3 = new Expression()
    expr3.init(exprs[2], this.cData, this.opts)
    result = expr3.evaluate()
    if (expr3.getError()) {
      this.error(expr3.getError())
      return null
    }
  }
  let base = this.enterBaseContext()
  if (this.opts.trace) {
    this.trace.args.push([])
  }  
  for (let i = 0; i < arg1Coll.length; i++) {
    this.setPosition(i)
    this.setPositionValue(arg1Coll[i])
    var expr2 = new Expression()
    expr2.init(exprs[1], this.cData, this.opts)
    this.pushResultValue(result)
    result = expr2.evaluate()
    if (this.opts.trace) {
      this.trace.args[1].push(expr2.getTrace())
    }  
    if (expr2.getError()) {
      this.error(expr2.getError())
      return null
    }
    this.setPosition(null)
    this.setPositionValue(null)
    this.popResultValue()
  }
  this.leaveBaseContext(base)
  if (this.opts.trace && exprs.length == 3) {
    this.trace.args.push(expr3.getTrace())
  }
  if (MC.isNull(result)) {
    return null
  } else {
    return result
  }
}

Expression.prototype.treeReduceApply = function(childExpressionDef, reduceExpressionDef) {
  let childrenExpr = new Expression()
  childrenExpr.init(childExpressionDef, this.cData, this.opts)
  let childrenColl =  MC.asArray(childrenExpr.evaluate())
  if (this.opts.trace) {
    this.trace.args[1].push(childrenExpr.getTrace())
  }  
  if (childrenExpr.getError()) {
    this.error(childrenExpr.getError())
    return null
  }
  let childrenResults = null
  if (!MC.isNull(childrenColl) && childrenColl.length > 0) {
    let base = this.enterBaseContext()
    childrenResults = []
    for (let i = 0; i < childrenColl.length; i++) {
      let item = childrenColl[i]
      this.setPosition(i)
      this.setPositionValue(item)
      this.pushResultValue(null)
      childrenResults.push(this.treeReduceApply(childExpressionDef, reduceExpressionDef))
      this.setPosition(null)
      this.setPositionValue(null)
      this.popResultValue()
    }
    this.leaveBaseContext(base)
  }
  this.pushResultValue(childrenResults)
  let reduceExpression = new Expression()
  reduceExpression.init(reduceExpressionDef, this.cData, this.opts)
  let result = reduceExpression.evaluate()
  if (this.opts.trace) {
    this.trace.args[2].push(reduceExpression.getTrace())
  }  
  if (reduceExpression.getError()) {
    this.error(reduceExpression.getError())
    return null
  }
  this.popResultValue()
  return result
}

Expression.prototype.operatorTreeReduce = function(exprs) {
  if (exprs.length != 3) {
    this.error('Function "treeReduce" must have three arguments! ' + exprs.length + ' arguments were passed.')
    return
  }
  let result = null
  let base = this.enterBaseContext()
  const expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  let initialNode = expr1.evaluate()
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()]
  }  
  if (expr1.getError()) {
    this.error(expr1.getError())
    return null
  }
  this.setPosition(0)
  this.setPositionValue(initialNode)
  this.pushResultValue(null)
  if (this.opts.trace) {
    this.trace.args.push([])
    this.trace.args.push([])
  }  
  result = this.treeReduceApply(exprs[1], exprs[2])
  this.setPosition(null)
  this.setPositionValue(null)
  this.popResultValue()
  this.leaveBaseContext(base)
  if (MC.isNull(result)) {
    return null
  } else {
    return result
  }
}

Expression.prototype.operatorTableLookup = function(args) {
  if (args.length < 2 || args.length > 4) {
    this.error('Function "tableLookup" must have two, three or four arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let value = MC.castToScalar(args[0], 'string')
  let tableName = MC.castToScalar(args[1], 'string')
  if (MC.isNull(tableName) || tableName == '') {
    this.error('Second argument of function "tableLookup" cannot be null or empty! Passed arguments: ' + JSON.stringify(args))
  }
  let vmts = this.cData.vmt ? this.cData.vmt[tableName] : null
  if (MC.isNull(vmts)) {
    this.error('Value mapping table ' + tableName + ' does not exist in component!')
  }
  let mandatory = false
  if (args.length > 2) {
    let argument3 = MC.castToScalar(args[2], 'boolean')
    if (!MC.isNull(argument3) && argument3 != '') {
      mandatory = argument3
    }
  }
  let echo = false
  if (args.length > 3) {
    let argument4 = MC.castToScalar(args[3], 'boolean')
    if (!MC.isNull(argument4) && argument4 != '') {
      echo = argument4
    }
  }
  let result = vmts[value]
  if (!MC.isNull(result)) {
    let evaluated = this.evaluateSource({source: result})
    if (MC.isNull(evaluated) && result !== 'null') {
      return result
    } else {
      return evaluated
    }
  } else {
    if (mandatory) {
      this.error('Function "tableLookup": Value ' + value + ' not found in table ' + tableName + ' input values! Passed arguments: ' + JSON.stringify(args))
    }
    return echo ? value : null
  }
}

Expression.prototype.operatorDistinct = function(args) {
  if (args.length != 1) {
    this.error('Function "distinct" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
  }
  if (Array.isArray(args[0])) {
    var result = [];
    for (var i = 0; i < args[0].length; i++) {
      if (result.indexOf(args[0][i]) == -1) {
        result.push(args[0][i]);
      }
    }
    return result;
  } else {
    return [args[0]];
  }
};

Expression.prototype.operatorAbs = function(args) {
  if (args.length != 1) {
    this.error('Function "abs" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  if (MC.isNumeric(args[0])) {
    return (new Decimal(args[0])).abs().toFixed()
  } else {
    let result = new Duration()
    result.parseIsoString(args[0])
    if (!result.isValidDuration()) {
      this.error('Function "abs" works only with numbers or durations! Passed arguments: ' + JSON.stringify(args[0]))
      return
    }
    if (result.getNegative()) {
      result.negate()
    }
    return result.toIsoString()
  }
}

Expression.prototype.operatorLogarithm = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('Function "logarithm" must have one or two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let number = MC.castToScalar(args[0], 'decimal')
  if (MC.isNull(number) || number === '') {
    return null
  }
  number = new Decimal(number)
  if (number.lessThan(0)) {
    this.error('Logarithm can be calculated only on positive number! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let base = null
  if (args.length > 1) {
    base = MC.castToScalar(args[1], 'decimal')
    if (MC.isNull(base) || base === '') {
      this.error('Logarithm base must not be null or empty! Passed arguments: ' + JSON.stringify(args))
      return
    }
    base = new Decimal(base)
    if (base.lessThan(0) || base.equals(1)) {
      this.error('Logarithm base must positive number other than one! Passed arguments: ' + JSON.stringify(args))
      return
    }
  }
  let result
  if (base == null) {
    result = Decimal.ln(number)
  } else if (base.equals(2)) {
    result = Decimal.log2(number)
  } else if (base.equals(10)) {
    result = Decimal.log10(number)
  } else {
    result = Decimal.log(number).div(Decimal.log(base))
  }
  return result.toFixed(result.isInt() ? 0 : 17)
}

Expression.prototype.operatorSort = function(exprs) {
  if (exprs.length < 1 || exprs.length > 4) {
    this.error('Function "sort" must have one to four arguments! ' + exprs.length + ' args were passed.')
    return null
  }
  let expr1 = new Expression()
  expr1.init(exprs[0], this.cData, this.opts)
  let arg1Coll = expr1.evaluate()
  if (this.opts.trace) {
    this.trace.args = [expr1.getTrace()]
  }  
  if (expr1.getError()) {
    this.error(expr1.getError())
    return null
  }
  if (MC.isNull(arg1Coll) && !Array.isArray(arg1Coll)) {
    return null
  }
  if (arg1Coll === '') {
    return ''
  }
  let collection = MC.asArray(arg1Coll)
  let sortBy = []
  if (exprs.length > 1) {
    if (this.opts.trace) {
      this.trace.args.push([])
    }  
    let base = this.enterBaseContext()
    for (let i = 0; i < collection.length; i++) {
      this.setPosition(i)
      this.setPositionValue(collection[i])
      let expr2 = new Expression()
      expr2.init(exprs[1], this.cData, this.opts)
      let value = MC.castToScalar(expr2.evaluate(), 'string')
      if (this.opts.trace) {
        this.trace.args[1].push(expr2.getTrace())
      }  
      if (expr2.getError()) {
        this.error(expr2.getError())
        return null
      }
      this.setPosition(null)
      this.setPositionValue(null)
      sortBy.push(MC.isNull(value) ? '' : value)
    }
    this.leaveBaseContext(base)
  }
  if (MC.isNull(sortBy)) {
    sortBy = collection
  }
  let objects = []
  for (var i=0; i<collection.length; i++) {
    objects.push({sortBy: (i < sortBy.length ? sortBy[i] : sortBy[sortBy.length-1]), value: collection[i]})
  }
  let desc = false
  if (exprs[2]) {
    const expr3 = new Expression()
    expr3.init(exprs[2], this.cData, this.opts)
    let descRes = MC.castToScalar(expr3.evaluate(), 'string')
    if (this.opts.trace) {
      this.trace.args.push(expr3.getTrace())
    }  
    if (expr3.getError()) {
      this.error(expr3.getError())
      return null
    }
    if ((descRes).toLowerCase() == 'desc' || (descRes).toLowerCase() == 'descending') {
      desc = true
    }
  }
  if (desc) {
    objects.sort(function(a, b) {
      if (a.sortBy < b.sortBy) return 1
      if (a.sortBy > b.sortBy) return -1
      return 0
    })
  } else {
    objects.sort(function(a, b) {
      if (a.sortBy < b.sortBy) return -1
      if (a.sortBy > b.sortBy) return 1
      return 0
    })
  }
  let result = []
  for (var i=0; i<objects.length; i++) {
    result.push(objects[i]['value'])
  }
  return result
}

Expression.prototype.operatorDelete = function(args) {
  if (args.length != 2) {
    this.error('Function "delete" must have exactly two args! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var indexes = [];
  var arg1 = MC.asArray(args[1]);
  for (var i = 0; i < arg1.length; i++) {
    if (!MC.isNull(arg1[i])) {
      if (MC.isNumeric(arg1[i])) {
        indexes.push(Number(arg1[i]).valueOf());
      } else {
        this.error('All indexes in second argument of "delete" function must be numbers! Passed arguments: ' + JSON.stringify(args));
      }
    }
  }
  var arg0 = MC.asArray(args[0]);
  if (indexes.length == 0) {
    return arg0;
  }
  var result = [];
  for (var i = 0; i < arg0.length; i++) {
    if (indexes.indexOf(i) == -1) {
      result.push(arg0[i]);
    }
  }
  if (result.length > 0) {
    return result;
  } else {
    return null;
  }
};

Expression.prototype.operatorUpdate = function(args) {
  if (args.length != 3) {
    this.error('Function "update" must have exactly three args! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var arg1 = MC.asArray(args[1]);
  var indexes = [];
  for (var i = 0; i < arg1.length; i++) {
    if (!MC.isNull(arg1[i])) {
      if (MC.isNumeric(arg1[i])) {
        indexes.push(Number(arg1[i]).valueOf());
      } else {
        this.error('All indexes in second argument of "update" function must be numbers! Passed arguments: ' + JSON.stringify(args));
      }
    } else {
      indexes.push(null);
    }
  }
  var arg0 = MC.asArray(args[0]);
  if (indexes.length == 0) {
    return arg0;
  }
  var arg2 = MC.asArray(args[2]);
  var result = [].concat(arg0);
  for (var i = 0; i < indexes.length; i++) {
    var index = indexes[i];
    if (index != null) {
      result[index] = i < arg2.length ? arg2[i] : arg2[arg2.lenght-1];
    }
  }
  if (result.length > 0) {
    return result;
  } else {
    return null;
  }
};

Expression.prototype.operatorCollectionUnwrap = function(args) {
  if (args.length < 1 || args.length > 3) {
    this.error('Function "collectionUnwrap" must have one, two or three args! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (Array.isArray(args[0])) {
    var index = 0;
    if (args.length > 1) {
      if (!MC.isNull(args[1]) && args[1] !== '') {
        if (MC.isNumeric(args[1]) && args[1] > 0) {
          index = parseInt(args[1]);
        }
      }
    }
    var depth = -1;
    if (args.length > 2) {
      if (!MC.isNull(args[2]) && args[2] !== '') {
        if (MC.isNumeric(args[2]) && args[2] > -1) {
          depth = parseInt(args[2]);
        }
      }
    }
    if (depth == -1) {
      depth = MC.collDepth(args[0]) - 1;
    }
    this.unwrap(args[0], depth, index);
  }
  return args[0];
};

Expression.prototype.unwrap = function(collection, atDepth, index) {
  for (var i = 0; i < collection.length; i++) {
    if (Array.isArray(collection[i])) {
      var collItem = MC.asArray(collection[i]);
      if (atDepth == 1) {
        var selectedItem = collItem.length > index ? collItem[index] : null;
        collection[i] = selectedItem;
      } else {
        this.unwrap(collItem, atDepth - 1, index);
      }
    }
  }
};

Expression.prototype.operatorIbanToDisplay = function(args) {
  if (args.length != 1) {
    this.error('Function "ibanToDisplay" must have exactly one argument! Passed arguments: ' + JSON.stringify(args));
    return null;
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  if (args[0] === '') {
    return '';
  }
  var iban = args[0]+'';
  if (!iban.match(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{0,30}$/)) {
    this.error("Invalid IBAN value: " + iban);
    return null;
  }
  var formatted = '';
  var i = 0;
  while (i + 4 <= iban.length) {
    if (i > 0) {
      formatted += " ";
    }
    formatted += iban.substring(i, i + 4);
    i += 4;
  }
  if (i < iban.length) {
    formatted += " ";
    formatted += iban.substring(i);
  }
  return formatted;
};

Expression.prototype.operatorLookup = function(args) {
  if (args.length != 3) {
    this.error('Function "lookup" must have exactly three args! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0])) {
    return null;
  }
  var collection = MC.asArray(args[0]);
  var keys = MC.asArray(args[1]);
  var values = MC.asArray(args[2]);
  if (keys.length != values.length) {
    this.error('Collections in second and third argument of function "lookup" must have same size! Passed arguments: ' + JSON.stringify(args));
    return null;
  }
  var result = [];
  for (var i=0; i<collection.length; i++) {
    var found = false;
    for (var k=0; k<keys.length; k++) {
      if (keys[k] == collection[i]) {
        result.push(values[k]);
        found = true;
        break;
      }
    }
    if (!found) {
      result.push(null);
    }
  }
  if (MC.isNull(result)) {
    return null;
  } else {
    return result;
  }
};

Expression.prototype.operatorReplace = function(args) {
  if (args.length < 2 || args.length > 5) {
    this.error('Function "replace" must have two to five arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let string = MC.castToScalar(args[0], 'string')
  if (MC.isNull(string)) {
    return null
  }
  if (string === '') {
    return ''
  }
  let what = MC.castToScalar(args[1], 'string')
  if (MC.isNull(what) || what === '') {
    return string
  }
  let rwith = null
  if (args.length > 2 ) {
    rwith = MC.castToScalar(args[2], 'string')
  }
  if (MC.isNull(rwith)) {
    rwith = ''
  }
  let useRegexp = false
  if (MC.castToScalar(args[3], 'boolean') == true) {
    useRegexp = true
  }
  let firstOnly = false
  if (MC.castToScalar(args[4], 'boolean') == true) {
    firstOnly = true
  }
  if (useRegexp) {
    rwith = rwith.replace('$0', function () { return '$&'}).replace("\\\\", "\\")  // hack - convert from JAVA replace to JAVASCRIPT - // -> /  , $0 -> $&
    return string.replace(new RegExp(what, firstOnly ? '' : 'g'), rwith)
  } else {
    if (firstOnly) {
      return string.replace(what, rwith)
    } else {
      return string.split(what).join(rwith)
    }
  }
}

Expression.prototype.operatorFirstNonNull = function(args) {
  for (var i=0; i<args.length; i++) {
    if (Array.isArray(args[i])) {
      let flattened = this.flattenCollection(args[i])
      if (flattened.length > 0) {
        return args[i]
      }
    } else {
      if (!MC.isPureNull(args[i])) {
        return args[i]
      }
    }
  }
  return null
}

Expression.prototype.operatorQuote = function(exprs) {
  if (exprs.length != 1) {
    this.error('Function "quote" must have exactly one argument! ' + exprs.length + ' args were passed.')
    return null
  }
  var props = '<rbs:Data xmlns:d="http://metarepository.com/fspl/svc_mta#" xmlns:fl="http://resourcebus.org/interpreters/flow/#" xmlns:rbs="http://resourcebus.org/ns/storage#">\n';
  props += this.exprToPropertiesXml(exprs[0]);
  if (Array.isArray(this.cData.env.ns)) {
    for (var i=0; i<this.cData.env.ns.length; i++) {
      var ns = this.cData.env.ns[i];
      props += '<fl:namespace rbs:id="' + ns.prefix + '">\n';
      props += '<d:prefix>' + ns.prefix + '</d:prefix>\n';
      props += '<d:uri>' + ns.uri + '</d:uri>\n';
      props += '</fl:namespace>\n';
    }
  }
  props += '</rbs:Data>';
  return props;
}

Expression.prototype.exprToPropertiesXml = function(expr) {
  let props = '';
  if (expr.operator) {
    if (expr.operator == 'unquote') {
      props += '<d:param1>' + this.operatorUnquote(expr.expr) + '</d:param1>\n';
      return props;
    } else {
      props += '<d:mfunction>' + MC.escapeXML(expr.operator) + '</d:mfunction>\n';
    }
  }
  if (expr.source) {
    props += '<d:param1>' + MC.escapeXML(expr.source) + '</d:param1>\n';
  }
  if (expr.expr && Array.isArray(expr.expr)) {
    for (var i=0; i<expr.expr.length; i++) {
      props += '<d:OperationActionMapping>\n';
      props += this.exprToPropertiesXml(expr.expr[i]);
      props += '</d:OperationActionMapping>\n';
    }
  }
  return props;
};

Expression.prototype.operatorUnquote = function(exprs) {
  if (!Array.isArray(exprs) || exprs.length != 1) {
    this.error('Function "unquote" must have exactly one argument! Passed arguments: ' + JSON.stringify(exprs) + '.');
  }
  var expression = new Expression();
  expression.init(exprs[0], this.cData, this.opts)
  var result = expression.evaluate();
  if (this.opts.trace) {
    if (!this.trace.args) {
      this.trace.args = [];
    }
    this.trace.args.push(expression.getTrace());
  }  
  if (expression.getError()) {
    this.error(expression.getError());
  }
  if (Array.isArray(result) && result.length > 0) {
    var res = '[';
    var sep = '';
    for (var i=0; i<result.length; i++) {
      res += sep + "'" + result[i].toString() + "'";
      sep = ', ';
    }
    return res + ']';
  } else { //TODO: support for other than simple types?????
    return "'" + result.toString() + "'";
  }
};

Expression.prototype.operatorPath = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('Function "path" must have  must have one or two arguments! Passed arguments: ' + JSON.stringify(args) + '.')
    return null
  }
  let argument = MC.castToScalar(args[0], 'string')
  if (MC.isNull(argument) || argument === '') {
    this.error('First argument of function "path" must be specified. Passed arguments: ' + JSON.stringify(args) + '.')
    return null
  }
  if (args.length == 1) {
    return this.evaluateSource({source: argument})
  } else {
    let argument2 = args[1]
    if (MC.isNull(argument2) || argument2 === '') {
      return null
    } else if (!MC.isPlainObject(argument2)) {
      this.error('Second argument of function "path" must be a data node value. Passed arguments: ' + JSON.stringify(args) + '.')
      return null
    } else {
      return this.getValue(argument2, argument.split("/"), Array.isArray(argument2))
    }
  }
}

Expression.prototype.operatorShorten = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Function "shorten" works only with two or three args! Passed arguments: ' + JSON.stringify(args));
  }
  if (MC.isNull(args[0])) {
    return null;
  } else if (args[0] === '') {
    return '';
  } else {
    var length = args[1];
    if (!MC.isNumeric(length)) {
      this.error('Second argument of function "shorten" must be number! Passed: ' + length);
    } else {
      length = parseInt(length);
    }
    var addDoots = true;
    if (args[2] === false) {
      addDoots = false;
    }
    var res = args[0]+'';
    if (res.length > length) {
      res = res.substring(0, length);
      if (addDoots) {
        res += '...';
      }
    }
    return res;
  }
};

Expression.prototype.operatorCast = function(args) {
  if (args.length != 2) {
    this.error('Function "cast" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let desiredType = MC.castToScalar(args[1], 'string')
  if (MC.isNull(desiredType) || desiredType === '') {
    this.error('Second argument of function "cast" must not be null or empty! Passed arguments: ' + JSON.stringify(args))
  }
  return MC.castToScalar(args[0], desiredType)
}

Expression.prototype.operatorCastable = function(args) {
  if (args.length != 2) {
    this.error('Function "castable" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let desiredType = MC.castToScalar(args[1], 'string')
  if (MC.isNull(desiredType) || desiredType === '') {
    this.error('Second argument of function "castable" must not be null or empty! Passed arguments: ' + JSON.stringify(args))
  }
  try {
    if (Array.isArray(args[0])) {
      let coll = this.flattenCollection(args[0])
      for (let item of coll) {
        MC.castToScalar(item, desiredType)
      }
    } else {
      MC.castToScalar(args[0], desiredType)
    }
  } catch (e) {
    return false
  }
  return true
}

Expression.prototype.operatorRiResolve = function(args) {
  if (args.length != 2) {
    this.error('Function "riResolve" must have two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const base = MC.normalizeValue(args[0], 'string')
  const ref = MC.normalizeValue(args[1], 'string')
  if (MC.isNull(ref)) {
    return null
  }
  if (MC.isNull(base)) {
    this.error('First argument can be null only if second argument is null as well! Passed arguments: ' + JSON.stringify(args))
  } 
  const sep = '/'
  if (ref.startsWith(sep)) {
    return ref.substring(1)
  } else {
    let result = new MC.URLUtils(ref, base).href
    if (result.startsWith(sep)) {
      return result.substring(1)
    } else {
      return result
    }
  }
}

Expression.prototype.operatorRiRelativize = function(args) {
  if (args.length !== 2 && args.length !== 3) {
    this.error('Function "riRelativize" must have two or two or three args! Passed arguments: ' + JSON.stringify(args));
    return;
  }
  if (MC.isNull(args[0]) && MC.isNull(args[1])) {
    return null;
  }
  const base = args[0].toString();
  const ri = args[1].toString();
  const preferAbsolute = (args[2] === false || args[2] === 'false') ? false : true
  const sep = '/';
  const pathSegments = base.split(sep).filter(t => t !== '');
  const riPathSegments = ri.split(sep).filter(t => t !== '');
  const fsStyle = (base.endsWith(sep) && base !== '/');

  let segments = [];
  let i = 0;
  // get index of last common segment (the highest index of segment for which preceding segments (including self) are
  // same and following segments are different - in other words, number of shared path segments)
  while (pathSegments.length > i && riPathSegments.length > i && pathSegments[i] === riPathSegments[i]) {
    i++;
  }
  if (i !== 0 || !preferAbsolute) {
    // add "step up" segment until this.pathSegments is traversed up to the last common segment (if necessary)
    // additional "minus one" is needed because of specific URI resolution rules (child segment is not appended to last
    // segment, but to a segment preceding last)
    const to = fsStyle ? (pathSegments.length - i) : (pathSegments.length - i - 1);
    for (let j = 0; j < to; j++) {
      segments.push('..');
    }
  }
  if (fsStyle) {
    // add all ri.pathSegments segments following last common segment
    for (let j = i; j < riPathSegments.length; j++) {
      segments.push(riPathSegments[j]);
    }
  } else {
    // add all ri.pathSegments segments following last common segment (repeat last common segment if not "stepping up")
    for (let j = (i < pathSegments.length || i === 0 ? i : i - 1); j < riPathSegments.length; j++) {
      segments.push(riPathSegments[j]);
    }
  }
  let relative = segments.join(sep);
  if (i === 0 && preferAbsolute) {
    relative = sep + relative;
  }
  if (ri.endsWith(sep) && relative === '') {
    return '.'
  }

  if (ri.endsWith(sep) && ri !== '/') {
    relative = relative + sep;
  }
  return relative;
};

Expression.prototype.operatorStringFind = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Function "stringFind" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (MC.isNull(args[1]) || args[1] === '') {
    return null
  }
  if (args[2] !== false && args[2] !== 'false') {
    let res = (new RegExp('^' + args[1] + '$')).exec(args[0])
    if (MC.isNull(res)) {
      return null
    } else {
      return res.filter((el, i) => i>0)
    }
  } else {
    let result = [];
    let matches = (args[0]+'').match(new RegExp(args[1], 'g'))
    if (matches === null) {
      matches = []
    }
    for (let match of matches) {
      let res = (new RegExp('^' + args[1] + '$')).exec(match)
      if (!MC.isNull(res)) {
        result.push(res.filter((el, i) => i>0))
      }
    }
    if (result.length > 0) {
      return result
    } else {
      return null
    }
  }
}

Expression.prototype.operatorStringContains = function(args) {
  if (args.length != 2 && args.length != 3) {
    this.error('Function "stringContains" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return false
  }
  if (MC.isNull(args[1])) {
    return false
  }
  let string = '' + args[0]
  let substring = '' + args[1]
  let caseSensitive = true
  if (args.length > 2) {
    if (args[2] === false || args[2] === 'false') {
      caseSensitive = false
    }
  }
  if (!caseSensitive) {
    string = string.toLowerCase()
    substring = substring.toLowerCase()
  }
  return string.indexOf(substring) > -1
}

Expression.prototype.operatorIndexOf = function(args) {
  if (args.length < 2 || args.length > 3) {
    this.error('Function "indexOf" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  return (''+args[0]).indexOf(args[1], args[2])
}

Expression.prototype.operatorLastIndexOf = function(args) {
  if (args.length < 2 || args.length > 3) {
    this.error('Function "lastIndexOf" must have two or three arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  return (''+args[0]).lastIndexOf(args[1], args[2])
}

Expression.prototype.operatorDataToEntries = function(args) {
  if (args.length != 1) {
    this.error('Function "dataToEntries" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0]) || args[0] === '') {
    return null
  }
  if (!MC.isPlainObject(args[0])) {
    this.error('Only data node value can be argument of function "dataToEntries"! Pass argument: ' + JSON.stringify(args[0]))
  }
  let result = []
  for (let key in args[0]) {
    result.push({key: key.endsWith('*') ? key.substring(0, key.length-1) : key, value: args[0][key]})
  }
  return result
}

Expression.prototype.operatorEntriesToData = function(args) {
  if (args.length != 2) {
    this.error('Function "entriesToData" must have exactly two arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let collection1 = MC.asArray(args[0])
  let collection2 = MC.asArray(args[1])
  let size = collection1.length > collection2.length ? collection1.length : collection2.length
  if (size <= 0) {
    return null
  }
  let dataNodeValue = {}
  for (let i = 0; i < size; i++) {
    let key = collection1.length > i ? collection1[i] : null
    key = MC.castToScalar(key, 'string')
    if (MC.isNull(key) || key === '') {
      key = 'undefined_key_at_index_' + i
    }
    let value = collection2.length > i ? collection2[i] : null
    if (!MC.isNull(value)) {
      dataNodeValue[key] = value
    }
  }
  return dataNodeValue
}

Expression.prototype.operatorUpdateData = function(args) {
  if (args.length < 3) {
    this.error('Function "updateData" must have at least three arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isPureNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  const arg1 = MC.normalizeValue(args[0], 'dataNode')
  let result = Object.assign({}, arg1)
  for (let i = 1; i < args.length - 1; i = i + 2) {
    let propertyName = MC.castToScalar(args[i], 'string')
    if (MC.isNull(propertyName) || propertyName === '') {
      this.error('Complex value property name in "updateData" cannot be null or empty! (argument # ' + (i+1) + ') Passed arguments: ' + JSON.stringify(args))
      return
    }
    let propertyValue = args[i+1]
    if (!MC.isNull(propertyValue)) {
      result[propertyName] = propertyValue
    }
  }
  return result
}

Expression.prototype.operatorAccumulateData = function(args) {
  if (args.length < 3) {
    this.error('Function "accumulateData" must have at least three arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isPureNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  const arg1 = MC.normalizeValue(args[0], 'dataNode')
  let result = Object.assign({}, arg1)
  for (let i = 1; i < args.length - 1; i = i + 2) {
    let propertyName = MC.castToScalar(args[i], 'string')
    if (MC.isNull(propertyName) || propertyName === '') {
      this.error('Complex value property name in "accumulateData" cannot be null or empty! (argument # ' + (i+1) + ') Passed arguments: ' + JSON.stringify(args))
      return
    }
    let propertyValue = args[i+1]
    if (!MC.isNull(propertyValue)) {
      if (MC.isNull(result[propertyName])) {
        result[propertyName] = propertyValue
      } else {
        let accumulatedValue = []
        accumulatedValue = accumulatedValue.concat(MC.asArray(result[propertyName]))
        accumulatedValue = accumulatedValue.concat(MC.asArray(propertyValue))
        result[propertyName] = accumulatedValue
      } 
    }
  }
  return result
}

Expression.prototype.operatorEncodeUrl = function(args) {
  if (args.length != 1) {
    this.error('Function "encodeUrl" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  return encodeURIComponent(MC.castToScalar(args[0], 'string'))
}

Expression.prototype.operatorDecodeUrl = function(args) {
  if (args.length != 1) {
    this.error('Function "decodeUrl" must have exactly one arguments! Passed arguments: ' + JSON.stringify(args))
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  return decodeURIComponent(MC.castToScalar(args[0], 'string'))
}

Expression.prototype.operatorFormatIban = function(args) {
  if (args.length < 3 || args.length > 4) {
    this.error('Function "formatIban" must have three or four arguments! Passed arguments: ' + JSON.stringify(args))
  }
  let countryCode = MC.castToScalar(args[0], 'string')
  let bankCode = MC.castToScalar(args[1], 'string')
  let accountNumber = MC.castToScalar(args[2], 'string')
  if (MC.isNull(countryCode) || countryCode === '' || MC.isNull(bankCode) || bankCode === '' || MC.isNull(accountNumber) || accountNumber === '') {
    this.error('First three arguments of function "formatIban" are mandatory! Passed arguments: ' + JSON.stringify(args))
  }
  let formatted = true
  if (args.length > 3 && !MC.isNull(args[3]) && args[3] !== '') {
    formatted = MC.castToScalar(args[3], 'boolean')
  }
  let iban = bankCode + accountNumber
  iban = iban.replace(/[-\ ]/g, "").toUpperCase()
  let countrySpecs = {
    AD: { chars: 24, bban_regexp: "^[0-9]{8}[A-Z0-9]{12}$", name: "Andorra", IBANRegistry: true },
    AE: { chars: 23, bban_regexp: "^[0-9]{3}[0-9]{16}$", name: "United Arab Emirates", IBANRegistry: true },
    AF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Afganistan" },
    AG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Antigua and Bermuda" },
    AI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Anguilla" },
    AL: { chars: 28, bban_regexp: "^[0-9]{8}[A-Z0-9]{16}$", name: "Albania", IBANRegistry: true },
    AM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Armenia" },
    AO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Angola" },
    AQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Antartica" },
    AR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Argentina" },
    AS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "American Samoa" },
    AT: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Austria", IBANRegistry: true },
    AU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Australia" },
    AW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Aruba" },
    AX: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Åland Islands", IBANRegistry: true },
    AZ: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "Republic of Azerbaijan", IBANRegistry: true },
    BA: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Bosnia and Herzegovina", IBANRegistry: true },
    BB: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Barbados" },
    BD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bangladesh" },
    BE: { chars: 16, bban_regexp: "^[0-9]{12}$", name: "Belgium", IBANRegistry: true },
    BF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Burkina Faso" },
    BG: { chars: 22, bban_regexp: "^[A-Z]{4}[0-9]{6}[A-Z0-9]{8}$", name: "Bulgaria", IBANRegistry: true },
    BH: { chars: 22, bban_regexp: "^[A-Z]{4}[A-Z0-9]{14}$", name: "Bahrain", IBANRegistry: true },
    BI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Burundi" },
    BJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Benin" },
    BL: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Barthelemy", IBANRegistry: true },
    BM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bermuda" },
    BN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Brunei Darusslam" },
    BO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bolivia, Plurinational State of" },
    BQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bonaire, Sint Eustatius and Saba" },
    BR: { chars: 29, bban_regexp: "^[0-9]{23}[A-Z]{1}[A-Z0-9]{1}$", name: "Brazil", IBANRegistry: true },
    BS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bahamas" },
    BT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bhutan" },
    BV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bouvet Island" },
    BW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Botswana" },
    BY: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{4}[A-Z0-9]{16}$", name: "Republic of Belarus", IBANRegistry: true },
    BZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Belize" },
    CA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Canada" },
    CC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cocos (Keeling) Islands" },
    CD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Congo, the Democratic Republic of the" },
    CF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Central African Republic" },
    CG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Congo" },
    CH: { chars: 21, bban_regexp: "^[0-9]{5}[A-Z0-9]{12}$", name: "Switzerland", IBANRegistry: true },
    CI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Côte d'Ivoire" },
    CK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cook Islands" },
    CL: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Chile" },
    CM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cameroon" },
    CN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "China" },
    CO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Columbia" },
    CR: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Costa Rica", IBANRegistry: true },
    CU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cuba" },
    CV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cabo Verde" },
    CW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Curaçao" },
    CX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Christmas Island" },
    CY: { chars: 28, bban_regexp: "^[0-9]{8}[A-Z0-9]{16}$", name: "Cyprus", IBANRegistry: true },
    CZ: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Czech Republic", IBANRegistry: true },
    DE: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Germany", IBANRegistry: true },
    DJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Djibouti" },
    DK: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Denmark", IBANRegistry: true },
    DM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Dominica" },
    DO: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "Dominican Republic", IBANRegistry: true },
    DZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Algeria" },
    EC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ecuador" },
    EE: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Estonia", IBANRegistry: true },
    EG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Egypt" },
    EH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Western Sahara" },
    ER: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Eritrea" },
    ES: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Spain", IBANRegistry: true },
    ET: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ethiopia" },
    FI: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Finland", IBANRegistry: true },
    FJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Fiji" },
    FK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Falkland Islands (Malvinas)" },
    FM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Micronesia, Federated States of" },
    FO: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Faroe Islands (Denmark)", IBANRegistry: true },
    FR: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "France", IBANRegistry: true },
    GA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Gabon" },
    GB: { chars: 22, bban_regexp: "^[A-Z]{4}[0-9]{14}$", name: "United Kingdom", IBANRegistry: true },
    GD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Grenada" },
    GE: { chars: 22, bban_regexp: "^[A-Z0-9]{2}[0-9]{16}$", name: "Georgia", IBANRegistry: true },
    GF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Guyana", IBANRegistry: true },
    GG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guernsey" },
    GH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ghana" },
    GI: { chars: 23, bban_regexp: "^[A-Z]{4}[A-Z0-9]{15}$", name: "Gibraltar", IBANRegistry: true },
    GL: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Greenland", IBANRegistry: true },
    GM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Gambia" },
    GN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guinea" },
    GP: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Guadeloupe", IBANRegistry: true },
    GQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Equatorial Guinea" },
    GR: { chars: 27, bban_regexp: "^[0-9]{7}[A-Z0-9]{16}$", name: "Greece", IBANRegistry: true },
    GS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Georgia and the South Sandwitch Islands" },
    GT: { chars: 28, bban_regexp: "^[A-Z0-9]{24}$", name: "Guatemala", IBANRegistry: true },
    GU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guam" },
    GW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guinea-Bissau" },
    GY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guyana" },
    HK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Hong Kong" },
    HM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Heard Island and McDonald Islands" },
    HN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Honduras" },
    HR: { chars: 21, bban_regexp: "^[0-9]{17}$", name: "Croatia", IBANRegistry: true },
    HT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Haiti" },
    HU: { chars: 28, bban_regexp: "^[0-9]{24}$", name: "Hungary", IBANRegistry: true },
    ID: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Indonesia" },
    IE: { chars: 22, bban_regexp: "^[A-Z0-9]{4}[0-9]{14}$", name: "Republic of Ireland", IBANRegistry: true },
    IL: { chars: 23, bban_regexp: "^[0-9]{19}$", name: "Israel", IBANRegistry: true },
    IM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Isle of Man" },
    IN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "India" },
    IO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "British Indian Ocean Territory" },
    IQ: { chars: 23, bban_regexp: "^[A-Z]{4}[0-9]{15}$", name: "Iraq", IBANRegistry: true },
    IR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Iran, Islamic Republic of" },
    IS: { chars: 26, bban_regexp: "^[0-9]{22}$", name: "Iceland", IBANRegistry: true },
    IT: { chars: 27, bban_regexp: "^[A-Z]{1}[0-9]{10}[A-Z0-9]{12}$", name: "Italy", IBANRegistry: true },
    JE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Jersey" },
    JM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Jamaica" },
    JO: { chars: 30, bban_regexp: "^[A-Z]{4}[0-9]{4}[A-Z0-9]{18}$", name: "Jordan", IBANRegistry: true },
    JP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Japan" },
    KE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kenya" },
    KG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kyrgyzstan" },
    KH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cambodia" },
    KI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kiribati" },
    KM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Comoros" },
    KN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Kitts and Nevis" },
    KP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Korea, Domocratic People's Republic of" },
    KR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Korea, Republic of" },
    KW: { chars: 30, bban_regexp: "^[A-Z]{4}[A-Z0-9]{22}$", name: "Kuwait", IBANRegistry: true },
    KY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cayman Islands" },
    KZ: { chars: 20, bban_regexp: "^[0-9]{3}[A-Z0-9]{13}$", name: "Kazakhstan", IBANRegistry: true },
    LA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Lao People's Democratic Republic" },
    LB: { chars: 28, bban_regexp: "^[0-9]{4}[A-Z0-9]{20}$", name: "Lebanon", IBANRegistry: true },
    LC: { chars: 32, bban_regexp: "^[A-Z]{4}[A-Z0-9]{24}$", name: "Saint Lucia", IBANRegistry: true },
    LI: { chars: 21, bban_regexp: "^[0-9]{5}[A-Z0-9]{12}$", name: "Liechtenstein", IBANRegistry: true },
    LK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sri Lanka" },
    LR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Liberia" },
    LS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Lesotho" },
    LT: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Lithuania", IBANRegistry: true },
    LU: { chars: 20, bban_regexp: "^[0-9]{3}[A-Z0-9]{13}$", name: "Luxembourg", IBANRegistry: true },
    LV: { chars: 21, bban_regexp: "^[A-Z]{4}[A-Z0-9]{13}$", name: "Latvia", IBANRegistry: true },
    LY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Libya" },
    MA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Marocco" },
    MC: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Monaco", IBANRegistry: true },
    MD: { chars: 24, bban_regexp: "^[A-Z0-9]{2}[A-Z0-9]{18}$", name: "Moldova", IBANRegistry: true },
    ME: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Montenegro", IBANRegistry: true },
    MF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Martin", IBANRegistry: true },
    MG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Madagascar" },
    MH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Marshall Islands" },
    MK: { chars: 19, bban_regexp: "^[0-9]{3}[A-Z0-9]{10}[0-9]{2}$", name: "Macedonia, the former Yugoslav Republic of", IBANRegistry: true },
    ML: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mali" },
    MM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Myanman" },
    MN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mongolia" },
    MO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Macao" },
    MP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Northern mariana Islands" },
    MQ: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Martinique", IBANRegistry: true },
    MR: { chars: 27, bban_regexp: "^[0-9]{23}$", name: "Mauritania", IBANRegistry: true },
    MS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Montserrat" },
    MT: { chars: 31, bban_regexp: "^[A-Z]{4}[0-9]{5}[A-Z0-9]{18}$", name: "Malta", IBANRegistry: true },
    MU: { chars: 30, bban_regexp: "^[A-Z]{4}[0-9]{19}[A-Z]{3}$", name: "Mauritius", IBANRegistry: true },
    MV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Maldives" },
    MW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Malawi" },
    MX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mexico" },
    MY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Malaysia" },
    MZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mozambique" },
    NA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Namibia" },
    NC: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "New Caledonia", IBANRegistry: true },
    NE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Niger" },
    NF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Norfolk Island" },
    NG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nigeria" },
    NI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nicaraqua" },
    NL: { chars: 18, bban_regexp: "^[A-Z]{4}[0-9]{10}$", name: "Netherlands", IBANRegistry: true },
    NO: { chars: 15, bban_regexp: "^[0-9]{11}$", name: "Norway", IBANRegistry: true },
    NP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nepal" },
    NR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nauru" },
    NU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Niue" },
    NZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "New Zealand" },
    OM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Oman" },
    PA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Panama" },
    PE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Peru" },
    PF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Polynesia", IBANRegistry: true },
    PG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Papua New Guinea" },
    PH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Philippines" },
    PK: { chars: 24, bban_regexp: "^[A-Z0-9]{4}[0-9]{16}$", name: "Pakistan", IBANRegistry: true },
    PL: { chars: 28, bban_regexp: "^[0-9]{24}$", name: "Poland", IBANRegistry: true },
    PM: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Pierre et Miquelon", IBANRegistry: true },
    PN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Pitcairn" },
    PR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Puerto Rico" },
    PS: { chars: 29, bban_regexp: "^[A-Z0-9]{4}[0-9]{21}$", name: "Palestine, State of", IBANRegistry: true },
    PT: { chars: 25, bban_regexp: "^[0-9]{21}$", name: "Portugal", IBANRegistry: true },
    PW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Palau" },
    PY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Paraguay" },
    QA: { chars: 29, bban_regexp: "^[A-Z]{4}[A-Z0-9]{21}$", name: "Qatar", IBANRegistry: true },
    RE: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Reunion", IBANRegistry: true },
    RO: { chars: 24, bban_regexp: "^[A-Z]{4}[A-Z0-9]{16}$", name: "Romania", IBANRegistry: true },
    RS: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Serbia", IBANRegistry: true },
    RU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Russian Federation" },
    RW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Rwanda" },
    SA: { chars: 24, bban_regexp: "^[0-9]{2}[A-Z0-9]{18}$", name: "Saudi Arabia", IBANRegistry: true },
    SB: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Solomon Islands" },
    SC: { chars: 31, bban_regexp: "^[[A-Z]{4}[]0-9]{20}[A-Z]{3}$", name: "Seychelles", IBANRegistry: true },
    SD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sudan" },
    SE: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Sweden", IBANRegistry: true },
    SG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Singapore" },
    SH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Helena, Ascension and Tristan da Cunha" },
    SI: { chars: 19, bban_regexp: "^[0-9]{15}$", name: "Slovenia", IBANRegistry: true },
    SJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Svalbard and Jan Mayen" },
    SK: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Slovak Republic", IBANRegistry: true },
    SL: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Siera Leone" },
    SM: { chars: 27, bban_regexp: "^[A-Z]{1}[0-9]{10}[A-Z0-9]{12}$", name: "San Marino", IBANRegistry: true },
    SN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Senegal" },
    SO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Somalia" },
    SR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Suriname" },
    SS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Sudan" },
    ST: { chars: 25, bban_regexp: "^[0-9]{21}$", name: "Sao Tome And Principe", IBANRegistry: true },
    SV: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "El Salvador", IBANRegistry: true },
    SX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sint Maarten (Dutch part)" },
    SY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Syrian Arab Republic" },
    SZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Swaziland" },
    TC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Turks and Caicos Islands" },
    TD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Chad" },
    TF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Southern Territories", IBANRegistry: true },
    TG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Togo" },
    TH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Thailand" },
    TJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tajikistan" },
    TK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tokelau" },
    TL: { chars: 23, bban_regexp: "^[0-9]{19}$", name: "Timor-Leste", IBANRegistry: true },
    TM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Turkmenistan" },
    TN: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Tunisia", IBANRegistry: true },
    TO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tonga" },
    TR: { chars: 26, bban_regexp: "^[0-9]{5}[A-Z0-9]{17}$", name: "Turkey", IBANRegistry: true },
    TT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Trinidad and Tobago" },
    TV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tuvalu" },
    TW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Taiwan, Province of China" },
    TZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tanzania, United republic of" },
    UA: { chars: 29, bban_regexp: "^[0-9]{6}[A-Z0-9]{19}$", name: "Ukraine", IBANRegistry: true },
    UG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uganda" },
    UM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "United States Minor Outlying Islands" },
    US: { chars: null, bban_regexp: null, IBANRegistry: false, name: "United States of America" },
    UY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uruguay" },
    UZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uzbekistan" },
    VA: { chars: 22, bban_regexp: "^[0-9]{18}", IBANRegistry: true, name: "Vatican City State" },
    VC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Vincent and the Granadines" },
    VE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Venezuela, Bolivian Republic of" },
    VG: { chars: 24, bban_regexp: "^[A-Z0-9]{4}[0-9]{16}$", name: "Virgin Islands, British", IBANRegistry: true },
    VI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Virgin Islands, U.S." },
    VN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Viet Nam" },
    VU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Vanautu" },
    WF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Wallis and Futuna", IBANRegistry: true },
    WS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Samoa" },
    XK: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Kosovo", IBANRegistry: true },
    YE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Yemen" },
    YT: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Mayotte", IBANRegistry: true },
    ZA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Africa" },
    ZM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Zambia" },
    ZW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Zimbabwe" },
  }
  let spec = countrySpecs[countryCode]
  if (iban !== null && spec !== undefined && spec.chars === (iban.length + 4)) {
    let reg = new RegExp(spec.bban_regexp, "")
    if (reg.test(iban)) {
      let checksom = countryCode + "00" + iban
      checksom = checksom.slice(3) + checksom.slice(0, 4)
      let validationString = ""
      for (let n = 1; n < checksom.length; n++) {
        let c = checksom.charCodeAt(n)
        if (c >= 65) {
          validationString += (c - 55).toString()
        } else {
          validationString += checksom[n]
        }
      }
      while (validationString.length > 2) {
        let part = validationString.slice(0, 6)
        validationString = (parseInt(part, 10) % 97).toString() + validationString.slice(part.length)
      }
      checksom = parseInt(validationString, 10) % 97
      iban =  countryCode + ("0" + (98 - checksom)).slice(-2) + iban
    }
  }
  if (formatted) {
    iban = iban.replace(/(.{4})(?!$)/g, "$1" + " ")
  }
  return iban
}

Expression.prototype.operatorTypeOf = function(args) {
  if (args.length != 1) {
    this.error('Function "typeOf" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
  }
  let arg = args[0]
  if (MC.isNull(arg)) {
    return 'null'
  } else if (arg === '') {
    return 'empty'
  } else if (Array.isArray(arg)) {
    return 'collection'
  } else if (MC.isPlainObject(arg)) {
    return 'dataNode'
  } else if (typeof arg == 'boolean') {
    return 'boolean'
  } else {
    return 'string'
  }
}

Expression.prototype.operatorReverse = function(args) {
  if (args.length != 1) {
    this.error('Function "reverse" must have exactly one argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  if (MC.isNull(args[0])) {
    return null
  }
  if (args[0] === '') {
    return ''
  }
  let coll = MC.asArray(args[0])
  if (coll.length == 0) {
    return null
  }
  return coll.reverse()
}

Expression.prototype.operatorMergeData = function(args) {
  if (args.length == 0) {
    this.error('Function "mergeData" must have at least one argument!')
    return
  }
  let result = {}
  for (let i=0; i<args.length; i++) {
    let coll = this.flattenCollection(MC.asArray(args[i]), false)
    for (let item of coll) {
      if (!MC.isPlainObject(item)) {
        this.error('Only data nodes can be passed to function "mergeData"! Passed arguments: ' + JSON.stringify(args))
        return
      }
      for (let prop in item) {
        let val = item[prop]
        if (!MC.isNull(val)) {
          result[prop] = val
        }
      }
      result = Object.assign(result, item)
    }
  }
  return result
}

Expression.prototype.operatorMergeDataDeep = function(args) {
  if (args.length == 0) {
    this.error('Function "mergeDataDeep" must have at least one argument!')
    return
  }
  let result = {}
  for (let i=0; i<args.length; i++) {
    let coll = this.flattenCollection(MC.asArray(args[i]), false)
    for (let item of coll) {
      if (!MC.isPlainObject(item)) {
        this.error('Only data nodes can be passed to function "mergeDataDeep"! Passed arguments: ' + JSON.stringify(args))
        return
      }
      result = MC.extend(result, item)
    }
  }
  return result
}

Expression.prototype.operatorDeleteData = function(args) {
  if (args.length != 2) {
    this.error('Function "deleteData" must have two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const arg1 = MC.normalizeValue(args[0], 'dataNode')
  if (MC.isPureNull(arg1)) {
    return null
  }
  if (arg1 === '') {
    return ''
  }
  let deletes = MC.asArray(args[1])
  let result = Object.assign({}, arg1)
  for (let del of deletes) {
    del = MC.normalizeValue(MC.castToScalar(del), 'string')
    if (result[del] != undefined) {
      delete result[del]
    }
  }
  return result
}

Expression.prototype.operatorRandom = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('Function "random" must have one or two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const argument1 = MC.normalizeValue(args[0], 'int')
  if (MC.isPureNull(argument1) || argument1 === '') {
    this.error('First argument of function "random" is mandatory! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const numOfChars = parseInt(argument1)
  if (numOfChars <= 0) {
    this.error('First argument of function "random" must be at least 1! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let corpusName = "alphanumeric"
  if (args.length > 1) {
    const argument2 = MC.castToScalar(args[1], 'string')
    if (!MC.isNull(argument2) && argument2 !== '') {
      corpusName = argument2.toLowerCase()
    }
  }
  const CORPUS_NUMBERS = "0123456789";
  const CORPUS_LOWERCASELETTERS = "abcdefghijklmnopqrstuvwxyz";
  const CORPUS_UPPERCASELETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const CORPUS_SYMBOLS = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
  let corpus
  if ("numeric" === corpusName) {
    corpus = CORPUS_NUMBERS
  } else if ("alphanumeric_lc" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS
  } else if ("alphanumeric_uc" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_UPPERCASELETTERS
  } else if ("alphanumeric" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS + CORPUS_UPPERCASELETTERS
  } else if ("full" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS + CORPUS_UPPERCASELETTERS + CORPUS_SYMBOLS
  } else {
    this.error('Second argument of function "random": unknown corpus type, use one of numeric, alphanumeric_lc, alphanumeric_uc, alphanumeric or full! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let res = ""
  for (let i = 0; i < numOfChars; i++) {
    res += corpus.charAt(Math.floor(Math.random() * corpus.length))
  }
  return res
}

Expression.prototype.operatorformatNumber = function(args) {
  if (args.length < 2) {
    this.error('Function "formatNumber" must have at least two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const argument1 = MC.castToScalar(args[0], 'decimal')
  if (argument1 === null || argument1 === '') {
    return argument1
  }
  const lang = MC.castToScalar(args[1], 'string')
  if (lang === null || lang === '') {
    this.error('Second argument (locale language) of function "formatNumber" must have value! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let opts = {}
  if (args.length > 2 && MC.castToScalar(args[2], 'boolean') === false) {
    opts.useGrouping = false
  }
  if (args.length > 3) {
    let minimumIntegerDigits = MC.castToScalar(args[3], 'int')
    if (MC.isNumeric(minimumIntegerDigits)) {
      opts.minimumIntegerDigits = minimumIntegerDigits
    }
  }
  if (args.length > 4) {
    let minimumFractionDigits = MC.castToScalar(args[4], 'int')
    if (MC.isNumeric(minimumFractionDigits)) {
      opts.minimumFractionDigits = minimumFractionDigits
    }
  }
  if (args.length > 5) {
    let maximumFractionDigits = MC.castToScalar(args[5], 'int')
    if (MC.isNumeric(maximumFractionDigits)) {
      opts.maximumFractionDigits = maximumFractionDigits
    }
  }
  if (args.length > 6) {
    let minimumSignificantDigits = MC.castToScalar(args[6], 'int')
    if (MC.isNumeric(minimumSignificantDigits)) {
      opts.minimumSignificantDigits = minimumSignificantDigits
    }
  }
  if (args.length > 7) {
    let maximumSignificantDigits = MC.castToScalar(args[7], 'int')
    if (MC.isNumeric(maximumSignificantDigits)) {
      opts.maximumSignificantDigits = maximumSignificantDigits
    }
  }
  if (args.length > 9) {
    let unit = MC.castToScalar(args[9], 'string')
    if (unit !== null && unit !== '') {
      opts.style = 'currency'
      opts.currency = unit 
    }
  }
  if (args.length > 10) {
    let unitWidth = MC.castToScalar(args[10], 'string')
    if (unitWidth !== null && unitWidth !== '') {
      if (unitWidth == 'ISO_CODE') {
        unitWidth = 'code'
      }
      opts.currencyDisplay = unitWidth
    }  
  }
  return new Intl.NumberFormat(lang, opts).format(argument1)
}

Expression.prototype.operatorNamespaceForPrefix = function(args) {
  if (args.length < 1 || args.length > 2) {
    this.error('Function "namespaceForPrefix" must have one or two arguments! Passed arguments: ' + JSON.stringify(args))
    return
  }
  const prefix = MC.castToScalar(args[0], 'string')
  if (MC.isNull(prefix)) {
    return null
  }
  if (prefix === '') {
    return ''
  }
  let failIfUndefined = true
  if (args.length > 1) {
    let argument2 = MC.castToScalar(args[1], 'boolean')
    if (!MC.isNull(argument2) && argument2 !== '') {
      failIfUndefined = argument2
    }
  }
  let namespace = null
  if (this.cData.env.ns) {
    let ns = this.cData.env.ns.find(i => i.prefix == prefix)
    if (ns) {
      namespace = ns.uri
    }
  }
  if (namespace == null) {
    if (failIfUndefined) {
      this.error('Namespace prefix ' + prefix + " is not defined")
    }
    return null
  }
  return namespace
}

Expression.prototype.operatorValidateIban = function(args) {
  if (args.length != 1) {
    this.error('Function "validateIban" must have one argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let iban = MC.castToScalar(args[0], 'string')
  if (MC.isNull(iban) || iban === '') {
    return "IbanFormat: Empty string can't be a valid Iban."
  }
  let CODE_LENGTHS = { AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, 
    GI: 23, GL: 18, GR: 27, GT: 28, HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, 
    MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26, AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, 
    SC: 31, ST: 25, SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20}
  iban = iban.toUpperCase().replace(/[^A-Z0-9]/g, '')
  let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length
  if (!code) {
    return "IbanFormat: Iban has not valid structure."
  }
  if (!code || !CODE_LENGTHS[code[1]]) {
    return "IbanFormat: Iban contains non existing country code."
  }
  if (iban.length !== CODE_LENGTHS[code[1]]) {
    return "IbanFormat: [" + code[3] + "] length is " + code[3].length + ", expected BBAN length is: " + (CODE_LENGTHS[code[1]] - code[1].length - code[2].length)
  }
  // rearrange country code and check digits, and convert chars to ints
  let digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  let checksum = digits.slice(0, 2)
  for (let offset = 2; offset < digits.length; offset += 7) {
    checksum = parseInt(String(checksum) + digits.substring(offset, offset + 7), 10) % 97
  }
  if (checksum == 1) {
    return null
  } else {
    return "InvalidCheckDigit: [" + iban + "] has invalid check digit"
  }
}

Expression.prototype.operatorValidateBic = function(args) {
  if (args.length != 1) {
    this.error('Function "validateBic" must have one argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let bic = MC.castToScalar(args[0], 'string')
  if (MC.isNull(bic)) {
    return "Null can't be a valid Bic."
  }
  if (bic === '') {
    return "BicFormat: Empty string can't be a valid Bic."
  }
  if (bic.length != 8 && bic.length != 11) {
    return "BicFormat: Bic length must be 8 or 11"
  }
  if (bic != bic.toUpperCase()) {
    return "BicFormat: Bic must contain only upper case letters."
  }
  if (!/^[A-Z]+$/.test(bic.substring(0, 4))) {
    return "BicFormat: Bank code must contain only letters."
  }
  let countryCode = bic.substring(4, 6)
  if (countryCode.trim().length < 2 || !/^[A-Z]+$/.test(countryCode)) {
    return "BicFormat: Bic country code must contain upper case letters"
  }
  let cCodes = ['AD','AE','AF','AG','AI','AL','AM','AO','AQ','AR','AS','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BQ','BR','BS','BT','BV','BW','BY','BZ','CA','CC','CD','CF','CG','CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CW','CX','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL','GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HM','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ','LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NF','NG','NI','NL','NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR','SS','ST','SV','SX','SY','SZ','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','UM','US','UY','UZ','VA','VC','VE','VG','VI','VN','VU','WF','WS','XK','YE','YT','ZA','ZM','ZW']
  if (cCodes.indexOf(countryCode) < 0) {
    return "UnsupportedCountry: Country code is not supported."
  }
  if (!/^[A-Z0-9]+$/.test(bic.substring(6, 8))) {
    return "BicFormat: Location code must contain only letters or digits."
  }
  if (bic.length == 11) {
    if (!/^[A-Z0-9]+$/.test(bic.substring(8, 11))) {
      return "BicFormat: Branch code must contain only letters or digits."
    }
  }
  return null
}

Expression.prototype.operatorRemoveDiacritics = function(args) {
  if (args.length != 1) {
    this.error('Function "removeDiacritics" must have one argument! Passed arguments: ' + JSON.stringify(args))
    return
  }
  let res = MC.castToScalar(args[0], 'string')
  if (MC.isNull(res)) {
    return null
  }
  if (res === '') {
    return ''
  }
  return res.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}  

export {Expression}