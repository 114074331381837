import React from "react"

import {MC} from './MC.js'

class Button extends React.Component {

  state = {rippleStyle: {}}
  elemRef = React.createRef()

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.timerAfter)
  }

  handleClick = (e) => {
    let field = this.props.button
    if (field.flow.getCfgParameter("mini:buttonRipple") === 'true') {
      this.ripple(e)
    }
    if (MC.eventHasKey(e)) {
      return
    }
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url') {
      e.preventDefault()
    }
    MC.handleReactEvent(field, e)
    if (behavior == 'url') {
      let url = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(url)) {
        let newWindow =  MC.getFieldParamBooleanValue(field.param, '@newWindow')
        if (newWindow) {
          return
        } else {
          field.flow.reactFlow().routeTo(e, url)
         return
        }
      }
    } else if (behavior == 'dialog') {
      field.flow.callDialog(field)
    } else {
      this.props.button.flow.handleSubmit(this.props.button)
    }
  }

  ripple(ev) {
    let elem = this.elemRef.current
    let pos = MC.getElemCoords(elem);
    let rect = elem.getBoundingClientRect();
    let left = ev.pageX - pos.left;
    let top = ev.pageY - pos.top;
    let size = Math.max(rect.width, rect.height);
    this.setState({ rippleStyle: {top: top, left: left, opacity: 1, transform: 'translate(-50%, -50%)', transition: 'initial', width: '35px', height: '35px'} });
    this.timer = setTimeout(() => {
      this.setState({ rippleStyle:  {top: top, left: left, opacity: 0, transform: 'scale(' + size / 9 + ')', transition: 'all 600ms', width: '35px', height: '35px'}});
      this.timerAfter = setTimeout(() => {
        this.setState({ rippleStyle:  {width: '0px', height: '0px'}})
      }, 600);
    });
  }

  render() {
    let field = this.props.button
    let label = this.props.value
    const labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    if (labelPlacement == 'IN') {
      label = MC.getFieldParamValue(field.param, '@title')
    }
    let cls = MC.classes('ui button ripper-wrapper', {'icon': MC.isNull(label)}, MC.getFieldParamValue(field.param, '@cssClass'))
    let url = MC.getFieldParamValue(field.param, '@url')
    let Tag = MC.isNull(url) ? 'button' : 'a'
    let target =  MC.getFieldParamBooleanValue(field.param, '@newWindow') ? '_blank' : null
    if (!MC.getFieldParamValue(field.param, '@escapeHtml')) {
      label = <div dangerouslySetInnerHTML={{__html: MC.customHtml(label)}}/>
    }
    return (
      <Tag ref={this.elemRef} type="button" className={cls} disabled={this.props.disabled} onClick={this.handleClick} data-widget-i-name={field.id} href={url} target={target}>
        {MC.iconize(field, label)}
        <s style={this.state.rippleStyle} className="ripper"/>
      </Tag>
    )
  }
}

export {Button}