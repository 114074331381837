import {MC} from './MC.js';

const MCBrws = {

  storageTree: {},

  checkPath(path) {
    if (!path || typeof path !== 'string') {
      return {error: "Browser storage error: Path must be not emtpy string!"};
    }
    if (!path.startsWith('/')) {
      return {error: "Browser storage error: Path must start with '/'!"};
    } else {
      path = path.substring(1);
    }
    if (path.endsWith('/')) {
      path = path.substring(0, path.length);
    }
    if (path.indexOf(';v=master') > -1) {
      path = path.replace(';v=master', '')
    }
    return path;
  },

  store: function(path, data) {
    path = MCBrws.checkPath(path);
    if (typeof path === 'string') { // no error
      return MCBrws.storeInTree(path, MCBrws.storageTree, data);
    } else { // error
      return path;
    }
  },

  storeInTree: function(path, subTree, data) {
    if (path !== '') { // put to subtree
      let key = path;
      if (key.indexOf('/') > -1) {
        key = path.substring(0, path.indexOf('/'));
        path = path.substring(path.indexOf('/') + 1);
      } else {
        path = "";
      }
      if (!subTree.items) {
        subTree.items = {};
      }
      if (!subTree.items[key]) {
        subTree.items[key] = {};
      }
      return MCBrws.storeInTree(path, subTree.items[key], data);
    } else { // put to leaf
      subTree.properties = data ? JSON.parse(JSON.stringify(data)) : null
      return {};
    }
  },

  get: function(path) {
    path = MCBrws.checkPath(path);
    if (typeof path === 'string') { // no error
      return MCBrws.getFromTree(path, MCBrws.storageTree);
    } else { // error
      return path;
    }
  },

  getFromTree: function(path, subTree) {
    if (path !== '') { // get from subtree
      let key = path;
      if (key.indexOf('/') > -1) {
        key = path.substring(0, path.indexOf('/'));
        path = path.substring(path.indexOf('/') + 1);
      } else {
        path = "";
      }
      if (!subTree.items) {
        return {result: null};
      }
      if (!subTree.items[key]) {
        return {result: null};
      }
      return MCBrws.getFromTree(path, subTree.items[key]);
    } else { // get from leaf
      let result = {};
      if (subTree.properties) {
        result.properties = JSON.parse(JSON.stringify(subTree.properties));
      }
      if (subTree.items && !MC.isEmptyObject(subTree.items)) {
        result.isFolder = true;
      } else {
        result.isFolder = false;
      }
      return {result: result};
    }
  },

  list: function(path) {
    path = MCBrws.checkPath(path);
    if (typeof path === 'string') { // no error
      return MCBrws.listFromTree(path, MCBrws.storageTree, path);
    } else { // error
      return path;
    }
  },

  listFromTree: function(path, subTree, wholePath) {
    if (path !== '') { // list from subtree
      let key = path;
      if (key.indexOf('/') > -1) {
        key = path.substring(0, path.indexOf('/'));
        path = path.substring(path.indexOf('/') + 1);
      } else {
        path = "";
      }
      if (!subTree.items) {
        return {result: null};
      }
      if (!subTree.items[key]) {
        return {result: null};
      }
      return MCBrws.listFromTree(path, subTree.items[key], wholePath);
    } else { // list from leaf
      let result = {};
      let itemsInRes = [];
      if (subTree.items && !MC.isEmptyObject(subTree.items)) {
        for (let prop in subTree.items) {
          let item = subTree.items[prop];
          let itemInRes = {};
          if (item.items && !MC.isEmptyObject(item.items)) {
            itemInRes.isFolder = true;
          } else {
            itemInRes.isFolder = false;
          }
          if (item.properties) {
            itemInRes.properties = JSON.parse(JSON.stringify(item.properties));
          }
          if (wholePath) {
            itemInRes.path = '/' + wholePath + '/' + prop
          } else {
            itemInRes.path = '/' + prop
          }
          itemsInRes.push(itemInRes);
        }
      }
      if (itemsInRes.length > 0) {
        result['item'] = itemsInRes;
      }
      return {result: result};
    }
  },

  delete: function(path) {
    path = MCBrws.checkPath(path);
    if (typeof path === 'string') { // no error
      return MCBrws.deleteFromTree(path, MCBrws.storageTree);
    } else { // error
      return path;
    }
  },

  deleteFromTree: function(path, subTree) {
    if (path !== '') { // delete from subtree
      let key = path;
      if (key.indexOf('/') > -1) {
        key = path.substring(0, path.indexOf('/'));
        path = path.substring(path.indexOf('/') + 1);
      } else {
        path = "";
      }
      if (!subTree.items) {
        return {};
      }
      if (!subTree.items[key]) {
        return {};
      }
      if (path === '') {
        delete subTree.items[key];
        return {};
      } else {
        return MCBrws.deleteFromTree(path, subTree.items[key]);
      }
    } else { // it is needed in case when deleting in root
      delete subTree.properties;
      delete subTree.items;
      return {};
    }
  },

  getForLog: function() {
    let newTree = {};
    MCBrws.getTreeForLog(MCBrws.storageTree, newTree);
    return newTree;
  },

  getTreeForLog: function(oNode, nNode) {
    if (!MC.isNull(oNode)) {
       for (let prop in oNode) {
         if (prop === 'properties') {
           nNode['{properties}'] = oNode[prop];
         } else if (prop === 'items') {
           if (MC.isNull(oNode['properties'])) {
             MCBrws.getTreeForLog(oNode[prop], nNode);
           } else {
             let subnNode = {};
             nNode['{items}'] = subnNode;
             MCBrws.getTreeForLog(oNode[prop], subnNode);
           }
         } else {
           let subnNode = {};
           nNode[prop] = subnNode;
           MCBrws.getTreeForLog(oNode[prop], subnNode);
         }
       }
    }
  }

};

export {MCBrws};
