import React from "react"

import {MC} from './MC.js'

class Modal extends React.Component {

  modelRef = React.createRef()

  onClose = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose()
    }
    document.querySelector('body').classList.remove('showing-modal')
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown)
    if (this.props.asContext) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    this.fixBody()
  }

  componentDidUpdate() {
    this.fixBody()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown)
    document.querySelector('body').classList.remove('showing-modal')
    if (this.props.asContext) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  fixBody() {
    if (this.props.show && !this.props.asContext) {
      document.querySelector('body').classList.add('showing-modal')
    }
  }

  handleOutsideClick = (e) => {
    if (e.target.classList.contains('mnc-backdrop') && e.target.classList.contains('context') && typeof this.props.onClose === 'function') {
      this.props.onClose()
    }
  }

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (this.props.closeIcon === false) {
        return
      }
      e.preventDefault()
      e.stopPropagation()
      this.onClose()
    }
  }  

  render() {
    if (!this.props.show) {
      return null;
    }
    let cls = MC.classes(this.props.cssClass, 'mnc-modal', {'context': this.props.asContext})
    var header = null;
    if (this.props.header) {
      header = <div className="header">{this.props.header}</div>;
    }
    let closeIcon = this.props.closeIcon !== false ? <i className="close icon" onClick={this.onClose}></i> : null
    let inlineCss = {}
    if (this.props.asContext && this.props.target) {
      let target = this.props.target
      let offset = MC.offset(target)
      let dimOffset = MC.offset(MC.findAncestor(target, "dimmable"))
      inlineCss.top = offset.top - dimOffset.top + target.offsetHeight
      if (offset.left > document.body.clientWidth / 2) {
        inlineCss.right = document.body.clientWidth - (offset.left - dimOffset.left + target.offsetWidth)
      } else {
        inlineCss.left = offset.left - dimOffset.left
      }
    }
    let modal = (<div className={cls} ref={this.modelRef} style={inlineCss}>
                  {closeIcon}{header}
                  <div className="content">{this.props.children}</div>
                </div>)
    if (this.props.asContext) {
      return <React.Fragment><div className={MC.classes("mnc-backdrop context", this.props.backDropCssClass)}/>{modal}</React.Fragment>
    } else {
      return <div className={MC.classes("mnc-backdrop", this.props.backDropCssClass)}>{modal}</div> 
    }
    
  }

}

export {Modal}