import "@babel/polyfill"
import ReactDOM from "react-dom"
import "semantic-ui-offline/semantic.min.css"

import MiniclientConsole from "./MiniclientConsole.jsx"
import TestApp from "./TestApp.jsx"
import FormModeler from "./FormModeler.jsx"
import {MiniClientApp} from "./miniclient-app.js"

function Miniclient() {

  this.config = (cobj) => {
    this.element = cobj.element || document.getElementById('content')
    this.conf =  cobj
    if (!cobj.baseUrl) {
      let base = document.querySelector('base').href.split('?')[0]
      cobj.baseUrl = base.substring(0, base.lastIndexOf("/") + 1)
    }
    if (cobj.registerServiceWorker) {
      if ('serviceWorker' in navigator) { // only when browser supports SWs
        navigator.serviceWorker.register('sw.js').then(function() {
          console.log("Service worker sw.js registered")
        }).catch(error => {
          console.error("Error during service worker registration:", error)
        })
      }
    }
    if (cobj.serverFunction) {
      window.mncServerFunction = cobj.serverFunction
    }
    return this
  }
  
  this.run = () => {
    let conf = this.conf
    if (conf.isApp) {
      let mncapp = new MiniClientApp().setMiniclientVars(conf).setElement(this.element).run()
    } else if (conf.isTestApp) {
      ReactDOM.render(<TestApp mconf={conf}/>, document.getElementById('content'))
    } else if (conf.isFormModeler) {
      ReactDOM.render(<FormModeler mconf={conf}/>, document.getElementById('content-modeler'))
    } else {
      ReactDOM.render(null, this.element)
      ReactDOM.render(<MiniclientConsole mconf={conf}/>, this.element)
    }
    return this
  } 

}

window.Miniclient = Miniclient  