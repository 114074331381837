import React from "react"

import {MC} from './MC.js'
import Dropdown from './semantic-widgets/Dropdown.jsx'

class Paginator extends React.Component {

  state = {page: 1, textValue: 1}

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.textValueChanged) {
      return {textValueChanged: false}
    }
    let pPage = MC.getFieldParamValue(nextProps.field.param, 'settings/@page')
    let page = 1
    if (MC.isNumeric(pPage)) {
      page = parseInt(pPage)
    } else if (Array.isArray(pPage) && MC.isNumeric(pPage[0])) {
      page = parseInt(pPage[0])
    }
    let perPage = 20
    let defaultRowsPerPage = MC.getFieldParamValue(nextProps.field.param, 'settings/@defaultRowsPerPage')
    if (MC.isNumeric(defaultRowsPerPage)) {
      perPage = parseInt(defaultRowsPerPage)
    }
    let rows = MC.getFieldParamValue(nextProps.field.param, 'settings/@rows')
    let totalCount = parseInt(rows)
    let totalPages = Math.ceil(totalCount/perPage)
    if (prevState.page !== page || totalPages !== prevState.totalPages || perPage != prevState.totalPages) {
      return {page: page, textValue: page, totalPages: totalPages, perPage: perPage}
    }
    return null
  }

  onChange = (page) => {
    const field = this.props.field
    MC.putFieldParamValue(field.param, 'settings/@page', page)
    field.flow.runLazyUpdate(field, true)
  }

  onChangeText = (e) => {
    this.setState({textValue: e.target.value, textValueChanged: true})
  }

  onBlurText = (e) => {
    var newVal = e.target.value
    if (MC.isNumeric(newVal)) {
      newVal = Number(newVal).valueOf()
      if (Number.isInteger(newVal) && newVal >= 1 && newVal <= this.state.totalPages) {
        this.onChange(newVal)
      }
    }
    this.setState({textValue: this.state.page})
  }

  handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      this.onBlurText(e)
    }
  }

  onRowsPerPage = (e, dropdown) => {
    const field = this.props.field
    MC.putFieldParamValue(field.param, 'settings/@defaultRowsPerPage', dropdown.value === "*" ? null : dropdown.value)
    field.flow.runLazyUpdate(field)
  }

  render() {
    let field = this.props.field
    let defaultRowsPerPage = MC.getFieldParamValue(field.param, 'settings/@defaultRowsPerPage')
    let rowsPerPageSelector = MC.getFieldParamBooleanValue(field.param, 'settings/@rowsPerPageSelector')
    let rowsSelector
    if (rowsPerPageSelector) {
      let rowsOptions = MC.getFieldParamValue(field.param, 'settings/@rowsPerPageItems')
      if (MC.isNull(rowsOptions) || !Array.isArray(rowsOptions)) {
        rowsOptions = [5, 10, 20, 50]
      }
      let options = rowsOptions.map(v => {return {value: v, text: v}})
      if (MC.getFieldParamBooleanValue(field.param, 'settings/@rowsPerPageSelectorAll')) {
        options.push({value: "*", text: MC.formatMessage("all", field.flow.reactFlow().props.mconf.lang)})
      }
      rowsSelector = <Dropdown className="compact selection" onChange={this.onRowsPerPage} value={MC.isNull(defaultRowsPerPage) ? '*' : this.state.perPage} options={options}/>
    }
    let self = this
    let halign = MC.getFieldParamValue(field.param, '@horizontalAlignment') || 'right'
    let totalPages = this.state.totalPages
    if (totalPages && totalPages > 1) {
      var current = this.state.page
      var children = [1]
      if (current !== 1 && current != totalPages) {
        children.push(current)
      }
      children.push(totalPages)
      children = children.map(function(child) { 
        if (child === current) {
          return (<div className="active item" key={child}>
                   <input type="number" name="page" min={1} max={totalPages} value={self.state.textValue} onChange={self.onChangeText} onBlur={self.onBlurText} onKeyDown={self.handleKeyDown}/>
                  </div>)
        } else {
          return <a className="item" key={child} onClick={self.onChange.bind(self, child)}>{child}</a>
        }
      })
      let NodeName, className
      if (current === 1) {
        NodeName = 'div'
        className = 'icon disabled item'
      } else {
        NodeName = 'a'
        className = 'icon item'
      }
      children.splice(0, 0, <NodeName className={className} key="leftArrow2" onClick={current > 1 ? self.onChange.bind(self, current - 1) : undefined}><i className="left arrow icon"/></NodeName>)
      if (current === totalPages) {
        NodeName = 'div'
        className = 'icon disabled item'
      } else {
        NodeName = 'a'
        className = 'icon item'
      }
      children.splice(children.length, 0, <NodeName className={className} key="rightArrow2" onClick={current < totalPages ? self.onChange.bind(self, current + 1) : undefined}><i className="right arrow icon"/></NodeName>)
      if (rowsSelector) {
        children.unshift(<div className="item gap" key="gap"></div>)
        children.unshift(<div className="item rows-per-page" key="rowsSelector">{rowsSelector}</div>)
      }
      return <div className={MC.classes('ui', halign, 'floated pagination menu')}>{children}</div>
    } else {
      if (rowsSelector) {
        return <div className={MC.classes('ui', halign, 'floated pagination menu')}><div className="item rows-per-page">{rowsSelector}</div></div>
      } else {
        return null
      }
    }
  }

}

export {Paginator}