const MCCache = {
  max: 200,
  cache: new Map(),
  put: function(key, val) {
    if (this.cache.has(key)) {
      this.cache.delete(key)
    } else if (this.cache.size == this.max) {
      this.cache.delete(this.cache.keys().next().value)
    }  
    this.cache.set(key, val)
  },
  get: function(key) {
    let item = this.cache.get(key)
    if (item) {
      this.cache.delete(key)
      this.cache.set(key, item)
    }
    return item
  },
  has: function(key) {
    return this.cache.has(key)
  },
  clear: function() {
    this.cache = new Map()
  }
}

export {MCCache}