import React from "react"

import {MC} from './MC.js'
import {MCHistory} from './MCHistory.js'

class MenuButton extends React.Component {

  buildButtons(field) {
    let buttons = []
    let items = MC.getFieldParamValue(field.param, 'items')
    if (!items || !Array.isArray(items)) {
      return null
    }
    for (let item of items) {
      let icon = null
      if (!MC.isNull(item['@imageUrl'])) {
        let imageUrl = MC.rebaseUrl(field.flow.flow.model, item['@imageUrl'], field.flow.reactFlow().props.mconf)
        icon = <img src={imageUrl} className={item['@icon']}/>
      } else if (!MC.isNull(item['@icon'])) {
        icon = <i className={MC.buildIconClass(item['@icon'])}></i>
      }
      let btnCls = MC.classes("ui button", {"disabled": item['@enabled'] === false || item['@permitted'] === false})
      let url = item['@url']
      let Tag = MC.isNull(url) ? 'button' : 'a'
      let target =  MC.getFieldParamBooleanValue(field.param, '@newWindow') ? '_blank' : null
      buttons.push(<Tag type="button" key={'b' + item['@key']} title={item['@tooltip']} className={btnCls} onClick={(e) => this.handleClick(e, item)} href={url} target={target}>{icon}{item['@title']}</Tag>)
    }
    return buttons
  }

  handleClick(e, item) {
    if (MC.eventHasKey(e)) {
      return
    }
    let field = this.props.data
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url') {
      e.preventDefault()
    }
    if (behavior == 'url') {
      let url = item['@url']
      if (!MC.isNull(url)) {
        let newWindow =  MC.getFieldParamBooleanValue(field.param, '@newWindow')
        if (newWindow) {
          return
        } else {
          field.flow.reactFlow().routeTo(e, url)
         return
        }
      }
    } else {
      MC.putFieldParamValue(field.param, 'value', item['@key'])
      MC.handleEvent(field, 'change')
      if (behavior == 'dialog') {
        field.flow.callDialog(field)
      } else {
        field.flow.handleSubmit(field)
      }
    }
  }

  render() {
    let field = this.props.data
    if (!this.deprMess) {
      this.deprMess = true
      MCHistory.log(MCHistory.T_WARNING, 'menubutton widget is deprecated. Use "menu" widget instead.', field.flow.debug())
    }
    let fitWidth = MC.getFieldParamBooleanValue(field.param, '@fitWidth')
    let type =  MC.getFieldParamValue(field.param, '@type')
    let cls = MC.getFieldParamValue(field.param, '@cssClass')
    cls = MC.classes('ui buttons', cls, {'fluid': fitWidth, 'vertical': type == 'vertical'})
    return (
      <div className={cls} data-widget-i-name={field.id}>
        {this.buildButtons(field)}
      </div>
    )
  }

}

export {MenuButton}