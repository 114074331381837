import {MC} from "../client/MC.js"
import {FieldDef} from "./FieldDef.js"
import {WidgetModel} from "./WidgetModel.js"
import {GetText} from "./GetText.js"

let FieldProto = {
  setDefinition: function(definition) {
    var self = this;
    this.FormField = deepCopy(definition);
    delete this.FormField.FormFieldGrid;
    delete this.FormField.FormField

    if (definition.FormField) {
      definition.FormField.forEach(function(fieldDefinition) {
        var field = self.getSubfields().find(function(field) {
          return field.rbsid == fieldDefinition['rbs:id'];
        });
        if (field) {
          field.setDefinition(fieldDefinition);
        }
      })
    }
    if (definition.FormFieldGrid) {
      definition.FormFieldGrid.forEach(function(gridDefinition) {
        var grid = self.grid.find(function(grid) {
          return grid.resolution == gridDefinition.resolution;
        });
        grid["rbs:id"] = gridDefinition["rbs:id"];
      });
    }
  },
  setParents: function() {
    var self = this;
    self.fields.map(f => f.parent = self);
    self.fields.forEach(f => f.setParents());
  },
  setFlow: function(flow) {
    this.flow = flow;
    this.fields.forEach(f => f.setFlow(flow));
  },
  setToStored: function() {
    delete this.isNotStored;
    this.fields.forEach(field => field.setToStored());
  },
  unsetParents: function() {
    this.parent = undefined;
    this.fields.forEach(field => field.unsetParents());
  },
  unsetFlow: function() {
    delete this.flow;
    this.fields.forEach(field => field.unsetFlow());
  },
  unsetProto: function() {
    this.fields.forEach(field => field.unsetProto());
    this.__proto__ = Object.prototype;
  },
  getModelerReact: function() {
    return this.flow.modelerReact;
  },
  isModelerActive: function() {
    return typeof this.flow != "undefined" && typeof this.flow.modelerReact != "undefined";
  },
  isInEyeMode: function() {
    if (!this.isModelerActive()) {
      return false;
    }
    var modelerReact = this.getModelerReact();
    return modelerReact.state.ghostMode;
  },
  isPlaceholder: function() {
    if (!this.isModelerActive()) {
      return false;
    }
    var modelerReact = this.getModelerReact();
    return  modelerReact.state.placeholderField == this.id;
  },
  isSelectedField: function() {
    if (!this.isModelerActive()) {
      return false;
    }
    var modelerReact = this.getModelerReact();
    var selectedFieldIds =modelerReact.state.selectedFieldIds;
    return  selectedFieldIds.indexOf(this.rbsid) != -1; // modelerReact.state.selectedField == this.id;
  },
  getForm: function() {
    if (typeof this.parent == "undefined") {
      return this;
    } else {
      return this.parent.getForm();
    }
  },
  getCopy: function() {
    var parent = this.parent;
    this.unsetParents();
    this.unsetProto();
    var copy = deepCopy(this);
    FieldDef.setProto(this);
    FieldDef.setProto(copy);
    this.setParents();
    this.parent = parent;
    copy.setParents();
    return copy;
  },
  findFieldsHelp: function(id, fields, containString) {
    if (fields.length != 0) {
      var field = fields[0];
      var rest = fields.slice(1);
      var result = [];
      if ((containString && strContains(field.id, id))|| id == field.id) {
        result = [field];
      }
      return result.concat(this.findFieldsHelp(id, rest.concat(field.fields), containString));
    }
    return [];
  },
  findFields: function(id, containString) {
    return this.findFieldsHelp(id, [this], containString);
  },
  findFieldsHelpByRbsId: function(id, fields, containString) {
    if (fields.length != 0) {
      var field = fields[0];
      var rest = fields.slice(1);
      var result = [];
      if ((containString && strContains(field.id, id))|| id == field.rbsid) {
        result = [field];
      }
      return result.concat(this.findFieldsHelpByRbsId(id, rest.concat(field.fields), containString));
    }
    return [];
  },
  findFieldsByRbsId: function(id, containString) {
    return this.findFieldsHelpByRbsId(id, [this], containString);
  },
  findFieldByRbsId: function(id) {
    /* najde pole v akutálním formuláři podle id */
    var result = this.findFieldsByRbsId(id);
    if (result.length != 0) {
      return result[0];
    }
    return null;
  },
  findField: function(id) {
    /* najde pole v akutálním formuláři podle id */
    var result = this.findFields(id);
    if (result.length != 0) {
      return result[0];
    }
    return null;
  },
  findFieldsByNameHelp: function(name, fields) {
    if (fields.length != 0) {
      var field = fields[0];
      var rest = fields.slice(1);
      var result = [];
      if (name == field.id) {
        result = [field];
      }
      return result.concat(this.findFieldsByNameHelp(name, rest.concat(field.fields)));
    }
    return [];
  },
  findFieldByName: function(name) {
    var result = this.findFieldsByNameHelp(name, [this]);
    if (result.length != 0) {
      return result[0];
    }
    return null;
  },
  getAllFieldIds: function() {
    var subfieldsIds = this.getSubfields().map(function(field) {
      return field.getAllFieldIds()
    })
    return subfieldsIds.reduce(function(a1,a2){
      return a1.concat(a2)
    }, []).concat(typeof this['id'] != 'undefined' ? [this['id']] : [])
  },
  getChildFieldIds: function() {
    var subfieldsIds = this.getSubfields().map(function(field) {
      return field['rbsid'];
    })
    return subfieldsIds;
  },
  getParent: function() {
    return this.parent;
  },
  getTrueParent: function() {
    var parent = this.getParent();
    if (typeof parent != "undefined" && parent.widget == "dummy") {
      parent = parent.getParent();
    }
    return parent;
  },
  isTopLevel: function() {
    return typeof this.parent == "undefined";
  },
  removeFromTable: function() {
    let table = this.getTrueParent()
    if (typeof this.param["@title"] == "undefined") {
      let columns = table.getOption(["param", "columns"])
      if (columns && columns[this.id]) {
        this.setOption(["param", "@title"], columns[this.id]["@title"])
      }
    }
    delete this.param.columns
    delete table.param.columns[this.id]
    let formFieldParams = this.FormField.FormFieldParam.filter(function(formFieldParam) {
      return formFieldParam.name != "columns"
    })
    this.FormField.FormFieldParam = formFieldParams
    this.addGrid()
    this.removeField()
  },
  removeInTableParams: function() {
    if (typeof this.param["@title"] == "undefined") {
      let columnTitle = this.getOption(["param", "columns", "@title"])
      if (columnTitle !== undefined) {
        this.setOption(["param", "@title"], columnTitle)
      }
    }
    delete this.param.columns
    this.FormField.FormFieldParam = this.FormField.FormFieldParam.filter(function(formFieldParam) {
      return formFieldParam.name != "columns"
    })
  },
  tableMoveColumn: function(sourceIndex, targetIndex) {
    var table = this;
    var tableFields = table.getSubfields();
    moveElement(tableFields, sourceIndex, targetIndex);
    var columns = objectToEntries(table.param.columns);
    moveElement(columns, sourceIndex, targetIndex);
    table.param.columns = entriesToObject(columns);
    table.setSubfields(tableFields);
  },
  putToTable: function(field, index) {
    var title = field.getOption(["param", "@title"]);
    let fieldColumn = WidgetModel.getTableColumnDefaults(title)

    field.param.columns = fieldColumn;

    var tableFields = this.getSubfields();
    tableFields.splice(index, 0, field);
    this.setSubfields(tableFields);

    var columns = objectToEntries(this.param.columns);
    columns.splice(index, 0, [field.id, fieldColumn]);
    this.param.columns = entriesToObject(columns);

    // add column to definition of table
    let defFieldColumn = []
    for (let prop in fieldColumn) {
      defFieldColumn.push({"name": prop, "val": fieldColumn[prop]})
    }
    field.FormField.FormFieldParam.push({name: "columns", FormFieldParam: defFieldColumn})
  },
  hasDummy() {
    return this.fields.length == 1 && this.fields[0].widget == "dummy";
  },
  getSubfields: function() {
    if (this.hasDummy()) {
      return this.fields[0].fields.slice();
    } else {
      return this.fields.slice();
    }
  },
  removeFromContainer: function() {
    if (this.isInTable()) {
      this.removeFromTable();
    } else if (this.isInContainerWithoutGrid()) {
      this.removeFromContainerWithoutGrid();
    } else {
      this.removeFromGrid();
    }

  },
  removeField: function() {
    var parent = this.getTrueParent();
    var subfields = parent.getSubfields();
    var index = subfields.indexOf(this);
    subfields.splice(index, 1);
    parent.setSubfields(subfields);
    delete this.parent;
  },
  removeFromContainerWithoutGrid: function() {
    this.removeField();
  },
  removeFromGrid: function() {
    var row = this.getRow();
    var indexInRow = row.indexOf(this);
    var isLastInRow = indexInRow == row.length - 1;
    if (isLastInRow) {
      row.splice(indexInRow, 1);
    }
    this.removeField();
  },
  addField: function(field, targetField) {
    if (targetField.fields.length == 1 && targetField.fields[0].widget == "dummy") {
      targetField = targetField.fields[0];
    }
    field.parent = targetField;
  },
  isInTable: function() {
    var parent = this.getTrueParent();
    return typeof parent != "undefined" && parent.isTable();
  },
  isInTabPanel: function() {
    var parent = this.getTrueParent();
    return typeof parent != "undefined" && parent.isTabPanel();
  },
  isInContainerWithoutGrid: function() {
    var parent = this.getTrueParent();
    return typeof parent == "undefined" || parent.isWithoutGrid();
  },
  isWithoutGrid: function() {
    return this.widget && this.widget == "tabpanel";
  },
  isTable: function() {
    return this.widget && ["basictable", "loadabletable", "pageabletable", "scrollabletable", "table"].indexOf(this.widget) > -1;
  },
  isRepeater: function() {
    return this.widget && this.widget == "repeater";
  },
  isTabPanel: function() {
    return this.widget && this.widget == "tabpanel";
  },
  getGrid: function(resolution) {
    return this.grid.find(function(grid) {
      return grid.resolution == resolution;
    });
  },
  getCurrentGrid: function() {
    var resolution = this.flow.modelerReact.state.resolution;
    return this.getGrid(resolution);
  },
  getAvailableGrid: function() {
    var modelerReact = this.getModelerReact();
    var form = modelerReact.state.form;
    var resolution = this.flow.modelerReact.state.resolution;
    var availableResolution = MC.getAvailableResolution(resolution, form);
    return this.getGrid(availableResolution);
  },
  addGrid: function() {
    var self = this;
    if (typeof this.grid == "undefined") {
      this.grid = [];
    }
    var modelerReact = this.getModelerReact();
    var form = modelerReact.state.form;

    ["x-small", "small", "medium", "large"].forEach(function(resolution) {
      if (MC.hasLayout(form, resolution)) {
        var grid = self.getGrid(resolution);
        var columns = "3";
        console.log(self.autoLayoutColumns);
        if (typeof self.autoLayoutColumns != "undefined") {
          columns = self.autoLayoutColumns;
        }
        if (typeof grid == "undefined") {
          self.grid.push({
            columns: columns,
            newLineAfter: "never",
            newLineBefore: "never",
            offset: "0",
            index: "0",
            resolution: resolution,
            visible: "true"
          });
        }
      }
    });
  },
  offset: function() {
    var option = this.getOption(["grid", "offset"]);
    if (typeof option == "undefined") {
      option = 0;
    }
    return parseInt(option);
  },
  columns: function() {
    var option = this.getOption(["grid", "columns"]);
    return parseInt(option);
  },
  setColumns: function(value) {
    this.setOption(["grid", "columns"], value);
  },
  newLineAfter: function() {
    var option = this.getOption(["grid", "newLineAfter"]);
    return option == "yes";
  },
  newLineBefore: function() {
    var option = this.getOption(["grid", "newLineBefore"]);
    return option == "yes";
  },
  removeNewLineAfter: function() {
    this.setOption(["grid", "newLineAfter"], "never");
  },
  removeNewLineBefore: function() {
    this.setOption(["grid", "newLineBefore"], "never");
  },
  putNewLineAfter: function() {
    this.setOption(["grid", "newLineAfter"], "yes");
  },
  setOffset: function(offset) {
    this.setOption(["grid", "offset"], offset);
  },
  ensureGridHelp: function(field, resolution, availableResolution) {
    var self = this;
    if (typeof field.grid != "undefined") {
      var grid = field.grid.find(function(grid) {
        return grid.resolution == resolution;
      });
      if (typeof grid == "undefined" && availableResolution) {
        var availableGrid = field.grid.find(function(grid) {
          return grid.resolution == availableResolution;
        });
        grid = MC.extend({}, availableGrid);
        grid["rbs:id"] = MC.generateId()
        grid.resolution = resolution;
        field.grid.push(grid);
      }
      if (typeof grid == "undefined") {
        var columns = 3;
        var newLineAfter = "never";
        var offset = 0;
        if (typeof field.autoLayoutColumns != "undefined") {
          columns = field.autoLayoutColumns;
        }
        if (typeof field.autoLayoutNewLineAfter != "undefined" && field.autoLayoutNewLineAfter == "yes") {
          newLineAfter = "yes";
        }
        if (typeof field.autoLayoutOffset != "undefined") {
          offset = field.autoLayoutOffset;
        }

        grid = {
          columns: columns,
          index: "0",
          newLineAfter: newLineAfter,
          newLineBefore: "never",
          offset: offset,
          resolution: resolution,
          visible: "true"
        };
        field.grid.push(grid);
      }
    }

    field.fields.forEach(function(f) {
      self.ensureGridHelp(f, resolution, availableResolution);
    });
  },
  ensureGrid: function(resolution) {
    var availableResolution = MC.getAvailableResolution(resolution, this.getForm());
    this.ensureGridHelp(this, resolution, availableResolution);
  },
  deleteGrid: function(resolution) {
    if (typeof this.grid != "undefined") {
      this.grid = this.grid.filter(function(grid) {
        return grid.resolution != resolution;
      });
    }
    this.fields.forEach(function(f) {
      f.deleteGrid(resolution);
    });
  },
  getNode: function() {
    return document.querySelector("#innerModeler [data-widget-id='" + this.rbsid + "']")
  },
  getBBox: function() {
    let field = this
    if (field.widget == "dummy") {
      field = field.getParent()
    }
    let el = field.getNode()
    if (!el) {
      return {
        left: 0,
        top: 0,
        width: 0,
        height: 0
      }
    }
    let offset = MC.offset(el)
    let bbox = {left: offset.left, top: offset.top, width: el.offsetWidth, height: el.offsetHeight}
    if (["basictable", "loadabletable", "pageabletable", "scrollabletable", "table"].indexOf(field.widget) > 0) {
      el = el.querySelector(".tableWrapper")
      let offset = MC.offset(el)
      let wrapbbox = {left: offset.left, top: offset.top, width: el.offsetWidth, height: el.offsetHeight}
      if (wrapbbox.width > bbox.width) {
        return wrapbbox
      }
    }
    return bbox
  },
  getLeftOffset: function() {
    let el = this.getNode()
    if (this.isVisible() && el) {
      return MC.offset(el).left
    } else {
      return Infinity
    }
  },
  setSubfields: function(subfields) {
    var field = this;
    if (field.fields.length == 1 && field.fields[0].widget == "dummy") {
      field = field.fields[0];
    }
    field.fields = subfields;
    subfields.forEach(function(f) {
      f.parent = field;
    });
  },
  isVisible: function() {
    var isInEyeMode = this.isInEyeMode();
    var gridVisible = this.getOption(["grid", "visible"]);
    var columnVisible = this.getOption(["column", "visible"]);
    var paramVisible = this.getOption(["param", "@visible"]);
    var isInTable = this.isInTable();
    return isInEyeMode || (gridVisible && paramVisible && (!isInTable || columnVisible));
  },
  getRows: function() {
    var rows = [];
    var row = [];
    var counter = 0;
    var subfields = this.getSubfields()
    subfields.forEach(function(f) {
      if (f.isVisible()) {
        var width = f.offset() + f.columns();
        if ((row.length > 0 && counter + width > 12) || f.newLineBefore()) {
          rows.push(row);
          row = [];
          counter = 0;
        }
        counter += width;
        row.push(f);
        if (f.newLineAfter()) {
          rows.push(row);
          row = [];
          counter = 0;
        }
      }
    });
    if (row.length > 0) {
      rows.push(row);
    }
    return rows;
  },
  getRow: function() {
    /* the row of the field */
    var self = this;
    var rows = this.parent.getRows();
    var index = rows.findIndex(row => row.includes(self));
    return rows[index];
  },
  beforeRow: function() {
    var self = this;
    var rows = this.parent.getRows();
    var index = rows.findIndex(row => row.includes(self));
    if (index != 0) {
      return rows[index - 1];
    }
  },
  beforeColumns: function() {
    /* number of columns before field */
    var row = this.getRow();
    var index = row.indexOf(this);
    var beforeFields = row.slice(0, index);
    return FieldDef.rowColumns(beforeFields);
  },
  /**
   * Get the value of a property of an object on a path.
   * @param {Object} object
   * @param {Array} path - an array of strings
   * @return {Anything} value
   */
  getValueOnPath: function(object, path) {
    if (path.length == 0) {
      throw "path canot be empty";
    }
    if (typeof object == "undefined") {
      return undefined;
    }
    var segment = path[0];


    if (path.length == 1) {
      return object[segment];
    } else {
      return this.getValueOnPath(object[segment], path.slice(1));
    }
  },
 /**
  * Set the value of a property of an object on a path.
  * @param {Object} object
  * @param {Array} path - an array of strings
  * @param {Anything} value
  */
  setValueOnPath: function(object, path, value) {
    if (path.length == 0) {
      throw "path canot be empty";
    }
    var segment = path[0];

    if (path.length == 1) {
      if (value === "") {
        delete object[segment]
      } else {
        object[segment] = value;
      }
    } else {
      if (typeof object[segment] == "undefined") {
        object[segment] = {};
      }
      this.setValueOnPath(object[segment], path.slice(1), value);
    }
  },
  /**
   * Work with a property in definition on path.
   * @param {Object} definition - resource bus property
   * @param {Array} path - array of strings, e.g. ["param", "@title"]
   * @param {Bool} allowModify - allow modify the definition?
   * @param {Bool} deleteIt - if true then delete property otherwise return the property
   */
  definitionOnPath: function(definition, path, allowModify, deleteIt) {
    var self = this;
    if (path.length == 0) {
      throw "path canot be empty";
    }
    if (typeof definition == "undefined") {
      return undefined;
    }
    var segment = path[0];
    var def = definition.find(function(def) {
      return def.name == segment;
    });
    if (!def) {
      if (allowModify) {
        def = { name: segment };
        definition.push(def);
      } else {
        return undefined;
      }
    }

    if (path.length == 1) {
      if (deleteIt) {
        var index = definition.indexOf(def);
        definition.splice(index, 1);
        return;
      } else {
        return def;
      }
    } else {
      if (!def.FormFieldParam && allowModify) {
        def.FormFieldParam = [];
      }
      return this.definitionOnPath(def.FormFieldParam, path.slice(1), allowModify, deleteIt);
    }
  },
  /**
   * Get value of a property in a definition on a path.
   * @param {Object} definition - resource bus property
   * @param {Array} path - array of strings, e.g. ["param", "@title"]
   */
  getDefinitionValueOnPath: function(definition, path) {
    var def = this.definitionOnPath(definition, path);
    if (def) {
      return def.val;
    }
  },
  /**
   * Delete property in a definition on a path.
   * @param {Object} definition - resource bus property
   * @param {Array} path - array of strings, e.g. ["param", "@title"]
   */
  deleteDefinitionOnPath: function(definition, path) {
    this.definitionOnPath(definition, path, true, true);
  },
  /**
   * Set property in definition on path to value. If the value is the empty string then delete the property.
   * @param {Object} definition - resource bus property
   * @param {Array} path - array of strings, e.g. ["param", "@title"]
   * @param {Anything} value
   */
  setDefinitionValueOnPath: function(definition, path, value) {
    if (value === "") {
      this.deleteDefinitionOnPath(definition, path);
    } else {
      var def = this.definitionOnPath(definition, path, true);
      if (def) {
        def.val =  String(value);
      }
    }
  },
  addMissingPropertiesDefinitionHelp: function(definition, defaultDefinition) {
    var self = this;
    defaultDefinition.forEach(function(innerDefaultDefinition) {
      var innerDefinition = definition.find(function(d) {
        return d.name == innerDefaultDefinition.name;
      });
      if (!innerDefinition) {
        definition.push(deepCopy(innerDefaultDefinition));
      } else if (innerDefaultDefinition.FormFieldParam) {
        if (!innerDefinition.FormFieldParam) {
          innerDefinition.FormFieldParam = [];
        }
        self.addMissingPropertiesDefinitionHelp(innerDefinition.FormFieldParam, innerDefaultDefinition.FormFieldParam);
      }
    })
  },
  /**
   * If there is a default property for the field and the field does not have the property then add the property to the field.
   * @param {Object} defaultPropertiesDefinition - a result of the method WidgetModel.getDefaultPropertiesDefinition
   */
  addMissingPropertiesDefinition: function(defaultPropertiesDefinition) {
    this.addMissingPropertiesDefinitionHelp(this.FormField.FormFieldParam, defaultPropertiesDefinition);
  },
  /**
   * Get option value of the field on the path.
   * @param {Array} path - array of strings
   */
  getOption: function(path) {
    if (path.length == 0) {
      throw "Path has to be nonempty."
    }
    if (typeof this == "undefined") { // Tomu nerozumim
      return undefined;
    }
    if (path.length == 1) {
      return this.getValueOnPath(this, path);
    }
    var type = path[0];
    var restPath = path.slice(1);

    switch (type) {
      case "param":
        return this.getDefinitionValueOnPath(this.FormField.FormFieldParam, restPath);
        break;
      case "grid":
        var resolution = this.flow.modelerReact.state.resolution;
        var form = this.getForm();
        var availableResolution = MC.getAvailableResolution(resolution, form);
        var grid = this.getGrid(availableResolution);
        var value = this.getValueOnPath(grid, restPath);
        var name = restPath[0];
        if (name == "visible") {
          value = value == "true";
        }
        return value;
        break;
    }
  },
  /**
   * Set option value of the field on the path.
   * @param {Array} path - array of strings
   * @param {Anything} value - new value of the option
   */
  setOption: function(path, value) {
    var self = this
    let type = path.length == 1 ? "root" : path[0]
    switch (type) {
      case "param":
        this.setValueOnPath(this, path, value)
        this.setDefinitionValueOnPath(this.FormField.FormFieldParam, path.slice(1), value)
        if (path[1] == "columns") {
          var table = this.getTrueParent()
          if (!table.param.columns) {
            table.param.columns = {}
          }
          if (!table.param.columns[this.id]) {
            table.param.columns[this.id] = {}
          }
          table.param.columns[this.id][path[2]] = value
        }
        break;
      case "root":
        var option = path[0];
        if (option == "id") {
          if (value == "") {
            throw GetText.t("Empty name!")
          }
          if (value !== this.FormField.name) {
            if (!this.isTopLevel()) {
              for (let fld of this.getParent().getSubfields()) {
                if (fld.id == value) {
                  throw GetText.t("Duplicate name at the same level!")
                }
              }
            }
          }
          this.FormField.name = value;
          if (this.isInTable()) {
            var table = this.getTrueParent();
            var columnsEntries = objectToEntries(table.param.columns);
            var columnEntry = columnsEntries.find(function(column) {
              return column[0] == self.id;
            })
            if (typeof columnEntry != 'undefined') { 
              // there is case when table coppied and the column is already renamed in checkFieldIdUniquness
              // this whole this.isInTable() block may be redundant or needed in another case
              columnEntry[0] = value;
              var columns = entriesToObject(columnsEntries);
              table.param.columns = columns;
            }
          }
        } else if (option == "basictype") {
          if (value === "") {
            delete this.FormField["basictype"]
          } else {
            this.FormField["basictype"] = value
          }
        }  
        this.setValueOnPath(this, path, value);
        if (option == "widget") {
          WidgetModel.updateAfterWidgetWasChanged(this);
          this.FormField.fwidget = value;
        }
        if (option == "rbsid") {
          this.FormField["rbs:id"] = value;
          delete this.FormField["rbs:fullId"];
        }
        break;
      case "grid":
        var grid = this.getAvailableGrid();
        if (typeof grid != "undefined") {
          this.setValueOnPath(grid, path.slice(1), String(value));
        }
        break;
    }

  },
  /**
   * Generate rbs id to the field and to all its subfields.
   */
  generateRbsId: function() {
    this.setOption(["rbsid"], MC.generateId())
    this.getSubfields().forEach(function(subfield) {
      subfield.generateRbsId();
    });
  },
/**
 * Delete all rbs:id and rbs:fullId properties in the given object. Deletion goes deep to the object.
 */
  deleteAllRbsIdsHelp: function(object) {
    var self = this;
    delete object["rbs:id"];
    delete object["rbs:fullId"];
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        /*if (object[key] instanceof Array) {
          object[key].forEach(function(subobject) {
            self.deleteAllRbsIdsHelp(subobject);
          })
        } else*/
        if (object[key] instanceof Object) {
          this.deleteAllRbsIdsHelp(object[key]);
        }
      }
    }
  },
  /*
  * Delete all rbs ids properties appearing in the field.
  */
  deleteAllRbsIds: function() {
    this.fields.forEach(function(subfield) {
      subfield.deleteAllRbsIds();
    })
    if (this.FormField) {
      this.deleteAllRbsIdsHelp(this.FormField);
    }
    if (this.grid) {
      this.deleteAllRbsIdsHelp(this.grid);
    }
    delete this.rbsid;
  },
  hasGrid: function() {
    return typeof this.grid != "undefined";
  },
  getOptions: function() {
    var field = this;
    let rootOptions = [{
        path: ["id"], name: "name", group: "basic",
        help: GetText.t("Field identification name unique within one form. The name should be without spaces or special characters.")
      },
      {
        path: ["widget"], name: "widget", enum: WidgetModel.getFieldList(), group: "basic", required: true,
        help: GetText.t("Select the widget used for this field here. The behavior and rendering of the field change based on the selected widget.")
      },
      {
        path: ["basictype"], name: "basictype", group: "basic",
        enum: ['integer', 'string', 'time', 'date', 'dateTime', 'decimal', 'boolean', 'long', 'int', 'short', 'byte', 'float', 'double', 'duration', 'base64Binary', 'hexBinary'],
        help: GetText.t("For some widgets that support multiple data types, the behavior may vary based on the selected data type.")
      }
    ]
    var viewOptions = [];
    if (this.isInTable(field)) {
      viewOptions = []
      let columnOptions = WidgetModel.getColumnsOptions()
      if (Array.isArray(columnOptions) && columnOptions.length > 0) {
        for (let column of columnOptions) {
          viewOptions.push({path: ["param", "columns", column["def:name"]], name: column["def:name"], dataType: column["def:type"], group: "column", enum: column["def:enum"]})
        }
      }
    } else if (typeof this.getAvailableGrid() != "undefined") {
      viewOptions = [{
          path: ["grid", "columns"],
          name: "columns",
          group: "grid",
          help: GetText.t("The number of columns that the field occupies.")
        },
        {
          path: ["grid", "offset"],
          name: "offset",
          group: "grid",
          help: GetText.t("The number of columns that take up free space to the left of the field.")
        },
        {
          path: ["grid", "index"],
          name: "index",
          group: "grid",
          help: GetText.t("The order of the field within the parent field, such as the panel.")
        },
        {
          path: ["grid", "newLineAfter"],
          enum: ["never", "yes"],
          name: "new line after",
          group: "grid",
          help: GetText.t("Specifies whether the field is always placed at the beginning of a line. Value <code>never</code> is not interpreted.")
        },
        {
          path: ["grid", "newLineBefore"],
          enum: ["never", "yes"],
          name: "new line before",
          group: "grid",
          help: GetText.t("Specifies whether the field always ends a line. Value <code>never</code> is not interpreted.")
        },
        {
          path: ["grid", "visible"],
          name: "visible",
          dataType: "boolean",
          group: "grid",
          help: GetText.t("Specifies whether the field is rendered at that resolution.")
        }
      ];
    }
    var paramOptions = WidgetModel.getParamOptions(field);
    paramOptions = paramOptions.sort((option1, option2) => strCompare(option1.name, option2.name));

    return rootOptions.concat(viewOptions).concat(paramOptions);
  },
  isLastField: function() {
    var parent = this.getParent();
    if (!parent.isTopLevel()) {
      return false;
    }
    return parent.fields.length == 1;
  },
  getGridDefintion: function () {
    if (typeof this.grid != "undefined") {
      return this.grid;
    };
  },
  getDefinition: function () {
    var definition =  deepCopy(this.FormField);
    definition.FormField =  this.getSubfields().map(field => field.getDefinition());
    if (this.grid) {
      definition.FormFieldGrid = this.grid;
    }
    return definition;
  },
  getTabPanelMenuItemOffsets: function() {
    let items = this.getNode().querySelectorAll(".menu .item")
    let offsets = []
    if (items.length != 0) {
      for (let item of items) {
        offsets.push(MC.offset(item).left)
      }
      let lastWidth = items[items.length - 1].offsetWidth
      let lastOffset = offsets[offsets.length - 1]
      offsets.push(lastOffset + lastWidth)
    }
    return offsets
  }
}

function deepCopy(object) {
  return MC.copyFormField(object)
}

function strCompare(str1, str2) {
  var nameA = str1.toUpperCase(); // ignore upper and lowercase
  var nameB = str2.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

function objectToEntries(object) {
  var result = [];
  for (var key in object) {
    result.push([key, object[key]]);
  }
  return result;
}

function entriesToObject(entries) {
  var result = [];
  entries.forEach(entry => result[entry[0]] = entry[1]);
  return result;
}

function moveElement(array, sourcePosition, targetPosition) {
  var element = array[sourcePosition];
  if (sourcePosition < targetPosition) {
    array.splice(targetPosition + 1, 0, element);
    array.splice(sourcePosition, 1);
  } else {
    array.splice(sourcePosition, 1);
    array.splice(targetPosition, 0, element);
  }
}

function strContains (str1, str2) {
    return str1.toUpperCase().includes(str2.toUpperCase())
}

export {FieldProto}