import React from "react"

import {MC} from '../client/MC.js'
import {Modeler} from '../modeler/Modeler.jsx'
import {GetText} from '../modeler/GetText.js'

class FormModeler extends React.Component {

  state = {flow: null, form: null}

  componentDidMount() {
    let base = document.querySelector('base').href.split('?')[0]
    base = base.substring(0, base.lastIndexOf("/") + 1)
    document.querySelector('base').href = base
    let self = this
    let contextModel = MC.getParameterByName("contextModel")
    let id = MC.getParameterByName("id")
    let lang = MC.getParameterByName("lang")
    GetText.setLang(lang)
    let formurl = this.props.mconf.baseUrl + 'miniapp/formdef/' + contextModel + '?id=' + id + '&lang=' + lang + '&inmodeler=true'
    MC.callServer('GET', formurl, MC.getJsonType()).then(function (result) {
      if (result.status == 200) {
        let form = JSON.parse(result.content)
        form.model = contextModel
        let flow = {}
        flow.lang = lang
        flow.flow = {model: contextModel}
        flow.getCfgParameter = () => {return null}
        flow.debug = () => {return false}
        flow.clearLogicTimers = () => {}
        flow.eventForm = () => {}
        flow.reactFlow = () => {return {props: {mconf: self.props.mconf}, state: {}}}
        flow.runLazyUpdate = () => {}
        flow.handleSubmit = () => {}
        form.flow = flow
        MC.setPropertyRecusively(form, 'fields', 'flow', flow)
        form.formId = id
        self.setState({flow, form})
      }
    })
  }

  render() {
    let modeler =<div className="ui active loader"/>
    if (this.state.flow && this.state.form) {
      const access = MC.getParameterByName("access")
      modeler = <Modeler flow={this.state.flow} form={this.state.form} readOnly={access === "read"} mconf={this.props.mconf}/>
    }
    return <div id="modelerContainer" className="meta column">{modeler}</div>
  }

}

if (!window.modelerChanged) {
  window.modelerChanged = function(value) {
    window.parent.postMessage(String(value),"*")
  }
}

export default FormModeler