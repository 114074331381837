import React from "react"

import {MC} from './MC.js'

class Label extends React.Component {

  render() {
    const field = this.props.field
    let value = this.props.value
    const labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    if (labelPlacement == 'IN' && !this.props.inTable) {
      value = MC.getFieldParamValue(field.param, '@title')
      if (MC.isNull(value) || value === '') {
        value = this.props.value
      }
    }
    let cssclass = MC.getFieldParamValue(field.param, '@cssClass')
    let HeaderType =  MC.getFieldParamValue(field.param, '@headerType')    
    if (MC.isNull(HeaderType)) {
      var escapeHtml = MC.getFieldParamValue(field.param, '@escapeHtml')
      if (escapeHtml) {
      return <span className={cssclass} id={this.props.id} data-widget-i-name={field.id}>{MC.iconize(field, <span>{value}</span>)}</span>
      } else {
        return MC.iconize(field, <div className={cssclass} id={this.props.id} dangerouslySetInnerHTML={{__html: MC.customHtml(value)}} data-widget-i-name={field.id}/>)
      }
    } else {
      let halign = MC.getFieldParamValue(field.param, '@horizontalAlignment')
      cssclass = MC.classes('ui header flex-row flex-middle', cssclass, {'flex-center': 'center' == halign, 'flex-right': 'right' == halign})
      HeaderType = HeaderType.toLowerCase()
      return <HeaderType className={cssclass} id={this.props.id} data-widget-i-name={field.id}>{MC.iconize(field, <span className="flex-column">{value}</span>, 'flex-column')}</HeaderType>
    }
  }

}

export {Label}