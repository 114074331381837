import React from "react"

import Dropdown from './semantic-widgets/Dropdown.jsx'
import Datebox from "./Datebox.jsx"
import {MC} from './MC.js'


class TableFilterCell extends React.Component {

  buildFilterOperatorOptions(field) {
    let options = []
    let items = MC.asArray(MC.getFieldParamValue(field, 'filterOperatorItem'))
    for (let item of items) {
      if (!item) break
      let key = item['@key']
      let title = item['@title'] !== undefined ? item['@title'] : key
      options.push({value: key, text: title, content: title})
    }
    return options
  }

  onChangeDateFilter = (field, fcolumn, value) => {
    if (value && value.isLuxonDateTime == true) {
      let withTimezone = MC.getFieldParamBooleanValue(field.param, '@outputTimezone')
      let basictype = MC.getFieldParamValue(fcolumn, '@filterBasicType') || field.basictype
      switch (basictype) {
        case 'dateTime': value = value.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS"); break
        case 'time': value = value.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss"); break
        default: value = value.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd"); break
      }
      fcolumn['@filterValue'] = value
      this.props.field.flow.runLazyUpdate(this.props.field)
    } else {
      fcolumn['@filterValue'] = value
      if (value === '') {
        this.props.field.flow.runLazyUpdate(this.props.field)
      }
    }
  }

  onChangeFilter = (column, e) => {
    column['@filterValue'] = [e.target.value]
    this.forceUpdate()
  }

  buildFilterOptions(field) {
    let options = []
    options.push({value: '', text: ''})
    let items = MC.asArray(MC.getFieldParamValue(field, 'item'))
    for (let item of items) {
      if (!item) break
      let key = item['@key']
      let title = item['@title'] !== undefined ? item['@title'] : key
      options.push({value: key, text: title, content: title})
    }
    return options
  }

  handleFilterKeyDown = (e) => {
    if (e.key == 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation()
      this.props.field.flow.runLazyUpdate(this.props.field)
    }
  }

  onChangeComboFilter = (e, combo) => {
    combo.column['@filterValue'] = combo.value
    this.props.field.flow.runLazyUpdate(this.props.field)
  }

  onChangeComboFilterOperator = (e, combo) => {
    combo.column['@filterOperatorValue'] = combo.value
    if (combo.column['@filterValue']) {
      this.props.field.flow.runLazyUpdate(this.props.field)
    }
  }

  render() {
    let fcolumn = this.props.tableColumn
    let colfield = this.props.columnField
    if (MC.getFieldParamBooleanValue(fcolumn, '@filterable') === false) {
      return <th key={colfield.rbsid}>&nbsp;</th>
    } else {
      let operator
      let operatorSelector = MC.getFieldParamBooleanValue(fcolumn, '@filterOperatorSelector')
      if (operatorSelector) {
        let options = this.buildFilterOperatorOptions(fcolumn)
        if (options.length == 0) {
          options = [{value: "=", text: "="}, {value: "!=", text: "!="}, {value: "<", text: "<"}, {value: ">", text: ">"}, {value: "<=", text: "<="},  {value: ">=", text: ">="}]
          if (!fcolumn['@filterOperatorValue']) {
            fcolumn['@filterOperatorValue'] = '='
          }
        } else {
          if (!fcolumn['@filterOperatorValue']) {
            fcolumn['@filterOperatorValue'] = options[0].value
          }
        }
        operator = <Dropdown className="compact selection" multiple={false} key={colfield.rbsid + 'o'} onChange={this.onChangeComboFilterOperator} value={fcolumn['@filterOperatorValue']} options={options} 
                      selectOnNavigation={false} selectOnBlur={false} closeOnChange={true} column={fcolumn} search={false}/>
      }
      const filType = MC.getFieldParamValue(fcolumn, '@filterType') || 'textbox'
      let filValue = MC.getFieldParamValue(fcolumn, '@filterValue') || ''
      let basicType = MC.getFieldParamValue(fcolumn, '@filterBasicType') || colfield.basictype
      let filterInput
      if (filType == 'datebox') {
        filValue = MC.castToScalar(filValue, 'string')
        filterInput = <Datebox value={filValue} field={colfield} onChange={this.onChangeDateFilter.bind(this, colfield, fcolumn)} basicType={basicType}/>
      } else if (filType == 'combobox') {
        let options = this.buildFilterOptions(fcolumn)
        filterInput = <Dropdown className="fluid selection" multiple={true} key={colfield.rbsid} onChange={this.onChangeComboFilter} value={filValue} options={options} 
                      selectOnNavigation={false} selectOnBlur={false} closeOnChange={true} column={fcolumn} search={true}/>
      } else {
        let type = ['integer', 'int', 'decimal'].indexOf(basicType) > -1 ? 'number' : 'text'
        let step = 'decimal' == basicType ? '0.01' : null
        filterInput = <input type={type} step={step} onChange={this.onChangeFilter.bind(this, fcolumn)} value={filValue} onKeyDown={this.handleFilterKeyDown}/>
      }
      if (operator) {
        return <th><div className="flex-row flex-no-wrap"><div className="flex-column">{operator}</div><div className="flex-column flex-grow">{filterInput}</div></div></th>
      } else {
        return <th>{filterInput}</th>
      }
    }
  }
}

export default TableFilterCell