import React from "react"

import {MC} from './MC.js'
import {MCHistory} from './MCHistory.js'

class WhisperBox extends React.Component {

  state = {matchedData: [], showDropdown: false, data: [], activeIndex: null, param: {}}
  rootRef = React.createRef()

  componentDidMount() {
    this.updateData(this.props)
    document.addEventListener('click', this.handleOutsideClick, false)
    if (!MC.isNull(MC.getFieldParamValue(this.props.data.param, '@operationName'))) {
      MCHistory.log(MCHistory.T_WARNING, 'Using "@operationNam" is deprecated. Use event with alternative mapping instead.', this.props.data.flow.debug())
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!MC.objectEqual(prevState.param, this.props.data.param, true)) {
      this.updateData(this.props)
    }
  }

  updateData(props) {
    var self = this;
    var field = props.data;
    var operName = MC.getFieldParamValue(props.data.param, '@operationName')
    var value = MC.getFieldParamValue(props.data.param, 'value')
    var titleValue = MC.getFieldParamValue(props.data.param, 'text')
    let newState = {...this.state, param: MC.extend({}, props.data.param)}
    if (!MC.isNull(operName)) {
      if (!MC.isNull(value) && MC.isNull(titleValue)) {
        field.flow.endOperationException('SYS_InvalidModelExc', 'When whisperbox has operationName, only text (not value) can be mapped into widget input!', props.data.param, null, null);
      } else if (MC.isNull(value) && !MC.isNull(titleValue) && this.whisperOperLastValue != titleValue) {
        this.whisperOperLastValue = titleValue
        MC.getWhisperItems(props.data, operName, titleValue).then(function (matchedData) {
          self.whisperOperLastValue = null
          for (var i = 0; i < matchedData.length; i++) {
            if (titleValue == matchedData[i].title.toString()) {
              self.handleSetValue(matchedData[i].key.toString(), titleValue);
              break;
            }
          }
        }).catch(function (ex) {
          field.flow.endOperationException(ex.type, ex.message, ex.input, ex.output, ex.log);
        });
      }
    } else {
      var data = []
      if (field.param['items']) {
        for (let item of field.param['items']) {
          if (!MC.isNull(item['@key'])) {
            data.push({key: item['@key'].toString(), title: MC.isNull(item['@title']) ? item['@key'].toString() : item['@title'].toString()})
            if (!MC.isNull(value) && MC.isNull(titleValue)) {
              if (value == item['@key'].toString()) {
                this.handleSetValue(value, item['@title'].toString())
              }
            } else if (!MC.isNull(titleValue) && MC.isNull(value)) {
              if (titleValue == item['@title'].toString()) {
                this.handleSetValue(item['@key'].toString(), titleValue)
              }
            }
          }
        }
      }
      if (MC.getFieldParamBooleanValue(props.data.param, '@forceSearch')) {
        MC.putFieldParamValue(props.data.param, '@forceSearch', false)
        newState.matchedData = data
        newState.showDropDown = true
      }
      newState = {...newState, data: data, activeIndex: null}
    }
    if (!MC.objectEqual(this.state, newState, true)) {
      this.setState(newState)
    }
  }

  handleChange = (e) => {
    let value = e.target.value
    this.props.widget.handleTextChange(value, value)
    let searchDelay = MC.getFieldParamValue(this.props.data.param, '@searchDelay')
    if (MC.isNumeric(searchDelay) && searchDelay > 0) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.triggerChange(value)
      }, 1000*searchDelay)
    } else {
      this.triggerChange(value)
    }
  }

  triggerChange(value) {
    let self = this
    let operName = MC.getFieldParamValue(this.props.data.param, '@operationName')
    if (!MC.isNull(operName)) {
      this.setState({showDropdown: false})
      this.whisperOperLastValue = value
      MC.getWhisperItems(this.props.data, operName, value).then(function (matchedData) {
        self.whisperOperLastValue = null
        self.setState({matchedData: matchedData, showDropdown: true, activeIndex: null})
      }).catch(function (ex) {
        self.props.data.flow.endOperationException(ex.type, ex.message, ex.input, ex.output, ex.log)
      })
    } else {
      if (value.trim()) {
        let matchedData = this.searchQuery(value.trim(), this.state.data)
        this.setState({matchedData: matchedData, showDropdown: true, activeIndex: null})
      } else {
        this.setState({showDropdown: false})
      }
    }
  }  

  searchQuery(value, array) {
    var whisperType =  MC.getFieldParamValue(this.props.data.param, '@whisperType')
    var searchTitle = true;
    if (whisperType == 'value') {
      searchTitle = false;
    }
    var searchKey = false;
    if (whisperType == 'value' || whisperType == 'both') {
      searchKey = true;
    }
    var result = [];
    value = value.toLowerCase()
    for (var i=0; i<array.length; i++) {
      if (searchKey && array[i]['key'].toLowerCase().indexOf(value) !== -1 || searchTitle && array[i]['title'].toLowerCase().indexOf(value) !== -1) {
        result.push(array[i]);
      }
    }
    return result;
  }

  handleSetValue(value, titleValue) {
    this.setState({showDropdown: false}, function() {
      if (this.props.textMode) {
        return
      }
      this.props.widget.handleTextChange(value, titleValue)
    })
  }

  handleNoValue() {
    this.setState({showDropdown: false}, function() {
      this.props.widget.handleTextChange(null, null)
    })
  }

  activateItem(i) {
    this.setState({activeIndex: i});
  }

  handleKeyDown = (e) => {
    if (!this.state.matchedData || !Array.isArray(this.state.matchedData)) {
      return;
    }
    if (e.key == 'Enter') {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      if (Number.isInteger(this.state.activeIndex) && (this.state.matchedData && Array.isArray(this.state.matchedData))) {
        this.handleSetValue(this.state.matchedData[this.state.activeIndex]['key'], this.state.matchedData[this.state.activeIndex]['title']);
      }
    } else if (e.key == 'Escape') {
      if (this.state.showDropdown) {
        e.stopPropagation()
        e.preventDefault()
      }
      this.setState({activeIndex: null, showDropdown: false});
    } else if (e.key == 'ArrowUp') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex > 0) {
        this.setState({activeIndex: this.state.activeIndex - 1});
      } else {
        this.setState({activeIndex: this.state.matchedData.length -1});
      }
    } else if (e.key == 'ArrowDown') {
      if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex + 1 < this.state.matchedData.length) {
        this.setState({activeIndex: this.state.activeIndex + 1});
      } else {
        this.setState({activeIndex: 0});
      }
    } else if (e.key == 'Tab') {
      if (this.state.showDropdown) {
        if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
          this.handleNoValue()
        } else {
          this.setState({activeIndex: null, showDropdown: false})
        }
      }
    }
  }

  handleOutsideClick = (e) => {
    if (this.props.textMode || this.rootRef.current.contains(e.target)) {
      return
    }
    if (this.state.showDropdown) {
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
        this.handleNoValue()
      } else {
        this.setState({activeIndex: null, showDropdown: false})
      }
    }
  }

  render() {
    var inputCls = "input " + (this.state.showDropdown ? "input-show-dropdown" : "");
    var dropDown = null;
    if (this.state.showDropdown) {
      var list = null;
      if (this.state.matchedData && Array.isArray(this.state.matchedData)) {
        var mdata = this.state.matchedData;
        var items = [];
        for (var i=0; i<mdata.length; i++) {
          var cls = 'dropdown-ul-li' + (this.state.activeIndex === i ? ' active' : '');
          items.push(<li key={i} className={cls} onClick={this.handleSetValue.bind(this, mdata[i]['key'], mdata[i]['title'])} onMouseEnter={this.activateItem.bind(this, i, true)}>{mdata[i]['title']}</li>);
        }
        list = <ul className="dropdown-ul">{items}</ul>;
      }
      dropDown = <div className="dropdown">{list}</div>;
    }
    let titleValue = MC.getFieldParamValue(this.props.data.param, 'text') || ""
    if (this.props.textMode) {
      return titleValue
    }
    let input =  <input type="text" className={inputCls} placeholder={this.props.placeholder} onChange={this.handleChange} value={titleValue} onFocus={this.props.onFocus} onBlur={this.props.onBlur}
                   readOnly={this.props.readOnly} disabled={this.props.disabled} data-widget-i-name={this.props.data.id} ref={this.props.widgetRef}/>
    let icon = MC.getFieldParamValue(this.props.data.param, '@icon')
    if (icon) {
      let iconPlacement = MC.getFieldParamValue(this.props.data.param, '@iconPlacement')
      input = <div className={MC.classes("ui", {"left": iconPlacement !== 'right'}, "icon input")} key="inwrap">{input}<i key="icon" className={MC.buildIconClass(icon)}></i></div>
    }
    return (
      <div className="whisperbox" onKeyDown={this.handleKeyDown} ref={this.rootRef}>
        <div className="search-input">{input}</div>
        <div className="search-dropdown">{dropDown}</div>
      </div>
    );
  }

}

export {WhisperBox}
