import React from 'react'

import {MC} from '../MC.js'

export default class Accordion extends React.Component {

  static defaultProps = {exclusive: true}
  state = {activeIndex: this.props.activeIndex !== undefined ? this.props.activeIndex : this.props.exclusive ? -1 : []}

  componentDidUpdate(prevProps) {
    if (prevProps.activeIndex != this.props.activeIndex) {
      this.setState({activeIndex: this.props.activeIndex})
    }
  }  

  computeNewIndex = (index) => {
    const { exclusive } = this.props
    const { activeIndex } = this.state
    if (exclusive) return index === activeIndex ? -1 : index
    const actual = activeIndex.indexOf(index)
    if (actual > -1) {
      activeIndex.splice(actual, 1)
      return activeIndex
    } else {
      return [...activeIndex, index]
    }
  }

  handleTitleClick = (index, e) => {
    this.setState({activeIndex: this.computeNewIndex(index)})
    if (MC.isFunction(this.props.onTitleClick)) {
      this.props.onTitleClick(e, this.isIndexActive(index))
    }
  }

  isIndexActive = (index) => {
    const exclusive = this.props.exclusive
    const activeIndex = this.state.activeIndex
    return exclusive ? activeIndex === index : activeIndex.indexOf(index) > -1
  }

  render() {
    const {className, panels} = this.props
    const classes = MC.classes('ui accordion', className)
    return (
      <div className={classes}>
        {panels.map((panel, index) => {
          const active = this.isIndexActive(index)
          const titleClass = MC.classes({'active': active}, 'title')
          const contentClass = MC.classes('content', {'active': active})
          return (
            <React.Fragment key={index}>
              <div className={titleClass} onClick={this.handleTitleClick.bind(this, index)}>
                <i className="ui icon dropdown"/>
                {panel.title}
              </div>
              <div className={contentClass}>{panel.content}</div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}