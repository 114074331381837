import React from "react"

import {MC} from './MC.js'

class Link extends React.Component {

  handleClick = (e) => {
    if (MC.eventHasKey(e)) {
      return
    }
    const field = this.props.data
    const behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url') {
      e.preventDefault()
    }
    const escapeHtml = MC.getFieldParamBooleanValue(field.param, '@escapeHtml')
    if (!escapeHtml && e.target.nodeName !== 'A') {
      return
    }
    if (MC.isFunction(this.props.onClick) && !MC.isModelerActive(this.props.data)) {
      this.props.onClick()
    }
    MC.handleReactEvent(field, e)
    if (behavior == 'url') {
      const url = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(url)) {
        let target = MC.getFieldParamValue(field.param, '@target')
        if (['blank', 'parent', 'top'].indexOf(target) > -1) {
          return
        }
        field.flow.reactFlow().routeTo(e, url)
        return
      }
    } else if (behavior == 'dialog') {
      field.flow.callDialog(field)
    } else {
      this.props.data.flow.handleSubmit(this.props.data)
    } 
  }

  render() {
    const field = this.props.data
    let title = this.props.value
    let escapeHtml = MC.getFieldParamBooleanValue(field.param, '@escapeHtml')
    const labelPlacement = MC.getFieldParamValue(field.param, '@titlePlacement')
    if (labelPlacement == 'IN') {
      title = MC.getFieldParamValue(field.param, '@title')
    }
    let cls
    if (this.props.cls) {
      cls = this.props.cls
    } else {
      cls = MC.getFieldParamValue(field.param, '@cssClass')
      if (escapeHtml) {
        cls = cls ? 'ui link curpointer ' + cls : 'ui link curpointer'
      }
    }
    let href = MC.getFieldParamValue(field.param, '@url')
    let imageUrl = MC.getFieldParamValue(field.param, '@imageUrl')
    if (!MC.isNull(imageUrl)) {
      imageUrl = MC.rebaseUrl(field.flow.flow.model, imageUrl, field.flow.reactFlow().props.mconf)
      if (MC.getFieldParamValue(field.param, '@iconPlacement') === 'right') {
        title = <React.Fragment>{title}<img src={imageUrl}/></React.Fragment>
      } else {
        title = <React.Fragment><img src={imageUrl}/>{title}</React.Fragment>
      }
    } else if (escapeHtml) {
      title = MC.iconize(field, <span>{title}</span>)
    }
    if (this.props.disabled) {
      return <span id={field.rbsid} className={cls}>{title}</span>
    } else {
      let target = MC.getFieldParamValue(field.param, '@target')
      if (['blank', 'parent', 'top'].indexOf(target) > -1) {
        target = '_' + target
      } else {
        target = null
      }
      if (escapeHtml) {
        return <a className={cls} id={field.rbsid} onClick={this.handleClick} href={href} target={target} data-widget-i-name={field.id}>{title}</a>
      } else {
        return <div className={cls} id={field.rbsid} onClick={this.handleClick} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}} data-widget-i-name={field.id}></div>
      }
    }
  }

}

export {Link}