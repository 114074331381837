import React from "react"

import {MC} from './MC.js'
import Datetime from "./datetime/DateTime.jsx"

class Datebox extends React.Component {

  render() {
    let props = this.props
    let field = props.field
    let value = props.value
    let placeholder = props.placeholder
    let disabled = props.disabled
    let readonly = props.readonly
    let {dateValue, dateFormat, timeFormat, withTimezone, altFormats} = MC.prepeareDate(field, value)
    if (!MC.isNull(value) && value != '' && dateValue.isValid) {
      switch (props.basicType) { //normalize bad mapped value for validation
        case 'dateTime': value = dateValue.toFormat(withTimezone ? "yyyy-MM-dd'T'HH:mm:ss.SSSZZ" : "yyyy-MM-dd'T'HH:mm:ss.SSS"); break
        case 'time': value = dateValue.toFormat(withTimezone ? "HH:mm:ssZZ" : "HH:mm:ss"); break
        default: value = dateValue.toFormat(withTimezone ? "yyyy-MM-ddZZ" : "yyyy-MM-dd"); break
      }
      MC.putFieldParamValue(field.param, 'value', value)
    }
    if ((!dateValue.isValid || !MC.isValidDateStringByType(value, props.basicType || 'date')) && !MC.isNull(value)) { // invalid value is not conveted to luxon
      dateValue = value
    }
    const maxValue = MC.getFieldParamValue(field.param, 'validation/@maxValue')
    const minValue = MC.getFieldParamValue(field.param, 'validation/@minValue')
    const hideCalendar = field.flow.getCfgParameter("mini:hideCalendar") === 'true'
    const lang = field.flow.reactFlow().props.mconf.lang
    const viewDate = MC.getFieldParamValue(field.param, '@viewDate')
    if (props.basicType == 'time') {
      return <Datetime onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} value={dateValue} dateFormat={false} timeFormat={timeFormat} closeOnSelect={true} locale={lang}
                          inputProps={{id: field.id, name: field.rbsid, placeholder: placeholder, readOnly: readonly, disabled: disabled, 'data-widget-i-name': field.id}} readOnly={readonly} 
                          key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar} viewDate={viewDate} altFormats={altFormats}/>
    } else if (props.basicType == 'dateTime') {
      return <Datetime onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} value={dateValue} dateFormat={dateFormat} timeFormat={timeFormat} closeOnSelect={true} locale={lang}
                          inputProps={{id: field.id, name: field.rbsid, placeholder: placeholder, readOnly: readonly, disabled: disabled, 'data-widget-i-name': field.id}} readOnly={readonly}
                          key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar} viewDate={viewDate} altFormats={altFormats}/>
    } else {
      return <Datetime onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus} value={dateValue} dateFormat={dateFormat} timeFormat={false} closeOnSelect={true} locale={lang}
                          inputProps={{id: field.id, name: field.rbsid, placeholder: placeholder, readOnly: readonly, disabled: disabled, 'data-widget-i-name': field.id}} readOnly={readonly}
                          key="input" min={minValue} max={maxValue} modelerActive={MC.isModelerActive(field)} hideCalendar={hideCalendar} viewDate={viewDate} altFormats={altFormats}/>
    }
  }

}

export default Datebox