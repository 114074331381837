class FixedScroll {

  init(container) {
    this.container = container
    this.visible = true
    this.initWidget()
    this.update()
    this.addEventHandlers()
    // Set skipSync flags to their initial values (because update() above calls syncWidget())
    this.skipSyncContainer = this.skipSyncWidget = false
  }

  initWidget() {
    this.widget = document.createElement("div")
    this.widget.classList.add("handy-scroll")
    let strut = document.createElement("div")
    strut.style.width = `${this.container.scrollWidth}px`
    this.widget.appendChild(strut)
    this.container.appendChild(this.widget)
  }

  addEventHandlers() {
    let instance = this
    this.eventHandlers = [
      {
        el: window,
        handlers: {
          scroll() {
            instance.checkVisibility()
          },
          resize() {
            instance.update()
          }
        }
      },
      {
        el: instance.widget,
        handlers: {
          scroll() {
            if (instance.visible && !instance.skipSyncContainer) {
              instance.syncContainer()
            }
            // Resume widget->container syncing after the widget scrolling has finished
            // (it might be temporally disabled by the container while syncing the widget)
            instance.skipSyncContainer = false
          }
        }
      },
      {
          el: instance.container,
          handlers: {
            scroll() {
              if (!instance.skipSyncWidget) {
                instance.syncWidget()
              }
              // Resume container->widget syncing after the container scrolling has finished
              // (it might be temporally disabled by the widget while syncing the container)
              instance.skipSyncWidget = false
            },
            focusin() {
              setTimeout(() => {
                // The widget might be destroyed before the timer is triggered (issue #14)
                if (instance.widget) {
                  instance.syncWidget()
                }
              }, 0)
            }
          }
      }
    ]
    this.eventHandlers.forEach(({el, handlers}) => {
      Object.keys(handlers).forEach(event => {
        el.addEventListener(event, handlers[event], false)
      })
    })
  }

  checkVisibility() {
    let mustHide = (this.widget.scrollWidth <= this.widget.offsetWidth)
    if (!mustHide) {
      let containerRect = this.container.getBoundingClientRect()
      let maxVisibleY = window.innerHeight || document.documentElement.clientHeight
      mustHide = ((containerRect.bottom <= maxVisibleY) || (containerRect.top > maxVisibleY))
    }
    if (this.visible === mustHide) {
      this.visible = !mustHide
      // We cannot simply hide the scrollbar since its scrollLeft property will not update in that case
      this.widget.classList.toggle("handy-scroll-hidden")
    }
  }

  syncContainer() {
    let scrollLeft = this.widget.scrollLeft
    if (this.container.scrollLeft !== scrollLeft) {
      // Prevents container’s “scroll” event handler from syncing back again widget scroll position
      this.skipSyncWidget = true
      // Note that this makes container’s “scroll” event handlers execute
      this.container.scrollLeft = scrollLeft
    }
  }

  syncWidget() {
    let {scrollLeft} = this.container
    if (this.widget.scrollLeft !== scrollLeft) {
      // Prevents widget’s “scroll” event handler from syncing back again container scroll position
      this.skipSyncContainer = true
      // Note that this makes widget’s “scroll” event handlers execute
      this.widget.scrollLeft = scrollLeft
    }
  }

  update() {
    this.widget.style.width = `${this.container.clientWidth}px`
    this.widget.style.left = `${this.container.getBoundingClientRect().left}px`
    this.widget.firstElementChild.style.width = `${this.container.scrollWidth}px`
    // Fit widget height to the native scroll bar height if needed
    if (this.container.scrollWidth > this.container.clientWidth) {
      this.widget.style.height = `${this.widget.offsetHeight - this.widget.clientHeight + 1}px`
    }
    this.syncWidget()
    this.checkVisibility()
  }

  destroy() {
    this.eventHandlers.forEach(({el, handlers}) => {
      Object.keys(handlers).forEach(event => el.removeEventListener(event, handlers[event], false))
    })
    this.widget.parentNode.removeChild(this.widget)
    this.eventHandlers = this.widget = this.container = null
  }
}

export default FixedScroll