import React from "react"

import {MC} from './MC.js'

class Upload extends React.Component {

  state = {drag: false, progress: null}
  dropRef = React.createRef()
  inputRef = React.createRef()
  chunkSize = 1000000
  chunkCounter = 0

  handleFileChange = (e) => {
    let self = this
    const props = this.props
    const field = props.field
    const widget = props.widget
    if (e.target.files && e.target.files.length > 0) {
      if (MC.getFieldParamBooleanValue(field.param, '@largeFileUpload')) {
        this.startLargeUpload(e.target.files[0])
      } else {
        field.param.value = []
        const files = e.target.files
        this.fileCounter = files.length
        for (let file of files) {
          let newRecord = {}
          newRecord['@fileName'] = file.name
          newRecord['@contentType'] = file.type
          newRecord['@size'] = file.size
          newRecord['@relativePath'] = file.webkitRelativePath ? file.webkitRelativePath : file.name
          let reader = new FileReader()
          reader.onload = function(e) {
            newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
            field.param.value.push(newRecord)
            self.fileCounter--
            if (self.fileCounter == 0) {
              MC.handleEvent(field, 'change')
              self.handleBeahaviour()
              if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] === 'blur') {
                widget.revalidate(true)
              } else {
                widget.revalidate()
              }
            }
          }
          reader.readAsDataURL(file)
        }
      }
    }
  }

  handleUploadClick = () => {
    this.inputRef.current.value = null
    this.inputRef.current.click()
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  checkList(e) {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const props = this.props
      const field = props.field
      let largeFileUpload = MC.getFieldParamBooleanValue(field.param, '@largeFileUpload')
      const multiple = largeFileUpload ? false : MC.getFieldParamBooleanValue(field.param, '@multiple')
      const directory = largeFileUpload ? false : MC.getFieldParamBooleanValue(field.param, '@directory')
      if (!multiple && e.dataTransfer.items.length > 1) {
        return false
      }
      if (largeFileUpload && Array.isArray(field.param.value) && field.param.value.length > 0) {
        return false
      }
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const entry = e.dataTransfer.items[i].webkitGetAsEntry()
        if (entry) {
          if (entry.isDirectory && !directory) {
            return false
          }
        }
      }
      return true
    }
    return false
  }

  countItem(item, path) {
    self = this
    return new Promise((resolve) => {
      if (item.isFile) {
        self.fitems.push({item: item, path: path})
        resolve()
      } else if (item.isDirectory) {
        let promises = []
        let dirReader = item.createReader()
        let fnReadEntries = (() => {
          return (entries) => {
            for (let entry of entries) {
              promises.push(self.countItem(entry, path + item.name + "/"))
            }
            if (entries.length > 0) {
              dirReader.readEntries(fnReadEntries)
            } else {
              if (promises.length > 0) {
                Promise.all(promises).then(function () {
                  resolve()
                })
              } else {
                resolve()
              }
            }
          }
        })()
        dirReader.readEntries(fnReadEntries)
      }
    })
  }  

  countList(items) {
    return new Promise((resolve) => {
      let promises = []
      if (items && items.length > 0) {
        for (let item of items) {
          const entry = item.webkitGetAsEntry()
          if (entry) {
            promises.push(this.countItem(entry, ""))
          }
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function () {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (this.checkList(e)) {
      this.setState({drag: true})
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }

  handleDrop = (e) => {
    self = this
    e.preventDefault()
    e.stopPropagation()
    e.persist()
    this.setState({drag: false})
    let field = this.props.field
    let items = e.dataTransfer.items
    if (this.checkList(e)) {
      this.fitems = []
      this.countList(items).then(() => {
        field.param.value = []
        this.fileCounter = this.fitems.length
        for (let item of this.fitems) {
          item.item.file((file) => {
            if (MC.getFieldParamBooleanValue(field.param, '@largeFileUpload')) {
              self.startLargeUpload(file)
            } else {
              let newRecord = {}
              newRecord['@fileName'] = file.name
              newRecord['@contentType'] = file.type
              newRecord['@size'] = file.size
              newRecord['@relativePath'] = file.name
              newRecord['@relativePath'] = item.path ? item.path + file.name : file.name
              const reader = new FileReader()
              reader.onload = function(e) {
                newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
                field.param.value.push(newRecord)
                self.fileCounter-- 
                if (self.fileCounter == 0) {
                  MC.handleEvent(field, 'change')
                  self.handleBeahaviour()
                  if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] === 'blur') {
                    self.props.widget.revalidate(true)
                  } else {
                    self.props.widget.revalidate()
                  }
                  self.fitems = null
                }
              }
              reader.readAsDataURL(file)
            }
          })
        }
        self.dragCounter = 0
        e.dataTransfer.clearData()
      })
    }
  }

  startLargeUpload(file) {
    let self = this
    let field = this.props.field
    MC.callServer('POST', field.flow.reactFlow().props.mconf.baseUrl + '../upload', '*/*').then(function(res) { //TODO: pass this threw miniapp
    //return MC.callServer('POST',  field.flow.reactFlow().props.mconf.baseUrl + 'miniapp/upload/', '*/*').then(function(res) {
      if (res.status == 200 && res.location) {
        self.blobId = res.location.substring(res.location.indexOf("blobId=")+7)
        self.numberOfChunks = Math.ceil(file.size/self.chunkSize)
        let start = 0
        field.param.value = []
        field.param.value.push({'@fileName': file.name, '@contentType': file.type, '@size': file.size, '@relativePath': file.webkitRelativePath ? file.webkitRelativePath : file.name, '@blobId': self.blobId})
        self.createChunk(start, file)
      } else {
        field.flow.endOperationException('SYS_IntegrationExc', 'Unable to start upload')
        return
      }
    }).catch(function(err) {
      field.flow.endOperationException('SYS_IntegrationExc', 'Unable to start upload: ' + err.message)
      return
    })
  }

  createChunk = (start, file) => {
    this.chunkCounter++
    let chunkEnd = Math.min(start + this.chunkSize, file.size)
    let chunk = file.slice(start, chunkEnd)
    this.uploadChunk(chunk, start, chunkEnd, file)
  }

  uploadChunk = (chunk, start, chunkEnd, file) => {
    const field = this.props.field
    let self = this 
    let header = (start > 0 || chunkEnd < file.size) ? {"Content-Range": "bytes " + start + "-" + (chunkEnd < file.size ? chunkEnd-1 : chunkEnd) + "/" + file.size} : null
    MC.callServer('PUT', field.flow.reactFlow().props.mconf.baseUrl + '../upload?blobId=' + this.blobId, null, chunk, null, header, this.updateProgress).then(function(res) { //TODO: pass this threw miniapp
      if (res.status == 200) {
        start += self.chunkSize
        if (start  < file.size) {
          self.createChunk(start, file)
        } else {
          self.chunkCounter = 0
          self.numberOfChunks = 0
          self.blobId = null
          MC.handleEvent(field, 'change')
          self.handleBeahaviour()
          if (field.flow && field.flow.context.data.env.cfg && field.flow.context.data.env.cfg['fl:validationStyle'] === 'blur') {
            self.props.widget.revalidate(true)
          } else {
            self.props.widget.revalidate()
          }
        }
      } else {
        field.flow.endOperationException('SYS_IntegrationExc', 'Unable to continue upload')
        return
      }
    }).catch(function(err) {
      field.flow.endOperationException('SYS_IntegrationExc', 'Unable to continue upload: ' + err.message)
      return
    })
  }
  
  updateProgress = (oEvent) => {
    if (oEvent.lengthComputable) {
      let percentComplete = Math.round(oEvent.loaded / oEvent.total * 100)
      let totalComplete =  Math.round((this.chunkCounter -1)/this.numberOfChunks*100 + percentComplete / this.numberOfChunks)
      if (totalComplete <= 100) {
        this.setState({progress: totalComplete})
      }
    } else {
      this.state.progress = '...'
    }
  }

  handleBeahaviour = () => {
    let behavior = MC.getFieldParamValue(this.props.field, '@behavior')
    if (behavior == 'dialog') {
      this.props.field.flow.callDialog(this.props.field)
    } else {
      this.props.field.flow.handleSubmit(this.props.field)
    }
  }

  render() {
    const props = this.props
    const field = props.field
    let innerDisabled = props.disabled
    if (MC.isModelerActive(field)) {
      innerDisabled = true
    }
    const buttonTitle = MC.getFieldParamValue(field.param, '@buttonTitle')
    let placeholder = props.placeholder
    let isSelectedFile = false
    let values = MC.getFieldParamValue(field.param, 'value')
    if (this.state.progress) {
      placeholder = <span className="progress">{this.state.progress}%</span>
    } else if (Array.isArray(values) && values.length > 0) {
      isSelectedFile = true
      if (values.length > 8) {
        placeholder = values.slice(0, 8).map((val) =>  val['@fileName']).join(', ') + ' ... (' +  values.length + ')'
      } else {
        placeholder = values.map((val) =>  val['@fileName']).join(', ')
      }
    }
    if (this.props.textMode) {
      return <React.Fragment>{placeholder}</React.Fragment>
    }
    let largeFileUpload = MC.getFieldParamBooleanValue(field.param, '@largeFileUpload')
    let multiple = largeFileUpload ? false : MC.getFieldParamBooleanValue(field.param, '@multiple')
    const accept = MC.getFieldParamValue(field.param, '@accept')
    const directory = largeFileUpload ?  null : MC.getFieldParamBooleanValue(field.param, '@directory') ? 'true' : null
    const showAsDropZone = MC.getFieldParamBooleanValue(field.param, '@showAsDropZone')
    if (largeFileUpload && Array.isArray(field.param.value) && field.param.value.length > 0) {
      innerDisabled = true
    }
    let iterationId = MC.asArray(MC.getFieldParamValue(field.param, '@iteration')).join('-')
    if (showAsDropZone) {
      const dropZoneHeight = MC.getFieldParamValue(field.param, '@dropZoneHeight') || '200px'
      let style = {height: dropZoneHeight}
      let dragClass = this.state.drag ? ' drag' : ''
      if (isSelectedFile) {
        dragClass += ' selected'
      }
      return (
        <div ref={this.dropRef} style={style} className={'mnc-file-drop-zone' + dragClass} data-widget-i-name={field.id} onDragEnter={this.handleDragIn} onDragLeave={this.handleDragOut} 
          onDragOver={this.handleDrag} onDrop={this.handleDrop} onClick={this.handleUploadClick}>
          <div className={'mnc-file-drop-zone-placeholder' + dragClass}>{MC.iconize(field, placeholder)}</div>
          <input key="input" ref={this.inputRef} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
        </div>)
    } else {
      return (
        <div>         
          <label htmlFor={field.id+iterationId} key="button" className={MC.classes("upload-button ui button", {"disabled": innerDisabled || this.props.readOnly})}>{MC.iconize(field, buttonTitle)}</label>
          <input key="input" ref={this.inputRef} data-widget-i-name={field.id} id={field.id+iterationId} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
          <span key="span" className="upload-file-name">{placeholder}</span>
        </div>
      )
    }
  }

}

export {Upload}