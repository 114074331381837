export const addEmptyRbsId = value => {
  if (Array.isArray(value)) {
    return value.map(item => addEmptyRbsId(item))
  }
  if (value instanceof Object) {
    if (typeof value['rbs:ref'] !== 'undefined') {
      return value
    } else {
      const result = {}
      Object.keys(value).map(key => {
        result[key] = addEmptyRbsId(value[key])
      })
      if (typeof result['rbs:id'] === 'undefined') {
        result['rbs:id'] = ''
      }
      return result
    }
  }
  return value
}