import React from "react"

import {MC} from './MC.js'
import {Widget} from "./Widget.jsx"

class Repeater extends React.Component {

  draggedRowIndex = null
  draggedStartIndex = null
  draggedLastIndex = null

  componentDidMount() {
    window.addEventListener('drop', this.dropRow, false)
    document.addEventListener('dragover', this.dragOverRow, false)
  }

  componentWillUnmount() {
    window.removeEventListener('drop', this.dropRow, false)
    document.removeEventListener('dragover', this.dragOverRow, false)
  }

  buildSubFields(subFields, disabled, readOnly, textMode) {
    let resolution = this.props.resolution
    let hrows = null
    if (Array.isArray(subFields)) {
      let rows = MC.splitFieldsIntoRows(subFields, resolution)
      hrows = []
      for (var i = 0; i < rows.length; i++) {
        var hrow = []
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii]
          let offsetDiv
          var grid = MC.getFieldGrid(subField, resolution)
          if (grid.offset > 0) {
            var cls = "ui " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field"
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>
          }
          hrow.push(<Widget key={subField.rbsid} widget={subField} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv} textMode={textMode}/>)
        }
        hrows.push(<div key={i} className="ui row">{hrow}</div>)
      }
    }
    return hrows
  }

  dragStartRow = (i, e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@reorderingRows')) {
      Repeater.draggedComponent = this
      e.stopPropagation()
      this.draggedRowIndex = i
      this.draggedStartIndex = i
    }
  }

  dragOverRow = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterRow = (i) => {
    if (MC.isNull(this.draggedStartIndex)) {
      return
    }
    let rows = this.props.data.fields[0].rows
    if (i != this.draggedRowIndex && i != this.draggedLastIndex) {
      let dRow = rows.splice(this.draggedRowIndex, 1)
      rows.splice(i, 0, dRow[0])
      this.draggedLastIndex = this.draggedRowIndex
      this.draggedRowIndex = i 
      this.forceUpdate()
    } else if (i == this.draggedRowIndex) {
      this.draggedLastIndex = this.draggedRowIndex
    }
  }

  dropRow = () => {
    let rpt = Repeater.draggedComponent
    if (!rpt || MC.isNull(rpt.draggedStartIndex)) {
      return
    }
    Repeater.draggedComponent = undefined
    if (rpt.draggedRowIndex != rpt.draggedStartIndex) {
      MC.ensureIterations(MC.findRootRepeatable(rpt.props.data, rpt.props.data), []) // recalculate iterations
      MC.putFieldParamValue(rpt.props.data.param, '@rowsReordered', true)
      MC.handleEvent(rpt.props.data, 'change')
    }
    this.draggedRowIndex = null
    this.draggedStartIndex = null
    this.draggedLastIndex = null
  }

  render() {
    let field = this.props.data
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    let widgets = []
    if (MC.showAtLeastOneIteration(field) && count == 0) {
      count = 1
    }
    let inline = MC.getFieldParamBooleanValue(this.props.data.param, '@inline')
    let inlineAuto = MC.getFieldParamBooleanValue(this.props.data.param, '@inlineAuto')
    const cls = MC.getFieldParamValue(this.props.data.param, '@cssClass')
    let draggable = MC.getFieldParamBooleanValue(field.param, '@reorderingRows') && !MC.isModelerActive(field)
    for (let i = 0; i < count; i++) {
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let textMode = this.props.textMode
      let clsRow
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          continue
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        if (!MC.isNull(MC.getFieldParamValue(row, '@textmode'))) {
          textMode = MC.getFieldParamBooleanValue(row, '@textmode')
        }
        subfields = field.fields[0].rows[i].fields
        clsRow = MC.getFieldParamValue(row, '@cssClass')
      }
      let resolution = this.props.resolution
      let wideClass = "twelve"
      if (inline) {
        let grid = MC.getFieldGrid(field, resolution)
        wideClass = MC.getFieldWideClassFromInt(grid.columns)
      } else if (inlineAuto) {
        wideClass = "auto-inline"
      }
      let className = MC.classes("ui", wideClass, "wide column", cls, clsRow, {'curgrab': draggable})
      widgets.push(<div key={i} className={className} draggable={draggable} onDragStart={this.dragStartRow.bind(this, i)} onDragEnter={this.dragEnterRow.bind(this, i)}><div className="ui twelve column grid stackable">{this.buildSubFields(subfields, disabled, readOnly, textMode)}</div></div>)
    }
    return (
    <div className="ui twelve column grid stackable" data-widget-i-name={field.id}>
      {widgets}
    </div>)
  }

}

export {Repeater}