import React from 'react'

import {MC} from '../MC.js'

const TRANSITION_TYPE = {ENTERING: 'show', EXITING: 'hide'}
const DIRECTIONAL_TRANSITIONS = ['browse', 'browse right', 'drop', 'fade', 'fade up', 'fade down', 'fade left', 'fade right', 'fly up', 'fly down', 'fly left', 'fly right',
  'horizontal flip', 'vertical flip', 'scale', 'slide up', 'slide down', 'slide left', 'slide right', 'swing up', 'swing down', 'swing left', 'swing right', 'zoom']
const normalizeTransitionDuration = (duration, type) => ((typeof duration === 'number' || typeof duration === 'string') ? duration : duration[type])  

export default class Transition extends React.Component {
 
  static defaultProps = {animation: 'fade', duration: 500, visible: true, mountOnShow: true, transitionOnMount: false, unmountOnHide: false}

  static ENTERED = 'ENTERED'
  static ENTERING = 'ENTERING'
  static EXITED = 'EXITED'
  static EXITING = 'EXITING'
  static UNMOUNTED = 'UNMOUNTED'

  constructor(...args) {
    super(...args)
    const { initial: status, next } = this.computeInitialStatuses()
    this.nextStatus = next
    this.state = { status }
  }

  componentDidMount() {
    this.updateStatus()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      const {current: status, next} = this.computeStatuses(this.props)
      this.nextStatus = next
      if (status) this.setState({status})
    }
    this.updateStatus()
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }

  handleStart = () => {
    const { duration } = this.props
    const status = this.nextStatus
    this.nextStatus = null
    this.setState({ status, animating: true }, () => {
      const durationType = TRANSITION_TYPE[status]
      const durationValue = normalizeTransitionDuration(duration, durationType)
      this.timeoutId = setTimeout(this.handleComplete, durationValue)
    })
  }

  handleComplete = () => {
    if (this.nextStatus) {
      this.handleStart()
      return
    }
    const status = this.computeCompletedStatus()
    this.setState({ status, animating: false })
  }

  updateStatus = () => {
    const { animating } = this.state
    if (this.nextStatus) {
      this.nextStatus = this.computeNextStatus()
      if (!animating) this.handleStart()
    }
  }

  computeClasses = () => {
    const { animation, directional, children } = this.props
    const { animating, status } = this.state
    const childClasses = children.props.className
    const isDirectional = directional == undefined ? DIRECTIONAL_TRANSITIONS.indexOf(animation) > -1 : directional
    if (isDirectional) {
      return MC.classes(animation, childClasses, {'animating': animating, 'in': status === Transition.ENTERING, 'out': status === Transition.EXITING, 'hidden': status === Transition.EXITED, 'visible': status !== Transition.EXITED},
        'transition'
      )
    }
    return MC.classes(animation, childClasses, {'animating transition': animating})
  }

  computeCompletedStatus = () => {
    const { unmountOnHide } = this.props
    const { status } = this.state
    if (status === Transition.ENTERING) return Transition.ENTERED
    return unmountOnHide ? Transition.UNMOUNTED : Transition.EXITED
  }

  computeInitialStatuses = () => {
    const { visible, mountOnShow, transitionOnMount, unmountOnHide } = this.props
    if (visible) {
      if (transitionOnMount) {
        return {
          initial: Transition.EXITED,
          next: Transition.ENTERING,
        }
      }
      return { initial: Transition.ENTERED }
    }
    if (mountOnShow || unmountOnHide) return { initial: Transition.UNMOUNTED }
    return { initial: Transition.EXITED }
  }

  computeNextStatus = () => {
    const { animating, status } = this.state
    if (animating) return status === Transition.ENTERING ? Transition.EXITING : Transition.ENTERING
    return status === Transition.ENTERED ? Transition.EXITING : Transition.ENTERING
  }

  computeStatuses = (props) => {
    const { status } = this.state
    const { visible } = props
    if (visible) {
      return {
        current: status === Transition.UNMOUNTED && Transition.EXITED,
        next:
          status !== Transition.ENTERING && status !== Transition.ENTERED && Transition.ENTERING,
      }
    }
    return {next: (status === Transition.ENTERING || status === Transition.ENTERED) && Transition.EXITING}
  }

  computeStyle = () => {
    const { children, duration } = this.props
    const { status } = this.state
    const childStyle = children.props.style
    const type = TRANSITION_TYPE[status]
    const animationDuration = type && `${normalizeTransitionDuration(duration, type)}ms`
    return { ...childStyle, animationDuration }
  }

  render() {
    const { children } = this.props
    const { status } = this.state
    if (status === Transition.UNMOUNTED) return null
    return React.cloneElement(children, {className: this.computeClasses(), style: this.computeStyle()})
  }
  
}
